import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseRatingsService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  setBaseRatings(baseRating: any) {
    return this.http.post<any>(environment.serverUrl + `save_base_cpp.php`, baseRating);
  }

  importBaseCPP(file: any) {
    const formData = new FormData();
    formData.append('file', file.file);
    return this.http.post<any>(environment.serverUrl + `save_base_cpp.php`, formData);
  }
}
