import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { ClashClass } from 'src/app/classes/clash';
import { SubClashClass } from 'src/app/classes/subclash';
import { SareClass } from 'src/app/classes/sare';

@Component({
  selector: 'app-view-clash',
  templateUrl: './view-clash.component.html',
  styleUrls: ['./view-clash.component.scss'],
})
export class ViewClashComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  channels: SareClass[] = [];
  categorieFormGroup: FormGroup;
  subgenreFormGroup: FormGroup;
  clash: ClashClass = new ClashClass(); 
  subgenre: SubClashClass = new SubClashClass(); 
  subgenre_list: SubClashClass[]; 
  clashId: number = 0;
  category: string ='';

  //Table
  displayedColumns: string[] = ['select', 'category'];
  dataSource: any;
  selection = new SelectionModel<SubClashClass>(true, [], true);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

    this.clashId = parseInt(JSON.parse(localStorage.getItem('clashId')));
    this.onReadOneCategory();
  } 

  ngOnInit() {
   
    this.categorieFormGroup = this.formBuilder.group({ 
      clashCtrl: [this.clash.clash, Validators.required],
      channelNoCtrl: [this.clash.channel, Validators.required],
    });

    this.subgenreFormGroup = this.formBuilder.group({ 
      subgenreCtrl: [this.subgenre.sub_clash, Validators.required],
      categoryCtrl: [this.clash.clash, Validators.required],
    });
    
    this.onChannels();
  }

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: 0,
      date: '',
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
		 this.channels = [];
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected sub-category?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.selection.selected.forEach(genre => {          
          this.deleteSubGenre(genre.idNo);
        });
        
        this.selection.clear();
        this.alertService.success('Sub-category successfully deleted')
      }
    }); 
  }

  async deleteSubGenre(idNo)
  {
    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_subclash.php?idNo=${idNo}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadOneCategory();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async saveSubGenre(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/save_subclash.php`,
    {
      sub_clash: this.subgenre.sub_clash,
      clashId: this.clashId

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.subgenreFormGroup.reset();
          this.alertService.success("Sub-category successfully saved");
          this.onReadOneCategory();
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }


  async onReadOneCategory() {

      const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_one_clash.php`,
      {
        clashId: this.clashId,

      }, {responseType: 'json'}).pipe()
      .subscribe(
          (data: any) => {
 
              this.category = data.genre[0].genre;
              this.clash.channel = data.genre[0].channel;
              this.clash.clash = data.genre[0].clash;

              this.subgenre_list = [];
        
              for(var i=0;i<data.subgenries.length;i++)
              {
                  this.subgenre_list[i] = {clashId: data.subgenries[i].clashId,idNo: data.subgenries[i].idNo,sub_clash: data.subgenries[i].sub_clash};
              } 

              this.dataSource = new MatTableDataSource<SubClashClass>(this.subgenre_list);
              this.dataSource.paginator = this.paginator;
          }
      )
  }

  async saveGenre(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/edit_clash.php`,
    {
      clash: this.clash.clash,
      channel: this.clash.channel,
      clashId: this.clashId,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.categorieFormGroup.reset();
          this.alertService.success("Category successfully saved");
          this.router.navigate(['/home/category-list']);
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}