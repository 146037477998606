import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';
import {AdvertiserModel} from '../../models/advertiser.model';

@Injectable({
  providedIn: 'root'
})
export class AdvertiserService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  getAdvertisersFromRemote(queryString): Observable<AdvertiserModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_advertiser.php?broadcasterId=` + this.currentUser.broadcasterId
      + '&search=' + queryString)
      .pipe(map(advertisers => {
        if (advertisers && advertisers.length > 0) {
          const mappedAdvertisers = this.advertiserMapper(advertisers);
          localStorage.setItem(queryString + 'advertisers', JSON.stringify(mappedAdvertisers));
        }
        return this.advertiserMapper(advertisers);
      }));
  }

  getAdvertisersFromLocal(queryString) {
    return JSON.parse(localStorage.getItem(queryString + 'advertisers'));
  }

  getAdvertisers(queryString): Observable<AdvertiserModel[]> {
    const advertisers = this.getAdvertisersFromLocal(queryString);
    if (!advertisers) {

      return this.getAdvertisersFromRemote(queryString);
    }

    return of(advertisers);
  }

  private advertiserMapper(advertiserData): AdvertiserModel[] {
    return advertiserData.map(advertiser => new AdvertiserModel(advertiser.owner, advertiser.owner_code));
  }
}
