export class UserClass{
    pers_code: number;
    atemps: number;
    role_id: number;
    email: String;
    paswd: String;
    bdcr_no: number;
    name: String;
    surname: String;
    phoneNumber: number;
    accountType: String;
    status: String;

    //Access control
    prog_read: boolean = false;
    prog_write: boolean = false;
    prog_delete: boolean = false;

    mat_man_read: boolean = false;
    mat_man_write: boolean = false;
    mat_man_delete: boolean = false;

    schedule_read: boolean = false;
    schedule_write: boolean = false;
    schedule_delete: boolean = false;

    web2app_read: boolean = false;
    web2app_write: boolean = false;
    web2app_delete: boolean = false;

    news_read: boolean = false;
    news_write: boolean = false;
    news_delete: boolean = false;

    content_sales_read: boolean = false;
    content_sales_write: boolean = false;
    content_sales_delete: boolean = false;

    market_place_read: boolean = false;
    market_place_write: boolean = false;
    market_place_delete: boolean = false;

    production_services_read: boolean = false;
    production_services_write: boolean = false;
    production_services_delete: boolean = false;

    content_management_read: boolean = false;
    content_management_write: boolean = false;
    content_management_delete: boolean = false;

    revenue_management_read: boolean = false;
    revenue_management_write: boolean = false;
    revenue_management_delete: boolean = false;

    analytics_read: boolean = false;
    analytics_write: boolean = false;
    analytics_delete: boolean = false;

    project_management_read: boolean = false;
    project_management_write: boolean = false;
    project_management_delete: boolean = false;

    inventory_management_read: boolean = false;
    inventory_management_write: boolean = false;
    inventory_management_delete: boolean = false;

    elearning_read: boolean = false;
    elearning_write: boolean = false;
    elearning_delete: boolean = false;

    hr_read: boolean = false;
    hr_write: boolean = false;
    hr_delete: boolean = false;

    finance_read: boolean = false;
    finance_write: boolean = false;
    finance_delete: boolean = false;
} 