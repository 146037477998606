import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {

  selectedInsight: any;

  constructor(private router: Router) {
  }

  ngOnInit() {
  } 

  redirect(path) {
    this.router.navigate(['home/' + path]);
  }

  selectInsight(insight) {
    this.selectedInsight = insight;
  }
}