import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ChannelService} from '../../services/channel/channel.service';

@Component({
  selector: 'app-channel-percentage-selector',
  templateUrl: './channel-percentage-selector.component.html',
  styleUrls: ['./channel-percentage-selector.component.scss']
})
export class ChannelPercentageSelectorComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() includePercentage: boolean;
  channels: any[];
  channelSelectorCtrl: FormControl = new FormControl('', Validators.required);
  selected = [];

  constructor(private channelService: ChannelService) {
  }

  ngOnInit() {
    this.channelService.getChannelsForBroadcaster().subscribe(channels => {
      this.channels = channels || [];
    });
    this.group.addControl('channel', this.channelSelectorCtrl);
  }

  change(event) { 
    if (event.isUserInput && this.includePercentage) {
      if (!event.source.selected) {
        this.selected.splice(this.selected.indexOf(event.source.value), 1);
        this.group.removeControl(event.source.value.channelCode + 'Ctrl');
      }
      if (event.source.selected) {
        this.selected.push(event.source.value);
        const ctrl = new FormControl(0, Validators.required);
        this.group.addControl(event.source.value.channelCode + 'Ctrl', ctrl);
      }
    }
  }

}
