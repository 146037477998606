import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter
} from '@angular/core';
import { Renderer2 } from '@angular/core';
@Component({
  selector: 'app-frame-app',
  templateUrl: './frame-app.component.html',
  styleUrls: ['./frame-app.component.scss']
})
export class FrameAppComponent implements OnInit {

  @Input()
  url: string;

  @Output()
  iFrameMessageEvent: EventEmitter<any> = new EventEmitter();

  messageListener: () => void;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.messageListener = this.renderer.listen(window, 'message', data => {
      this.iFrameMessageEvent.emit(data);
    });
  }

  ngOnDestroy() {
    if (this.messageListener) {
      this.messageListener();
    }
  }

}
