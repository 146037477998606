import { CommonModule, CurrencyPipe} from '@angular/common';

export class SetupClass{
    months= ['January', 'February', 'March', 'April', 'May', 'June', 'July','August', 'September', 'October', 'November', 'December'];

    chartColors = {
      red: '#FF3B3F', 
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(245, 245, 120)',
      green: 'rgb(48,193,106)',
      blue: '#34ABD7',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',
      black: 'rgb(50, 50, 50)',
      like: '#597387'
    };

    currency = {
      style: "currency",
      currency: "ZAR"
    };
}