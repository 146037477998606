import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AudienceGuaranteedModel} from '../../models/audience-guaranteed.model';
import {FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import * as _moment from 'moment';
import {SponsorshipModel} from '../../models/sponsorship.model';
import {AgencyModel} from '../../models/agency.model';
import {AdvertiserModel} from '../../models/advertiser.model';
import {PackageChannelModel} from '../../models/packageChannel';
import {Location} from '@angular/common';

const moment = _moment;

@Component({
  selector: 'app-audience-guaranteed-details',
  templateUrl: './audience-guaranteed-details.component.html',
  styleUrls: ['./audience-guaranteed-details.component.scss']
})
export class AudienceGuaranteedDetailsComponent implements OnInit {
  @Input() currentModel: AudienceGuaranteedModel;
  @Input() detailsFormGroup: FormGroup;
  @Output() submitDetails = new EventEmitter<AudienceGuaranteedModel>();
  @Output() selectProductModel = new EventEmitter<any>();
  product: any;
  minWeeklyPercentage = 0;
  maxWeeklyPercentage = 0;
  weekNumbers = 0;
  weekArray = [];
  productAdvertiser: any;
  productAgency: any;

  constructor(private _location: Location) {
  }

  ngOnInit() {

    const businessTypeCtrl = new FormControl('CLA', Validators.required);
    this.detailsFormGroup.addControl('businessTypeCtrl', businessTypeCtrl);
  }

  getWeeks() {
    if (this.currentModel.startDate && this.currentModel.endDate) {
      this.weekNumbers = moment(this.currentModel.startDate).diff(moment(this.currentModel.endDate), 'week', true);
      this.weekNumbers = Math.ceil(this.weekNumbers * -1);
      this.weekArray = [];
      if (this.weekNumbers === 0) {
        this.weekArray.push(0);
      }
      for (let i = 0; i < this.weekNumbers; i++) {
        this.weekArray.push(i);
      }
    }
  }

  selectStartDate(startDate) {
    this.currentModel.startDate = moment(startDate).format();
    this.getWeeks();
  }

  selectEndDate(endDate) {
    this.currentModel.endDate = moment(endDate).format();
    this.getWeeks();
  }

  selectProduct(product) {
    this.product = product;
    this.selectProductModel.emit(product);
    this.productAdvertiser = new AdvertiserModel(product.owner, product.ownerId);
    this.currentModel.advertiser = this.productAdvertiser;
    this.productAgency = new AgencyModel(product.payer, product.payer_Id);
    this.currentModel.agency = this.productAgency;
    this.currentModel.productId = product && product.productId;
  }

  selectDuration(duration) {
    this.currentModel.duration = duration; 
  }

  selectAdvertiser(advertiser) {
    this.currentModel.advertiser = advertiser;
  }

  selectAgency(agency) {
    this.currentModel.agency = agency;
  }

  selectTarget(target) {
    this.currentModel.targetId = target.targetId;
  }

  selectTradingModel(model) {
    this.removeControl();
    this.setFieldsForModel(model);
  }

  // TODO: remove magic strings
  setFieldsForModel(tradingModel) {
    if (tradingModel === 'Fixed rating') {
      const ratingCtrl = new FormControl(0, Validators.required);
      this.detailsFormGroup.addControl('ratingCtrl', ratingCtrl);
    }
    if (tradingModel === 'Fixed CPP') {
      const CPPCtrl = new FormControl(0, Validators.required);
      this.detailsFormGroup.addControl('CPPCtrl', CPPCtrl);
    }
    if (tradingModel === 'Fixed CPT') {
      const CPPCtrl = new FormControl(0, Validators.required);
      this.detailsFormGroup.addControl('CPTCtrl', CPPCtrl);
    }
    if (tradingModel === 'CPM') {
      const CPMCtrl = new FormControl(0, Validators.required);
      this.detailsFormGroup.addControl('CPMCtrl', CPMCtrl);
    }
    if (tradingModel === 'Fixed spots') {
      const numberOfSpotsCtrl = new FormControl(0, Validators.required);
      this.detailsFormGroup.addControl('NumberOfSpotsCtrl', numberOfSpotsCtrl);
      if (this.detailsFormGroup.get('target')) {
        this.detailsFormGroup.removeControl('target');
      }
    }
    if (tradingModel === 'Added value') {
      const numberOfSpotsCtrl = new FormControl(0, Validators.required);
      this.detailsFormGroup.addControl('AddedValueCtrl', numberOfSpotsCtrl);
      if (this.detailsFormGroup.get('target')) {
        this.detailsFormGroup.removeControl('target');
      }
    }

    this.currentModel.tradingModel = tradingModel;

  }

  removeControl() {
    if (this.currentModel.tradingModel === 'Fixed rating') {
      this.detailsFormGroup.removeControl('ratingCtrl');
    }
    if (this.currentModel.tradingModel === 'Fixed CPP') {
      this.detailsFormGroup.removeControl('CPPCtrl');
    }
    if (this.currentModel.tradingModel === 'Fixed CPT') {
      this.detailsFormGroup.removeControl('CPTCtrl');
    }
    if (this.currentModel.tradingModel === 'CPM') {
      this.detailsFormGroup.removeControl('CPMCtrl');
    }
    if (this.currentModel.tradingModel === 'Fixed spots') {
      this.detailsFormGroup.removeControl('NumberOfSpotsCtrl');
    }
    if (this.currentModel.tradingModel === 'Added value') {
      this.detailsFormGroup.removeControl('AddedValueCtrl');
    }
  }

  setTradingValues() {
    if (this.currentModel.tradingModel === 'Fixed rating') {
      this.currentModel.tradingModelValues = {
        budget: this.detailsFormGroup.controls['budgetCtrl'].value,
        rating: this.detailsFormGroup.controls['ratingCtrl'].value,
        CPP: this.detailsFormGroup.controls['budgetCtrl'].value / this.detailsFormGroup.controls['ratingCtrl'].value
      };
    }
    if (this.currentModel.tradingModel === 'Fixed CPP') {
      this.currentModel.tradingModelValues = {
        budget: this.detailsFormGroup.controls['budgetCtrl'].value,
        CPP: this.detailsFormGroup.controls['CPPCtrl'].value,
        rating: this.detailsFormGroup.controls['budgetCtrl'].value / this.detailsFormGroup.controls['CPPCtrl'].value
      };
    }
    if (this.currentModel.tradingModel === 'Fixed CPT') {
      this.currentModel.tradingModelValues = {
        budget: this.detailsFormGroup.controls['budgetCtrl'].value,
        CPT: this.detailsFormGroup.controls['CPTCtrl'].value,
        Impacts: this.detailsFormGroup.controls['budgetCtrl'].value / this.detailsFormGroup.controls['CPTCtrl'].value
      };
    }
    if (this.currentModel.tradingModel === 'CPM') {
      this.currentModel.tradingModelValues = {
        budget: this.detailsFormGroup.controls['budgetCtrl'].value,
        CPM: this.detailsFormGroup.controls['CPMCtrl'].value,
        minutes: this.detailsFormGroup.controls['budgetCtrl'].value / this.detailsFormGroup.controls['CPMCtrl'].value
      };
    }
    if (this.currentModel.tradingModel === 'Fixed spots') {
      this.currentModel.tradingModelValues = {
        budget: this.detailsFormGroup.controls['budgetCtrl'].value,
        numberOfSpots: this.detailsFormGroup.controls['NumberOfSpotsCtrl'].value,
        costPerSpot: this.detailsFormGroup.controls['budgetCtrl'].value / this.detailsFormGroup.controls['NumberOfSpotsCtrl'].value
      };
    }
    if (this.currentModel.tradingModel === 'Added value') {
      this.currentModel.tradingModelValues = {
        budget: this.detailsFormGroup.controls['budgetCtrl'].value,
        addedValue: this.detailsFormGroup.controls['AddedValueCtrl'].value,
        exposureValue: this.detailsFormGroup.controls['budgetCtrl'].value + this.detailsFormGroup.controls['AddedValueCtrl'].value
      };
    }
    // this.submitModel.emit(this.currentModel);
  }

  submitModel() {
    this.currentModel.business_type = this.detailsFormGroup.controls['businessTypeCtrl'].value;
    this.currentModel.channels = this.detailsFormGroup.controls['channel'].value;
    const packageChannelModels = this.detailsFormGroup.controls['channel'].value.map((v, i) =>
      v ? new PackageChannelModel(this.detailsFormGroup.controls['channel'].value[i].channelName,
        this.detailsFormGroup.controls['channel'].value[i].channelId, this.detailsFormGroup.controls[v.channelCode + 'Ctrl'].value) : null)
      .filter(v => v !== null);
    this.currentModel.packageChannelModels = packageChannelModels;
    this.setTradingValues();
    this.currentModel.numberOfWeeks = this.weekArray;

    this.submitDetails.emit(this.currentModel);
  }

  back(){
    this._location.back();
  }
}
