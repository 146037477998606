import {Component, OnInit} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-minimum-slots',
  templateUrl: './minimum-slots.component.html',
  styleUrls: ['./minimum-slots.component.scss']
})
export class MinimumSlotsComponent implements OnInit {
  rateSettingModel: RateSettingModel;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  minimumFormDialogGroup: FormGroup;
  rateSettingMonth: any;
  displayedColumns: string[] = ['month', 'randValue', 'mondayRandValue', 'tuesdayRandValue', 'wednesdayRandValue', 'thursdayRandValue',
    'fridayRandValue', 'saturdayRandValue', 'sundayRandValue', 'keySlotTargets'];


  constructor(private rateSettingActionsService: RateSettingActionsService, private _formBuilder: FormBuilder, private router: Router) {
    this.minimumFormDialogGroup = this._formBuilder.group({
      sellout: [0, <any>Validators.required],
      lessDiscount: [0, <any>Validators.required],
      advMinPerHour: [0, <any>Validators.required],
    });
  }

  ngOnInit() {
    const data = this.rateSettingActionsService.getRateSettingMon();
    if (data) {
      this.rateSettingMonth = data;
      this.rateSettingModel = data.rateSettingModel;
    }
  }

  matchResultToModel(result) {
    if (result) {
      this.rateSettingModel.months.forEach(mnth => {
        if (mnth.month === result.month.month) {
          mnth = result;
        }
      });
    }
  }

  saveChanges() {
    if (this.minimumFormDialogGroup.valid) {
      this.daysOfWeek.forEach(day => {
        this.rateSettingMonth.month[day.toLowerCase() + 'KeySlots'].forEach(keySlot => {
          keySlot.minNetRatePerSlot = keySlot.revTarget / (this.minimumFormDialogGroup.controls['sellout'].value / 100) /
            (this.minimumFormDialogGroup.controls['advMinPerHour'].value * 2);
          keySlot.minGrossPerSlot = keySlot.minNetRatePerSlot / (this.minimumFormDialogGroup.controls['lessDiscount'].value / 100);
        });
      });
      this.matchResultToModel(this.rateSettingMonth);
      this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
      this.router.navigate(['home/createRateSettingTool/keySlotTargets']);
    }
  }
}
