import {Injectable} from '@angular/core';
import {BreakTypeModel} from '../../models/break-type.model';

@Injectable({
  providedIn: 'root'
})
export class BreakTypeService {

  constructor() {
  }

  getBreakTypes(): BreakTypeModel[] {
    return [new BreakTypeModel(1, 'CA', 'Classic Advertising'),
      new BreakTypeModel(2, 'CB', 'Closing Billboard'),
      new BreakTypeModel(3, 'CS', 'Closing Sting'),
      new BreakTypeModel(4, 'CO', 'Competition'),
      new BreakTypeModel(5, 'IF', 'Infomercial'),
      new BreakTypeModel(6, 'IV', 'Interview'),
      new BreakTypeModel(7, 'LR', 'Live Read'),
      new BreakTypeModel(8, 'CL', 'News Clock'),
      new BreakTypeModel(9, 'OB', 'Opening Billboard'),
      new BreakTypeModel(10, 'OS', 'Opening Sting'),
      new BreakTypeModel(11, 'OV', 'Outside Van (broadcast)'),
      new BreakTypeModel(12, 'PP', 'Prefpromo'),
      new BreakTypeModel(13, 'RB', 'Ribbon'),
      new BreakTypeModel(14, 'SC', 'Screen Credit Squeezeback'),
      new BreakTypeModel(15, 'SO', 'Solus break'),
      new BreakTypeModel(16, 'SB', 'Special Bulletin'),
      new BreakTypeModel(17, 'SP', 'Sponsorship Insert'),
      new BreakTypeModel(18, 'SQ', 'Squeezeback'),
      new BreakTypeModel(19, 'PR', 'Station Promo'),
      new BreakTypeModel(20, 'ST', 'Sting'),
    ];
  }
}
