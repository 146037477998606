import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {SpotSummaryModel} from '../../models/spot-summary.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpotSummaryService {
  currentUser: User;
  mockSummariesData: any[] = [{
    early_S_spots: 10,
    early_s_cost: 0,
    prime_s_spots: 2,
    prime_s_cost: 0,
    late_s_spots: 8,
    late_s_cost: 0,
    channel: 'LIFE SKILLS TV',
    channel_spots: 20,
    channel_cost: 0
  },
    {
      early_S_spots: 0,
      early_s_cost: 0,
      prime_s_spots: 12,
      prime_s_cost: 0,
      late_s_spots: 8,
      late_s_cost: 0,
      channel: 'DOT NET',
      channel_spots: 20,
      channel_cost: 0
    }
  ];

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  getSpotSummariesForCampaignIdFromRemote(campaignId): Observable<SpotSummaryModel[]> {
    return this.http.get<any>(environment.serverUrl + `get_summery.php?camp_no=` + campaignId)
      .pipe(map(spotSummaries => {
        if (spotSummaries && spotSummaries.length > 0) {

          localStorage.setItem(this.setKeyForBroadcaster(campaignId), JSON.stringify(this.spotSummaryMapper(spotSummaries)));
        }
        return this.spotSummaryMapper(spotSummaries);
      }));
  }

  setKeyForBroadcaster(campaignId) {
    return 'spotSummaries-' + campaignId;
  }

  getSpotSummariesForCampaignIdFromLocal(campaignId) {
    return JSON.parse(localStorage.getItem(this.setKeyForBroadcaster(campaignId)));
  }

  getSpotSummariesForCampaignId(campaignId): Observable<SpotSummaryModel[]> {
    const spotSummaries = this.getSpotSummariesForCampaignIdFromLocal(campaignId);
    if (!spotSummaries) {
      // spotSummaries = this.spotSummaryMapper(this.mockSummariesData);
      // localStorage.setItem(this.setKeyForBroadcaster(campaignId), JSON.stringify(spotSummaries));

      return this.getSpotSummariesForCampaignIdFromRemote(campaignId);
    }

    return of(spotSummaries);
  }

  private spotSummaryMapper(mockSummariesData): SpotSummaryModel[] {
    return mockSummariesData.map(spotSummary => new SpotSummaryModel(spotSummary.early_S_spots, spotSummary.early_s_cost,
      spotSummary.prime_s_spots, spotSummary.prime_s_cost, spotSummary.late_s_spots, spotSummary.late_s_cost, spotSummary.channel,
      spotSummary.channel_spots, spotSummary.channel_cost));
  }
}
