import {CampaignSpotModel} from './campaign-spot.model';
import {SponsorshipSpotModel} from './sponsorship-spot.model';

export class FlightingCodeModel {
  flightingCode: string;
  spots: CampaignSpotModel [];
  sponsorshipSpots: SponsorshipSpotModel[];
  productId: number;

  constructor() {
    this.spots = [];
    this.sponsorshipSpots = [];
  }
}
 