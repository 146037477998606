export class ContractClass{
    idNo: number;
    exhibition: number;
    re_screening: number;
    prog_no: number;
    contract_no: String;
    sst_date: String;
    end_date: String;
    cost: number;
    seas_no: number;
    no_of_runs: number;
    contract_type: String;
    prod_partner: String;
} 