import {AfterViewInit, Component, OnInit} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {TopDemographicsService} from '../../services/top-demographics/top-demographics.service';
import {FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {Router} from '@angular/router';
import {Chart} from 'chart.js';
import {EditInventoryDialogComponent} from '../edit-inventory-dialog/edit-inventory-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-day-part-targets',
  templateUrl: './day-part-targets.component.html',
  styleUrls: ['./day-part-targets.component.scss']
})
export class DayPartTargetsComponent implements OnInit, AfterViewInit {
  dayPartFormGroup: FormGroup;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  rateSettingMonth: any;
  rateSettingModel: RateSettingModel;
  ratingsPerHour = [];
  ratingsPerHourChart = [];
  topDemographic = '';
  topDemographicChart = '';
  timeSlotsChart = [];
  timeSlots = [];
  daySlotTotals = [];
  chart: any;
  displayedColumns = ['StartTime', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  columnsToDisplay = this.displayedColumns;
  colours = ['#6917c2', '#C71585', '#F09423', '#043BC4', '#240B58', '#0090C1', '#69140E'];

  constructor(private rateSettingService: RateSettingService, private rateSettingActionsService: RateSettingActionsService,
              private topDemographicsService: TopDemographicsService, private _formBuilder: FormBuilder, private router: Router,
              public dialog: MatDialog) {
  }

  ngOnInit() {

    const data = this.rateSettingActionsService.getRateSettingMon();
    this.dayPartFormGroup = this._formBuilder.group({
      dummy: [0]
    });
    if (data) {
      this.rateSettingMonth = data.month;
      this.rateSettingModel = data.rateSettingModel;

      this.topDemographicsService.getTop5Demographics(this.rateSettingModel.channelId).subscribe(demoG => {
        this.ratingsPerHourChart = demoG[0].dataset;
        this.topDemographicChart = demoG[0].name;

        this.timeSlotsChart = this.ratingsPerHourChart.filter(rating => {
          return rating.day === this.daysOfWeek[0];
        }).map(rat => {
          return rat.start_time;
        });

        if (!this.chart) {
          this.createChart();
        }
      });
    }

    this.topDemographicsService.getTopDemographicsTable(this.rateSettingModel.channelId).subscribe(demoG => {
      this.ratingsPerHour = demoG[0].dataset;
      this.topDemographic = demoG[0].name;

      this.timeSlots = this.ratingsPerHour.filter(rating => {
        return rating.day === this.daysOfWeek[0];
      }).map(rat => {
        return rat.start_time;
      });
      
      this.setFormData();

    });
  }

  ngAfterViewInit() {
    if (this.ratingsPerHourChart.length > 0) {
      this.createChart();
    }
  }

  minDaySlotPercentage(min = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      this.daysOfWeek.forEach(day => {
        let totalSelected = 0;
        this.timeSlots.forEach(slot => {
          if (this.dayPartFormGroup.get(day + '-' + slot)) {
            totalSelected += formGroup.controls[day + '-' + slot].value;
          }
        });
        this.daySlotTotals.forEach(dayTotal => {
          if (dayTotal.day === day) {
            dayTotal.total = totalSelected;
          }
        });
      });
      return this.roundOffNumber(100) >= min ? null : {minDaySlotPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  createChart() {
    const dataSet = this.daysOfWeek.map((day, index) => {
      return {
        data: this.ratingsPerHourChart.filter(rating => {
          return rating.day === day;
        }).map(data => data && data.rating || 0), label: day, fill: false, borderColor: this.colours[index]
      };
    });
    const labels = this.timeSlotsChart.map(hour => {
      return hour;
    });
    const canvas = <HTMLCanvasElement>document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: dataSet
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            display: true
          }],
        }
      }
    });
  }


  setFormData() {
    this.daysOfWeek.forEach(day => {
      this.daySlotTotals.push({
        day: day,
        total: 0
      });
      this.timeSlots.forEach(slot => {
        this.dayPartFormGroup.addControl(day + '-' + slot, new FormControl(this.getRating(day, slot)));
      });
    });
    this.dayPartFormGroup.setValidators([this.minDaySlotPercentage()]);
  }

  getRating(day, slot) {
    const rate = this.ratingsPerHour.find(rating => {
      return rating.start_time === slot && rating.day === day;
    });
    return rate && rate.rating || 0;
  }

  editYields() {
    const dialogRef = this.dialog.open(EditInventoryDialogComponent, {
      width: '600px',
      data: this.rateSettingMonth
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.rateSettingMonth.addedValue = result.addedValue / 100;
        this.rateSettingMonth.sellout = result.sellout / 100;
        this.rateSettingMonth.advMinPerHour = result.advMinPerHour;
        this.rateSettingMonth.lessDiscount = result.lessDiscount / 100;
        this.rateSettingMonth.compensation = result.compensation / 100;
        this.rateSettingMonth.volumeDiscount = result.volumeDiscount / 100;
      }
    });
  }

  updateTargets() {
    const postTargets = {
      rateSettingId: this.rateSettingModel.rateSettingId,
      month: this.rateSettingMonth.month,
      dayPartTargets: []
    };
    this.daysOfWeek.forEach(day => {
      const ratingsPerTimeSlot = [];
      this.timeSlots.forEach(slot => {
        ratingsPerTimeSlot.push({
          timeSlot: slot,
          rating: this.dayPartFormGroup.controls[day + '-' + slot].value
        });
      });
      postTargets.dayPartTargets.push({
        day: day,
        ratingsPerTimeSlot: ratingsPerTimeSlot
      });
    });
    this.navigateBack();
  }

  navigateBack() {
    this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
    this.router.navigate(['home/viewTargets']);
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }
}
