export class BreakTypeModel {
  breakTypeId: number;
  breakTypeCode: string;
  breakTypeDescription: string;

  constructor(breakTypeId = 0, breakTypeCode = '', breakTypeDescription = '') {
    this.breakTypeId = breakTypeId;
    this.breakTypeCode = breakTypeCode;
    this.breakTypeDescription = breakTypeDescription;
  }
}
