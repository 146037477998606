import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { ProgrammeClass } from "./programme";

export class WebSlideShowClass{
    idNo: number;
    programe_name: String;
    programme_no: any;
    channel: string;
    status: string;
    myControl: FormControl;
    filteredOptions: Observable<ProgrammeClass[]>;
}