import { Component, OnInit } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-invoice-pdf',
  templateUrl: './invoice-pdf.component.html',
  styleUrls: ['./invoice-pdf.component.scss'],
})
export class InvoicePdfComponent implements OnInit {

  database_name: string = '';
  employee_no: number = 0;
  campaignNo: number = 0;
  safeUrl;

  constructor(route:ActivatedRoute,private sanitizer: DomSanitizer,private router: Router) { 
    this.database_name = JSON.parse(localStorage.getItem('database_name'));
    this.campaignNo = parseInt(JSON.parse(localStorage.getItem('campaignNo')));
    
    this.sanitizer = sanitizer;
  }

  ngOnInit() {
    this.getTrustedUrl('https://inxprime.mmsafrica.co.za/api/sales/invoice_pdf.php?campaignNo='+this.campaignNo+'&database_name='+this.database_name);
   }

   getTrustedUrl(url:any){ 
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
   }
}