import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IntegratedTradingModel} from '../../models/integrated-trading.model';
import {IntegratedTradingService} from '../../services/integrated-trading/integrated-trading.service';
import {CampaignService} from '../../services/campaign/campaign.service';
import {Router} from '@angular/router';
import {CampaignSpotService} from '../../services/campaignSpot/campaign-spot.service';

@Component({
  selector: 'app-create-rate-card',
  templateUrl: './create-rate-card.component.html',
  styleUrls: ['./create-rate-card.component.scss']
})
export class CreateRateCardComponent implements OnInit {
  integratedTradingDetailsFormGroup: FormGroup;
  integratedTradingModel: IntegratedTradingModel;
  program: any;
  product: any;
  success = false;
  error = false;

  constructor(private _formBuilder: FormBuilder, private integratedTradingService: IntegratedTradingService,
              private campaignService: CampaignService, private router: Router, private campaignSpotService: CampaignSpotService) {
  }

  ngOnInit() {
    this.integratedTradingModel = new IntegratedTradingModel();
    this.integratedTradingDetailsFormGroup = this._formBuilder.group({
      discountPercentage: [0, [
        Validators.required,
        <any>Validators.pattern(/^-?(0|[1-9]\d*)?$/)]], netBudgetCtrl: [0, [
        Validators.required,
        <any>Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      uploadCSV: ['']
    });
  }

  selectProgram(program) {
    this.program = program;
  }

  selectProduct(product) {
    this.product = product;
  }

  submitRateCardDetails(integratedTradingModel) {
    this.integratedTradingModel = integratedTradingModel;
    this.integratedTradingService.postSpots(integratedTradingModel).subscribe(data => {
      this.integratedTradingModel = data;
      this.campaignService.getCampaignsFromRemote().subscribe(campaigns => {

      });
      this.campaignSpotService.getCampaignSpotsFromRemote().subscribe(spots => {

      });
      this.success = true;
      
      
    }, error => {
      this.error = true;
    });
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

}
