import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {BreakTypeModel} from '../../models/break-type.model';
import {map, startWith} from 'rxjs/operators';
import {BreakTypeService} from '../../services/break-type/break-type.service';

@Component({
  selector: 'app-break-type-selector',
  templateUrl: './break-type-selector.component.html',
  styleUrls: ['./break-type-selector.component.scss']
})
export class BreakTypeSelectorComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Output() SelectedItem = new EventEmitter<BreakTypeModel>();
  breakTypes: any[];
  breakTypeCtrl: FormControl = new FormControl('', Validators.required);
  filteredBreakTypes: Observable<BreakTypeModel[]>;

  constructor(private breakTypeService: BreakTypeService) {
  }

  ngOnInit() {
    this.breakTypes = this.breakTypeService.getBreakTypes();
    this.filteredBreakTypes = this.breakTypeCtrl.valueChanges
      .pipe(
        startWith(''),
        map(breakType => breakType ? this._filterBreakTypes(breakType) : this.breakTypes.slice())
      );
    this.formGroup.addControl('breakType', this.breakTypeCtrl);
  }

  private _filterBreakTypes(value: string): BreakTypeModel[] {
    const filterValue = value.toLowerCase();

    return this.breakTypes.filter(breakType => breakType.breakTypeName.toLowerCase().indexOf(filterValue) > -1);
  }

  onSelect(breakType) {
    this.SelectedItem.emit(breakType);
  }

}
