import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  importSchedule(schedule: any) {
    const formData = new FormData();
    formData.append('channelId', schedule.channel.channelId);
    formData.append('file', schedule.file);
    return this.http.post<any>(environment.serverUrl + `import_programmes.php`, formData);

  }

}
