import {PackageChannelModel} from './packageChannel';
import {ChannelModel} from './channel.model';

export class AudienceGuaranteedModel {
  campaignId: number;
  spots: number;
  totalCost: number;
  targetId: number;
  tradingModel: string;
  productId: number;
  broadcasterId: number;
  userId: number;
  duration: number;
  business_type: string;
  startDate: string;
  endDate: string;
  channels: ChannelModel[];
  tradingModelValues: any;
  packageChannelModels: PackageChannelModel[];
  advertiser: any;
  agency: any;
  numberOfWeeks: number[];

  constructor() {
    this.channels = [];
    this.packageChannelModels = [];
    this.numberOfWeeks = [];
  }
}
