import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { GenreClass } from 'src/app/classes/genre';
import { SubGenreClass } from 'src/app/classes/subgenre';
import { Timechannel } from 'src/app/classes/timechannel';
import { SareClass } from 'src/app/classes/sare';

@Component({
  selector: 'app-time-channels',
  templateUrl: './time-channels.component.html',
  styleUrls: ['./time-channels.component.scss'],
})
export class TimeChannelsComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  channels: SareClass[] = [];
  timechannelFormGroup: FormGroup;

  newTimechannel: Timechannel = new Timechannel(); 
  monday_timechannel: Timechannel[]; 
  tuesday_timechannel: Timechannel[]; 
  wednesday_timechannel: Timechannel[]; 
  thursday_timechannel: Timechannel[]; 
  friday_timechannel: Timechannel[]; 
  saturday_timechannel: Timechannel[]; 
  sunday_timechannel: Timechannel[]; 
  selectedDays: string[] = [];

  //Table
  displayedColumns: string[] = ['select', 'day','timechannel','color'];
  dayIndex: number = 0;
  
  //Monday
  dataSource: any;
  selection = new SelectionModel<Timechannel>(true, [], true);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  //Tuesday
  dataSource_tue: any;
  selection_tue = new SelectionModel<Timechannel>(true, [], true);
  @ViewChild(MatPaginator) paginator_tue: MatPaginator;

  //Wednesday
  dataSource_wed: any;
  selection_wed = new SelectionModel<Timechannel>(true, [], true);
  @ViewChild(MatPaginator) paginator_wed: MatPaginator;

  //Thursday
  dataSource_thu: any;
  selection_thu = new SelectionModel<Timechannel>(true, [], true);
  @ViewChild(MatPaginator) paginator_thu: MatPaginator;

  //Friday
  dataSource_fri: any;
  selection_fri = new SelectionModel<Timechannel>(true, [], true);
  @ViewChild(MatPaginator) paginator_fri: MatPaginator;

  //Saturday
  dataSource_sat: any;
  selection_sat = new SelectionModel<Timechannel>(true, [], true);
  @ViewChild(MatPaginator) paginator_sat: MatPaginator;

  //Sunday
  dataSource_sun: any;
  selection_sun = new SelectionModel<Timechannel>(true, [], true);
  @ViewChild(MatPaginator) paginator_sun: MatPaginator;


  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

    this.newTimechannel.channel = 1;
    this.newTimechannel.color = '#142FB8';

    this.onReadTimechannels();
  } 


  ngOnInit() {
   
    this.timechannelFormGroup = this.formBuilder.group({ 
      channelCtrl: [this.newTimechannel.channel, Validators.required],
      start_timeCtrl: [this.newTimechannel.start_time, Validators.required],
      end_timeCtrl: [this.newTimechannel.end_time, Validators.required],
      dayCtrl: [this.selectedDays, Validators.required],
      colorCtrl: [this.newTimechannel.color, Validators.required],
    });

    this.onChannels();
  }

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: 0,
      date: '',
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
		 this.channels = [];
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  changeColor(event,idNo){
    console.log(event);
    console.log(idNo);
  }

  onTabChanged(event){

    let clickedIndex = event.index;
    this.dayIndex = clickedIndex;
  }

  isAllSelected() {

    let numSelected = this.selection.selected.length;
    let numRows = this.dataSource.data.length;

    if(this.dayIndex == 1){
       numSelected = this.selection_tue.selected.length;
       numRows = this.dataSource_tue.data.length;
    }
    else
    if(this.dayIndex == 2){
        numSelected = this.selection_wed.selected.length;
        numRows = this.dataSource_wed.data.length;
    }
    else
    if(this.dayIndex == 3){
        numSelected = this.selection_thu.selected.length;
        numRows = this.dataSource_thu.data.length;
    }
    else
    if(this.dayIndex == 4){
        numSelected = this.selection_fri.selected.length;
        numRows = this.dataSource_fri.data.length;
    }
    else
    if(this.dayIndex == 5){
        numSelected = this.selection_sat.selected.length;
        numRows = this.dataSource_sat.data.length;
    }
    else
    if(this.dayIndex == 6){
        numSelected = this.selection_sun.selected.length;
        numRows = this.dataSource_sun.data.length;
    }
    
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {

      if(this.dayIndex == 0){
        this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
      }
      else
      if(this.dayIndex == 1){
        this.isAllSelected() ?
        this.selection_tue.clear() :
        this.dataSource_tue.data.forEach(row => this.selection_tue.select(row));
      }
      else
      if(this.dayIndex == 2){
        this.isAllSelected() ?
        this.selection_wed.clear() :
        this.dataSource_wed.data.forEach(row => this.selection_wed.select(row));
      }
      else
      if(this.dayIndex == 3){
        this.isAllSelected() ?
        this.selection_thu.clear() :
        this.dataSource_thu.data.forEach(row => this.selection_thu.select(row));
      }
      else
      if(this.dayIndex == 4){
        this.isAllSelected() ?
        this.selection_fri.clear() :
        this.dataSource_fri.data.forEach(row => this.selection_fri.select(row));
      }
      else
      if(this.dayIndex == 5){
        this.isAllSelected() ?
        this.selection_sat.clear() :
        this.dataSource_sat.data.forEach(row => this.selection_sat.select(row));
      }
      else
      if(this.dayIndex == 6){
        this.isAllSelected() ?
        this.selection_sun.clear() :
        this.dataSource_sun.data.forEach(row => this.selection_sun.select(row));
      }
  }


  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected time channel?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        if(this.dayIndex == 0){
          this.selection.selected.forEach(timechannel => {          
            this.deleteTimechannel(timechannel.idNo);
          });
          this.selection.clear();
        }
        else
        if(this.dayIndex == 1){
          this.selection_tue.selected.forEach(timechannel => {          
            this.deleteTimechannel(timechannel.idNo);
          });
          this.selection_tue.clear();
        }
        else
        if(this.dayIndex == 2){
          this.selection_wed.selected.forEach(timechannel => {          
            this.deleteTimechannel(timechannel.idNo);
          });
          this.selection_wed.clear();
        }
        else
        if(this.dayIndex == 3){
          this.selection_thu.selected.forEach(timechannel => {          
            this.deleteTimechannel(timechannel.idNo);
          });
          this.selection_thu.clear();
        }
        else
        if(this.dayIndex == 4){
          this.selection_fri.selected.forEach(timechannel => {          
            this.deleteTimechannel(timechannel.idNo);
          });
          this.selection_fri.clear();
        }
        else
        if(this.dayIndex == 5){
          this.selection_sat.selected.forEach(timechannel => {          
            this.deleteTimechannel(timechannel.idNo);
          });
          this.selection_sat.clear();
        }
        else
        if(this.dayIndex == 6){
          this.selection_sun.selected.forEach(timechannel => {          
            this.deleteTimechannel(timechannel.idNo);
          });
          this.selection_sun.clear();
        }

        this.alertService.success('Time channel successfully deleted')
      }
    });
  }

  async deleteTimechannel(idNo)
  {

    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_timechannel.php?idNo=${idNo}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadTimechannels();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async saveTimesheet(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/save_timechannel.php`,
    {
      channel: this.newTimechannel.channel,
      start_time: this.newTimechannel.start_time,
      end_time: this.newTimechannel.end_time,
      color: this.newTimechannel.color,
      selectedDays: this.selectedDays,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          //this.timechannelFormGroup.reset();
          this.alertService.success("Time channel successfully saved");
          this.onReadTimechannels();
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }


  async onReadTimechannels() {

      const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_timechannels.php`,
      {
        channel: this.newTimechannel.channel,

      }, {responseType: 'json'}).pipe()
      .subscribe(
          (data: any) => {
 
              this.monday_timechannel = [];
              this.tuesday_timechannel = [];
              this.wednesday_timechannel = [];
              this.thursday_timechannel = [];
              this.friday_timechannel = [];
              this.saturday_timechannel = [];
              this.sunday_timechannel = [];
        
              for(var i=0;i<data.monday.length;i++)
              {
                  this.monday_timechannel[i] = {idNo: data.monday[i].idNo,channel: data.monday[i].channel,day: data.monday[i].day,end_time: data.monday[i].end_time,start_time: data.monday[i].start_time,color: data.monday[i].color};
              } 

              for(var i=0;i<data.tuesday.length;i++)
              {
                  this.tuesday_timechannel[i] = {idNo: data.tuesday[i].idNo,channel: data.tuesday[i].channel,day: data.tuesday[i].day,end_time: data.tuesday[i].end_time,start_time: data.tuesday[i].start_time,color: data.tuesday[i].color};
              } 

              for(var i=0;i<data.wednesday.length;i++)
              {
                  this.wednesday_timechannel[i] = {idNo: data.wednesday[i].idNo,channel: data.wednesday[i].channel,day: data.wednesday[i].day,end_time: data.wednesday[i].end_time,start_time: data.wednesday[i].start_time,color: data.wednesday[i].color};
              } 

              for(var i=0;i<data.thursday.length;i++)
              {
                  this.thursday_timechannel[i] = {idNo: data.thursday[i].idNo,channel: data.thursday[i].channel,day: data.thursday[i].day,end_time: data.thursday[i].end_time,start_time: data.thursday[i].start_time,color: data.thursday[i].color};
              } 

              for(var i=0;i<data.friday.length;i++)
              {
                  this.friday_timechannel[i] = {idNo: data.friday[i].idNo,channel: data.friday[i].channel,day: data.friday[i].day,end_time: data.friday[i].end_time,start_time: data.friday[i].start_time,color: data.friday[i].color};
              } 

              for(var i=0;i<data.saturday.length;i++)
              {
                  this.saturday_timechannel[i] = {idNo: data.saturday[i].idNo,channel: data.saturday[i].channel,day: data.saturday[i].day,end_time: data.saturday[i].end_time,start_time: data.saturday[i].start_time,color: data.saturday[i].color};
              } 

              for(var i=0;i<data.sunday.length;i++)
              {
                  this.sunday_timechannel[i] = {idNo: data.sunday[i].idNo,channel: data.sunday[i].channel,day: data.sunday[i].day,end_time: data.sunday[i].end_time,start_time: data.sunday[i].start_time,color: data.sunday[i].color};
              } 

              //Monday
              this.dataSource = new MatTableDataSource<Timechannel>(this.monday_timechannel);
              this.dataSource.paginator = this.paginator;

              //Tuesday
              this.dataSource_tue = new MatTableDataSource<Timechannel>(this.tuesday_timechannel);
              this.dataSource_tue.paginator = this.paginator_tue;

              //Wednesday
              this.dataSource_wed = new MatTableDataSource<Timechannel>(this.wednesday_timechannel);
              this.dataSource_wed.paginator = this.paginator_wed;

              //Thursday
              this.dataSource_thu = new MatTableDataSource<Timechannel>(this.thursday_timechannel);
              this.dataSource_thu.paginator = this.paginator_thu;

              //Friday
              this.dataSource_fri = new MatTableDataSource<Timechannel>(this.friday_timechannel);
              this.dataSource_fri.paginator = this.paginator_fri;

              //Saturday
              this.dataSource_sat = new MatTableDataSource<Timechannel>(this.saturday_timechannel);
              this.dataSource_sat.paginator = this.paginator_sat;

              //Sunday
              this.dataSource_sun = new MatTableDataSource<Timechannel>(this.sunday_timechannel);
              this.dataSource_sun.paginator = this.paginator_sun;
          }
      )
  }

  selectColor(color)
  {
    this.newTimechannel.color = color;
  }
 
  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}