import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {IntegratedTradingModel} from '../../models/integrated-trading.model';
import {User} from '../../models/user';
import { AlertService } from '../alert/alert.service';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IntegratedTradingService {
  currentUser: User;

  constructor(private alertService: AlertService,private http: HttpClient, private router: Router) {
  }

  postSpots(integratedTradingModel: IntegratedTradingModel): Observable<any> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    integratedTradingModel.userId = this.currentUser.userId;
    return this.http.post<any>(environment.serverUrl + `book_spots.php`, integratedTradingModel).pipe(map(data => {
      integratedTradingModel.campaignId = data.camp_no;
      integratedTradingModel.spots = data.saved_spots;
      integratedTradingModel.totalCost = data.total_cost;

      if(data.message == 'success'){
        localStorage.removeItem('createdPackage');
        localStorage.setItem('createdPackage', JSON.stringify(integratedTradingModel));

        this.alertService.success('Spots successfully imported !');
        localStorage.setItem('selectedCampaign', JSON.stringify(data.camp_no));
        this.router.navigate(['/home/spotView']);
        return integratedTradingModel;
      }
      else{
        this.alertService.error(data.message);
      }
      
    })); 
  }
}
