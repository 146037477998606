import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AudienceGuaranteedModel} from '../../models/audience-guaranteed.model';

@Component({
  selector: 'app-channel-config-form',
  templateUrl: './channel-config-form.component.html',
  styleUrls: ['./channel-config-form.component.scss']
})
export class ChannelConfigFormComponent implements OnInit {
  @Input() currentModel: AudienceGuaranteedModel;
  @Input() channelConfigFormGroups: FormGroup;
  @Input() shoulderPercentageTotals: any[];
  @Input() dayPercentageTotals: any[];
  @Input() weekChannelPercentageTotals: any[];
  @Input() cardTitle: string;
  @Input() cardIcon: string;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  @Output() submitModel = new EventEmitter<AudienceGuaranteedModel>();

  constructor() {
  }

  ngOnInit() {
  }

  setRemainderShoulders(packageModel) {
    let total = 0;
    const emptyValues = [];
    const shoulders = ['early', 'prime', 'late'];
    shoulders.forEach(shoulder => {
      if (this.channelConfigFormGroups.controls[packageModel.channelId + '-' + shoulder].value === 0) {
        emptyValues.push(shoulder);
      } else {
        total += this.channelConfigFormGroups.controls[packageModel.channelId + '-' + shoulder].value;
      }
    });
    emptyValues.forEach(shoulder => {
      this.channelConfigFormGroups.controls[packageModel.channelId + '-' + shoulder]
        .setValue(this.calculateRemainderWithDecimal(total, emptyValues));
    });
  }

  setRemainderForDays(packageModel) {
    let total = 0;
    const emptyValues = [];
    this.daysOfWeek.forEach(day => {
      if (this.channelConfigFormGroups.controls[packageModel.channelId + '-' + day].value === 0) {
        emptyValues.push(day);
      } else {
        total += this.channelConfigFormGroups.controls[packageModel.channelId + '-' + day].value;
      }
    });

    emptyValues.forEach(day => {
      this.channelConfigFormGroups.controls[packageModel.channelId + '-' + day]
        .setValue(this.calculateRemainderWithDecimal(total, emptyValues));
    });
  }

  setRemainderForWeeks(packageModel) {
    let total = 0;
    const emptyValues = [];
    this.currentModel.numberOfWeeks.forEach(week => {
      if (this.channelConfigFormGroups.controls[packageModel.channelId + '-' + week].value === 0) {
        emptyValues.push(week);
      } else {
        total += this.channelConfigFormGroups.controls[packageModel.channelId + '-' + week].value;
      }
    });

    emptyValues.forEach(week => {
      this.channelConfigFormGroups.controls[packageModel.channelId + '-' + week]
        .setValue(this.calculateRemainderWithDecimal(total, emptyValues));
    });
  }

  calculateRemainderWithDecimal(totalEntered, arrayWithoutPercentage) {
    const val = (100 - totalEntered) / arrayWithoutPercentage.length;
    return this.roundOffNumber(val);
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }

  submitScheduleSlots() {
    this.currentModel.packageChannelModels.forEach(pckModel => {
      pckModel.earlyShoulderPercentage = this.channelConfigFormGroups.value[pckModel.channelId + '-early'];
      pckModel.primeTimePercentage = this.channelConfigFormGroups.value[pckModel.channelId + '-prime'];
      pckModel.lateShoulderPercentage = this.channelConfigFormGroups.value[pckModel.channelId + '-late'];
      this.daysOfWeek.forEach(day => {
        pckModel[day] = this.channelConfigFormGroups.controls[pckModel.channelId + '-' + day].value;
      });
      this.currentModel.numberOfWeeks.forEach(week => {
        pckModel.weekPercentages.push({
          week: week + 1,
          percentage: this.channelConfigFormGroups.controls[pckModel.channelId + '-' + week].value
        });
      });
    });

    this.submitModel.emit(this.currentModel);
  }

}
