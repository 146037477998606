export class WebAdvertClass{
    idNo: number;
    Name: string;
    Slug: string;
    Collection_ID: string;
    Item_ID: string;
    Created_On: string;
    Updated_On: string;
    Published_On: string;
    Large_Image: any;
    Medium_Image: any;
    Mobile_Image: any;
    Link: string;
    Starting_Time: string;
    End_date: String;
    End_Time: string;
    Add_categories: string;
    Add_articles: string;
    Category: string;
    agency_code: string;
    owner_code: string;
    prod_code: string;
    Status: any;
    channels: any;
    percentages: any;

    //Mobile
    home_imoja_Logo: boolean = false;
    home_Feed_section_1: boolean = false;
    home_Feed_section_2: boolean = false;
    home_under_More_Feed: boolean = false;
    home_Celeb_Buzz_section: boolean = false;
    home_LIFE_99_section: boolean = false;
    home_NEWS_section: boolean = false;
    home_MULTIMEDIA_section: boolean = false;
    home_Sponsored_Content_section: boolean = false;
    bottom_of_home_page: boolean = false;
    article_Zimoja_Logo: boolean = false;
    article_paragraph_1: boolean = false;
    article_paragraph_2: boolean = false;
    article_paragraph_3: boolean = false;
    article_Popular_Stories: boolean = false;
    article_More_like_this: boolean = false;

    //Desktop
    desk_home_imoja_Logo: boolean = false;
    desk_home_under_More_Feed: boolean = false;
    desk_home_Celeb_Buzz_section: boolean = false;
    desk_home_LIFE_99_section: boolean = false;
    desk_home_NEWS_section: boolean = false;
    desk_home_MULTIMEDIA_section: boolean = false;
    desk_home_Sponsored_Content_section: boolean = false;
    desk_bottom_of_home_page: boolean = false;
    desk_article_Zimoja_Logo: boolean = false;
    desk_article_paragraph_1: boolean = false;
    desk_article_paragraph_2: boolean = false;
    desk_article_paragraph_3: boolean = false;
    desk_article_Popular_Stories: boolean = false;
    desk_article_More_like_this: boolean = false;
}