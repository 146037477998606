import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {AlertService} from '../../services/alert/alert.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from 'src/app/models/user';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signInForm: FormGroup;
  hide = true; // password hiding
  returnUrl: string;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private authenticationService: AuthenticationService,
              private alertService: AlertService,
              private httpClient: HttpClient) {

  }

  ngOnInit() {


    // this.signInForm = this.fb.group({
    //   'email': [null, [Validators.required, <any>Validators.pattern(
    //     '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'
    //   )]],
    //   'password': [null, [Validators.required]]
    // });

    this.signInForm = this.fb.group({
      'email': [null, [Validators.required]],
      'password': [null, [Validators.required]]
    });

    
    // reset login status
    this.authenticationService.logout();
    this.router.navigate(['signin']);

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  async loginAuthentication(){ 
    
    this._markAsDirty(this.signInForm);

    if (this.signInForm.valid) {
      const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/json');

      const url = 'https://internal.nbcapps.com.na:7703/AD_Authentification';
      const payload = {
        User: this.f.email.value,  
        Password: this.f.password.value 
      };

      const response = this.httpClient.post(url, payload, { headers })
      .subscribe(
        (response: any) => {
      
          if(response){
            let user = new User(5321738, this.f.email.value, 1, 'support@mmsafrica.co.za', 1,'Admin');
            localStorage.setItem('currentUser', JSON.stringify(user));

            localStorage.setItem('employee_no', JSON.stringify(1));
            this.router.navigate(['/', 'home']);
          }
          else
          if(this.f.email.value == "support@mmsafrica.co.za" || this.f.email.value == "support@mms.africa"){
            this.onLogin();
          }
          else{ 
            this.alertService.error("Incorrect username or password !");
          }
        },
        (error) => {
          // Handle errors here
          this.alertService.error("Something went wrong, Try again");
        }
      );
    }
  }

  mapUser(userData) {
    return new User(userData.userid, userData.name, userData.bdcr_no, userData.email, userData.role_id,userData.accountType);

  }

  // convenience getter for easy access to form fields
  get f() {
    return this.signInForm.controls;
  }

  onLogin() {
    this._markAsDirty(this.signInForm);

    // this.authenticationService.signMock()
    // add login logic here...
    if (this.signInForm.valid) {
      this.authenticationService.signIn(this.f.email.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          data => {

            //localStorage.setItem('accountType', JSON.stringify(data.accountType));
            localStorage.setItem('employee_no', JSON.stringify(data.userId));
            this.router.navigate(['/', 'home']);
          },
          error => {
            this.alertService.error("Something went wrong, Try again");
          });
    }

    // this.router.navigate(['/home']);
  }

  private _markAsDirty(group: FormGroup) {
    group.markAsDirty();
    for (let i in group.controls) {
      group.controls[i].markAsDirty();
    }
  }

  forgortPassword(){

    //this.router.navigate(['/', 'forgort-password']);
    this.router.navigate(['/forgort-password']);
  }
}