import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TopDemographicsService {
  currentUser: User;
 
  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  getTop5Demographics(channelId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + `get_top_demographics_graph.php?channelId=` + channelId);
  }

  getTopDemographicsTable(channelId): Observable<any> { 
    return this.http.get<any>(environment.serverUrl + `get_top_demographics_table.php?channelId=` + channelId);
  }
}
