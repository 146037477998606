import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EditCampaignSpotDialogComponent} from '../edit-campaign-spot-dialog/edit-campaign-spot-dialog.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-edit-fiscal-dialog',
  templateUrl: './edit-fiscal-dialog.component.html',
  styleUrls: ['./edit-fiscal-dialog.component.scss']
})
export class EditFiscalDialogComponent implements OnInit {
  fiscalFormGroup: FormGroup;
  constructor(public dialogRef: MatDialogRef<EditFiscalDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder) { }

  ngOnInit() {
    this.fiscalFormGroup = this._formBuilder.group({
      fiscal: [0, <any>Validators.required]
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
