import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {SigninComponent} from '../components/signin/signin.component';
import {AuthenticationGuard} from '../guards/authentication/authentication.guard';
import {DashboardComponent} from '../components/dashboard/dashboard.component';
import {CreatePackageComponent} from '../components/create-package/create-package.component';
import {DashboardContentComponent} from '../components/dashboard-content/dashboard-content.component';
import {CreateSponsorshipComponent} from '../components/create-sponsorship/create-sponsorship.component';
import {SpotViewComponent} from '../components/spot-view/spot-view.component';
import {SponsorshipSpotsComponent} from '../components/sponsorship-spots/sponsorship-spots.component';
import {CampaignsComponent} from '../components/campaigns/campaigns.component';
import {CreateAudienceGuaranteedTradingComponent} from '../components/create-audience-guaranteed-trading/create-audience-guaranteed-trading.component';
import {SponsorshipsComponent} from '../components/sponsorships/sponsorships.component';
import {CreateRateCardComponent} from '../components/create-rate-card/create-rate-card.component';
import {CreateRateSettingToolComponent} from '../components/create-rate-setting-tool/create-rate-setting-tool.component';
import {CaptureKeySlotTargetsComponent} from '../components/capture-key-slot-targets/capture-key-slot-targets.component';
import {PhasingComponent} from '../components/phasing/phasing.component';
import {KeySlotTargetsComponent} from '../components/key-slot-targets/key-slot-targets.component';
import {MinimumSlotsComponent} from '../components/minimum-slots/minimum-slots.component';
import {BaseRatingsComponent} from '../components/base-ratings/base-ratings.component';
import {ViewRateTargetsComponent} from '../components/view-rate-targets/view-rate-targets.component';
import {EditKeyTrackingComponent} from '../components/edit-key-tracking/edit-key-tracking.component';
import {EditBaseRatingsComponent} from '../components/edit-base-ratings/edit-base-ratings.component';
import {RegionalTargetsComponent} from '../components/regional-targets/regional-targets.component';
import {NationalTargetsComponent} from '../components/national-targets/national-targets.component';
import {RevenueManagementDashboardComponent} from '../components/revenue-management-dashboard/revenue-management-dashboard.component';
import {SalesDashboardComponent} from '../components/sales-dashboard/sales-dashboard.component';
import {EditRegionalTargetsComponent} from '../components/edit-regional-targets/edit-regional-targets.component';
import {DayPartTargetsComponent} from '../components/day-part-targets/day-part-targets.component';
import {DayTargetsComponent} from '../components/day-targets/day-targets.component';
import {CreateBaseCppComponent} from '../components/create-base-cpp/create-base-cpp.component';
import {ImportScheduleComponent} from '../components/import-schedule/import-schedule.component';
import {LogEditorComponent} from '../components/log-editor/log-editor.component';
import {ScheduleProgrammesComponent} from '../components/schedule-programmes/schedule-programmes.component';
import {SchedulingComponent} from '../components/scheduling/scheduling.component';
import {ReconciliationComponent} from '../components/reconciliation/reconciliation.component';
import {ImportsComponent} from '../components/imports/imports.component';
import {ExportsComponent} from '../components/exports/exports.component';
import {CopyScheduleComponent} from '../components/copy-schedule/copy-schedule.component';
import {NewProgrammeComponent} from '../components/new-programme/new-programme.component';
import {ProgrammeListComponent} from '../components/programme-list/programme-list.component';
import {ViewProgrammeComponent} from '../components/view-programme/view-programme.component';
import {ViewSeasonComponent} from '../components/view-season/view-season.component';
import {BreaksComponent} from '../components/breaks/breaks.component';
import {ScheduleDashboardComponent} from '../components/schedule-dashboard/schedule-dashboard.component';
import {PlanningComponent} from '../components/planning/planning.component';
import {SponsorshipDashboardComponent} from '../components/sponsorship-dashboard/sponsorship-dashboard.component';
import {ProgramaticDashboardComponent} from '../components/programatic-dashboard/programatic-dashboard.component';
import {ProjectDashboardComponent} from '../components/project-dashboard/project-dashboard.component';
import {InventoryDashboardComponent} from '../components/inventory-dashboard/inventory-dashboard.component';
import {HrDashboardComponent} from '../components/hr-dashboard/hr-dashboard.component';
import {TimesheetDashboardComponent} from '../components/timesheet-dashboard/timesheet-dashboard.component';
import {FinanceDashboardComponent} from '../components/finance-dashboard/finance-dashboard.component';
import {ELearningDashboardComponent} from '../components/e-learning-dashboard/e-learning-dashboard.component';
import {AdminComponent} from '../components/admin/admin.component';
import {ProfileComponent} from '../components/profile/profile.component';
import {PromosComponent} from '../components/promos/promos.component';
import {EditUserComponent} from '../components/edit-user/edit-user.component';
import {NewUserComponent} from '../components/new-user/new-user.component';
import {UsersListComponent} from '../components/users-list/users-list.component';
import {ProgrammeCategoryListComponent} from '../components/programme-category-list/programme-category-list.component';
import {NewProgrammeCategoryComponent} from '../components/new-programme-category/new-programme-category.component';
import {ViewProgrammeCategoryComponent} from '../components/view-programme-category/view-programme-category.component';
import {TimeChannelsComponent} from '../components/time-channels/time-channels.component';
import {AdvertisersListComponent} from '../components/advertisers-list/advertisers-list.component';
import {NewAdvertisersComponent} from '../components/new-advertisers/new-advertisers.component';
import {ViewAdvertiserComponent} from '../components/view-advertiser/view-advertiser.component';
import {ProductsListComponent} from '../components/products-list/products-list.component';
import {NewProductComponent} from '../components/new-product/new-product.component';
import {ViewProductComponent} from '../components/view-product/view-product.component';
import {FileTransferProtocolComponent} from '../components/file-transfer-protocol/file-transfer-protocol.component';
import {AgencyListComponent} from '../components/agency-list/agency-list.component';
import {NewAgencyComponent} from '../components/new-agency/new-agency.component';
import {ViewAgencyComponent} from '../components/view-agency/view-agency.component';
import {BulkProgrammeComponent} from '../components/bulk-programme/bulk-programme.component';
import {AudienceComponent} from '../components/audience/audience.component';
import {ReportingComponent} from '../components/reporting/reporting.component';
import {SpotsReportComponent} from '../components/spots-report/spots-report.component';
import {NewClashComponent} from '../components/new-clash/new-clash.component';
import {ViewClashComponent} from '../components/view-clash/view-clash.component';
import {NewIcasaCategoryComponent} from '../components/new-icasa-category/new-icasa-category.component';
import {ViewIcasaCategoryComponent} from '../components/view-icasa-category/view-icasa-category.component';
import {NewContractTypeComponent} from '../components/new-contract-type/new-contract-type.component';
import {ViewContractTypeComponent} from '../components/view-contract-type/view-contract-type.component';
import {NewLanguageComponent} from '../components/new-language/new-language.component';
import {ViewLanguageComponent} from '../components/view-language/view-language.component';
import {NewRegionComponent} from '../components/new-region/new-region.component';
import {ViewRegionComponent} from '../components/view-region/view-region.component';
import {NewTypeOfRightComponent} from '../components/new-type-of-right/new-type-of-right.component';
import {ViewTypeOfRightComponent} from '../components/view-type-of-right/view-type-of-right.component';
import {MaterialManagementComponent} from '../components/material-management/material-management.component';
import {SpotsMaterialComponent} from '../components/spots-material/spots-material.component';
import {InvoicePdfComponent} from '../components/invoice-pdf/invoice-pdf.component';
import {SpotsListComponent} from '../components/spots-list/spots-list.component';
import {NewSentechGenreComponent} from '../components/new-sentech-genre/new-sentech-genre.component';
import {ViewSentechGenreComponent} from '../components/view-sentech-genre/view-sentech-genre.component';
import {DistributorListComponent} from '../components/distributor-list/distributor-list.component';
import {NewDistributorComponent} from '../components/new-distributor/new-distributor.component';
import {ViewDistributorComponent} from '../components/view-distributor/view-distributor.component';
import {NewMediaComponent} from '../components/new-media/new-media.component';
import {ViewMediaComponent} from '../components/view-media/view-media.component';
import {AssetManagementComponent} from '../components/asset-management/asset-management.component';
import {ProgrammeAssetsComponent} from '../components/programme-assets/programme-assets.component';
import {ViewAssetComponent} from '../components/view-asset/view-asset.component';
import {PlaylistComponent} from '../components/playlist/playlist.component';
import {WebsiteAdminComponent} from '../components/website-admin/website-admin.component';
import {ArticleListComponent} from '../components/article-list/article-list.component';
import {NewArticleComponent} from '../components/new-article/new-article.component';
import {EditArticleComponent} from '../components/edit-article/edit-article.component';
import {ZimojaComponent} from '../components/zimoja/zimoja.component';
import { ForgortPasswordComponent } from '../components/forgort-password/forgort-password.component';
import { ResetPasswordComponent } from '../components/reset-password/reset-password.component';
import { NewAbcdMaterialComponent } from '../components/mat-man/new-abcd-material/new-abcd-material.component';
import { ViewAbcdMaterialComponent } from '../components/mat-man/view-abcd-material/view-abcd-material.component';

import { EditAdvertComponent } from '../components/edit-advert/edit-advert.component';
import { NewAdvertComponent } from '../components/new-advert/new-advert.component';

const routes: Routes = [
  {path: '', redirectTo: 'signin', pathMatch: 'full'},
  {path: 'signin', component: SigninComponent},
  {path: 'forgort-password', component: ForgortPasswordComponent}, 
  {path: 'reset-password', component: ResetPasswordComponent},

  {
    path: 'home', component: DashboardComponent, canActivate: [AuthenticationGuard], children:
      [
        {path: '', component: DashboardContentComponent},
        {path: 'revenueManagement', component: RevenueManagementDashboardComponent},
        {path: 'sales', component: SalesDashboardComponent},
        {path: 'createPackage', component: CreatePackageComponent},
        {path: 'createSponsorship', component: CreateSponsorshipComponent},
        {path: 'spotView', component: SpotViewComponent},
        {path: 'sponsorshipSpots', component: SponsorshipSpotsComponent},
        {path: 'campaigns', component: CampaignsComponent},
        {path: 'sponsorships', component: SponsorshipsComponent},
        {path: 'createAudience', component: CreateAudienceGuaranteedTradingComponent},
        {path: 'createRateCardPackage', component: CreateRateCardComponent},
        {path: 'viewTargets', component: ViewRateTargetsComponent},
        {path: 'editKeyTracking', component: EditKeyTrackingComponent},
        {path: 'exportRates', component: EditBaseRatingsComponent},
        {path: 'editRegionalTargets', component: EditRegionalTargetsComponent},
        {path: 'dayPartTargets', component: DayPartTargetsComponent},
        {path: 'exportDayPartTargets', component: DayTargetsComponent},
        {path: 'createBaseCPP', component: CreateBaseCppComponent},
        {path: 'importSchedule', component: ImportScheduleComponent},
        {path: 'logEditor', component: LogEditorComponent},
        {path: 'scheduleProgrammes', component: ScheduleProgrammesComponent},
        {path: 'scheduling', component: SchedulingComponent},
        {path: 'reconciliation', component: ReconciliationComponent},
        {path: 'imports', component: ImportsComponent},
        {path: 'copy-schedule', component: CopyScheduleComponent},
        {path: 'new-programme', component: NewProgrammeComponent},
        {path: 'programme-list', component: ProgrammeListComponent},
        {path: 'view-programme', component: ViewProgrammeComponent},
        {path: 'view-season', component: ViewSeasonComponent},
        {path: 'exports', component: ExportsComponent},
        {path: 'breaks', component: BreaksComponent},
        {path: 'schedule', component: ScheduleDashboardComponent},
        {path: 'planning', component: PlanningComponent},
        {path: 'sponsorship-dashboard', component: SponsorshipDashboardComponent},
        {path: 'programatic-dashboard', component: ProgramaticDashboardComponent},
        {path: 'project-dashboard', component: ProjectDashboardComponent},
        {path: 'inventory-dashboard', component: InventoryDashboardComponent},
        {path: 'hr-dashboard', component: HrDashboardComponent},
        {path: 'timesheet-dashboard', component: TimesheetDashboardComponent},
        {path: 'finance-dashboard', component: FinanceDashboardComponent},
        {path: 'elearning-dashboard', component: ELearningDashboardComponent},
        {path: 'admin', component: AdminComponent},
        {path: 'profile', component: ProfileComponent},
        {path: 'promos', component: PromosComponent},
        {path: 'edit-user', component: EditUserComponent},
        {path: 'new-user', component: NewUserComponent},
        {path: 'user-list', component: UsersListComponent},
        {path: 'category-list', component: ProgrammeCategoryListComponent},
        {path: 'new-category', component: NewProgrammeCategoryComponent},
        {path: 'view-category', component: ViewProgrammeCategoryComponent},
        {path: 'time-channels', component: TimeChannelsComponent},
        {path: 'advertisers-list', component: AdvertisersListComponent},
        {path: 'new-advertiser', component: NewAdvertisersComponent},
        {path: 'view-advertiser', component: ViewAdvertiserComponent},
        {path: 'products-list', component: ProductsListComponent},
        {path: 'new-product', component: NewProductComponent},
        {path: 'view-product', component: ViewProductComponent},
        {path: 'ftp', component: FileTransferProtocolComponent},
        {path: 'agency-list', component: AgencyListComponent},
        {path: 'new-agency', component: NewAgencyComponent},
        {path: 'view-agency', component: ViewAgencyComponent},
        {path: 'bulk-programme', component: BulkProgrammeComponent},
        {path: 'audience', component: AudienceComponent},
        {path: 'reporting', component: ReportingComponent},
        {path: 'spots-report', component: SpotsReportComponent},
        {path: 'new-clash', component: NewClashComponent},
        {path: 'view-clash', component: ViewClashComponent},
        {path: 'new-icasa', component: NewIcasaCategoryComponent},
        {path: 'view-icasa', component: ViewIcasaCategoryComponent},
        {path: 'new-contract-type', component: NewContractTypeComponent},
        {path: 'view-contract-type', component: ViewContractTypeComponent},
        {path: 'new-language', component: NewLanguageComponent},
        {path: 'view-language', component: ViewLanguageComponent}, 
        {path: 'new-region', component: NewRegionComponent},
        {path: 'view-region', component: ViewRegionComponent},
        {path: 'new-type-of-right', component: NewTypeOfRightComponent},
        {path: 'view-type-of-right', component: ViewTypeOfRightComponent},
        {path: 'material-management', component: MaterialManagementComponent},
        {path: 'spots-material', component: SpotsMaterialComponent},
        {path: 'invoice-pdf', component: InvoicePdfComponent},
        {path: 'spots-list', component: SpotsListComponent},
        {path: 'new-sentech-genre', component: NewSentechGenreComponent},
        {path: 'view-sentech-genre', component: ViewSentechGenreComponent},
        {path: 'distributor-list', component: DistributorListComponent},
        {path: 'new-distributor', component: NewDistributorComponent},
        {path: 'view-distributor', component: ViewDistributorComponent},
        {path: 'new-media', component: NewMediaComponent},
        {path: 'view-media', component: ViewMediaComponent},
        {path: 'asset-management', component: AssetManagementComponent},
        {path: 'programme-assets', component: ProgrammeAssetsComponent},
        {path: 'view-asset', component: ViewAssetComponent},
        {path: 'playlist', component: PlaylistComponent},
        {path: 'web-admin', component: WebsiteAdminComponent},
        {path: 'article-list', component: ArticleListComponent},
        {path: 'new-article', component: NewArticleComponent},
        {path: 'edit-article', component: EditArticleComponent},
        {path: 'zimoja', component: ZimojaComponent},
        {path: 'new-abcd-material', component: NewAbcdMaterialComponent},
        {path: 'view-abcd-material', component: ViewAbcdMaterialComponent},
        {path: 'new-advert', component: NewAdvertComponent},
        {path: 'edit-advert', component: EditAdvertComponent},
        
        { 
          path: 'createRateSettingTool', component: CreateRateSettingToolComponent, children:
            [
              {path: '', redirectTo: 'phasing', pathMatch: 'full'},
              {path: 'phasing', component: PhasingComponent},
              {path: 'keySlotTargets', component: KeySlotTargetsComponent},
              {path: 'regionalTargets', component: RegionalTargetsComponent},
              {path: 'nationalTargets', component: NationalTargetsComponent},
              {path: 'captureKeySlotTarget', component: CaptureKeySlotTargetsComponent},
              {path: 'captureMinimumSlots', component: MinimumSlotsComponent},
              {path: 'captureBaseRatings', component: BaseRatingsComponent}
            ]
        },
      ]
  },
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule {
// }

export const routing = RouterModule.forRoot(routes);
