import {ProgramSegmentModel} from './program-segment.model';
import {SponsorshipElementModel} from './sponsorship-element.model';

export class SponsorshipModel {
  sponsorshipId: number;
  programName: string;
  programId: number;
  productId: number;
  product: any;
  advertiser: any;
  agency: any;
  broadcasterId: number;
  channelId: number;
  numberOfBroadcasts: number;
  startDate: string;
  endDate: string;
  discountPercentage: number;
  userId: number;
  totalNominalPrice: number;
  rateCardId: string;
  days: any[];
  elements: SponsorshipElementModel[];

  constructor() {
    this.elements = [];
    this.totalNominalPrice = 0;
    this.days = [];
  }
}
