import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {ChannelService} from '../../services/channel/channel.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {Router} from '@angular/router';
import {EditFiscalDialogComponent} from '../edit-fiscal-dialog/edit-fiscal-dialog.component';
import {AlertService} from '../../services/alert/alert.service';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-rates-table',
  templateUrl: './rates-table.component.html',
  styleUrls: ['./rates-table.component.scss']
})
export class RatesTableComponent implements OnInit {
  @Input() channels: any[];
  @Input() revManFormGroup: FormGroup;
  selectedChannel: any;
  rateSettings: RateSettingModel[];
  displayedColumns: string[] = ['number', 'startMonth', 'endMonth', 'fiscalTarget',
    'channel', 'edit', 'editRegional', 'viewMonthRates', 'delete'];
  dataSource: any;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private rateSettingService: RateSettingService, private channelService: ChannelService,
              private rateSettingActionsService: RateSettingActionsService, private router: Router, public dialog: MatDialog,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.rateSettingService.getStoredRateSettings().subscribe(data => {
      this.rateSettings = data;
    });
  }

  setData(data) {
    data.forEach(setting => {
      this.rateSettingService.getTargetsForRateSetting(setting.rateSettingId).subscribe(targets => {
        setting.months = targets;
      });
    });
    if (this.channels) {
      data.forEach(rateSet => {
        rateSet.channel = this.channelService.getChannelForId(rateSet.channelId, this.channels);
      });
    }
    this.dataSource = new MatTableDataSource<RateSettingModel>(data);
    this.dataSource.data = data;
    this.dataSource.paginator = this.paginator;
  }

  openEditDialog(rateSetting) {
    const dialogRef = this.dialog.open(EditFiscalDialogComponent, {
      width: '600px',
      data: {fiscal: rateSetting.fiscalTarget}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (rateSetting.fiscalTarget !== result.fiscal) {
          this.rateSettings.forEach(rate => {
            if (rate.rateSettingId === rateSetting.rateSettingId) {
              this.rateSettingService.updateFiscalTarget({
                rateSettingId: rate.rateSettingId,
                fiscalTarget: result.fiscal
              }).subscribe(message => {
                this.alertService.success(message);
                this.rateSettingService.getStoredRateSettingsFromRemote().subscribe(data => {
                  this.setData(data);
                });
                rateSetting.fiscalTarget = result.fiscal;
              }, error => {
                this.alertService.error('Error updating fiscal target');
              });
            }
          });
        }
      }
    });
  }

  deleteRateSetting(rateSetting): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the rate?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.rateSettingService.deleteRateSetting(rateSetting.rateSettingId).subscribe(success => {
          this.alertService.success(success);
          this.rateSettingService.getStoredRateSettingsFromRemote().subscribe(data => {
            this.setData(data);
            this.dataSource.data = data;
            this.table.renderRows();
          });
        }, error => {
          this.alertService.error('Error deleting');
        });
      }

    });
  }

  viewMonthlyRates(rateSetting) {
    this.rateSettingActionsService.setRateSettingModel(rateSetting);
    this.router.navigate(['home/viewTargets']);
  }

  selectChannel(channel) {
    this.selectedChannel = channel;
    if (this.rateSettings) {
      const filtertedRateSettings = this.rateSettings.filter(rate => {
        return rate.channelId === this.selectedChannel.channelId;
      });
      this.setData(filtertedRateSettings);
    }
  }


  editRegionalTarget(rateSetting) {
    this.rateSettingActionsService.setRateSettingModel(rateSetting);
    this.router.navigate(['home/editRegionalTargets']);
  }

}
