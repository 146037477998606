export class CampaignSpotModel {
  channelName: string;
  breakScheduledDate: string;
  startTime: string;
  endTime: string;
  rate: number;
  duration: number;
  u1: number;
  industryCode: string;
  programName: string;
  spotNo: number;
  campaignId: number;
  programId: number;
  channelId: number;

  constructor(channelName = '', breakScheduledDate = '', startTime = '', endTime = '', rate = 0, duration = 0, u1 = 0,
              industryCode = '', programName = '', spotNo = 0) {
    this.channelName = channelName;
    this.breakScheduledDate = breakScheduledDate;
    this.startTime = startTime;
    this.endTime = endTime;
    this.rate = rate;
    this.duration = duration;
    this.u1 = u1;
    this.industryCode = industryCode;
    this.programName = programName;
    this.spotNo = spotNo;
  }
}
