import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {AlertService} from '../../services/alert/alert.service';

@Component({
  selector: 'app-alert-component',
  templateUrl: './alert-component.component.html',
  styleUrls: ['./alert-component.component.scss']
})
export class AlertComponentComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  message: any;

  constructor(public snackBar: MatSnackBar, private alertService: AlertService) {
  }

  ngOnInit() {
    this.subscription = this.alertService.getMessage().subscribe(message => {
      if (message) {
        if (message.type === 'error') {
          this.snackBar.open(message.text, 'ok', {
            duration: 25000,
            panelClass: 'errorClass'
          });
        } else {
          this.snackBar.open(message.text, 'ok', {
            duration: 25000,
            panelClass: 'successClass'
          });
        }
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
