import {AfterViewInit, Component, OnInit} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {TopDemographicsService} from '../../services/top-demographics/top-demographics.service';
import {Router} from '@angular/router';
import {Chart} from 'chart.js';
import {MatDialog} from '@angular/material/dialog';
import {EditInventoryDialogComponent} from '../edit-inventory-dialog/edit-inventory-dialog.component';
import {AlertService} from '../../services/alert/alert.service';

@Component({
  selector: 'app-edit-key-tracking',
  templateUrl: './edit-key-tracking.component.html',
  styleUrls: ['./edit-key-tracking.component.scss']
})
export class EditKeyTrackingComponent implements OnInit, AfterViewInit {
  keyTrackingFormGroup: FormGroup;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  hoursByHalfAnHour = [
    {
      startTime: '06:00',
      endTime: '6:29:59 AM'
    },
    {
      startTime: '06:30',
      endTime: '6:59:59 AM'
    },
    {
      startTime: '07:00',
      endTime: '7:29:59 AM'
    },
    {
      startTime: '07:30',
      endTime: '7:59:59 AM'
    },
    {
      startTime: '08:00',
      endTime: '8:29:59 AM'
    },
    {
      startTime: '08:30',
      endTime: '8:59:59 AM'
    },
    {
      startTime: '09:00',
      endTime: '9:29:59 AM'
    },
    {
      startTime: '09:30',
      endTime: '9:59:59 AM'
    },
    {
      startTime: '10:00',
      endTime: '10:29:59 AM'
    },
    {
      startTime: '10:30',
      endTime: '10:59:59 AM'
    },
    {
      startTime: '11:00',
      endTime: '11:29:59 AM'
    },
    {
      startTime: '11:30',
      endTime: '11:59:59 AM'
    },
    {
      startTime: '12:00',
      endTime: '12:29:59 PM'
    },
    {
      startTime: '12:30',
      endTime: '12:59:59 PM'
    },
    {
      startTime: '13:00',
      endTime: '13:29:59 PM'
    },
    {
      startTime: '13:30',
      endTime: '13:59:59 PM'
    },
    {
      startTime: '14:00',
      endTime: '2:59:59 PM'
    },
    {
      startTime: '14:30',
      endTime: '2:59:59 PM'
    },
    {
      startTime: '15:00',
      endTime: '3:59:59 PM'
    },
    {
      startTime: '15:30',
      endTime: '3:59:59 PM'
    },
    {
      startTime: '16:00',
      endTime: '4:59:59 PM'
    },
    {
      startTime: '16:30',
      endTime: '4:59:59 PM'
    },
    {
      startTime: '17:00',
      endTime: '5:59:59 PM'
    },
    {
      startTime: '17:30',
      endTime: '5:59:59 PM'
    },
    {
      startTime: '18:00',
      endTime: '6:59:59 PM'
    },
    {
      startTime: '18:30',
      endTime: '6:59:59 PM'
    },
    {
      startTime: '19:00',
      endTime: '7:59:59 PM'
    },
    {
      startTime: '19:30',
      endTime: '7:59:59 PM'
    },
    {
      startTime: '20:00',
      endTime: '8:59:59 PM'
    },
    {
      startTime: '20:30',
      endTime: '8:59:59 PM'
    },
    {
      startTime: '21:00',
      endTime: '9:59:59 PM'
    },
    {
      startTime: '21:30',
      endTime: '9:59:59 PM'
    },
    {
      startTime: '22:00',
      endTime: '10:59:59 PM'
    },
    {
      startTime: '22:30',
      endTime: '10:59:59 PM'
    },
    {
      startTime: '23:00',
      endTime: '11:59:59 PM'
    },
    {
      startTime: '23:30',
      endTime: '11:59:59 PM'
    },
    {
      startTime: '00:00',
      endTime: '12:59:59 AM'
    },
  ];
  rateSettingMonth: any;
  rateSettingModel: RateSettingModel;
  demographics: any[];
  chart: any;
  dayHourTotals: any[];
  step = 0;
  colours = ['#6917c2', '#C71585', '#F09423', '#043BC4', '#240B58'];
  displayedColumns: string[] = ['startTime', 'target', 'revTarget', 'weeklyTargets', 'minatage', 'minNetRatePerSlot', 'minGrossPerSlot'];

  constructor(private rateSettingService: RateSettingService, private rateSettingActionsService: RateSettingActionsService,
              private _formBuilder: FormBuilder, private router: Router, private topDemographicsService: TopDemographicsService,
              public dialog: MatDialog, private alertService: AlertService) {
  }

  ngOnInit() {
    const data = this.rateSettingActionsService.getRateSettingMon();
    if (data) {
      this.rateSettingMonth = data.month;
      this.rateSettingModel = data.rateSettingModel;
      this.dayHourTotals = [];
      this.keyTrackingFormGroup = this._formBuilder.group({
        dummy: [0],
      });
      this.rateSettingService.getKeySlotsForRateSetting(this.rateSettingModel.rateSettingId, this.rateSettingMonth.month)
        .subscribe(ratesData => {
          this.daysOfWeek.forEach(day => {
            const daySlot = ratesData.find(slot => {
              return slot.day === day;
            });
            if (daySlot) {
              this.rateSettingMonth[day.toLowerCase() + 'KeySlots'] = daySlot.slots;
            } else {
              this.rateSettingMonth[day.toLowerCase() + 'KeySlots'] = this.createSlots();
            }
            this.hoursByHalfAnHour.forEach(hour => {
              this.keyTrackingFormGroup.addControl(day + '-target-' + hour.startTime, new FormControl(0));
            });
            this.dayHourTotals.push({day: day, hourTotal: 0, randTotal: 0});
          });
          this.keyTrackingFormGroup.setValidators([this.maxMonPercentage(),
            this.maxTuePercentage(),
            this.maxWedPercentage(),
            this.maxThuPercentage(),
            this.maxFriPercentage(),
            this.maxSatPercentage(),
            this.maxSunPercentage()]);
        });
    }
  }

  createSlots() {
    return this.hoursByHalfAnHour.map(hour => {
      return {
        startTime: hour.startTime,
        target: 0,
        revTarget: 0,
        weeklyTargetSlots: 0,
        minNetRatePerSlot: 0,
        minGrossPerSlot: 0
      };
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ngAfterViewInit() {
    this.topDemographicsService.getTop5Demographics(this.rateSettingModel.channelId).subscribe(demoG => {
      this.demographics = demoG;
      this.daysOfWeek.forEach((day, index) => {
        const demosForDay = demoG.filter(demo => {
          return demo.day === day;
        });
        this.createChart(index, day);
      });
    });
  }

  createChart(indexOfDay, day) {
    const dataSet = this.demographics.map((demoG, index) => {
      return {
        data: demoG.dataset.filter(demo => {
          return demo.day === day;
        }).map(data => data.rating), label: demoG.name, fill: false, borderColor: this.colours[index]
      };
    });
    const labels = this.hoursByHalfAnHour.map(hour => {
      return hour.startTime;
    });
    const canvas = <HTMLCanvasElement>document.getElementById('canvas' + indexOfDay);
    const ctx = canvas.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: dataSet
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            display: true
          }],
        }
      }
    });
  }

  mapDayHourTotal(day, total, totalRand) {
    this.dayHourTotals.forEach(dayTotal => {
      if (dayTotal.day === day) {
        dayTotal.hourTotal = total;
        dayTotal.randTotal = totalRand;
      }
    });
  }

  calculateRemainder() {
    this.daysOfWeek.forEach(day => {
      let totalEnteredPercentage = 0;
      const hoursWithoutPercentage = this.rateSettingMonth[day.toLowerCase() + 'KeySlots'].filter(dayValue => {
        return dayValue.target === 0;
      });
      const hoursWithPercentage = this.rateSettingMonth[day.toLowerCase() + 'KeySlots'].filter(dayValue => {
        return dayValue.target !== 0;
      });
      hoursWithPercentage.forEach(hr => {
        totalEnteredPercentage += hr.target;
      });
      this.rateSettingMonth[day.toLowerCase() + 'KeySlots'].forEach(dayValue => {
        let target = this.hoursByHalfAnHour.length;
        if (dayValue.target === 0) {
          if (hoursWithoutPercentage.length > 0) {
            target = (100 - totalEnteredPercentage) / hoursWithoutPercentage.length;
          }
          dayValue.target = target;
          dayValue.revTarget = this.rateSettingMonth[day.toLowerCase() + 'RandValue'] * (target / 100);
          dayValue.weeklyTargetSlots = dayValue.revTarget / 4;
        }
      });
    });
  }

  setRemainder(day) {
    let totalEnteredPercentage = 0;
    const hoursWithoutPercentage = this.rateSettingMonth[day.toLowerCase() + 'KeySlots'].filter(dayValue => {
      return dayValue.target === 0;
    });
    const hoursWithPercentage = this.rateSettingMonth[day.toLowerCase() + 'KeySlots'].filter(dayValue => {
      return dayValue.target !== 0;
    });
    hoursWithPercentage.forEach(hr => {
      totalEnteredPercentage += hr.target;
    });
    this.rateSettingMonth[day.toLowerCase() + 'KeySlots'].forEach(dayValue => {
      let target = this.hoursByHalfAnHour.length;
      if (dayValue.target === 0) {
        if (hoursWithoutPercentage.length > 0) {
          target = this.calculateRemainderWithDecimal(totalEnteredPercentage, hoursWithoutPercentage);
        }
        dayValue.target = target;
        dayValue.revTarget = this.rateSettingMonth[day.toLowerCase() + 'RandValue'] * (target / 100);
        dayValue.weeklyTargetSlots = dayValue.revTarget / 4;
      }
    });
  }

  calculateRemainderWithDecimal(totalEntered, hoursWithoutPercentage) {
    const val = (100 - totalEntered) / hoursWithoutPercentage.length;
    return this.roundOffNumber(val);
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }

  editYields() {
    const dialogRef = this.dialog.open(EditInventoryDialogComponent, {
      width: '600px',
      data: this.rateSettingMonth
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.rateSettingMonth.addedValue = result.addedValue / 100;
        this.rateSettingMonth.sellout = result.sellout / 100;
        this.rateSettingMonth.advMinPerHour = result.advMinPerHour;
        this.rateSettingMonth.lessDiscount = result.lessDiscount / 100;
        this.rateSettingMonth.compensation = result.compensation / 100;
        this.rateSettingMonth.volumeDiscount = result.volumeDiscount / 100;
      }
    });
  }

  calculateGross() {
    this.daysOfWeek.forEach(day => {
      this.rateSettingMonth[day.toLowerCase() + 'KeySlots'].forEach(keySlot => {
        keySlot.revTarget = this.roundOffNumber(this.rateSettingMonth[day.toLowerCase() + 'RandValue'] * (keySlot.target / 100));
        keySlot.weeklyTargetSlots = this.roundOffNumber(keySlot.revTarget / 4);
        keySlot.minNetRatePerSlot = this.roundOffNumber(keySlot.weeklyTargetSlots /
          ((this.rateSettingModel.advMinPerHour * this.rateSettingModel.sellout) / (1 + this.rateSettingModel.addedValue)));
        keySlot.minGrossPerSlot = this.roundOffNumber(keySlot.minNetRatePerSlot / this.rateSettingMonth.lessDiscount);
      });
    });
  }

  returnToTargets() {
    this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
    this.router.navigate(['home/viewTargets']);
  }

  matchResultToModel(selectedMonth) {
    this.rateSettingModel.months.forEach(month => {
      if (month.month === selectedMonth.month) {
        month.mondayKeySlots = selectedMonth.mondayKeySlots;
        month.tuesdayKeySlots = selectedMonth.tuesdayKeySlots;
        month.wednesdayKeySlots = selectedMonth.wednesdayKeySlots;
        month.thursdayKeySlots = selectedMonth.thursdayKeySlots;
        month.fridayKeySlots = selectedMonth.fridayKeySlots;
        month.saturdayKeySlots = selectedMonth.saturdayKeySlots;
        month.sundayKeySlots = selectedMonth.sundayKeySlots;
      }
    });
  }

  saveChanges() {
    const max = this.dayHourTotals.find(day => {
      return day.hourTotal > 100;
    });
    if (!max) {
      this.calculateGross();
      this.matchResultToModel(this.rateSettingMonth);
      this.rateSettingService.updateRateSettingModel(this.rateSettingModel).subscribe(success => {
        this.returnToTargets();
      }, error => {
        this.alertService.error('Error saving changes, please try again later.');
      });
    }
  }

  maxMonPercentage(max = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        totalSelected += formGroup.controls['Monday-target-' + hour.startTime].value;
        totalRand += this.rateSettingMonth['mondayRandValue'] * (formGroup.controls['Monday-target-' + hour.startTime].value / 100);
      });
      totalSelected = this.roundOffNumber(totalSelected);
      this.mapDayHourTotal('Monday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxMondayPercentage: true};
    };
    return validator;
  }

  maxTuePercentage(max = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        totalSelected += formGroup.controls['Tuesday-target-' + hour.startTime].value;
        totalRand += this.rateSettingMonth['tuesdayRandValue'] * (formGroup.controls['Tuesday-target-' + hour.startTime].value / 100);
      });
      this.mapDayHourTotal('Tuesday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxTuesdayPercentage: true};
    };

    return validator;
  }

  maxWedPercentage(max = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        totalSelected += formGroup.controls['Wednesday-target-' + hour.startTime].value;
        totalRand += this.rateSettingMonth['wednesdayRandValue'] * (formGroup.controls['Wednesday-target-' + hour.startTime].value / 100);
      });
      this.mapDayHourTotal('Wednesday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxWednesdayPercentage: true};
    };
    return validator;
  }

  maxThuPercentage(max = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        totalSelected += formGroup.controls['Thursday-target-' + hour.startTime].value;
        totalRand += this.rateSettingMonth['thursdayRandValue'] * (formGroup.controls['Thursday-target-' + hour.startTime].value / 100);
      });
      this.mapDayHourTotal('Thursday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxThursdayPercentage: true};
    };
    return validator;
  }

  maxFriPercentage(max = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        totalSelected += formGroup.controls['Friday-target-' + hour.startTime].value;
        totalRand += this.rateSettingMonth['fridayRandValue'] * (formGroup.controls['Friday-target-' + hour.startTime].value / 100);
      });
      this.mapDayHourTotal('Friday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxFridayPercentage: true};
    };
    return validator;
  }

  maxSatPercentage(max = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        totalSelected += formGroup.controls['Saturday-target-' + hour.startTime].value;
        totalRand += this.rateSettingMonth['saturdayRandValue'] * (formGroup.controls['Saturday-target-' + hour.startTime].value / 100);
      });
      this.mapDayHourTotal('Saturday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxSaturdayPercentage: true};
    };
    return validator;
  }

  maxSunPercentage(max = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        totalSelected += formGroup.controls['Sunday-target-' + hour.startTime].value;
        totalRand += this.rateSettingMonth['sundayRandValue'] * (formGroup.controls['Sunday-target-' + hour.startTime].value / 100);
      });
      this.mapDayHourTotal('Sunday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxSundayPercentage: true};
    };
    return validator;
  }

}
