import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {MatStepper} from '@angular/material/stepper';


@Component({
  selector: 'app-create-rate-setting-tool',
  templateUrl: './create-rate-setting-tool.component.html',
  styleUrls: ['./create-rate-setting-tool.component.scss']
})
export class CreateRateSettingToolComponent implements OnInit, OnDestroy {
  rateSettingModel: RateSettingModel;
  private subscription: Subscription;
  steps = [{title: 'Phasing and Yields', url: '/home/createRateSettingTool/phasing'},
    {title: 'Targets', url: '/home/createRateSettingTool/keySlotTargets'}];

  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(private rateSettingActionsService: RateSettingActionsService, private router: Router) {
  }

  ngOnInit() {
    this.rateSettingModel = new RateSettingModel();
    this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
    this.router.events.subscribe((res) => {
      const index = this.steps.indexOf(this.steps.find(step => step.url === this.router.url));
      if (index > -1) {
        this.stepper.selectedIndex = index;
      }
    });
  }

  onDeactivate(component) {
    const text = this.router.url;
    if (!text.toString().includes('createRateSettingTool')) {
      localStorage.removeItem('currentRateSettingModel');
    }
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      localStorage.removeItem('currentRateSettingModel');
    }
  }

}
