export class BulkProgramme {
    prog_no: number;
    prog_name: String;
    language: number;
    prod_house: String;
    length: number;
    image: String;
    genre: string;
    bdcr_no: number;
    sare_no: number;
    rcrd_no: String;
    repeat_name: String;
    type: String;
    subgenre: String;
    category: String;
    seasons: number;
    episodes: number;
    segments: number;
    pg_rating: string;
    synanopis: string;
    censorship: string[];
    sub_genre: string;
    year_of_release: number;
    producer: string;
    actors: string;
    directors: string;
    hashtag: string;
    genre_sentech: String;
    subgenre_sentech: String;
}
