import {ChannelModel} from './channel.model';

export class PackageChannelModel {
  packageChannelId: number;
  packageChannelName: string;
  channelId: number;
  packageChannelPercentage: number;
  earlyShoulderPercentage: number;
  primeTimePercentage: number;
  lateShoulderPercentage: number;
  mondayPercentage: number;
  tuesdayPercentage: number;
  wednesdayPercentage: number;
  thursdayPercentage: number;
  fridayPercentage: number;
  saturdayPercentage: number;
  sundayPercentage: number;
  spots: number;
  cost: number;
  weekPercentages: any[];
 
  constructor(packageChannelName = null, channelId = null, packageChannelPercentage = 0, earlyShoulderPercentage = 0,
              primeTimePercentage = 0, lateShoulderPercentage = 0) {
    this.packageChannelName = packageChannelName;
    this.channelId = channelId;
    this.packageChannelPercentage = packageChannelPercentage;
    this.earlyShoulderPercentage = earlyShoulderPercentage;
    this.primeTimePercentage = primeTimePercentage;
    this.lateShoulderPercentage = lateShoulderPercentage;
    this.spots = 0;
    this.cost = 0;
    this.weekPercentages = [];
  }
}
