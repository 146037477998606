import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {

  userId: number;
  baseUrl = environment.serverUrl;

  userFormGroup: FormGroup;
  user: UserClass = new UserClass();

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

    this.userId = parseInt(JSON.parse(localStorage.getItem('userId')));
    this.onReadOneUser();
  }

  ngOnInit() {
   
    this.userFormGroup = this.formBuilder.group({ 
      emailCtrl: [this.user.email, Validators.required],
      nameCtrl: [this.user.name, Validators.required],
      surnameCtrl: [this.user.surname, Validators.required],
      phoneNumberCtrl: [this.user.phoneNumber],
      accountTypeCtrl: [this.user.accountType, Validators.required],
      statusCtrl: [this.user.status, Validators.required],

      //Access control
      prog_readCtrl: [this.user.prog_read, Validators.required],
      prog_writeCtrl: [this.user.prog_write, Validators.required],
      prog_deleteCtrl: [this.user.prog_delete, Validators.required],

      mat_man_readCtrl: [this.user.mat_man_read, Validators.required],
      mat_man_writeCtrl: [this.user.mat_man_write, Validators.required],
      mat_man_deleteCtrl: [this.user.mat_man_delete, Validators.required],

      schedule_readCtrl: [this.user.schedule_read, Validators.required],
      schedule_writeCtrl: [this.user.schedule_write, Validators.required],
      schedule_deleteCtrl: [this.user.schedule_delete, Validators.required],

      web2app_readCtrl: [this.user.web2app_read, Validators.required],
      web2app_writeCtrl: [this.user.web2app_write, Validators.required],
      web2app_deleteCtrl: [this.user.web2app_delete, Validators.required],

      news_readCtrl: [this.user.news_read, Validators.required],
      news_writeCtrl: [this.user.news_write, Validators.required],
      news_deleteCtrl: [this.user.news_delete, Validators.required],

      content_sales_readCtrl: [this.user.content_sales_read, Validators.required],
      content_sales_writeCtrl: [this.user.content_sales_write, Validators.required],
      content_sales_deleteCtrl: [this.user.content_sales_delete, Validators.required],

      market_place_readCtrl: [this.user.market_place_read, Validators.required],
      market_place_writeCtrl: [this.user.market_place_write, Validators.required],
      market_place_deleteCtrl: [this.user.market_place_delete, Validators.required],

      production_services_readCtrl: [this.user.production_services_read, Validators.required],
      production_services_writeCtrl: [this.user.production_services_write, Validators.required],
      production_services_deleteCtrl: [this.user.production_services_delete, Validators.required],

      content_management_readCtrl: [this.user.content_management_read, Validators.required],
      content_management_writeCtrl: [this.user.content_management_write, Validators.required],
      content_management_deleteCtrl: [this.user.content_management_delete, Validators.required],

      revenue_management_readCtrl: [this.user.revenue_management_read, Validators.required],
      revenue_management_writeCtrl: [this.user.revenue_management_write, Validators.required],
      revenue_management_deleteCtrl: [this.user.revenue_management_delete, Validators.required],

      analytics_readCtrl: [this.user.analytics_read, Validators.required],
      analytics_writeCtrl: [this.user.analytics_write, Validators.required],
      analytics_deleteCtrl: [this.user.analytics_delete, Validators.required],

      project_management_readCtrl: [this.user.project_management_read, Validators.required],
      project_management_writeCtrl: [this.user.project_management_write, Validators.required],
      project_management_deleteCtrl: [this.user.project_management_delete, Validators.required],

      inventory_management_readCtrl: [this.user.inventory_management_read, Validators.required],
      inventory_management_writeCtrl: [this.user.inventory_management_write, Validators.required],
      inventory_management_deleteCtrl: [this.user.inventory_management_delete, Validators.required],

      elearning_readCtrl: [this.user.elearning_read, Validators.required],
      elearning_writeCtrl: [this.user.elearning_write, Validators.required],
      elearning_deleteCtrl: [this.user.elearning_delete, Validators.required],

      hr_readCtrl: [this.user.hr_read, Validators.required],
      hr_writeCtrl: [this.user.hr_write, Validators.required],
      hr_deleteCtrl: [this.user.hr_delete, Validators.required],

      finance_readCtrl: [this.user.finance_read, Validators.required],
      finance_writeCtrl: [this.user.finance_write, Validators.required],
      finance_deleteCtrl: [this.user.finance_delete, Validators.required],
    });
    
  }


  async onReadOneUser() {

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/admin/read_one_employee.php`,
    {
      userId: this.userId,

    }, {responseType: 'json'}).pipe()
    .subscribe(
        (data: any) => {

            this.user.email = data[0].email;
            this.user.name = data[0].name;
            this.user.surname = data[0].surname;
            this.user.phoneNumber = data[0].phoneNumber;
            this.user.accountType = data[0].accountType;
            this.user.status = data[0].status;
            this.user.prog_read   = data[0].prog_read;
            this.user.prog_write = data[0].prog_write;
            this.user.prog_delete = data[0].prog_delete;
            this.user.mat_man_read = data[0].mat_man_read;
            this.user.mat_man_write = data[0].mat_man_write;
            this.user.mat_man_delete = data[0].mat_man_delete;
            this.user.schedule_read = data[0].schedule_read;
            this.user.schedule_write = data[0].schedule_write;
            this.user.schedule_delete = data[0].schedule_delete;
            this.user.web2app_read = data[0].web2app_read;
            this.user.web2app_write = data[0].web2app_write;
            this.user.web2app_delete = data[0].web2app_delete;
            this.user.news_read = data[0].news_read;
            this.user.news_write = data[0].news_write;
            this.user.news_delete = data[0].news_delete;
            this.user.content_sales_read = data[0].content_sales_read;
            this.user.content_sales_write = data[0].content_sales_write;
            this.user.content_sales_delete = data[0].content_sales_delete;
            this.user.market_place_read = data[0].market_place_read;
            this.user.market_place_write = data[0].market_place_write;
            this.user.market_place_delete = data[0].market_place_delete;
            this.user.production_services_read = data[0].production_services_read;
            this.user.production_services_write = data[0].production_services_write;
            this.user.production_services_delete = data[0].production_services_delete;
            this.user.content_management_read = data[0].content_management_read;
            this.user.content_management_write = data[0].content_management_write;
            this.user.content_management_delete = data[0].content_management_delete;
            this.user.revenue_management_read = data[0].revenue_management_read;
            this.user.revenue_management_write = data[0].revenue_management_write;
            this.user.revenue_management_delete = data[0].revenue_management_delete;
            this.user.analytics_read = data[0].analytics_read;
            this.user.analytics_write = data[0].analytics_write;
            this.user.analytics_delete = data[0].analytics_delete;
            this.user.project_management_read = data[0].project_management_read;
            this.user.project_management_write = data[0].project_management_write;
            this.user.project_management_delete = data[0].project_management_delete;
            this.user.inventory_management_read = data[0].inventory_management_read;
            this.user.inventory_management_write = data[0].inventory_management_write;
            this.user.inventory_management_delete = data[0].inventory_management_delete;
            this.user.elearning_read = data[0].elearning_read;
            this.user.elearning_write = data[0].elearning_write;
            this.user.elearning_delete = data[0].elearning_delete;
            this.user.hr_read = data[0].hr_read;
            this.user.hr_write = data[0].hr_write;
            this.user.hr_delete = data[0].hr_delete;
            this.user.finance_read = data[0].finance_read;
            this.user.finance_write = data[0].finance_write;
            this.user.finance_delete = data[0].finance_delete;
        }
    )
}

  async saveUser(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/admin/update_user.php`,
    {
      userId: this.userId,
      name: this.user.name,
      surname: this.user.surname,
      email: this.user.email,
      phoneNumber: this.user.phoneNumber,
      accountType: this.user.accountType,
      status: this.user.status,
      changePassword: false,
      prog_read: this.user.prog_read,
      prog_write: this.user.prog_write,
      prog_delete: this.user.prog_delete,
      mat_man_read: this.user.mat_man_read,
      mat_man_write: this.user.mat_man_write,
      mat_man_delete: this.user.mat_man_delete,
      schedule_read: this.user.schedule_read,
      schedule_write: this.user.schedule_write,
      schedule_delete: this.user.schedule_delete,
      web2app_read: this.user.web2app_read,
      web2app_write: this.user.web2app_write,
      web2app_delete: this.user.web2app_delete,
      news_read: this.user.news_read,
      news_write: this.user.news_write,
      news_delete: this.user.news_delete,
      content_sales_read: this.user.content_sales_read,
      content_sales_write: this.user.content_sales_write,
      content_sales_delete: this.user.content_sales_delete,
      market_place_read: this.user.market_place_read,
      market_place_write: this.user.market_place_write,
      market_place_delete: this.user.market_place_delete,
      production_services_read: this.user.production_services_read,
      production_services_write: this.user.production_services_write,
      production_services_delete: this.user.production_services_delete,
      content_management_read: this.user.content_management_read,
      content_management_write: this.user.content_management_write,
      content_management_delete: this.user.content_management_delete,
      revenue_management_read: this.user.revenue_management_read,
      revenue_management_write: this.user.revenue_management_write,
      revenue_management_delete: this.user.revenue_management_delete,
      analytics_read: this.user.analytics_read,
      analytics_write: this.user.analytics_write,
      analytics_delete: this.user.analytics_delete,
      project_management_read: this.user.project_management_read,
      project_management_write: this.user.project_management_write,
      project_management_delete: this.user.project_management_delete,
      inventory_management_read: this.user.inventory_management_read,
      inventory_management_write: this.user.inventory_management_write,
      inventory_management_delete: this.user.inventory_management_delete,
      elearning_read: this.user.elearning_read,
      elearning_write: this.user.elearning_write,
      elearning_delete: this.user.elearning_delete,
      hr_read: this.user.hr_read,
      hr_write: this.user.hr_write,
      hr_delete: this.user.hr_delete, 
      finance_read: this.user.finance_read,
      finance_write: this.user.finance_write,
      finance_delete: this.user.finance_delete,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {

          this.userFormGroup.reset();
          this.alertService.success("Account successfully saved");
          localStorage.setItem('userId', JSON.stringify(0));
          this.router.navigate(['/home/admin']);
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}
