import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {environment} from '../../../environments/environment';
import {User} from '../../models/user';
import {HttpClient} from '@angular/common/http';
import {RateSettingModel} from '../../models/rate-setting-model';
import * as _moment from 'moment';
import {IntegratedTradingModel} from '../../models/integrated-trading.model';

const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class RateSettingService {
  currentUser: User;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  postRateSettingModel(rateSettingModel: RateSettingModel): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `save_rate_settings.php`,
      rateSettingModel).pipe(map(repsonse => {
      rateSettingModel.rateSettingId = repsonse && repsonse.rate_settingsID;
      return rateSettingModel;
    }));
  }

  postNationalTargets(rateSettingModel: RateSettingModel): Observable<any> {
    return this.http.post<any>(environment.serverUrl + 'save_national_rate_settings.php',
      rateSettingModel).pipe(map(repsonse => {
      rateSettingModel.rateSettingId = repsonse && repsonse.rate_settingsID;
      return rateSettingModel;
    }));
  }

  updateRateSettingModel(rateSettingModel: RateSettingModel): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `edit_rate_settings.php`,
      rateSettingModel);
  }

  deleteRateSetting(rateSettingModelId): Observable<any> {
    return this.http.get<any>(environment.serverUrl + `delete_rate_settings.php?rate_settingsID=` + rateSettingModelId);
  }


  validatePeriod(channels): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `get_ratesettings_period.php`, channels);
  }

  updateFiscalTarget(target): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `edit_fiscalTarget.php`, target);
  }

  exportTargetRates(targets): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `export_tr.php`,
      targets).pipe(map(response => {
      //this.saveFile(response);
      return response;
    }));
  }

  exportAudienceRates(audience): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `export_tr.php`,
      audience).pipe(map(response => {
      //this.saveFile(response);
      return response;
    }));
  }

  saveFile(response) {
    response = response.path.replaceAll('/r', '/');
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none'); 
    a.href = response;
    a.download = response.filename || 'Exported rate';
    a.click();
    window.URL.revokeObjectURL(response);
    a.remove(); // remove the element
  }

  getBaseRatingFromRemote(month, channelId): Observable<any[]> {
    return this.http.get<any>(environment.serverUrl + `get_base_cpp.php?month=` + month + `&channelId=` + channelId)
      .pipe(map(baseRatings => {
        if (baseRatings && baseRatings.length > 0) {
          localStorage.setItem('baseRatings' + channelId, JSON.stringify(baseRatings));
        }
        return baseRatings;
      }));
  }


  getTopDemosFromRemote(channelId): Observable<any[]> {
    return this.http.get<any>(environment.serverUrl + `get_base_ratings.php?sare_no=` + channelId)
      .pipe(map(topDemos => {
        if (topDemos && topDemos.length > 0) {
          localStorage.setItem('topDemos' + channelId, JSON.stringify(topDemos));
        }
        return topDemos;
      }));
  }


  getBaseRatingFromLocal(month) {
    return JSON.parse(localStorage.getItem('baseRatings' + month));
  }

  getTopDemosFromLocal(channelId) {
    return JSON.parse(localStorage.getItem('topDemos' + channelId));
  }

  getBaseRatingsForMonth(month, channelId): Observable<any[]> {
    const monthName = moment(month, 'MMM').format('MMMM').toLowerCase();
    // const baseRatings = this.getBaseRatingFromLocal(monthName);
    // if (!baseRatings) {
    // uncomment when using API
    return this.getBaseRatingFromRemote(monthName, channelId);
    // }
    //
    // return of(baseRatings);
  }

  getTopDemos(channelId): Observable<any[]> {
    return this.getTopDemosFromRemote(channelId);

  }

  getMonthlyRatings(channelId, month, year): Observable<any[]> {
    return this.http.get<any>(environment.serverUrl + `get_base_ratings.php?sare_no=` + channelId + `&month=` + month + `&year=` + year);
  }

  getDemographicPercentages(): Observable<any[]> {
    return this.http.get<any>(environment.serverUrl + 'get_demographic_percent.php');
  }

  getStoredRateSettingsFromLocal() {
    return JSON.parse(localStorage.getItem('storedRateSettings'));
  }

  getStoredRateSettingsFromRemote(): Observable<any[]> {
    return this.http.get<any>(environment.serverUrl + `get_rate_settings.php`)
      .pipe(map(storedRateSettings => {
        if (storedRateSettings && storedRateSettings.length > 0) {
          localStorage.setItem('storedRateSettings', JSON.stringify(this.mapRateSettings(storedRateSettings)));
        }
        return this.mapRateSettings(storedRateSettings);
      }));
  }

  getStoredRateSettings(): Observable<any[]> {
    return this.getStoredRateSettingsFromRemote();
  }

  getTargetsFromLocal(rateSettingId) {
    return JSON.parse(localStorage.getItem('rateTargets' + rateSettingId));
  }

  getTargetsFromRemote(rateSettingId) {
    return this.http.get<any>(environment.serverUrl + `get_monthly_ratings.php?rate_settingsID=` + rateSettingId)
      .pipe(map(rateTargets => {
        if (rateTargets && rateTargets.length > 0) {
          const mappedTargets = rateTargets.map(target => {
            return this.mapRateSettingsMonth(target);
          });
          localStorage.setItem('rateTargets' + rateSettingId, JSON.stringify(mappedTargets));
          return mappedTargets;
        } else {
          return [];
        }
      }));
  }

  getTargetsForRateSetting(rateSettingId) {
    return this.getTargetsFromRemote(rateSettingId);
  }

  getKeySlotsFromLocal(rateSettingId, month) {
    return JSON.parse(localStorage.getItem('keySlots-' + rateSettingId + '-' + month));
  }

  getKeySlotsFromRemote(rateSettingId, month) {
    return this.http.get<any>(environment.serverUrl + `get_keyslots.php?rate_settingsID=` + rateSettingId + `&month=` + month)
      .pipe(map(keySlots => {
        if (keySlots && keySlots.length > 0) {
          const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
          const mappedKeySlots = daysOfWeek.map(day => {
            return {
              day: day,
              slots: this.mapKeySlots(keySlots, day)
            };
          });
          localStorage.setItem('keySlots-' + rateSettingId + '-' + month, JSON.stringify(mappedKeySlots));
          return mappedKeySlots;
        } else {
          return [];
        }
      }));
  }

  getKeySlotsForRateSetting(rateSettingId, month) {
    const storedKeySlots = this.getKeySlotsFromLocal(rateSettingId, month);
    if (!storedKeySlots) {
      return this.getKeySlotsFromRemote(rateSettingId, month);
    }
    return of(storedKeySlots);
  }

  mapRateSettings(storedRateSettings) {
    return storedRateSettings.map(rateCard => {
      const rateSetting = new RateSettingModel();
      rateSetting.rateSettingId = rateCard.rate_settingsID;
      rateSetting.startMonth = rateCard.start_month;
      rateSetting.endMonth = rateCard.end_month;
      rateSetting.fiscalTarget = rateCard.fiscalTarget;
      rateSetting.channelId = rateCard.sare_no;
      rateSetting.year = moment(rateCard.start_month, 'YYYY-MM').format('YYYY');

      return rateSetting;
    });
  }

  mapRateSettingsMonth(monthData) {
    return {
      month: monthData.month,
      lessDiscount: parseFloat(monthData.lessDiscount),
      sellout: parseFloat(monthData.sellout),
      addedValue: parseFloat(monthData.addedValue),
      compensation: parseFloat(monthData.compensation),
      volumeDiscount: parseFloat(monthData.volumeDiscount),
      advMinPerHour: monthData.advMinPerHour,
      percentage: parseFloat(monthData.percentage),
      randValue: monthData.rand_value,
      mondayPercentage: monthData.mon_percent,
      mondayRandValue: monthData.mon_value,
      tuesdayPercentage: monthData.tue_percent,
      tuesdayRandValue: monthData.tue_value,
      wednesdayPercentage: monthData.wed_percent,
      wednesdayRandValue: monthData.wed_value,
      thursdayPercentage: monthData.thu_percent,
      thursdayRandValue: monthData.thu_value,
      fridayPercentage: monthData.fri_percent,
      fridayRandValue: monthData.fri_value,
      saturdayPercentage: monthData.sat_percent,
      saturdayRandValue: monthData.sat_value,
      sundayPercentage: monthData.sun_percent,
      sundayRandValue: monthData.sun_value,
      mondayKeySlots: [],
      tuesdayKeySlots: [],
      wednesdayKeySlots: [],
      thursdayKeySlots: [],
      fridayKeySlots: [],
      saturdayKeySlots: [],
      sundayKeySlots: []
    };
  }

  mapKeySlots(keySlotData, day) {
    const arrayOfDay = keySlotData.filter(keySlot => {
      return keySlot.day === day;
    });

    return arrayOfDay.map(keysSlot => {
      return {
        startTime: keysSlot.start_time,
        target: parseFloat(keysSlot.target),
        revTarget: parseFloat(keysSlot.revTarget),
        minNetRatePerSlot: parseFloat(keysSlot.minNetRatePerSlot),
        minGrossPerSlot: parseFloat(keysSlot.minGrossPerSlot)
      };
    });
  }

}
