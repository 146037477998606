import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-end-date-picker',
  templateUrl: './end-date-picker.component.html',
  styleUrls: ['./end-date-picker.component.scss']
})
export class EndDatePickerComponent implements OnInit {

  @Input() group: FormGroup;
  @Output() SelectedItem = new EventEmitter<any>();
  endDateCtrl: FormControl = new FormControl('', Validators.required);

  constructor() {
  }

  ngOnInit() {
    this.group.addControl('endDateCtrl', this.endDateCtrl);
  }

  onSelect(endDate) {
    this.SelectedItem.emit(endDate.value);
  }

}
