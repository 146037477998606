export class SpotSummaryModel {
  earlyShoulderSpots: number;
  earlyShoulderCost: number;
  primeTimeSpots: number;
  primeTimeCost: number;
  lateShoulderSpots: number;
  lateShoulderCost: number;
  channel: string;
  channelSpots: number;
  channelCost: number;

  constructor(_earlyShoulderSpots = 0, _earlyShoulderCost = 0, _primeTimeSpots = 0, _primeTimeCost = 0,
              _lateShoulderSpots = 0, _lateShoulderCost = 0, _channel = null,
              _channelSpots = 0, _channelCost = 0) {
    this.earlyShoulderSpots = _earlyShoulderSpots;
    this.earlyShoulderCost = _earlyShoulderCost;
    this.primeTimeSpots = _primeTimeSpots;
    this.primeTimeCost = _primeTimeCost;
    this.lateShoulderSpots = _lateShoulderSpots;
    this.lateShoulderCost = _lateShoulderCost;
    this.channel = _channel;
    this.channelSpots = _channelSpots;
    this.channelCost = _channelCost;
  }
}
