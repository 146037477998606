import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DemographicService} from '../../services/demographic/demographic.service';
import * as _moment from 'moment';
import {User} from '../../models/user';
import {BaseRatingsService} from '../../services/base-ratings/base-ratings.service';
import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {Location} from '@angular/common';
import { AlertService } from 'src/app/services/alert/alert.service';
import { FreeCPP } from 'src/app/classes/free_to_air_cpp';

const moment = _moment;


@Component({ 
  selector: 'app-create-base-cpp',
  templateUrl: './create-base-cpp.component.html',
  styleUrls: ['./create-base-cpp.component.scss']
})
export class CreateBaseCppComponent implements OnInit {
  baseCPPFormGroup: FormGroup;
  months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August','September', 'October', 'November', 'December'];
  selectedYear: any = new Date().getFullYear();
  demographics: any[] = [];
  displayedColumns: string[] = ['Month'];
  columnsToDisplay: string[] = []; 
  tableSource: any[] = ['INDEX']; 
  currentUser: User;
 
  index_array: number[] = [];
  january_array: number[] = []; 
  freeToAirCPP: FreeCPP[] = [];


  constructor(private _formBuilder: FormBuilder, private demographicService: DemographicService,
              private baseRatingsService: BaseRatingsService, private router: Router,private httpClient: HttpClient,private _location: Location,
              private alertService: AlertService) {

                this.onReadBasecpp();
                this.onReadFreecpp();
  }

  ngOnInit() {
    

  }

  async onReadBasecpp()
  {
    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/sales/get_january_cpp_and_index.php?month=january&channelId=1&selectedYear=${this.selectedYear}`).pipe( )
    .subscribe(
      (data:any) =>{

        this.index_array = []; 
        this.january_array = []; 

        for(var i=0;i<data.basecpp_array.length;i++)
        {
            this.january_array[i] = data.basecpp_array[i].rate;
        }

        for(var i=0;i<data.index_array.length;i++)
        {
            this.index_array[i] = data.index_array[i].index_value;
        }
        
        this.demographicService.getDemographics().subscribe(demographics => {
          this.demographics = demographics;
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.setPageData();
        });
      }
    )
  }

  async onReadFreecpp()
  {
    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/sales/get_free_to_air_cpp.php?channelId=1`).pipe( )
    .subscribe(
      (data:any) =>{

        this.freeToAirCPP = []; 

        for(var i=0;i<data.length;i++)
        { 
            this.freeToAirCPP[i] = {channel: data[i].channel,idNo: data[i].idNo,month: data[i].month,target: data[i].target,value: data[i].value,year: data[i].year};
        }

      }
    )
  }

  setPageData() {
    this.baseCPPFormGroup = this._formBuilder.group({
      dummy: [0]
    });
    
    this.demographics.forEach(demographic => {
      this.tableSource.push(demographic.demographic);
    });

    var index = 0;
    var index_jan = 0;

    this.months.forEach(month => {
      this.displayedColumns.push(month);

      this.tableSource.forEach(item => {
        if (item === 'INDEX') {
          this.baseCPPFormGroup.addControl(month + '-' + item, new FormControl(this.index_array[index]));
          index = index + 1;
        }

        if (month === this.months[0] && item !== 'INDEX') {
          this.baseCPPFormGroup.addControl(month + '-' + item, new FormControl(this.january_array[index_jan]));
          index_jan = index_jan + 1;
        }
      });
    });
    this.columnsToDisplay = this.displayedColumns;
  }

  getYear(year) {
    this.selectedYear = (moment(year).year());
  }

  getDemographicCPP(index) {
    return this.demographics.map(demographic => {
      return {
        demographic: demographic.demographic,
        rating: this.baseCPPFormGroup.controls[this.months[0] + '-' + demographic.demographic].value * index
      };
    });
  }

  setBaseCPP() {
    const cppData = this.months.map(month => {
      return {
        year: this.selectedYear || new Date().getFullYear(),
        month: month,
        broadcasterId: this.currentUser.broadcasterId,
        index: this.baseCPPFormGroup.controls[month + '-INDEX'].value,
        ratings: this.getDemographicCPP(this.baseCPPFormGroup.controls[month + '-INDEX'].value)
      };
    });
    this.baseRatingsService.setBaseRatings(cppData).subscribe(success => {

      this.alertService.success('Base ratings successfully saved');
        this.router.navigate(['/home/revenueManagement']);
      }
    );
  }

  back(){
    this._location.back();
  }
}

