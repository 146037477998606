import {Injectable} from '@angular/core';
import {FlightCodeModel} from '../../models/flight-code.model';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FlightingCodeService {
  currentUser: User;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  getFlightingCodesForProductIdFromRemote(productId): Observable<FlightCodeModel[]> {
    return this.http.get<any>(environment.serverUrl + `get_existing_codes.php?prod_code=` + productId)
      .pipe(map(flightingCodes => {
        if (flightingCodes && flightingCodes.length > 0) {
          const mappedFlightingCodes = this.flightingCodeMapper(flightingCodes);
          localStorage.setItem(this.setKeyForBroadcaster(productId), JSON.stringify(mappedFlightingCodes));
        }
        return this.flightingCodeMapper(flightingCodes);
      }));
  }

  setKeyForBroadcaster(productId) {
    return 'flightCodes-' + productId;
  }

  getFlightingCodesForProductIdFromLocal(productId) {
    return JSON.parse(localStorage.getItem(this.setKeyForBroadcaster(productId)));
  }

  getFlightingCodesForProductId(productId): Observable<FlightCodeModel[]> {
    const flightingCodes = this.getFlightingCodesForProductIdFromLocal(productId);
    if (!flightingCodes) {
      return this.getFlightingCodesForProductIdFromRemote(productId);
    }

    return of(flightingCodes);
  }

  saveFlightingCodes(codeModel) {
    return this.saveFlightingCodesRemote(codeModel);
  }

  saveFlightingCodesForSponsorship(codeModel) {
    return this.http.post<any>(environment.serverUrl + `save_sponsorship_code.php`,
      [codeModel]);
  }

  saveFlightingCodesRemote(codeModel) {
    return this.http.post<any>(environment.serverUrl + `save_code.php`,
      [codeModel]);
  }

  private flightingCodeMapper(flightingCodeData): FlightCodeModel[] {
    return flightingCodeData.map(flightingCode => new FlightCodeModel(flightingCode.indury_code, flightingCode.prod_code));
  }
}
