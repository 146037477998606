import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {AlertService} from '../../services/alert/alert.service';
import {Router} from '@angular/router';
import {SponsorshipService} from '../../services/sponsorship/sponsorship.service';
import {SponsorshipTableModel} from '../../models/sponsorship-table.model';

import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-sponsorshup-table',
  templateUrl: './sponsorshup-table.component.html',
  styleUrls: ['./sponsorshup-table.component.scss']
})
export class SponsorshupTableComponent implements OnInit {
  sponsorships: SponsorshipTableModel[];
  displayedColumns: string[] = ['sponsorshipId', 'start_date', 'end_date', 'product', 'advertiser', 'agency', 'numberOfElements',
    'totalNominalPrice', 'viewSpots'];
  dataSource: any;
  @ViewChild('paginator10') paginator10: MatPaginator;

  constructor(private sponsorshipService: SponsorshipService, private alertService: AlertService, private router: Router) {
  }

  ngOnInit() {
    this.sponsorshipService.getSponsorships().subscribe(sponsorships => {
      this.sponsorships = sponsorships;
      this.dataSource = new MatTableDataSource<SponsorshipTableModel>(sponsorships);
      this.dataSource.paginator = this.paginator10;
    }, error => {
      this.alertService.error("Something went wrong, Try again");
    });
  }

  applyFilter2(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  formatDate(date) {
    return moment(date).format('YYYY/MM/DD');
  }

  viewSpots(sponsorship) {
    localStorage.setItem('selectedSponsorship', JSON.stringify(sponsorship));
    this.router.navigate(['/home/sponsorshipSpots']);
  }
}
