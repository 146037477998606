import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProgramService} from '../../services/program/program.service';
import * as _moment from 'moment';
import {SponsorshipSpotModel} from '../../models/sponsorship-spot.model';

const moment = _moment;

@Component({
  selector: 'app-edit-sponsorship-spot-dialog',
  templateUrl: './edit-sponsorship-spot-dialog.component.html',
  styleUrls: ['./edit-sponsorship-spot-dialog.component.scss']
})
export class EditSponsorshipSpotDialogComponent implements OnInit {

  sponsorshipSpotFormGroup: FormGroup;
  channel: any;
  programs: any[];
  segments: any[];
  elementNames: string[] = ['Opening Billboard', 'Sting', 'Promo commercial', 'Closing Billboard'];

  constructor(public dialogRef: MatDialogRef<EditSponsorshipSpotDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: SponsorshipSpotModel,
              private _formBuilder: FormBuilder,
              private programService: ProgramService) {
  }

  ngOnInit() {
    this.programs = [];
    this.sponsorshipSpotFormGroup = this._formBuilder.group({
      elementNameCtrl: [''],
      percentageCtrl: [''],
    });
  }

  selectDate(date) {
    this.data.breakScheduleDate = moment(date).format('YYYY/MM/DD');
  }

  getPrograms() {
    if (this.data.breakScheduleDate && this.channel) {
      this.programService.getProgramsForChannelAndDate({
        channelId: this.channel.channelId,
        startDate: this.data.breakScheduleDate,
        endDate: this.data.breakScheduleDate,
        broadcasterId: 0
      }).subscribe(programs => this.programs = programs);
    }
  }

  selectDuration(duration) {
    this.data.duration = duration;
  }
  cancel(): void {
    this.dialogRef.close();
  }

}
