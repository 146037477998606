import {Component, Input, OnInit} from '@angular/core';
import {SponsorshipModel} from '../../models/sponsorship.model';
import {Router} from '@angular/router';
import {SponsorshipService} from '../../services/sponsorship/sponsorship.service';

@Component({
  selector: 'app-sponsorship-summary',
  templateUrl: './sponsorship-summary.component.html',
  styleUrls: ['./sponsorship-summary.component.scss']
})
export class SponsorshipSummaryComponent implements OnInit {
  @Input() sponsorship: SponsorshipModel;
  @Input() product: any;
  @Input() program: any;
  displayedColumns: string[] = ['name', 'duration', 'costPerElement', 'rateCardTotal', 'discount', 'sponsorshipCost'];

  constructor(private router: Router, private sponsorshipService: SponsorshipService) {
  }

  ngOnInit() {
  }

  done() {
    this.sponsorshipService.getSponsorshipsFromRemote().subscribe(success => {
      return success;
    });
    this.router.navigate(['/home/sponsorshipSpots']);
  }
} 
