import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-finance-dashboard',
  templateUrl: './finance-dashboard.component.html',
  styleUrls: ['./finance-dashboard.component.scss'],
})
export class FinanceDashboardComponent implements OnInit {

  selectedInsight: any;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  redirect(path) {
    this.router.navigate(['home/' + path]);
  }

  selectInsight(insight) {
    this.selectedInsight = insight;
  }
}
