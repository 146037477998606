import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MaterialModule} from './modules/material.module';
import {PrimeFacesModule} from './modules/primefaces.module';
import {routing} from './modules/app-routing.module';
import {AuthenticationGuard} from './guards/authentication/authentication.guard';
import {JwtInterceptor} from './helpers/jwt.interceptor';
import {AuthenticationService} from './services/authentication/authentication.service';
import {AppComponent} from './app.component';
import {SigninComponent} from './components/signin/signin.component';
import {MonthPickerComponent} from './components/month-picker/month-picker.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DashboardComponent} from './components/dashboard/dashboard.component';
import {CampaignService} from './services/campaign/campaign.service';
import {CampaignSpotService} from './services/campaignSpot/campaign-spot.service';
import {CreatePackageComponent} from './components/create-package/create-package.component';
import {ChannelSelectorComponent} from './components/channel-selector/channel-selector.component';
import {DashboardContentComponent} from './components/dashboard-content/dashboard-content.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ProductSelectorComponent} from './components/product-selector/product-selector.component';
import {DurationPickerComponent} from './components/duration-picker/duration-picker.component';
import {StartDatePickerComponent} from './components/start-date-picker/start-date-picker.component';
import {EndDatePickerComponent} from './components/end-date-picker/end-date-picker.component';
import {CreateSponsorshipComponent} from './components/create-sponsorship/create-sponsorship.component';
import {ChannelAutoCompleteComponent} from './components/channel-auto-complete/channel-auto-complete.component';
import {ProgramSelectorComponent} from './components/program-selector/program-selector.component';
import {ProgramDayPickerComponent} from './components/program-day-picker/program-day-picker.component';
import {SponsorshipDetailsComponent} from './components/sponsorship-details/sponsorship-details.component';
import {SponosorshipElementsComponent} from './components/sponosorship-elements/sponosorship-elements.component';
import {SponsorshipSummaryComponent} from './components/sponsorship-summary/sponsorship-summary.component';
import {AlertComponentComponent} from './components/alert-component/alert-component.component';
import {AlertService} from './services/alert/alert.service';
import {ErrorInterceptor} from './helpers/error/error.interceptor';
import {SpotTableComponent} from './components/spot-table/spot-table.component';
import {SpotViewComponent} from './components/spot-view/spot-view.component';
import {SponsorshipSpotsComponent} from './components/sponsorship-spots/sponsorship-spots.component';
import {CampaignsComponent} from './components/campaigns/campaigns.component';
import {AudienceGuaranteedTradingComponent} from './components/audience-guaranteed-trading/audience-guaranteed-trading.component';
import {TargetAudienceSelectorComponent} from './components/target-audience-selector/target-audience-selector.component';
import {TradingModelSelectorComponent} from './components/trading-model-selector/trading-model-selector.component';
import {AllocateFlightingCodeDialogComponent} from './components/allocate-flighting-code-dialog/allocate-flighting-code-dialog.component';
import {FlightingCodeAutoCompleteComponent} from './components/flighting-code-auto-complete/flighting-code-auto-complete.component';
import {CreateAudienceGuaranteedTradingComponent} from './components/create-audience-guaranteed-trading/create-audience-guaranteed-trading.component';
import {AudienceGuaranteedDetailsComponent} from './components/audience-guaranteed-details/audience-guaranteed-details.component';
import {SponsorshipsComponent} from './components/sponsorships/sponsorships.component';
import {EditCampaignSpotDialogComponent} from './components/edit-campaign-spot-dialog/edit-campaign-spot-dialog.component';
import {CreateRateCardComponent} from './components/create-rate-card/create-rate-card.component';
import {RateCardDetailsComponent} from './components/rate-card-details/rate-card-details.component';
import {BreakTypeSelectorComponent} from './components/break-type-selector/break-type-selector.component';
import {SpotFormComponent} from './components/spot-form/spot-form.component';
import {ChannelConfigFormComponent} from './components/channel-config-form/channel-config-form.component';
import {DatePickerComponent} from './components/date-picker/date-picker.component';
import {AddSpotDialogComponent} from './components/add-spot-dialog/add-spot-dialog.component';
import {ConfirmationDialogComponent} from './components/confirmation-dialog/confirmation-dialog.component';
import {EditSponsorshipSpotDialogComponent} from './components/edit-sponsorship-spot-dialog/edit-sponsorship-spot-dialog.component';
import {AudienceGuaranteedTradingSummaryComponent} from './components/audience-guaranteed-trading-summary/audience-guaranteed-trading-summary.component';
import {CreateRateSettingToolComponent} from './components/create-rate-setting-tool/create-rate-setting-tool.component';
import {PhasingComponent} from './components/phasing/phasing.component';
import {KeySlotTargetsComponent} from './components/key-slot-targets/key-slot-targets.component';
import {KeySlotDialogComponent} from './components/key-slot-dialog/key-slot-dialog.component';
import {RateSettingActionsService} from './services/rate-setting-actions/rate-setting-actions.service';
import {CaptureKeySlotTargetsComponent} from './components/capture-key-slot-targets/capture-key-slot-targets.component';
import {AdvertiserSelectorComponent} from './components/advertiser-selector/advertiser-selector.component';
import {AgencySelectorComponent} from './components/agency-selector/agency-selector.component';
import {MinimumSlotsComponent} from './components/minimum-slots/minimum-slots.component';
import {BaseRatingsComponent} from './components/base-ratings/base-ratings.component';
import {ChannelPercentageSelectorComponent} from './components/channel-percentage-selector/channel-percentage-selector.component';
import {FormatDecimalPlacesPipe} from './pipes/format-two-decimal-places.pipe';
import {SafePipe} from './pipes/safe.pipe';
import {RatesTableComponent} from './components/rates-table/rates-table.component';
import {RateSettingService} from './services/rate-setting/rate-setting.service';
import {ViewRateTargetsComponent} from './components/view-rate-targets/view-rate-targets.component';
import {EditKeyTrackingComponent} from './components/edit-key-tracking/edit-key-tracking.component';
import {EditInventoryDialogComponent} from './components/edit-inventory-dialog/edit-inventory-dialog.component';
import {EditBaseRatingsComponent} from './components/edit-base-ratings/edit-base-ratings.component';
import {BaseCppDialogComponent} from './components/base-cpp-dialog/base-cpp-dialog.component';
import {EditFiscalDialogComponent} from './components/edit-fiscal-dialog/edit-fiscal-dialog.component';
import { RatesGraphComponent } from './components/rates-graph/rates-graph.component';
import { SponsorshupTableComponent } from './components/sponsorshup-table/sponsorshup-table.component';
import { TradingTableComponent } from './components/trading-table/trading-table.component';
import { EditCampaignDialogComponent } from './components/edit-campaign-dialog/edit-campaign-dialog.component';
import { ChannelTargetSelectorComponent } from './components/channel-target-selector/channel-target-selector.component';
import { RegionalTargetsComponent } from './components/regional-targets/regional-targets.component';
import { NationalTargetsComponent } from './components/national-targets/national-targets.component';
import {LoadingScreenInterceptor} from './helpers/loading.interceptor';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { RevenueManagementDashboardComponent } from './components/revenue-management-dashboard/revenue-management-dashboard.component';
import { SalesDashboardComponent } from './components/sales-dashboard/sales-dashboard.component';
import { RegionalTableComponent } from './components/regional-table/regional-table.component';
import { EditRegionalTargetsComponent } from './components/edit-regional-targets/edit-regional-targets.component';
import { DayPartTargetsComponent } from './components/day-part-targets/day-part-targets.component';
import { DayTargetsComponent } from './components/day-targets/day-targets.component';
import { CreateBaseCppComponent } from './components/create-base-cpp/create-base-cpp.component';
import { YearPickerComponent } from './components/year-picker/year-picker.component';
import { ImportScheduleComponent } from './components/import-schedule/import-schedule.component';
import { ImportBaseCppComponent } from './components/import-base-cpp/import-base-cpp.component';
import { AddProductDialogComponent } from './components/add-product-dialog/add-product-dialog.component';
import { FrameAppComponent } from './components/frame-app/frame-app.component';
import { LogEditorComponent } from './components/log-editor/log-editor.component';
import { ScheduleProgrammesComponent } from './components/schedule-programmes/schedule-programmes.component';
import { SchedulingComponent } from './components/scheduling/scheduling.component';
import { ReconciliationComponent } from './components/reconciliation/reconciliation.component';
import { ImportsComponent } from './components/imports/imports.component';
import { ExportsComponent } from './components/exports/exports.component';
import { CopyScheduleComponent } from './components/copy-schedule/copy-schedule.component';
import { NewProgrammeComponent } from './components/new-programme/new-programme.component'; 
import { ProgrammeListComponent } from './components/programme-list/programme-list.component';
import { ViewProgrammeComponent } from './components/view-programme/view-programme.component';
import { ViewSeasonComponent } from './components/view-season/view-season.component';
import { BreaksComponent } from './components/breaks/breaks.component';
import {ScheduleDashboardComponent} from './components/schedule-dashboard/schedule-dashboard.component';
import {PlanningComponent} from './components/planning/planning.component';
import {SponsorshipDashboardComponent} from './components/sponsorship-dashboard/sponsorship-dashboard.component';
import {ProgramaticDashboardComponent} from './components/programatic-dashboard/programatic-dashboard.component';
import {ProjectDashboardComponent} from './components/project-dashboard/project-dashboard.component';
import {InventoryDashboardComponent} from './components/inventory-dashboard/inventory-dashboard.component';
import {HrDashboardComponent} from './components/hr-dashboard/hr-dashboard.component'; 
import {TimesheetDashboardComponent} from './components/timesheet-dashboard/timesheet-dashboard.component';
import {FinanceDashboardComponent} from './components/finance-dashboard/finance-dashboard.component';
import {ELearningDashboardComponent} from './components/e-learning-dashboard/e-learning-dashboard.component';
import {AdminComponent} from './components/admin/admin.component';
import {ProfileComponent} from './components/profile/profile.component';
import {PromosComponent} from './components/promos/promos.component';
import {EditUserComponent} from './components/edit-user/edit-user.component';
import {NewUserComponent} from './components/new-user/new-user.component';
import {EpisodeDialogComponent} from './components/episode-dialog/episode-dialog.component';
import {UsersListComponent} from './components/users-list/users-list.component';
import {ProgrammeCategoryListComponent} from './components/programme-category-list/programme-category-list.component';
import {NewProgrammeCategoryComponent} from './components/new-programme-category/new-programme-category.component';
import {ViewProgrammeCategoryComponent} from './components/view-programme-category/view-programme-category.component';
import {TimeChannelsComponent} from './components/time-channels/time-channels.component';
import {AdvertisersListComponent} from './components/advertisers-list/advertisers-list.component';
import {NewAdvertisersComponent} from './components/new-advertisers/new-advertisers.component';
import {ViewAdvertiserComponent} from './components/view-advertiser/view-advertiser.component';
import {ProductsListComponent} from './components/products-list/products-list.component';
import {NewProductComponent} from './components/new-product/new-product.component';
import {ViewProductComponent} from './components/view-product/view-product.component';
import {AddNewEpisodeDialogComponent} from './components/add-new-episode-dialog/add-new-episode-dialog.component';
import {FileTransferProtocolComponent} from './components/file-transfer-protocol/file-transfer-protocol.component';
import {AgencyListComponent} from './components/agency-list/agency-list.component';
import {NewAgencyComponent} from './components/new-agency/new-agency.component';
import {ViewAgencyComponent} from './components/view-agency/view-agency.component';
import {BulkProgrammeComponent} from './components/bulk-programme/bulk-programme.component';
import {AudienceComponent} from './components/audience/audience.component';
import {ReportingComponent} from './components/reporting/reporting.component';
import {SpotsReportComponent} from './components/spots-report/spots-report.component';
import {NewClashComponent} from './components/new-clash/new-clash.component';
import {ViewClashComponent} from './components/view-clash/view-clash.component';
import {NewIcasaCategoryComponent} from './components/new-icasa-category/new-icasa-category.component';
import {ViewIcasaCategoryComponent} from './components/view-icasa-category/view-icasa-category.component';
import {NewContractTypeComponent} from './components/new-contract-type/new-contract-type.component';
import {ViewContractTypeComponent} from './components/view-contract-type/view-contract-type.component';
import {NewLanguageComponent} from './components/new-language/new-language.component';
import {ViewLanguageComponent} from './components/view-language/view-language.component';
import {NewRegionComponent} from './components/new-region/new-region.component';
import {ViewRegionComponent} from './components/view-region/view-region.component';
import {NewTypeOfRightComponent} from './components/new-type-of-right/new-type-of-right.component';
import {ViewTypeOfRightComponent} from './components/view-type-of-right/view-type-of-right.component';
import {MaterialManagementComponent} from './components/material-management/material-management.component';
import {SpotsMaterialComponent} from './components/spots-material/spots-material.component';
import {InvoicePdfComponent} from './components/invoice-pdf/invoice-pdf.component';
import {SpotsListComponent} from './components/spots-list/spots-list.component';
import {NewSentechGenreComponent} from './components/new-sentech-genre/new-sentech-genre.component';
import {ViewSentechGenreComponent} from './components/view-sentech-genre/view-sentech-genre.component';
import {DistributorListComponent} from './components/distributor-list/distributor-list.component';
import {NewDistributorComponent} from './components/new-distributor/new-distributor.component';
import {ViewDistributorComponent} from './components/view-distributor/view-distributor.component';
import {NewMediaComponent} from './components/new-media/new-media.component';
import {ViewMediaComponent} from './components/view-media/view-media.component';
import {AssetManagementComponent} from './components/asset-management/asset-management.component';
import {ProgrammeAssetsComponent} from './components/programme-assets/programme-assets.component';
import {ViewAssetComponent} from './components/view-asset/view-asset.component';
import {PlaylistComponent} from './components/playlist/playlist.component';
import {ReconDialogComponent} from './components/recon-dialog/recon-dialog.component';
import {WebsiteAdminComponent} from './components/website-admin/website-admin.component';
import {ArticleListComponent} from './components/article-list/article-list.component';
import {NewArticleComponent} from './components/new-article/new-article.component';
import {EditArticleComponent} from './components/edit-article/edit-article.component';
import {ZimojaComponent} from './components/zimoja/zimoja.component';
import { VideoProcessingService } from './components/new-article/video-processing-service';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import { ForgortPasswordComponent } from './components/forgort-password/forgort-password.component';
import {NewAbcdMaterialComponent} from './components/mat-man/new-abcd-material/new-abcd-material.component';
import { ViewAbcdMaterialComponent } from './components/mat-man/view-abcd-material/view-abcd-material.component';

import { FullCalendarModule } from '@fullcalendar/angular';
import { IonicModule } from '@ionic/angular';
import { environment } from '../environments/environment';
import { AddParticipantsComponent } from './components/mat-man/add-participants/add-participants.component';
import { EditAdvertComponent } from './components/edit-advert/edit-advert.component';
import { NewAdvertComponent } from './components/new-advert/new-advert.component';
 
@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    ZimojaComponent,
    ReportingComponent,
    InvoicePdfComponent,
    PlaylistComponent,
    ReconDialogComponent,
    AssetManagementComponent,
    ProgrammeAssetsComponent,
    ResetPasswordComponent,
    ForgortPasswordComponent,
    EditArticleComponent,
    ArticleListComponent,
    NewArticleComponent,
    WebsiteAdminComponent,
    ViewAssetComponent,
    NewClashComponent,
    SpotsListComponent,
    NewMediaComponent,
    ViewMediaComponent,
    ViewDistributorComponent,
    NewDistributorComponent,
    DistributorListComponent,
    NewSentechGenreComponent,
    ViewSentechGenreComponent,
    SpotsMaterialComponent,
    MaterialManagementComponent,
    SpotsReportComponent,
    ViewClashComponent,
    NewLanguageComponent,
    ViewLanguageComponent,
    NewRegionComponent,
    ViewRegionComponent,
    ViewTypeOfRightComponent,
    NewTypeOfRightComponent,
    ViewContractTypeComponent,
    NewContractTypeComponent,
    NewIcasaCategoryComponent,
    ViewIcasaCategoryComponent,
    AdvertisersListComponent,
    NewAdvertisersComponent,
    ViewAdvertiserComponent,
    AgencyListComponent,
    NewAgencyComponent,
    ViewAgencyComponent,
    AddNewEpisodeDialogComponent,
    FileTransferProtocolComponent,
    ProductsListComponent, 
    NewProductComponent,
    AudienceComponent,
    ViewProductComponent,
    UsersListComponent,
    EpisodeDialogComponent,
    TimeChannelsComponent,
    ProgrammeCategoryListComponent,
    NewProgrammeCategoryComponent,
    ViewProgrammeCategoryComponent,
    ProfileComponent,
    PromosComponent,
    EditUserComponent,
    NewUserComponent,
    SigninComponent,
    DashboardComponent,
    MonthPickerComponent,
    CreatePackageComponent,
    ChannelSelectorComponent,
    BulkProgrammeComponent,
    DashboardContentComponent,
    ProductSelectorComponent,
    DurationPickerComponent,
    StartDatePickerComponent,
    EndDatePickerComponent,
    CreateSponsorshipComponent,
    ChannelAutoCompleteComponent,
    ProgramSelectorComponent,
    ProgramDayPickerComponent,
    SponsorshipDetailsComponent,
    SponosorshipElementsComponent,
    SponsorshipSummaryComponent,
    AlertComponentComponent,
    SpotTableComponent,
    SpotViewComponent,
    SponsorshipSpotsComponent,
    CampaignsComponent,
    AudienceGuaranteedTradingComponent,
    TargetAudienceSelectorComponent,
    TradingModelSelectorComponent,
    AllocateFlightingCodeDialogComponent,
    FlightingCodeAutoCompleteComponent,
    CreateAudienceGuaranteedTradingComponent,
    AudienceGuaranteedDetailsComponent,
    SponsorshipsComponent,
    EditCampaignSpotDialogComponent,
    CreateRateCardComponent,
    RateCardDetailsComponent,
    BreakTypeSelectorComponent,
    SpotFormComponent,
    ChannelConfigFormComponent,
    DatePickerComponent,
    AddSpotDialogComponent,
    ConfirmationDialogComponent,
    EditSponsorshipSpotDialogComponent,
    AudienceGuaranteedTradingSummaryComponent,
    CreateRateSettingToolComponent,
    PhasingComponent,
    KeySlotTargetsComponent,
    KeySlotDialogComponent,
    CaptureKeySlotTargetsComponent,
    AdvertiserSelectorComponent,
    AgencySelectorComponent,
    MinimumSlotsComponent,
    BaseRatingsComponent,
    ChannelPercentageSelectorComponent,
    FormatDecimalPlacesPipe,
    SafePipe,
    RatesTableComponent,
    ViewRateTargetsComponent,
    EditKeyTrackingComponent,
    EditInventoryDialogComponent,
    EditBaseRatingsComponent,
    BaseCppDialogComponent,
    EditFiscalDialogComponent,
    RatesGraphComponent,
    SponsorshupTableComponent,
    TradingTableComponent,
    EditCampaignDialogComponent,
    ChannelTargetSelectorComponent,
    RegionalTargetsComponent,
    NationalTargetsComponent,
    LoadingScreenComponent,
    RevenueManagementDashboardComponent,
    SalesDashboardComponent,
    RegionalTableComponent,
    EditRegionalTargetsComponent,
    DayPartTargetsComponent,
    DayTargetsComponent,
    CreateBaseCppComponent,
    YearPickerComponent,
    ImportScheduleComponent,
    ImportBaseCppComponent,
    AddProductDialogComponent,
    FrameAppComponent,
    LogEditorComponent,
    ScheduleProgrammesComponent,
    SchedulingComponent,
    ReconciliationComponent,
    ImportsComponent,
    ExportsComponent,
    CopyScheduleComponent,
    NewProgrammeComponent,
    ProgrammeListComponent,
    ViewProgrammeComponent,
    ViewSeasonComponent,
    BreaksComponent,
    ScheduleDashboardComponent,
    PlanningComponent,
    SponsorshipDashboardComponent,
    ProgramaticDashboardComponent,
    ProjectDashboardComponent,
    InventoryDashboardComponent,
    HrDashboardComponent,
    TimesheetDashboardComponent,
    FinanceDashboardComponent,
    ELearningDashboardComponent,
    ViewAbcdMaterialComponent,
    NewAbcdMaterialComponent,
    AddParticipantsComponent,
    EditAdvertComponent,
    NewAdvertComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    routing,
    MaterialModule,
    PrimeFacesModule,
    FlexLayoutModule,
    FullCalendarModule,
    IonicModule,
  ],
  entryComponents: [
    AllocateFlightingCodeDialogComponent,
    EditCampaignSpotDialogComponent,
    AddSpotDialogComponent,
    EditSponsorshipSpotDialogComponent,
    ConfirmationDialogComponent,
    KeySlotDialogComponent,
    EditInventoryDialogComponent,
    BaseCppDialogComponent,
    EditFiscalDialogComponent,
    EditCampaignDialogComponent,
    ImportBaseCppComponent,
    AddProductDialogComponent
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  providers: [
    AuthenticationGuard,
    AuthenticationService,
    CampaignService,
    CampaignSpotService,
    AlertService,
    RateSettingActionsService,
    RateSettingService,
    VideoProcessingService,
    {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
