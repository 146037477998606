import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {ProgramModel} from '../../models/program.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-program-day-picker',
  templateUrl: './program-day-picker.component.html',
  styleUrls: ['./program-day-picker.component.scss']
})
export class ProgramDayPickerComponent implements OnInit, OnChanges {

  @Input() program: any;
  @Input() formGroup: FormGroup;
  programDayCtrl: FormControl = new FormControl('', Validators.required);

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const _program: SimpleChange = changes.program;
    this.program = _program.currentValue;
  }

  ngOnInit() {
    this.formGroup.addControl('packageDay', this.programDayCtrl);
  }

}
