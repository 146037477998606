import {Injectable} from '@angular/core';
import {ProgramModel} from '../../models/program.model';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProgramService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  getProgramsFromRemote(): Observable<ProgramModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.post<any>(environment.serverUrl + `getPrograms`, {})
      .pipe(map(programs => {
        if (programs && programs.length > 0) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('programs', JSON.stringify(programs));
        }
        return programs;
      }));
  }

  getProgramsForChannelAndDateFromRemote(channelAndDate): Observable<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + 'get_programmes.php?startDate=' + channelAndDate.startDate +
      '&endDate=' + channelAndDate.endDate + '&channelId=' + channelAndDate.channelId + '&broadcasterId=' + this.currentUser.broadcasterId);
  }

  setKeyForChannelDate(channelAndDate) {
    return 'programs-' + channelAndDate.channelId + '-' + channelAndDate.startDate + '-' + channelAndDate.endDate;
  }

  getProgramsFromLocal() {
    return JSON.parse(localStorage.getItem('programs'));
  }

  getProgramsFromForChannelAndDateLocal(channelAndDate) {
    return JSON.parse(localStorage.getItem(this.setKeyForChannelDate(channelAndDate)));
  }

  getPrograms(): Observable<ProgramModel[]> {
    const programs = this.getProgramsFromLocal();
    if (!programs) {
     return this.getProgramsFromRemote();
    }
    return of(programs);
  }

  getProgramsForChannelAndDate(channelAndDate): Observable<ProgramModel[]> {
      return this.getProgramsForChannelAndDateFromRemote(channelAndDate);
  }

  getProgramForId(id, programs): ProgramModel {
    const program = programs.find(prog => prog.programId === id);
    return program;
  }

  mapBoolValue(value) {
    return value === 'Y';
  }

  private programMapper(programData): ProgramModel[] {
    const mapppedPrograms: ProgramModel[] = [];
    if (programData && programData.length > 0) {
      programData.forEach(program => {
        mapppedPrograms.push({
          programId: program.prog_no || null,
          programName: program.prog_name || null,
          broadcasterId: program.bdcr_no || null,
          channelId: program.sare_no || null,
          day: program.day || null,
          startTime: program.stt_time || null,
          endTime: program.end_time || null,
          genre: program.genre || null,
          language: program.language || null,
          rateCardId: program.rcrd_no || null,
          mon: this.mapBoolValue(program.mon),
          tue: this.mapBoolValue(program.tue),
          wed: this.mapBoolValue(program.wed),
          thu: this.mapBoolValue(program.thu),
          fri: this.mapBoolValue(program.fri),
          sat: this.mapBoolValue(program.sat),
          sun: this.mapBoolValue(program.sun)
        });
      });
    }

    return mapppedPrograms;
  }
}
