import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import * as _moment from 'moment';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {AlertService} from '../../services/alert/alert.service';
import {Location} from '@angular/common';

const moment = _moment;


@Component({
  selector: 'app-phasing',
  templateUrl: './phasing.component.html',
  styleUrls: ['./phasing.component.scss']
})
export class PhasingComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  rateSettingModel: RateSettingModel;
  phasingDetailsFormGroup: FormGroup;
  submitForm = new EventEmitter<any>();
  monthValues = [];
  startDate: any;
  endDate: any;

  constructor(private _formBuilder: FormBuilder, private rateSettingActionsService: RateSettingActionsService, private router: Router,
              private rateSettingService: RateSettingService, private alertService: AlertService,private _location: Location) {

                
  }

  ngOnInit() {

    if (!this.rateSettingModel) {
      this.rateSettingModel = this.rateSettingActionsService.getRateSettingMod();
    }
    this.phasingDetailsFormGroup = this._formBuilder.group({
      sellout: [0, <any>Validators.required],
      lessDiscount: [0, <any>Validators.required],
      advMinPerHour: [0, <any>Validators.required],
      addedValue: [0, <any>Validators.required],
      compensation: [0, <any>Validators.required],
      volumeDiscount: [0, <any>Validators.required]
    });
  }

  getStartMonth(startMonth) {

    this.rateSettingModel.startMonth = startMonth.value;
    this.startDate = startMonth.value;

    if (this.endDate && this.monthValues.length > 0) {
      this.monthValues = [];
    }
    this.getAmountOfMonths();
  }
 
  getEndMonth(month) {

    this.rateSettingModel.endMonth = month.value;
    this.endDate = month.value;

    if (this.startDate && this.monthValues.length > 0) {
      this.monthValues = [];
    }
    this.getAmountOfMonths();
  }


  getAmountOfMonths() {

    if (this.startDate && this.endDate) {
      this.rateSettingModel.year = this.startDate.format('YYYY');
      this.monthValues = [];
      let dateStart = this.startDate;
      const dateEnd = this.endDate;
      if (dateEnd > dateStart) {
        dateStart = moment(this.rateSettingModel.startMonth);
      }

      while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        this.monthValues.push(dateStart.format('MMM'));
        dateStart.add(1, 'month');
      }
    }
  }

  validateChannelPeriods() {

    const channelArray = {
      'channels': this.rateSettingModel.channels.map(channel => {
        return {channelId: channel.channelId};
      }) 
    };

    this.rateSettingService.validatePeriod(channelArray).subscribe(channelData => {
      const valid = [];
      channelData.forEach(channel => {
        if (channel.start_month.length !== 0 && channel.end_month.length !== 0) {
          const compareDate = moment(channel.start_month, 'YYYY-MM');
          if (compareDate.isBetween(this.startDate, this.endDate, 'months', '[]')) {
            valid.push(channel);
          }
        }
      });

      if (valid.length > 0) {

        let channelString = '';
        valid.forEach(month => {
          channelString += ' Channel ' + month.sare_no;
        });
        this.alertService.error('Rates already created in this period for the following channels:' + channelString);
        this.monthValues = [];
        this.startDate = null;
        this.endDate = null;
      } else {

        this.saveData();
      } 
    });
  }

  submitPhasing() {
    this.validateChannelPeriods();
  }

  setSelected(selectedChannels) {
    this.rateSettingModel.channels = selectedChannels;
  }

  saveData() {

    if (this.monthValues && this.monthValues.length > 0 && this.phasingDetailsFormGroup.valid) {
      this.rateSettingModel.channels.forEach(channel => {
        this.rateSettingModel.channelTargets.push({
          channel: channel,
          target: this.phasingDetailsFormGroup.controls[channel.channelId + 'TargetCtrl'].value,
          monthlyTargets: []
        });
      });

      this.rateSettingModel.months = this.monthValues;
      this.rateSettingModel.lessDiscount = this.rateSettingModel.lessDiscount / 100;
      this.rateSettingModel.sellout = this.rateSettingModel.sellout / 100;
      this.rateSettingModel.addedValue = this.rateSettingModel.addedValue / 100;
      this.rateSettingModel.compensation = this.rateSettingModel.compensation / 100;
      this.rateSettingModel.volumeDiscount = this.rateSettingModel.volumeDiscount / 100;
      this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
      this.router.navigate(['home/createRateSettingTool/nationalTargets']);
    }
  }

  ngOnDestroy() {
  }

  back(){
    this._location.back();
  }
}
