import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {IntegratedTradingModel} from '../../models/integrated-trading.model';
import * as _moment from 'moment';
import {ProgramService} from '../../services/program/program.service';
import * as _XLSX from 'xlsx';
import {AdvertiserModel} from '../../models/advertiser.model';
import {AgencyModel} from '../../models/agency.model';
import {Location} from '@angular/common';

const moment = _moment;
const xlsx = _XLSX;

@Component({
  selector: 'app-rate-card-details',
  templateUrl: './rate-card-details.component.html',
  styleUrls: ['./rate-card-details.component.scss']
})
export class RateCardDetailsComponent implements OnInit {
  @Input() integratedTradingDetailsFormGroup: FormGroup;
  @Input() integratedTradingModel: IntegratedTradingModel;
  @Output() submitRateCardDetails = new EventEmitter<IntegratedTradingModel>();
  @Output() changeProgram = new EventEmitter<any>();
  @Output() changeProduct = new EventEmitter<any>();
  product: any;
  programs: any[] = [];
  program: any;
  file: any;
  productAdvertiser: any;
  productAgency: any;

  constructor(private programService: ProgramService,private _location: Location) {
  }

  ngOnInit() {
  }

  selectProduct(product) {
    this.product = product;
    this.integratedTradingModel.productId = product && product.productId;
    this.productAdvertiser = new AdvertiserModel(product.owner, product.ownerId);
    this.integratedTradingModel.advertiser = this.productAdvertiser;
    this.productAgency = new AgencyModel(product.payer, product.payer_Id);
    this.integratedTradingModel.agency = this.productAgency;
    this.changeProduct.emit(product);
  }

  selectBreakType(breakType) {
    this.integratedTradingModel.breakType = breakType.breakTypeId;
  }

  fileChanged(e) {
    this.file = e.target.files[0];
    let fileReader = new FileReader();

    fileReader.onload = (evt) => {
      const data = evt.target.result;

      const workbook = xlsx.read(data, {type: 'binary'});

      this.integratedTradingModel.uploadedFile = workbook;
    };
    fileReader.readAsBinaryString(this.file);
  }


  selectAdvertiser(advertiser) {
    this.integratedTradingModel.advertiser = advertiser;
  }

  selectAgency(agency) {
    this.integratedTradingModel.agency = agency;
  }

  onSubmitRateCard() {
    this.integratedTradingModel.discountPercentage = this.integratedTradingDetailsFormGroup.controls['discountPercentage'].value;
    this.submitRateCardDetails.emit(this.integratedTradingModel);
  }

  back(){
    this._location.back();
  }

}
