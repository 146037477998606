import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {User} from '../../models/user';
import {ElementModel} from '../../models/element.model';
import {environment} from '../../../environments/environment';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ElementService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  getElementsFromRemote(): Observable<ElementModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_elements.php?broadcasterID=` + this.currentUser.broadcasterId)
      .pipe(map(elements => {
        if (elements && elements.length > 0) {
          const mappedElements = this.elementMapper(elements);
          localStorage.setItem('elements', JSON.stringify(mappedElements));
        }
        return this.elementMapper(elements);
      }));
  }

  getElementsFromLocal() {
    return JSON.parse(localStorage.getItem('elements'));
  }

  getElements(): Observable<ElementModel[]> {
    const elements = this.getElementsFromLocal();
    if (!elements) {

      return this.getElementsFromRemote();
    }

    return of(elements);
  }

  private elementMapper(elementData): ElementModel[] {
    return elementData.map(element => new ElementModel(element.broadcasterID, element.element, element.element_type, element.index_value));
  }
}
