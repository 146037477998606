export class TargetAudienceModel {
  targetId: number;
  targetAudience: string;
  broadcasterId: number

  constructor(targetId = 0, targetAudience = '', broadcasterId = 0) {
    this.targetId = targetId;
    this.targetAudience = targetAudience;
    this.broadcasterId = broadcasterId;
  }
}
