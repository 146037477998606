import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { GenreClass } from 'src/app/classes/genre';
import { ClashClass } from 'src/app/classes/clash';
import { CategoryClass } from 'src/app/classes/category';
import { SareClass } from 'src/app/classes/sare';

@Component({
  selector: 'app-view-type-of-right',
  templateUrl: './view-type-of-right.component.html',
  styleUrls: ['./view-type-of-right.component.scss'],
})
export class ViewTypeOfRightComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  categorieFormGroup: FormGroup;
  icasa: CategoryClass = new CategoryClass();

  channels: SareClass[] = [];
  channel = "1";
  rightsId: number = 0;

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

    this.rightsId = parseInt(JSON.parse(localStorage.getItem('rightsId')));
    this.onReadOneCategory();
  }

  ngOnInit() {
   
    this.categorieFormGroup = this.formBuilder.group({ 
      nameCtrl: [this.icasa.name, Validators.required],
      channelNoCtrl: [this.channel, Validators.required],
    });
    
    this.onChannels();
  }

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: 0,
      date: '',
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
		 this.channels = [];
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  async onReadOneCategory() {

      const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_one_type_of_rights.php`,
      {
        rightsId: this.rightsId,

      }, {responseType: 'json'}).pipe()
      .subscribe(
          (data: any) => {

              this.icasa.name = data[0].name;
              this.icasa.channel = data[0].channel;
          }
      )
  }

  async saveIcasa(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/edit_type_of_rights.php`,
    {
      channel: this.channel,
      name: this.icasa.name,
      rightsId: this.rightsId

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.categorieFormGroup.reset();
          this.alertService.success("Contract Type successfully saved");
          this.router.navigate(['/home/category-list']);
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }
}