export class WebArticleClass{
    idNo: number;
    headline: string;
    slug: string;
    tag: string;
    summary_pull_quote: string;
    post_content: string;
    image: string;
    video: string;
    twitter_embaded: string;
    article_color: string;
    is_popular: any;
    date_published: string;
    time_published: string;
    auto_publish: string;
    date_created: string;
    time_created: string;
    date_modified: string;
    time_modified: string;
    userId: string;
    views: number;
    likes: number;
    status: any;
    article_by: string;
    video_file: string;
    category: string;
    image_caption:string;
    image_credit: string;
    text_over_color: string;
    featured_homepage: boolean;
    featured_popular: boolean;
    featured_subscetion: boolean;
    author: string;
    guest_author: string;
}