import { Component, OnInit } from '@angular/core';
import {SchedulingEndpoints} from '../frame-app/apis/end-points.model';

@Component({
  selector: 'app-schedule-programmes',
  templateUrl: './schedule-programmes.component.html',
  styleUrls: ['./schedule-programmes.component.scss']
})
export class ScheduleProgrammesComponent implements OnInit {

  url: string;
  constructor() { }

  ngOnInit() {
    this.url = SchedulingEndpoints.tenant['scheduleProgrammes'];
  }

}
