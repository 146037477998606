import {Component, OnInit} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {RegionsService} from '../../services/regions/regions.service';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {AlertService} from '../../services/alert/alert.service';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {Router} from '@angular/router';
import {RegionalService} from '../../services/regional/regional.service';
import {forkJoin} from 'rxjs';
import {isSuccess} from '@angular/http/src/http_utils';

@Component({
  selector: 'app-edit-regional-targets',
  templateUrl: './edit-regional-targets.component.html',
  styleUrls: ['./edit-regional-targets.component.scss']
})
export class EditRegionalTargetsComponent implements OnInit {
  rateSettingModel: RateSettingModel;
  regions: any[] = [];
  regionTotals: any[] = [];
  regionalTargets: any[] = [];
  dataSource: any[] = [];
  editRegionalTargetsFormGroup: FormGroup;
  displayedColumns: string[] = ['Month'];
  columnsToDisplay: string[] = [];
  minMonthlyTotals = [];
  months = [];
  requestData = {
    rateSettingId: 0,
    regionalTargets: []
  };

  constructor(private regionsService: RegionsService,
              private rateSettingActionsService: RateSettingActionsService,
              private _formBuilder: FormBuilder,
              private regionalService: RegionalService,
              private alertService: AlertService,
              private router: Router) {
  }

  ngOnInit() {
    this.rateSettingModel = this.rateSettingActionsService.getRateSettingMod();
    this.editRegionalTargetsFormGroup = this._formBuilder.group({
      dummy: [0]
    });
    forkJoin(this.regionsService.getMonthsForRegionalTargets(this.rateSettingModel.rateSettingId), this.regionsService.getRegions(),
      this.regionsService.getRegionalTargets( this.rateSettingModel.rateSettingId))
      .subscribe(result => {
        this.months = result[0];
        this.regions = result[1];
        this.regionalTargets = result[2];
        this.requestData.rateSettingId = this.rateSettingModel.rateSettingId;
        this.regions.forEach(region => {
          this.requestData.regionalTargets.push({
            region: region.region,
            monthlyTargets: []
          });
          this.regionTotals.push({
            region: region.region,
            total: 0
          });
          this.displayedColumns.push(region.region);
          this.rateSettingModel.regionalTargets.push({
            region: region.region,
            monthlyTargets: []
          });
        });

        this.displayedColumns.push('Total');
        this.columnsToDisplay = this.displayedColumns;

        this.months.forEach(month => {
          this.dataSource.push({
            month: month.month,
          });
          this.minMonthlyTotals.push({
            month: month.month,
            total: 0
          });
          this.regions.forEach(region => {
            this.editRegionalTargetsFormGroup.addControl(month.month + '-' + region.region,
              new FormControl(this.getPercentageForField(month.month, region.region)));
          });
        });

        this.editRegionalTargetsFormGroup.setValidators([this.minMonthPercentage()]);
      });
  }

  minMonthPercentage(min = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      this.months.forEach(month => {
        let totalSelected = 0;
        this.regions.forEach(region => {
          if (this.editRegionalTargetsFormGroup.get(month.month + '-' + region.region)) {
            totalSelected += formGroup.controls[month.month + '-' + region.region].value;
          }
        });
        this.minMonthlyTotals.forEach(mnthTotal => {
          if (mnthTotal.month === month.month) {
            mnthTotal.total = totalSelected;
          }
        });
      });
      return this.roundOffNumber(100) >= min ? null : {minMonthPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  getPercentageForField(month, region) {
    const value = this.regionalTargets.find(result => {
      return result.month === month && result.region === region;
    });

    return value && value.percentage || 0;
  }

  returnToDashboard() {
    localStorage.removeItem('currentRateSettingModel');
    this.router.navigate(['/home/revenueManagement']);
  }

  updateRegionalTargets() {
    this.requestData.regionalTargets.forEach(regionalTarget => {
      const region = regionalTarget.region;
      this.months.forEach((month, index) => {
        regionalTarget.monthlyTargets.push({
          month: month,
          target: this.editRegionalTargetsFormGroup.controls[month.month + '-' + region].value,
          randValue: (this.editRegionalTargetsFormGroup.controls[month.month + '-' + region].value / 100)
            * (this.rateSettingModel.fiscalTarget)
        });
      });
    });
    this.regionsService.updateRegionalTargets(this.requestData).subscribe(success => {
      this.returnToDashboard();
    });
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }


}
