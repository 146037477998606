import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sponsorship-dashboard',
  templateUrl: './sponsorship-dashboard.component.html',
  styleUrls: ['./sponsorship-dashboard.component.scss'],
})
export class SponsorshipDashboardComponent implements OnInit {

  selectedInsight: any;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  redirect(path) {
    this.router.navigate(['home/' + path]);
  }

  selectInsight(insight) {
    this.selectedInsight = insight;
  }

}
