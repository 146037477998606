import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {KeySlotDialogComponent} from '../key-slot-dialog/key-slot-dialog.component';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {AlertService} from '../../services/alert/alert.service';

@Component({
  selector: 'app-key-slot-targets',
  templateUrl: './key-slot-targets.component.html',
  styleUrls: ['./key-slot-targets.component.scss']
})
export class KeySlotTargetsComponent implements OnInit, OnDestroy {
  rateSettingModel: RateSettingModel;
  displayedColumns: string[] = ['month', 'percentage', 'randValue', 'mondayPercentage', 'mondayRandValue', 'tuesdayPercentage',
    'tuesdayRandValue', 'wednesdayPercentage', 'wednesdayRandValue', 'thursdayPercentage', 'thursdayRandValue', 'fridayPercentage',
    'fridayRandValue', 'saturdayPercentage', 'sundayRandValue', 'sundayPercentage', 'sundayRandValue', 'keySlotTargets'];
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  minMonthlyTotals = 0;
  monthDayTotals = 0;
  maxMonthlyTotals = 0;
  phasingPercentageFormGroup: FormGroup;

  constructor(public dialog: MatDialog, private rateSettingActionsService: RateSettingActionsService, private router: Router,
              private rateSettingService: RateSettingService, private alertService: AlertService, private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.rateSettingModel = this.rateSettingActionsService.getRateSettingMod();
    this.phasingPercentageFormGroup = this._formBuilder.group({
      dummy: [0]
    });
    if (this.rateSettingModel.months && this.rateSettingModel.months.length > 0) {
      this.rateSettingModel.months.forEach(month => {
        this.phasingPercentageFormGroup.addControl(month.month, new FormControl(0));
        this.daysOfWeek.forEach(day => {
          this.phasingPercentageFormGroup.addControl(month.month + '-' + day.toLowerCase(), new FormControl(0));
        });
      });

      this.phasingPercentageFormGroup.setValidators([this.minMonthPercentage(), this.maxMonthPercentage()]);
    }
  }

  openEditDialog(month, day) {
    this.rateSettingActionsService.setRateSettingMonth({month: month, rateSettingModel: this.rateSettingModel});
    this.router.navigate(['home/createRateSettingTool/captureKeySlotTarget']);
  }

  submitTargets() {
    this.rateSettingService.postRateSettingModel(this.rateSettingModel).subscribe(data => {
        this.rateSettingModel.rateSettingId = data.rateSettingId;
        this.rateSettingService.getStoredRateSettingsFromRemote().subscribe(success => {
          this.alertService.success('Rate card created', true);
          this.router.navigate(['home']);
        });
      },
      error => {
        this.alertService.error('Rate card not created', true);
        this.router.navigate(['home']);
      });
  }

  ngOnDestroy() {
  }

  minMonthPercentage(min = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0;
      this.rateSettingModel.months.forEach(month => {
        this.calculateRandValuesForMonth(month, formGroup);
        totalSelected += formGroup.controls[month.month].value;
      });
      this.minMonthlyTotals = this.roundOffNumber(totalSelected);
      this.maxMonthlyTotals = this.roundOffNumber(totalSelected);
      return this.roundOffNumber(totalSelected) >= min ? null : {minMonthPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  calculateRandValuesForMonth(month, formGroup) {
    month.randValue = this.roundOffNumber((formGroup.controls[month.month].value / 100) * this.rateSettingModel.fiscalTarget);
    this.daysOfWeek.forEach(day => {
      month[day.toLowerCase() + 'RandValue'] = this.roundOffNumber((month[day.toLowerCase() + 'Percentage'] / 100) * month.randValue);
    });
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }

  maxMonthPercentage(max = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0;
      this.rateSettingModel.months.forEach(month => {
        totalSelected += formGroup.controls[month.month].value;
      });
      return this.roundOffNumber(totalSelected) <= max ? null : {maxMonthPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

}
