export class User {
  userId: number;
  name: string;
  broadcasterId: number;
  roleId: number;
  token: string;
  email: string;
  role: string;

  constructor(_userId = 0, _name = '', _broadcasterId = 0, _email = '', _role_id, _role) {
    this.userId = _userId;
    this.name = _name;
    this.broadcasterId = _broadcasterId;
    this.email = _email;
    this.roleId = _role_id;
    this.role = _role;
    this.token = 'token' + _userId;
  }

}
