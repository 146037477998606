import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FlightingCodeModel} from '../../models/flighting-code.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-allocate-flighting-code-dialog',
  templateUrl: './allocate-flighting-code-dialog.component.html',
  styleUrls: ['./allocate-flighting-code-dialog.component.scss']
})
export class AllocateFlightingCodeDialogComponent implements OnInit {
  flightingCodeFormGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<AllocateFlightingCodeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: FlightingCodeModel, private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.flightingCodeFormGroup = this._formBuilder.group({
      newFlightingCode: ['', [<any>Validators.required]],
      createNew: [false]
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

  selectFlightCode(flightingCode) {
    this.data.flightingCode = flightingCode.code;
  }
}
