import {Injectable} from '@angular/core';
import {PackageModel} from '../../models/packageModel';
import {config, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  package: PackageModel;

  constructor(private http: HttpClient) {
  }

  createPackage(): PackageModel {
    this.package = new PackageModel();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.package.userId = currentUser.userId;
    this.package.broadcasterId = currentUser.broadcasterId;
    return this.package;
  }

  getCreatedPackage() {
    return JSON.parse(localStorage.getItem('createdPackage'));
  }

  confirmPackageCreation(packageModel: PackageModel): Observable<any> {
    this.package = packageModel;
    return this.postPackage(packageModel);
  }

  postPackage(packageModel: PackageModel): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `save_package.php`, [this.package]).pipe(map(data => {
      packageModel.campaignId = data.camp_no;
      packageModel.spots = data.saved_spots;
      packageModel.totalCost = data.total_cost;

      localStorage.removeItem('createdPackage');
      localStorage.setItem('createdPackage', JSON.stringify(packageModel));

      return packageModel;
    }));
  }
}
