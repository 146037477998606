import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProgramService} from '../../services/program/program.service';
import { EpisodeDialogClass } from 'src/app/classes/episode.dialog';

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { PGClass } from 'src/app/classes/parentalguidance';
import { SareClass } from 'src/app/classes/sare';
import { ProgrammeClass } from 'src/app/classes/programme';
import { SeasonClass } from 'src/app/classes/season';
import { EpisodeClass } from 'src/app/classes/episode';
import {map, startWith} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { BaseRatings } from 'src/app/classes/base_ratings';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { ReconSpots } from 'src/app/classes/recon.spots';
import { Reconcile } from 'src/app/classes/recon';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-recon-dialog',
  templateUrl: './recon-dialog.component.html',
  styleUrls: ['./recon-dialog.component.scss'],
})
export class ReconDialogComponent implements OnInit {
 

  spots_array: Reconcile[] = [];
  displayedColumns: string[] = ['date','start_time', 'advertiser_name','industry_code'];
  dataSource = new MatTableDataSource(this.spots_array);
  selection = new SelectionModel<Reconcile>(true, []);
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('paginator1') paginator1: MatPaginator;

  constructor(public dialogRef: MatDialogRef<ReconDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ReconDialogComponent,
              private httpClient: HttpClient,
              private alertService: AlertService,
              private formBuilder: FormBuilder) {

  
  } 
 
  ngOnInit() {  

      const entries = Object.entries(this.data);
      this.spots_array = [];

      for(var i=0;i<entries.length;i++)
      {
        console.log(entries[i][1]);
        this.spots_array[i] = {advertiser_name: entries[i][1].advertiser_name,break_time: entries[i][1].break_time,brek_sched_date: entries[i][1].brek_sched_date,industry_code: entries[i][1].industry_code,selectedAsrunID: entries[i][1].selectedAsrunID,selectedPlannedID: entries[i][1].selectedPlannedID}; 
      }

      this.dataSource = new MatTableDataSource(this.spots_array);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator1;
  }

  ionViewWillEnter(){

    
  }   

  cancel(): void {
    this.dialogRef.close();
  }
}
