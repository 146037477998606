export class RateCardModel {
  rate: number;
  rateCardId: string;
  broadcasterId: number;

  constructor(_rate = 0, _rateCardId, _broadcasterId) {
    this.rate = _rate;
    this.rateCardId = _rateCardId;
    this.broadcasterId = _broadcasterId;
  }
}
