import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProgramService} from '../../services/program/program.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {ProgramModel} from '../../models/program.model';

@Component({
  selector: 'app-program-selector',
  templateUrl: './program-selector.component.html',
  styleUrls: ['./program-selector.component.scss']
})
export class ProgramSelectorComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Output() SelectedItem = new EventEmitter<ProgramModel>();
  @Input() programs: any[];
  programCtrl: FormControl = new FormControl('', Validators.required);
  filteredPrograms: Observable<any[]>;

  constructor(private programService: ProgramService) {

  }

  ngOnInit() {
    // this.programService.getPrograms().subscribe(programs => this.programs = programs || []);
    this.filteredPrograms = this.programCtrl.valueChanges
      .pipe(
        startWith(''),
        map(program => program ? this._filterPrograms(program) : this.programs.slice())
      );
    this.formGroup.addControl('program', this.programCtrl);

  }

  private _filterPrograms(value: string): ProgramModel[] {
    const filterValue = value.toLowerCase();

    return this.programs.filter(program => program.prog_name.toLowerCase().indexOf(filterValue)  > -1);
  }
 
  onSelect(program) {
    this.SelectedItem.emit(program);
  }

}
