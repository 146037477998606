export class CampaignClass{
    camp_no: number;
    prod_code: number;
    no_of_spots: number;
    sst_date: string;
    end_date: string;
    pers_code: number;
    total_nominal_price: number;
    copy_weeks: string;
    status: string;
    camp_type: string;
    owner_code: number;
    agency_code: number;
    product_name: string;
    advertiser_name: string;
    budget: number;
    discount: number;
    business_type: string;
}