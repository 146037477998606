import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spots-report',
  templateUrl: './spots-report.component.html',
  styleUrls: ['./spots-report.component.scss'],
})
export class SpotsReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
