import {Component, Inject, OnInit} from '@angular/core';
import {formatDate} from '@angular/common';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {Router} from '@angular/router';
import {CampaignService} from '../../services/campaign/campaign.service';
import {User} from '../../models/user';
import {CampaignModel} from '../../models/campaign.model';
import {CampaignSpotService} from '../../services/campaignSpot/campaign-spot.service';
import {CampaignSpotsModel} from '../../models/campaignSpots.model';
import * as _moment from 'moment';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {ChannelService} from '../../services/channel/channel.service';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import { DOCUMENT } from '@angular/common';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMMMM/YYYY',
  },
  display: {
    dateInput: 'D MMMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-dashboard-content',
  templateUrl: './dashboard-content.component.html',
  styleUrls: ['./dashboard-content.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class DashboardContentComponent implements OnInit {
  currentUser: User;
  campaigns: CampaignModel[];
  filteredCampaigns: CampaignModel[] = [];
  campaignSpots: CampaignSpotsModel[];
  sent: number;
  approved: number;
  running: number;
  closed: number;
  monthPicked: any;
  dayPicked: any;
  channels: any[];
  selectedInsight: any;
  rateSettings = [];

  constructor(private router: Router,
              private campaignService: CampaignService,
              private campaignSpotService: CampaignSpotService,
              private channelService: ChannelService,
              private rateSettingService: RateSettingService,
              @Inject(DOCUMENT) private document: Document) {
  }
 
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.closed = 0;
    this.running = 0;
    this.approved = 0;
    this.sent = 0;
    this.filteredCampaigns = [];
    this.selectedInsight = null;

    // this.campaignService.getCampaigns().subscribe(campaigns => {
    //   this.campaigns = campaigns;
    //   if (campaigns && campaigns.length > 0) {
    //     this.monthPicked = formatDate(Date(), 'MMMM', 'en-za');
    //     this.filterByMonth(Date());
    //     this.loadDashboard();
    //   }
    // });
    // this.campaignSpotService.getCampaignSpots().subscribe(campaignSpots => {
    //   this.campaignSpots = campaignSpots;
    // });

    this.channelService.getChannelsForBroadcaster().subscribe(channels => this.channels = channels);
    this.monthPicked = formatDate(Date(), 'MMMM', 'en-za');
    this.dayPicked = formatDate(Date(), 'd MMMM', 'en-za');

    this.rateSettingService.getStoredRateSettings().subscribe(data => {
      this.rateSettings = data;
    });
  }

  loadDashboard() {
    this.closed = this.getStatusForCampaign('Proposed');
    this.running = this.getStatusForCampaign('Active');
    this.approved = this.getStatusForCampaign('Approved');
    this.sent = this.getStatusForCampaign('Sent');
  }

  getStatusForCampaign(statusName) {
    return this.filteredCampaigns.filter(function (campaign) {
      return campaign.status === statusName;
    }).length;
  }

  filterByMonth(date) {
    const currentMonth = moment(date).month() + 1;
    this.filteredCampaigns = [];
    this.campaigns.forEach(campaign => {
      if (moment(campaign.start_date).month() + 1 <= currentMonth ||
        moment(campaign.end_date).month() + 1 <= currentMonth) {
        this.filteredCampaigns.push(campaign);
      }
    });
    this.loadDashboard();
  }

  onChange(input) {
    this.monthPicked = formatDate(input.value, 'MMMM', 'en-za');
    this.filterByMonth(input.value);
  }

  viewCampaigns() {
    this.router.navigate(['home/campaigns']);
  }

  loadRates() {
    this.rateSettingService.getStoredRateSettings().subscribe(data => this.rateSettings = data);
  }

  selectInsight(insight) {
    this.selectedInsight = insight;
  }

  changePage(url) {

    this.document.location.href = url;
  }

}
