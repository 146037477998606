import {Injectable} from '@angular/core';
import {ProductModel} from '../../models/product.model';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  currentUser: User;
  mockProductsData: any = [
    {
      owner: '1 LIFE FUNERA 231560',
      owner_code: 'A31560',
      payer: 'UPSTREAM ADVE 101025',
      payer_code: 'B01025',
      prod_name: '1LIFE GENERIC',
      prod_category: 'Short Term Insurance',
      category_code: 'ST INS',
      prod_code: 31039
    },
    {
      owner: '100 PERCENT SA COMED',
      owner_code: 'A34779',
      payer: '100 PERCENT SA COMED',
      payer_code: 'A34779',
      prod_name: '100% SA COMEDY',
      prod_category: 'Direct Response',
      category_code: 'DIR/R',
      prod_code: 35030
    },
    {
      owner: 'ABSA  GROUP L 203303',
      owner_code: 'A03303',
      payer: 'THE MEDIA SHOP  PTY',
      payer_code: 'B00201',
      prod_name: 'Absa Input 2000',
      prod_category: 'Banking',
      category_code: 'BANK',
      prod_code: 332
    },
    {
      owner: 'GAME DISCOUNT 200279',
      owner_code: 'A00279',
      payer: 'MASSTORES PTY LTD TA',
      payer_code: 'B00985',
      prod_name: 'Game Tools & Gate Automation',
      prod_category: 'Retail - Miscellaneous',
      category_code: 'RT MIS',
      prod_code: 18850
    }];

  constructor(private http: HttpClient) {
  }

  getProductsFromRemote(queryString): Observable<ProductModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_products.php?broadcasterId=` + this.currentUser.broadcasterId
      + '&search=' + queryString)
      .pipe(map(products => {
        if (products && products.length > 0) {
          const mappedProducts = this.productMapper(products);
          localStorage.setItem(queryString + 'products', JSON.stringify(mappedProducts));
        }
        return this.productMapper(products);
      }));
  }

  getProductsFromLocal(queryString) {
    return JSON.parse(localStorage.getItem(queryString + 'products'));
  }

  getProducts(queryString): Observable<ProductModel[]> {
    const products = this.getProductsFromLocal(queryString);
    if (!products) {

      return this.getProductsFromRemote(queryString);
    }

    return of(products);
  }

  getProductForId(id, products): ProductModel {
    const product = products.find(prod => prod.productId === id);
    return product;
  }

  private productMapper(productData): ProductModel[] {
    let mappedSpotCampaigns: ProductModel[] = [];
    if (productData && productData.length > 0) {
      productData.forEach(function (product) {
        mappedSpotCampaigns.push({
          productId: product.prod_code || null,
          productName: product.prod_name || null,
          productCategory: product.prod_category || null,
          owner: product.owner || null,
          ownerId: product.owner_code || null,
          payer: product.payer || null,
          payer_Id: product.payer_code || null,
          categoryId: product.category_code || null
        });
      });
    }

    return mappedSpotCampaigns;
  }
}
