import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() placeholder: string;
  @Input() isRequired: boolean;
  @Output() SelectedItem = new EventEmitter<any>();
  DateCtrl: FormControl = new FormControl('', Validators.required);

  constructor() {
  }

  ngOnInit() {
    this.group.addControl('DateCtrl', this.DateCtrl);
  }

  onSelect(Date) {
    this.SelectedItem.emit(Date.value);
  }

}
