import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SponsorshipModel} from '../../models/sponsorship.model';
import {ProgramSegmentModel} from '../../models/program-segment.model';
import {SponsorshipElementModel} from '../../models/sponsorship-element.model';
import {BehaviorSubject} from 'rxjs';
import {RateCalculationService} from '../../services/rate-calculation/rate-calculation.service';
import {ElementService} from '../../services/element/element.service';

@Component({
  selector: 'app-sponosorship-elements',
  templateUrl: './sponosorship-elements.component.html',
  styleUrls: ['./sponosorship-elements.component.scss']
})
export class SponosorshipElementsComponent implements OnInit {
  @Input() sponsorshipElementsFormGroup: FormGroup;
  @Input() sponsorship: SponsorshipModel;
  @Input() rateCards: any[];
  elements: any[] = [];
  primaryElements: any[] = [];
  secondaryElements: any[] = [];
  durations: number[] = [5, 10, 15, 20, 25, 30, 35, 40, 55, 60, 65, 70, 75, 80, 85, 90];
  selected = [];
  elementNames: string[] = ['Opening Billboard', 'Sting', 'Promo commercial', 'Closing Billboard'];
  displayedColumns: string[] = ['name', 'duration', 'costPerElement', 'rateCardTotal',
    'discount', 'sponsorshipCost', 'removeElement'];
  dataSource = new BehaviorSubject([]);
  @Output() submitSponsorElements = new EventEmitter<SponsorshipModel>();

  constructor(private rateCalculationService: RateCalculationService, private elementService: ElementService) {
  }

  ngOnInit() {
    this.elementService.getElements().subscribe(elements => {
      this.elements = elements;
      this.primaryElements = elements.filter(el => {
        return el.elementType === 'primary';
      });
      this.secondaryElements = elements.filter(ele => {
        return ele.elementType === 'secondary';
      });
    });
  }

  onSubmitElements() {
    this.submitSponsorElements.emit(this.sponsorship);
  }

  selectDuration(duration) {
    return;
  }

  change(event) {
    if (event.isUserInput) {
      if (!event.source.selected) {
        this.selected.splice(this.selected.indexOf(event.source.value), 1);
      }
      if (event.source.selected) {
        this.selected.push(event.source.value);
      }
    }
  }

  changeDuration(el, value, index) {
    this.sponsorship.elements[index].duration = value;
    this.sponsorship.elements[index].rateCardTotal = this.getRateCalculation(value);
    this.dataSource.next(this.sponsorship.elements);
  }

  changeDiscount(element, index) {
    this.sponsorship.elements[index].sponsorshipCost = element.rateCardTotal * ((100 - element.discount) / 100);
    this.dataSource.next(this.sponsorship.elements);
  }

  getRate(rateCardId) {
    const rateC = this.rateCards.find(rateCard => rateCard.rateCardId === rateCardId);
    return rateC && rateC.rate || 0;
  }

  getRateCalculation(duration) {
    return this.rateCalculationService.ratios.find(ratio => ratio.seconds === duration).ratio;
  }

  addElement() {
    this.selected.forEach(el => {
      const element = new SponsorshipElementModel(el.element, el.elementType, parseFloat(el.indexValue),
        5, this.sponsorship.rateCardId,
        this.getRate(this.sponsorship.rateCardId), this.sponsorship.startDate,
        this.getRateCalculation(5),
        0);
      this.sponsorship.elements.push(element);
      this.dataSource.next(this.sponsorship.elements);
    });
  }

  removeElement(element) {
    this.sponsorship.elements.splice(this.sponsorship.elements.indexOf(element), 1);
    this.dataSource.next(this.sponsorship.elements);
  }
}
