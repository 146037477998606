import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
 
//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { GenreClass } from 'src/app/classes/genre';
import { ClashClass } from 'src/app/classes/clash';
import { CategoryClass } from 'src/app/classes/category';
import { LanguageClass } from 'src/app/classes/language';
import { RegionClass } from 'src/app/classes/regions';

@Component({
  selector: 'app-programme-category-list',
  templateUrl: './programme-category-list.component.html',
  styleUrls: ['./programme-category-list.component.scss'],
})
export class ProgrammeCategoryListComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  //Programme Categories - Multichoice
  genre_list: GenreClass[];  
  displayedColumns: string[] = ['select', 'category','color','edit'];
  dataSource: any;
  selection = new SelectionModel<GenreClass>(true, [], true);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild('paginator') paginator: MatPaginator;


  //Programme Categories - Sentech
  sentech_list: GenreClass[];  
  sentech_displayedColumns: string[] = ['select', 'category','code','color','edit'];
  sentech_dataSource: any;
  sentech_selection = new SelectionModel<GenreClass>(true, [], true);
  @ViewChild(MatTable) sentech_table: MatTable<any>;
  @ViewChild('sentech_paginator') sentech_paginator: MatPaginator;


  //Clash Categories
  clash_list: ClashClass[];  
  clash_displayedColumns: string[] = ['select', 'category','edit'];
  clash_dataSource: any;
  clash_selection = new SelectionModel<ClashClass>(true, [], true);
  @ViewChild(MatTable) clash_table: MatTable<any>;
  @ViewChild('clash_paginator') clash_paginator: MatPaginator;

  //Icasa Categories
  icasa_list: CategoryClass[];  
  icasa_displayedColumns: string[] = ['select', 'category','edit'];
  icasa_dataSource: any;
  icasa_selection = new SelectionModel<CategoryClass>(true, [], true);
  @ViewChild(MatTable) icasa_table: MatTable<any>;
  @ViewChild('icasa_paginator') icasa_paginator: MatPaginator;

  //Conytract Type
  contract_list: CategoryClass[];  
  contract_displayedColumns: string[] = ['select', 'category','edit'];
  contract_dataSource: any;
  contract_selection = new SelectionModel<CategoryClass>(true, [], true);
  @ViewChild(MatTable) contract_table: MatTable<any>;
  @ViewChild('contract_paginator') contract_paginator: MatPaginator;

  //Type Of Rights
  rights_list: CategoryClass[];  
  rights_displayedColumns: string[] = ['select', 'category','edit'];
  rights_dataSource: any;
  rights_selection = new SelectionModel<CategoryClass>(true, [], true);
  @ViewChild(MatTable) rights_table: MatTable<any>;
  @ViewChild('rights_paginator') rights_paginator: MatPaginator;

  //Languages
  language_list: LanguageClass[];   
  language_displayedColumns: string[] = ['select', 'language','edit'];
  language_dataSource: any;
  language_selection = new SelectionModel<LanguageClass>(true, [], true);
  @ViewChild(MatTable) language_table: MatTable<any>;
  @ViewChild('language_paginator') language_paginator: MatPaginator;

  //Regions
  region_list: RegionClass[];   
  region_displayedColumns: string[] = ['select', 'region','edit'];
  region_dataSource: any;
  region_selection = new SelectionModel<RegionClass>(true, [], true);
  @ViewChild(MatTable) region_table: MatTable<any>;
  @ViewChild('region_paginator') region_paginator: MatPaginator;

  //Media
  media_list: CategoryClass[];   
  media_displayedColumns: string[] = ['select', 'media','edit'];
  media_dataSource: any;
  media_selection = new SelectionModel<CategoryClass>(true, [], true);
  @ViewChild(MatTable) media_table: MatTable<any>;
  @ViewChild('media_paginator') media_paginator: MatPaginator;


  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient, private loadingCtrl: LoadingController, private navCtrl: NavController, public dialog: MatDialog,) {

    this.onReadSentechGenries();
    this.onReadGenreies();
    this.onReadClashes();
    this.onReadIcasa();
    this.onReadContracts();
    this.onReadRights();
    this.onReadLanguages();
    this.onReadRegion();
    this.onReadMedias();
  }

  ngOnInit() {
   
    
  }

  ionViewWillEnter(){

  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  sentech_isAllSelected() {
    const numSelected = this.sentech_selection.selected.length;
    const numRows = this.sentech_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  sentech_masterToggle() {
    this.sentech_isAllSelected() ?
      this.sentech_selection.clear() :
      this.sentech_dataSource.data.forEach(row => this.sentech_selection.select(row));
  }

  clash_isAllSelected() {
    const numSelected = this.clash_selection.selected.length;
    const numRows = this.clash_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  clash_masterToggle() {
    this.clash_isAllSelected() ?
      this.clash_selection.clear() :
      this.clash_dataSource.data.forEach(row => this.clash_selection.select(row));
  }

  icasa_isAllSelected() {
    const numSelected = this.icasa_selection.selected.length;
    const numRows = this.icasa_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  icasa_masterToggle() {
    this.icasa_isAllSelected() ?
      this.icasa_selection.clear() :
      this.icasa_dataSource.data.forEach(row => this.icasa_selection.select(row));
  }

  contract_isAllSelected() {
    const numSelected = this.contract_selection.selected.length;
    const numRows = this.contract_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  contract_masterToggle() {
    this.contract_isAllSelected() ?
      this.contract_selection.clear() :
      this.contract_dataSource.data.forEach(row => this.contract_selection.select(row));
  }

  rights_isAllSelected() {
    const numSelected = this.rights_selection.selected.length;
    const numRows = this.rights_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  rights_masterToggle() {
    this.contract_isAllSelected() ?
      this.rights_selection.clear() :
      this.rights_dataSource.data.forEach(row => this.rights_selection.select(row));
  }

  language_isAllSelected() {
    const numSelected = this.language_selection.selected.length;
    const numRows = this.language_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  language_masterToggle() {
    this.language_isAllSelected() ?
    this.language_selection.clear() :
    this.language_dataSource.data.forEach(row => this.language_selection.select(row));
  }


  region_isAllSelected() {
    const numSelected = this.region_selection.selected.length;
    const numRows = this.region_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  region_masterToggle() {
    this.region_isAllSelected() ?
    this.region_selection.clear() :
    this.region_dataSource.data.forEach(row => this.region_selection.select(row));
  }

  media_isAllSelected() {
    const numSelected = this.media_selection.selected.length;
    const numRows = this.media_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  media_masterToggle() {
    this.media_isAllSelected() ?
    this.media_selection.clear() :
    this.media_dataSource.data.forEach(row => this.media_selection.select(row));
  }


  openDeleteMediaDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected media ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.media_selection.selected.forEach(media => {          
          this.deleteMedia(media.idNo);
        });
        
        this.media_selection.clear();
        this.alertService.success('Media successfully deleted')
      }
    });
  } 

  async deleteMedia(mediaId)
  {
    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_media.php?mediaId=${mediaId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadMedias();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  openDeleteRegionDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected region ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.region_selection.selected.forEach(region => {          
          this.deleteRegion(region.reg_Id);
        });
        
        this.region_selection.clear();
        this.alertService.success('Region successfully deleted')
      }
    });
  } 

  async deleteRegion(regionId)
  {

    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_region.php?regionId=${regionId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadRegion(); 
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  openDeleteLanguageDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected language ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.language_selection.selected.forEach(language => {          
          this.deleteLanguage(language.idNo);
        });
        
        this.language_selection.clear();
        this.alertService.success('Language successfully deleted')
      }
    });
  }  

  async deleteLanguage(languageId)
  {

    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_language.php?languageId=${languageId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadLanguages(); 
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  openDeleteContractDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected category?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.contract_selection.selected.forEach(contract => {          
          this.deleteContractType(contract.idNo);
        });
        
        this.contract_selection.clear();
        this.alertService.success('Category successfully deleted')
      }
    });
  } 

  async deleteContractType(contractId)
  {

    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_contract_type.php?contractId=${contractId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadContracts(); 
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  openDeleteRightsDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected category?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.rights_selection.selected.forEach(contract => {          
          this.deleteRightsType(contract.idNo);
        });
        
        this.rights_selection.clear();
        this.alertService.success('Category successfully deleted')
      }
    });
  } 

  async deleteRightsType(contractId)
  {

    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_type_of_rights.php?contractId=${contractId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadRights(); 
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected category?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.selection.selected.forEach(genre => {          
          this.deleteGenre(genre.genreId);
        });
        
        this.selection.clear();
        this.alertService.success('Category successfully deleted')
      }
    });
  } 

  async deleteGenre(genreId)
  {

    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_genre.php?genreId=${genreId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadGenreies();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  openDeleteSentechDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected category?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.sentech_selection.selected.forEach(genre => {          
          this.deleteSentechGenre(genre.genreId);
        });
        
        this.sentech_selection.clear();
        this.alertService.success('Category successfully deleted')
      }
    });
  } 

  async deleteSentechGenre(genreId)
  {

    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_sentech_genre.php?genreId=${genreId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadSentechGenries();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  openDeleteClashDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected category?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.clash_selection.selected.forEach(clash => {          
          this.deleteClash(clash.idNo);
        });
        
        this.clash_selection.clear();
        this.alertService.success('Category successfully deleted')
      }
    });
  } 

  async deleteClash(clashId)
  {

    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_clash.php?clashId=${clashId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadClashes();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  openDeleteIcasaDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected category?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.icasa_selection.selected.forEach(category => {          
          this.deleteIcasa(category.idNo);
        });
        
        this.icasa_selection.clear();
        this.alertService.success('Category successfully deleted')
      }
    });
  } 

  async deleteIcasa(icasaId)
  {

    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/delete_icasa_category.php?icasaId=${icasaId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadIcasa();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  async onReadMedias(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_media_categories.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.media_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.media_list[i] = {channel: data[i].channel,idNo: data[i].idNo,name: data[i].name};
        } 

        this.media_dataSource = new MatTableDataSource<CategoryClass>(this.media_list);
        this.media_dataSource.paginator = this.media_paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async onReadRegion(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_regions.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
          
        this.region_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.region_list[i] = {reg_Id: data[i].reg_Id,name: data[i].name};
        } 

        this.region_dataSource = new MatTableDataSource<RegionClass>(this.region_list);
        this.region_dataSource.paginator = this.region_paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async onReadLanguages(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_languages.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.language_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.language_list[i] = {idNo: data[i].idNo,bdcr_no: data[i].bdcr_no,language: data[i].language};
        } 

        this.language_dataSource = new MatTableDataSource<LanguageClass>(this.language_list);
        this.language_dataSource.paginator = this.language_paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async onReadClashes(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_clashes.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.clash_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.clash_list[i] = {idNo: data[i].idNo,clash: data[i].clash,channel: data[i].channel};
        } 

        this.clash_dataSource = new MatTableDataSource<ClashClass>(this.clash_list);
        this.clash_dataSource.paginator = this.clash_paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async onReadRights(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_types_of_rights.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.rights_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.rights_list[i] = {idNo: data[i].idNo,channel: data[i].channel,name: data[i].name};
        } 

        this.rights_dataSource = new MatTableDataSource<CategoryClass>(this.rights_list);
        this.rights_dataSource.paginator = this.rights_paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async onReadIcasa(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_icasa_categories.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.icasa_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.icasa_list[i] = {idNo: data[i].idNo,channel: data[i].channel,name: data[i].name};
        } 

        this.icasa_dataSource = new MatTableDataSource<CategoryClass>(this.icasa_list);
        this.icasa_dataSource.paginator = this.icasa_paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async onReadGenreies(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_genries.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.genre_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.genre_list[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,genreId: data[i].genreId,color: data[i].color,code: data[i].code};
        } 

        this.dataSource = new MatTableDataSource<GenreClass>(this.genre_list);
        this.dataSource.paginator = this.paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    )  
  }

  async onReadSentechGenries(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_sentech_genries.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.sentech_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.sentech_list[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,genreId: data[i].genreId,color: data[i].color,code: data[i].code};
        } 

        this.sentech_dataSource = new MatTableDataSource<GenreClass>(this.sentech_list);
        this.sentech_dataSource.paginator = this.sentech_paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    )  
  }

  async onReadContracts(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/read_contract_types.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.contract_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.contract_list[i] = {channel: data[i].channel,idNo: data[i].idNo,name: data[i].name};
        } 

        this.contract_dataSource = new MatTableDataSource<CategoryClass>(this.contract_list);
        this.contract_dataSource.paginator = this.paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  editGenre(genreId){ 

    localStorage.setItem('genreId', JSON.stringify(genreId));
    this.router.navigate(['/home/view-category']);
  }

  editSentechGenre(genreId){
    localStorage.setItem('genreId', JSON.stringify(genreId));
    this.router.navigate(['/home/view-sentech-genre']);
  }

  editClash(clashId){

    localStorage.setItem('clashId', JSON.stringify(clashId));
    this.router.navigate(['/home/view-clash']);
  }

  editIcasa(icasaId){

    localStorage.setItem('icasaId', JSON.stringify(icasaId));
    this.router.navigate(['/home/view-icasa']);
  }

  addNewContractType(){
    this.router.navigate(['/home/new-contract-type']);
  }

  addNewRightsType(){
    this.router.navigate(['/home/new-type-of-right']);
  }

  addNewLanguage(){
    this.router.navigate(['/home/new-language']);
  }

  addNewRegion(){
    this.router.navigate(['/home/new-region']);
  }

  addNewMedia(){
    this.router.navigate(['/home/new-media']);
  }

  editRegion(regionId){
    localStorage.setItem('regionId', JSON.stringify(regionId));
    this.router.navigate(['/home/view-region']);
  }

  editMedia(mediaId){
    localStorage.setItem('mediaId', JSON.stringify(mediaId));
    this.router.navigate(['/home/view-media']);
  }

  editLanguage(languageId){
    localStorage.setItem('languageId', JSON.stringify(languageId));
    this.router.navigate(['/home/view-language']);
  }

  editRightsType(rightsId){
    localStorage.setItem('rightsId', JSON.stringify(rightsId));
    this.router.navigate(['/home/view-type-of-right']);
  }

  editContractType(contractId){
    localStorage.setItem('contractId', JSON.stringify(contractId));
    this.router.navigate(['/home/view-contract-type']);
  }

  addNewIcasa(){
    this.router.navigate(['/home/new-icasa']);
  }
 
  addNewGenre(){
    this.router.navigate(['/home/new-category']);
  }

  addNewSentechGenre(){
    this.router.navigate(['/home/new-sentech-genre']);
  }

  addNewClash(){
    this.router.navigate(['/home/new-clash']);
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }
}