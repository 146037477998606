import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ChannelService} from '../../services/channel/channel.service';
import {AudienceGuaranteedModel} from '../../models/audience-guaranteed.model';

@Component({
  selector: 'app-channel-target-selector',
  templateUrl: './channel-target-selector.component.html',
  styleUrls: ['./channel-target-selector.component.scss']
})
export class ChannelTargetSelectorComponent implements OnInit {
  @Input() group: FormGroup;
  @Output() setSelectedChannels = new EventEmitter<any>();
  channels: any[];
  channelSelectorCtrl: FormControl = new FormControl('', Validators.required);
  selected = [];

  constructor(private channelService: ChannelService) {
  }

  ngOnInit() {
    this.channelService.getChannelsForBroadcaster().subscribe(channels => {
      this.channels = channels || [];
    });
    this.group.addControl('channel', this.channelSelectorCtrl);
  }

  change(event) {
    if (event.isUserInput) {
      if (!event.source.selected) {
        this.selected.splice(this.selected.indexOf(event.source.value), 1);
        this.group.removeControl(event.source.value.channelId + 'TargetCtrl');
        this.setSelectedChannels.emit(this.selected);
      }
      if (event.source.selected) {
        this.selected.push(event.source.value);
        const ctrl = new FormControl(0, Validators.required);
        this.group.addControl(event.source.value.channelId + 'TargetCtrl', ctrl);
        this.setSelectedChannels.emit(this.selected);
      }
    }
  }

}
