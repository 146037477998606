import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {CampaignSpotModel} from '../../models/campaign-spot.model';
import {ProgramService} from '../../services/program/program.service';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-edit-campaign-spot-dialog',
  templateUrl: './edit-campaign-spot-dialog.component.html',
  styleUrls: ['./edit-campaign-spot-dialog.component.scss']
})
export class EditCampaignSpotDialogComponent implements OnInit {
  campaignSpotFormGroup: FormGroup;
  channel: any;
  programs: any[];

  constructor(public dialogRef: MatDialogRef<EditCampaignSpotDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CampaignSpotModel,
              private _formBuilder: FormBuilder,
              private programService: ProgramService) {
  }

  ngOnInit() {
    this.campaignSpotFormGroup = this._formBuilder.group({
      breakScheduledDate: [''],
      startTime: ['']
    });
  }

  selectDate(date) {
    this.data.breakScheduledDate = moment(date).format('YYYY/MM/DD');
  }

  selectChannel(channel) {
    this.data.channelName = channel.channelName;
    this.data.channelId = channel.channelId;
    this.channel = channel;
  }

  selectDuration(duration) {
    this.data.duration = duration;
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
