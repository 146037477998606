import {Injectable} from '@angular/core';
import {SponsorshipSpotModel} from '../../models/sponsorship-spot.model';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SponsorshipSpotService {
  currentUser: User;

  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }

  getSponsorshipSpotsFromRemote(sponsorshipId): Observable<SponsorshipSpotModel[]> {
    return this.http.get<any>(environment.serverUrl + `get_sponsorship_spots.php?spon_no=` + sponsorshipId)
      .pipe(map(sponsorshipSpots => {
        if (sponsorshipSpots && sponsorshipSpots.length > 0) {
          const mappedSponsorshipSpots = this.sponsorshipSpotMapper(sponsorshipSpots);
          localStorage.setItem(this.setKeyForBroadcaster(sponsorshipId), JSON.stringify(mappedSponsorshipSpots));
        }
        return this.sponsorshipSpotMapper(sponsorshipSpots);
      }));
  }

  setKeyForBroadcaster(sponsorshipId) {
    return 'sponsorshipSpots-' + sponsorshipId;
  }

  getSponsorshipSpotsFromLocal(sponsorshipId) {
    return JSON.parse(localStorage.getItem(this.setKeyForBroadcaster(sponsorshipId)));
  }

  getSponsorshipSpots(sponsorshipId): Observable<SponsorshipSpotModel[]> {
    const sponsorshipSpots = this.getSponsorshipSpotsFromLocal(sponsorshipId);
    if (!sponsorshipSpots) {
      return this.getSponsorshipSpotsFromRemote(sponsorshipId);
    }

    return of(sponsorshipSpots);
  }

  private sponsorshipSpotMapper(sponsorshipSpotData): SponsorshipSpotModel[] {
    return sponsorshipSpotData.map(sponsorshipSpot => new SponsorshipSpotModel(sponsorshipSpot.element, sponsorshipSpot.segment_no,
      sponsorshipSpot.duration, sponsorshipSpot.rcrd_no, sponsorshipSpot.rate, sponsorshipSpot.brek_sched_date, sponsorshipSpot.day,
      sponsorshipSpot.industry_code, sponsorshipSpot.discount, sponsorshipSpot.sponsorshipCost, sponsorshipSpot.rateCardTotal,
      sponsorshipSpot.element_id));
  }
}
