export class EpisodeDialogClass{
    idNo: number;
    prog_no: number;
    seas_no: number;
    epi_no: number;
    exhibition_no: number;

    prog_name: string;
    epi_start: string;
    epi_end: string;
    epi_desc: string;
} 