import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-start-date-picker',
  templateUrl: './start-date-picker.component.html',
  styleUrls: ['./start-date-picker.component.scss']
})
export class StartDatePickerComponent implements OnInit {
  @Input() group: FormGroup;
  @Output() SelectedItem = new EventEmitter<any>();
  startDateCtrl: FormControl = new FormControl('', Validators.required);

  constructor() {
  }

  ngOnInit() {
    this.group.addControl('startDateCtrl', this.startDateCtrl);
  }

  onSelect(startDate) {
    this.SelectedItem.emit(startDate.value);
  }

}
