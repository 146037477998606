import {Component, Inject, OnInit} from '@angular/core';
import {CampaignSpotModel} from '../../models/campaign-spot.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EditCampaignSpotDialogComponent} from '../edit-campaign-spot-dialog/edit-campaign-spot-dialog.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-key-slot-dialog',
  templateUrl: './key-slot-dialog.component.html',
  styleUrls: ['./key-slot-dialog.component.scss']
})
export class KeySlotDialogComponent implements OnInit {
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  hoursInDay = [
    {
      startTime: '6:00:00 AM',
      endTime: '6:59:59 AM'
    },
    {
      startTime: '7:00:00 AM',
      endTime: '7:59:59 AM'
    },
    {
      startTime: '8:00:00 AM',
      endTime: '8:59:59 AM'
    },
    {
      startTime: '9:00:00 AM',
      endTime: '9:59:59 AM'
    },
    {
      startTime: '10:00:00 AM',
      endTime: '10:59:59 AM'
    },
    {
      startTime: '11:00:00 AM',
      endTime: '11:59:59 AM'
    },
    {
      startTime: '12:00:00 PM',
      endTime: '12:59:59 PM'
    },
    {
      startTime: '1:00:00 PM',
      endTime: '1:59:59 PM'
    },
    {
      startTime: '2:00:00 PM',
      endTime: '2:59:59 PM'
    },
    {
      startTime: '3:00:00 PM',
      endTime: '3:59:59 PM'
    },
    {
      startTime: '4:00:00 PM',
      endTime: '4:59:59 PM'
    },
    {
      startTime: '5:00:00 PM',
      endTime: '5:59:59 PM'
    },
    {
      startTime: '6:00:00 PM',
      endTime: '6:59:59 PM'
    },
    {
      startTime: '7:00:00 PM',
      endTime: '7:59:59 PM'
    },
    {
      startTime: '8:00:00 PM',
      endTime: '8:59:59 PM'
    },
    {
      startTime: '9:00:00 PM',
      endTime: '9:59:59 PM'
    },
    {
      startTime: '10:00:00 PM',
      endTime: '10:59:59 PM'
    },
    {
      startTime: '11:00:00 PM',
      endTime: '11:59:59 PM'
    },
    {
      startTime: '12:00:00 AM',
      endTime: '12:59:59 AM'
    },
  ];
  keySlotFormDialogGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditCampaignSpotDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.keySlotFormDialogGroup = this._formBuilder.group({
      daysInMonth: [0, <any>Validators.required]
    });
    this.daysOfWeek.forEach(day => {
      this.keySlotFormDialogGroup.addControl(day, new FormControl('', <any>Validators.required));
      this.hoursInDay.forEach(hour => {
        this.keySlotFormDialogGroup.addControl(day + '-target-' + hour.startTime, new FormControl(0));
      });
    });
  }

  getRevTarget(day, hour) {
    const target = this.keySlotFormDialogGroup.controls[day + '-target-' + hour.startTime].value;
    if (target) {
      return this.data.month[day.toLowerCase() + 'RandValue'] * (target / 100);
    } else {
      return 0;
    }
  }

  saveData(): void {
    if (this.keySlotFormDialogGroup.valid) {
      this.daysOfWeek.forEach(day => {
        this.hoursInDay.forEach(hour => {
          this.data.month[day.toLowerCase() + 'KeySlots'].push({
            startTime: hour.startTime,
            endTime: hour.endTime,
            target: this.keySlotFormDialogGroup.controls[day + '-target-' + hour.startTime].value,
            revTarget: this.getRevTarget(day, hour),
          });
        });
      });
      this.dialogRef.close(this.data);
    }

  }

  cancel(): void {
    this.dialogRef.close();
  }

}
