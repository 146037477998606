import {AdvertiserModel} from './advertiser.model';
import {AgencyModel} from './agency.model';

export class IntegratedTradingModel {
  campaignId: number;
  programName: string;
  programId: number;
  productId: number;
  broadcasterId: number;
  discountPercentage: number;
  userId: number;
  breakType: string;
  netBudget: number;
  uploadedFile: any;
  advertiser: AdvertiserModel;
  agency: AgencyModel;
  spots: number;
  totalCost: number;
}
