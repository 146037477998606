import {Component, OnInit, ViewChild} from '@angular/core';
import {SponsorshipService} from '../../services/sponsorship/sponsorship.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {SponsorshipTableModel} from '../../models/sponsorship-table.model';
import {Location} from '@angular/common';

import * as _moment from 'moment';
 
const moment = _moment;

@Component({
  selector: 'app-sponsorships',
  templateUrl: './sponsorships.component.html',
  styleUrls: ['./sponsorships.component.scss']
})
export class SponsorshipsComponent implements OnInit {

  sponsorships: SponsorshipTableModel[];
  displayedColumns: string[] = ['sponsorshipId', 'start_date', 'end_date', 'product', 'advertiser', 'agency', 'numberOfElements',
    'totalNominalPrice', 'viewSpots'];
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private sponsorshipService: SponsorshipService, private alertService: AlertService, private router: Router,private _location: Location) {
  }

  ngOnInit() {
    this.sponsorshipService.getSponsorships().subscribe(sponsorships => {
      this.sponsorships = sponsorships;
      this.dataSource = new MatTableDataSource<SponsorshipTableModel>(sponsorships);
      this.dataSource.paginator = this.paginator;
    }, error => {
      this.alertService.error("Something went wrong, Try again");
    });
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  formatDate(date) {
    return moment(date).format('YYYY/MM/DD');
  }

  viewSpots(sponsorship) {
    localStorage.setItem('selectedSponsorship', JSON.stringify(sponsorship));
    this.router.navigate(['/home/sponsorshipSpots']);
  }

  back(){
    this._location.back();
  }

}
