import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RateSettingModel} from '../../models/rate-setting-model';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {MatDialog} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-view-rate-targets',
  templateUrl: './view-rate-targets.component.html',
  styleUrls: ['./view-rate-targets.component.scss']
})
export class ViewRateTargetsComponent implements OnInit {
  rateSettingModel: RateSettingModel;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  displayedColumns: string[] = ['month', 'percentage', 'randValue', 'mondayPercentage', 'tuesdayPercentage',
    'wednesdayPercentage', 'thursdayPercentage', 'fridayPercentage', 'saturdayPercentage', 'sundayPercentage',
    'keySlotTargets', 'exportRates'];
  editPhasingPercentageFormGroup: FormGroup;
  minMonthlyTotals = 0;
  monthDayTotals = 0;
  maxMonthlyTotals = 0;
  hoursInDay = [
    {
      startTime: '06:00',
      endTime: '6:59:59 AM'
    },
    {
      startTime: '07:00',
      endTime: '7:59:59 AM'
    },
    {
      startTime: '08:00',
      endTime: '8:59:59 AM'
    },
    {
      startTime: '09:00',
      endTime: '9:59:59 AM'
    },
    {
      startTime: '10:00',
      endTime: '10:59:59 AM'
    },
    {
      startTime: '11:00',
      endTime: '11:59:59 AM'
    },
    {
      startTime: '12:00',
      endTime: '12:59:59 PM'
    },
    {
      startTime: '13:00',
      endTime: '1:59:59 PM'
    },
    {
      startTime: '14:00',
      endTime: '2:59:59 PM'
    },
    {
      startTime: '15:00',
      endTime: '3:59:59 PM'
    },
    {
      startTime: '16:00',
      endTime: '4:59:59 PM'
    },
    {
      startTime: '17:00',
      endTime: '5:59:59 PM'
    },
    {
      startTime: '18:00',
      endTime: '6:59:59 PM'
    },
    {
      startTime: '19:00',
      endTime: '7:59:59 PM'
    },
    {
      startTime: '20:00',
      endTime: '8:59:59 PM'
    },
    {
      startTime: '21:00',
      endTime: '9:59:59 PM'
    },
    {
      startTime: '22:00',
      endTime: '10:59:59 PM'
    },
    {
      startTime: '23:00',
      endTime: '11:59:59 PM'
    },
    {
      startTime: '00:00',
      endTime: '12:59:59 AM'
    },
  ];

  constructor(public dialog: MatDialog, private rateSettingActionsService: RateSettingActionsService, private router: Router,
              private _formBuilder: FormBuilder, private rateSettingService: RateSettingService,private _location: Location) {
  }

  ngOnInit() {
    this.rateSettingModel = this.rateSettingActionsService.getRateSettingMod();
    this.editPhasingPercentageFormGroup = this._formBuilder.group({
      dummy: [0]
    });
    if (this.rateSettingModel.months && this.rateSettingModel.months.length > 0) {
      this.rateSettingModel.months.forEach(month => {
        this.editPhasingPercentageFormGroup.addControl(month.month, new FormControl(0));
        this.daysOfWeek.forEach(day => {
          this.editPhasingPercentageFormGroup.addControl(month.month + '-' + day.toLowerCase(), new FormControl(0));
        });
        this.rateSettingService.getKeySlotsForRateSetting(this.rateSettingModel.rateSettingId, month.month)
          .subscribe(ratesData => {
            this.daysOfWeek.forEach(day => {
              const daySlot = ratesData.find(slot => {
                return slot.day === day;
              });
              if (daySlot) {
                month[day.toLowerCase() + 'KeySlots'] = daySlot.slots;
              } else {
                month[day.toLowerCase() + 'KeySlots'] = this.createSlots();
              }
            });

          });
      });

      this.editPhasingPercentageFormGroup.setValidators([this.minMonthPercentage(), this.maxMonthPercentage()]);
    }
  }

  createSlots() {
    return this.hoursInDay.map(hour => {
      return {
        startTime: hour.startTime,
        target: 0,
        revTarget: 0,
        minNetRatePerSlot: 0,
        minGrossPerSlot: 0
      };
    });
  }

  openEditDialog(month) {
    this.rateSettingActionsService.setRateSettingMonth({month: month, rateSettingModel: this.rateSettingModel});
    this.router.navigate(['home/dayPartTargets']);
  }

  exportRates(month) {
    this.rateSettingActionsService.setRateSettingMonth({month: month, rateSettingModel: this.rateSettingModel});
    this.router.navigate(['home/exportDayPartTargets']);
    // this.router.navigate(['home/exportRates']);
  }

  minMonthPercentage(min = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0;
      this.rateSettingModel.months.forEach(month => {
        this.calculateRandValuesForMonth(month, formGroup);
        totalSelected += formGroup.controls[month.month].value;
      });
      this.minMonthlyTotals = this.roundOffNumber(totalSelected);
      this.maxMonthlyTotals = this.roundOffNumber(totalSelected);
      return this.roundOffNumber(totalSelected) >= min ? null : {minMonthPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  calculateRandValuesForMonth(month, formGroup) {
    month.randValue = this.roundOffNumber((formGroup.controls[month.month].value / 100) * this.rateSettingModel.fiscalTarget);
    this.daysOfWeek.forEach(day => {
      month[day.toLowerCase() + 'RandValue'] = this.roundOffNumber((month[day.toLowerCase() + 'Percentage'] / 100) * month.randValue);
    });
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }

  maxMonthPercentage(max = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0;
      this.rateSettingModel.months.forEach(month => {
        totalSelected += formGroup.controls[month.month].value;
      });
      return this.roundOffNumber(totalSelected) <= max ? null : {maxMonthPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  returnToDashboard() {
    localStorage.removeItem('currentRateSettingModel');
    this.router.navigate(['/home/revenueManagement']);
  }

  back(){
    this._location.back();
  }
}
