import {Component, Input, OnInit} from '@angular/core';
import {AudienceGuaranteedModel} from '../../models/audience-guaranteed.model';
import {Router} from '@angular/router';

@Component({
  selector: 'app-audience-guaranteed-trading-summary',
  templateUrl: './audience-guaranteed-trading-summary.component.html',
  styleUrls: ['./audience-guaranteed-trading-summary.component.scss']
})
export class AudienceGuaranteedTradingSummaryComponent implements OnInit {
  @Input() currentModel: AudienceGuaranteedModel;
  @Input() complete: boolean;
  @Input() product: any;

  constructor(private router: Router) {
  }

  ngOnInit() {

  }

  done() {
    this.router.navigate(['/', 'home']);
  }

}
