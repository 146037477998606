import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map, startWith} from 'rxjs/operators';
import {ChannelService} from '../../services/channel/channel.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {ChannelModel} from '../../models/channel.model';

@Component({
  selector: 'app-channel-auto-complete',
  templateUrl: './channel-auto-complete.component.html',
  styleUrls: ['./channel-auto-complete.component.scss']
})
export class ChannelAutoCompleteComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Output() SelectedItem = new EventEmitter<ChannelModel>();
  channels: any[];
  channelCtrl: FormControl = new FormControl('', Validators.required);
  filteredChannels: Observable<ChannelModel[]>;

  constructor(private channelService: ChannelService) {
  }

  ngOnInit() {
    this.channelService.getChannelsForBroadcaster().subscribe(channels => {
      this.channels = channels || [];
      this.filteredChannels = this.channelCtrl.valueChanges
        .pipe(
          startWith(''),
          map(channel => channel ? this._filterchannels(channel) : this.channels.slice())
        );
    });

    this.formGroup.addControl('channel', this.channelCtrl);

  }

  private _filterchannels(value: string): ChannelModel[] {
    const filterValue = value.toLowerCase();

    return this.channels.filter(channel => channel.channelName.toLowerCase().indexOf(filterValue) > -1);
  }

  onSelect(channel) {
    this.SelectedItem.emit(channel);
  }

}
