import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { GenreClass } from 'src/app/classes/genre';
import { SareClass } from 'src/app/classes/sare';

@Component({
  selector: 'app-new-programme-category',
  templateUrl: './new-programme-category.component.html',
  styleUrls: ['./new-programme-category.component.scss'],
})
export class NewProgrammeCategoryComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  genreFormGroup: FormGroup;

  channels: SareClass[] = [];
  genre: GenreClass = new GenreClass();

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {
    
    this.genre.bdcr_no = 10;
    this.genre.color = '#142FB8';
  }

  ngOnInit() {
   
    this.genreFormGroup = this.formBuilder.group({ 
      genreCtrl: [this.genre.genre, Validators.required],
      channelNoCtrl: [this.genre.bdcr_no, Validators.required],
      colorCtrl: [this.genre.color, Validators.required],
    });
   
    this.onChannels();
  }

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: 0,
      date: '',
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
		 this.channels = [];
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  async saveGenre(){ 

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/save_genre.php`,
    {
      genre: this.genre.genre,
      bdcr_no: this.genre.bdcr_no,
      color: this.genre.color,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {

          this.genreFormGroup.reset();
          this.alertService.success("Category successfully saved");
          localStorage.setItem('genreId', JSON.stringify(0));
          this.router.navigate(['/home/category-list']);
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  selectColor(color)
  {
    this.genre.color = color;
  }


  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}