import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {ChannelService} from '../../services/channel/channel.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-regional-table',
  templateUrl: './regional-table.component.html',
  styleUrls: ['./regional-table.component.scss']
})
export class RegionalTableComponent implements OnInit {
  @Input() revManFormGroup: FormGroup;
  @Input() channels: any[];
  selectedChannel: any;
  rateSettings: RateSettingModel[];
  displayedColumns: string[] = ['number', 'startMonth', 'endMonth', 'fiscalTarget', 'channel', 'edit'];
  dataSource: any;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private rateSettingService: RateSettingService, private channelService: ChannelService,
              private rateSettingActionsService: RateSettingActionsService, private router: Router,
              private alertService: AlertService) {
  }

  ngOnInit() {
    this.rateSettingService.getStoredRateSettings().subscribe(data => {
      this.rateSettings = data;
    });
  }

  setData(filtertedRateSettings) {

    filtertedRateSettings.forEach(setting => {
      this.rateSettingService.getTargetsForRateSetting(setting.rateSettingId).subscribe(targets => {
        setting.months = targets;
      });
    });

    if (this.channels) {
      filtertedRateSettings.forEach(rateSet => {
        rateSet.channel = this.channelService.getChannelForId(rateSet.channelId, this.channels);
      });
    }
    this.dataSource = new MatTableDataSource<RateSettingModel>(filtertedRateSettings);
    this.dataSource.data = filtertedRateSettings;
    this.dataSource.paginator = this.paginator;
  }

  selectChannel(channel) {
    this.selectedChannel = channel;
    if (this.rateSettings) {
      const filtertedRateSettings = this.rateSettings.filter(rate => {
        return rate.channelId === this.selectedChannel.channelId;
      });
      this.setData(filtertedRateSettings);
    }
  }

  editRegionalTarget(rateSetting) {
    this.rateSettingActionsService.setRateSettingModel(rateSetting);
    this.router.navigate(['home/editRegionalTargets']);
  }

}
