import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SponsorshipService} from '../../services/sponsorship/sponsorship.service';
import {SponsorshipModel} from '../../models/sponsorship.model';
import * as _moment from 'moment';
import {ProgramService} from '../../services/program/program.service';
import {ProgramModel} from '../../models/program.model';
import {AdvertiserModel} from '../../models/advertiser.model';
import {AgencyModel} from '../../models/agency.model';
import {Location} from '@angular/common';

const moment = _moment;

@Component({
  selector: 'app-sponsorship-details',
  templateUrl: './sponsorship-details.component.html',
  styleUrls: ['./sponsorship-details.component.scss']
})
export class SponsorshipDetailsComponent implements OnInit {

  @Input() sponsorshipDetailsFormGroup: FormGroup;
  @Input() sponsorship: SponsorshipModel;
  @Input() rateCards: any[];
  @Output() submitSponsorDetails = new EventEmitter<SponsorshipModel>();
  @Output() changeProgram = new EventEmitter<any>();
  @Output() changeProduct = new EventEmitter<any>();
  product: any;
  programs: any[];
  program: any;
  productAdvertiser: any;
  productAgency: any;

  constructor(private _formBuilder: FormBuilder,
              private sponsorShipService: SponsorshipService,
              private programService: ProgramService,private _location: Location) {
  }

  ngOnInit() {
    this.programs = [];
  }

  selectProduct(product) {
    this.product = product;
    this.productAdvertiser = new AdvertiserModel(product.owner, product.ownerId);
    this.sponsorship.advertiser = this.productAdvertiser;
    this.productAgency = new AgencyModel(product.payer, product.payer_Id);
    this.sponsorship.agency = this.productAgency;
    this.sponsorship.productId = product && product.productId;
    this.changeProduct.emit(product);
  }

  selectAgency(agency) {
    this.sponsorship.agency = agency;
  }

  selectAdvertiser(advertiser) {
    this.sponsorship.advertiser = advertiser;
  }

  selectStartDate(startDate) {
    this.sponsorship.startDate = moment(startDate).format('YYYY/MM/DD');
    this.getPrograms();
  }

  selectEndDate(endDate) {
    this.sponsorship.endDate = moment(endDate).format('YYYY/MM/DD');
    this.getPrograms();
  }

  selectChannel(channel) {
    this.sponsorship.channelId = channel.channelId;
    this.getPrograms();
  }

  getPrograms() {
    if (this.sponsorship.endDate && this.sponsorship.startDate && this.sponsorship.channelId) {
      this.programService.getProgramsForChannelAndDate({
        channelId: this.sponsorship.channelId,
        startDate: this.sponsorship.startDate,
        endDate: this.sponsorship.endDate,
        broadcasterId: 0
      }).subscribe(programs => this.programs = programs);
    }
  }

  selectProgram(program) {
    this.program = program;
    this.sponsorship.programId = this.program.prog_no;
    this.sponsorship.programName = program.prog_name;
    this.sponsorship.rateCardId = program.rcrd_no;
    this.changeProgram.emit(program);
  }

  setDaysForSponsorship(daysOfWeekArray) {
    if (daysOfWeekArray && daysOfWeekArray.length > 0) {
      daysOfWeekArray.forEach(day => {
        this.sponsorship[day] = true;
      });
    }
  }

  onSubmitSponsorship() {
    this.sponsorship.days = this.sponsorshipDetailsFormGroup.controls['packageDay'].value;
    this.setDaysForSponsorship(this.sponsorshipDetailsFormGroup.controls['packageDay'].value);
    this.submitSponsorDetails.emit(this.sponsorship);
  }

  back(){
    this._location.back();
  }

}
