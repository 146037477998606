import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {RateCardModel} from '../../models/rate-card.model';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RateCardService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  getRateCardsForBroadcasterIdFromRemote(): Observable<RateCardModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_rates.php?broadcasterId=` + this.currentUser.broadcasterId)
      .pipe(map(rateCards => {
        if (rateCards && rateCards.length > 0) {
          const mappedRateCards = this.rateCardMapper(rateCards);
          localStorage.setItem(this.setKeyForBroadcaster(this.currentUser.broadcasterId), JSON.stringify(mappedRateCards));
        }
        return this.rateCardMapper(rateCards);
      }));
  }

  setKeyForBroadcaster(broadcasterId) {
    return 'rateCards-' + broadcasterId;
  }

  getRateCardsForBroadcasterIdFromLocal(broadcasterId) {
    return JSON.parse(localStorage.getItem(this.setKeyForBroadcaster(broadcasterId)));
  }

  getRateCardsForBroadcasterId(): Observable<RateCardModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const rateCards = this.getRateCardsForBroadcasterIdFromLocal(this.currentUser.broadcasterId);
    if (!rateCards) {
      // rateCards = this.rateCardMapper(this.mockRatesData);
      // localStorage.setItem(this.setKeyForBroadcaster(this.currentUser.broadcasterId), JSON.stringify(rateCards));
      return this.getRateCardsForBroadcasterIdFromRemote();
    }

    return of(rateCards);
  }

  private rateCardMapper(rateCardData): RateCardModel[] {
    return rateCardData.map(rateCard => new RateCardModel(rateCard.rate, rateCard.rcrd_no, rateCard.bdcr_no));
  }
}
