export class RightsManagement {
    groupsChannels: number;
    region: string[];
    typesOfRights: string;
    platform: string;
    media: string;
    language: string;
    description: string;
    numberOfPlays: number;
    txPerPlay: number;
    exhibitionPeriod: number;
    totalTxPerEp: number;
    startDate: Date;
    endDate: Date; 
    playsPer: string;
    exclusive: boolean;
    unlimitedPlays: boolean;
    unlimitedTxPerEpi: boolean;
    
    costPerEpisode: number;
    costPerEpisode_InRand: number;
    currency: string;
    totalEpisodes: number;
    deliveryExpenses: number;
    boughtAt: number;
    totalAmount: number;
}
