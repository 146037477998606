export class ClientClass{
    idNo: number;
    employee_no: number;
    company: String;
    company_logo: String;
    first_name: String;
    last_name: String;
    email: String;
    phone: String;
    vat_number: String;
    tax_number: String;
    currency: String;
    date_time: String;
    type: String;
    agencyId: number;
    agency: string;
    
    rem_days: number;
    rem_position: String;

    late_fee_option: String;
    late_fee_percentage: number;
    late_fee_value: number;
    late_fee_days: number;
    late_fee_tax_rate: number;
    late_fee_tax_name: String;
    late_fee_tax_number: String;
    checked: boolean;

    //Billing Address
    address:String;
    city:String;
    state: String;
    zip_code: String;

    //Shipping Address
    ship_address:String;
    ship_city:String;
    ship_state: String;
    ship_zip_code: String;
}