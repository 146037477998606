export class SegmentClass{
    idNo: number;
    prog_no: number;
    seas_no: number;
    epis_no: number;
    segm_no: number;
    segm_len: number;
    brek_len: number;
    run_no: number;
    exhibition_no: number;
    omni_epi_no: number;
    sst_time: String;
    brek_time: String;
} 