import {Injectable} from '@angular/core';
import {SponsorshipModel} from '../../models/sponsorship.model';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';
import {SponsorshipTableModel} from '../../models/sponsorship-table.model';

@Injectable({
  providedIn: 'root'
})
export class SponsorshipService {
  currentUser: User;
  sponsorship: SponsorshipModel;

  constructor(private http: HttpClient) {
  }

  getSponsorshipsFromRemote(): Observable<SponsorshipTableModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_sponsorships.php?userId=` + this.currentUser.userId)
      .pipe(map(sponsorships => {
        if (sponsorships && sponsorships.length > 0) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('sponsorships', JSON.stringify(this.sponsorshipMapper(sponsorships)));
        }
        return this.sponsorshipMapper(sponsorships);
      }));
  }


  getSponsorshipsFromLocal() {
    return JSON.parse(localStorage.getItem('sponsorships'));
  }

  getSponsorships(): Observable<SponsorshipTableModel[]> {
    const sponsorships = this.getSponsorshipsFromLocal();
    if (!sponsorships) {
      // uncomment when using API
      return this.getSponsorshipsFromRemote();
    }

    return of(sponsorships);
  }

  getSponsorshipForId(id, sponsorships): SponsorshipModel {
    const sponsorship = sponsorships.find(spons => spons.sponsorshipId === id);
    return sponsorship;
  }

  createSponsorship(): SponsorshipModel {
    localStorage.removeItem('sponsorship');
    this.sponsorship = new SponsorshipModel();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.sponsorship.userId = currentUser.userId;
    this.sponsorship.broadcasterId = currentUser.broadcasterId;
    return this.sponsorship;
  }

  confirmSponsorshipCreation(sponsorshipModel: SponsorshipModel) {
    this.sponsorship = sponsorshipModel;
    return this.postSponsorship(sponsorshipModel);
  }

  postSponsorship(sponsorshipModel: SponsorshipModel): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `save_sponsorship.php`,
      [sponsorshipModel]).pipe(map(repsonse => {
      sponsorshipModel.totalNominalPrice = repsonse.total_nominal_price;
      sponsorshipModel.sponsorshipId = repsonse.sponsorshipNo;
      localStorage.removeItem('createdSponsorship');
      localStorage.setItem('createdSponsorship', JSON.stringify(sponsorshipModel));
      return sponsorshipModel;
    }));
  }

  getCreatedSponsorship() {
    return JSON.parse(localStorage.getItem('createdSponsorship'));
  }

  private sponsorshipMapper(sponsorshipData): SponsorshipTableModel[] {
    return sponsorshipData.map(sponsorship => new SponsorshipTableModel(sponsorship.spons_no, sponsorship.sst_date, sponsorship.end_date,
      sponsorship.prod_name, sponsorship.advertiser, sponsorship.agency, sponsorship.no_of_elements, sponsorship.total_nominal_price));
  }
}
