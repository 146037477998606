import {Injectable} from '@angular/core';
import {TargetAudienceModel} from '../../models/target-audience.model';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TargetAudienceService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  getTargetAudiencesForBroadcasterIdFromRemote(): Observable<TargetAudienceModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_audience.php?broadcasterId=` + this.currentUser.broadcasterId)
      .pipe(map(targetAudiences => {
        if (targetAudiences && targetAudiences.length > 0) {
          const mappedTargetAudiences = this.targetAudienceMapper(targetAudiences);
          localStorage.setItem(this.setKeyForBroadcaster(this.currentUser.broadcasterId), JSON.stringify(mappedTargetAudiences));
        }
        return this.targetAudienceMapper(targetAudiences);
      }));
  }

  setKeyForBroadcaster(broadcasterId) {
    return 'targetAudiences-' + broadcasterId;
  }

  getTargetAudiencesForBroadcasterIdFromLocal(broadcasterId) {
    return JSON.parse(localStorage.getItem(this.setKeyForBroadcaster(broadcasterId)));
  }

  getTargetAudiencesForBroadcasterId(): Observable<TargetAudienceModel[]> {
    return this.getTargetAudiencesForBroadcasterIdFromRemote();
  }

  private targetAudienceMapper(targetAudienceData): TargetAudienceModel[] {
    return targetAudienceData.map(targetAudience => new TargetAudienceModel(targetAudience.audienceId,
      targetAudience.target_audience, targetAudience.broadcasterId));
  }
}
