import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-trading-model-selector',
  templateUrl: './trading-model-selector.component.html',
  styleUrls: ['./trading-model-selector.component.scss']
})
export class TradingModelSelectorComponent implements OnInit {

  @Input() group: FormGroup;
  @Output() SelectedItem = new EventEmitter<any>();
  tradingModels: any[];
  tradingModelCtrl: FormControl = new FormControl('', Validators.required);

  constructor() {
  }

  ngOnInit() {
    this.group.addControl('tradingModelCtrl', this.tradingModelCtrl);
    this.tradingModels = ['Fixed rating', 'Fixed CPP', 'Fixed CPT', 'CPM', 'Fixed spots', 'Added value'];
  }

  onSelect(tradingModel) {
    this.SelectedItem.emit(tradingModel);
  }

}
