import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
 
//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';
import { ClientClass } from 'src/app/classes/client';

@Component({
  selector: 'app-agency-list',
  templateUrl: './agency-list.component.html',
  styleUrls: ['./agency-list.component.scss'],
})
export class AgencyListComponent implements OnInit {

  campaign: any;
  display_array: ClientClass[] = [];
  baseUrl = environment.serverUrl;
  displayedColumns: string[] = ['select','çompany', 'firstName', 'surname', 'emailAddress', 'phoneNumber','edit'];
  dataSource: any;
  selection = new SelectionModel<ClientClass>(true, [], true);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient, private loadingCtrl: LoadingController, private navCtrl: NavController, public dialog: MatDialog,) {

    this.onReadClients();
  }

  ngOnInit() {
   
    
  }

  /*ionViewWillEnter(){

    this.onReadUsers();
  }*/

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected agency?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.selection.selected.forEach(advertiser => {          
          this.deleteAdvertiser(advertiser.idNo);
        });
        
        this.selection.clear(); 
        this.alertService.success('Agency successfully deleted')
      }
    });
  }

  async deleteAdvertiser(clientId)
  {
    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/sales/agency/delete_client.php?clientId=${clientId}&employee_no=0`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadClients();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  async onReadClients()
  {

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/sales/agency/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.display_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: 0,agency: ''};
        }

        this.dataSource = new MatTableDataSource<ClientClass>(this.display_array);
        this.dataSource.paginator = this.paginator;
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  editAdvertiser(clientId){

    localStorage.setItem('client_id', JSON.stringify(clientId));
    this.router.navigate(['/home/view-agency']);
  }
 
  addNewAdvertiser(){
    this.router.navigate(['/home/new-agency']);
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }
}