import {CampaignSpotsModel} from './campaignSpots.model';
import {AdvertiserModel} from './advertiser.model';
import {AgencyModel} from './agency.model';

export class CampaignModel {
  campaignId: number;
  start_date: Date;
  end_date: Date;
  productId: number;
  product: string;
  advertiser: string;
  advertiserModel: AdvertiserModel;
  agencyModel: AgencyModel;
  advertiserId: string;
  agency: string;
  agencyId: string;
  spots: CampaignSpotsModel[];
  numberOfSpots: number;
  userId: number;
  status: string;
  totalNominalPrice: number;
}
