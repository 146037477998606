import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _moment from 'moment';
import {FormControl, FormGroup} from '@angular/forms';
import {Moment} from 'moment';
import {MatDatepicker} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';

const moment = _moment;

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class YearPickerComponent implements OnInit {
  date: FormControl;
  @Input() group: FormGroup;
  @Input() name: string;
  @Input() title: string;
  @Output() inputChange = new EventEmitter<{ value: string }>();
  constructor() { }

  ngOnInit() {
    this.date = new FormControl(moment());
    if (this.group && this.name) {
      this.group.addControl(this.name, this.date);
    }

  }

  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    this.emitValue(ctrlValue);
    datepicker.close();
  }

  emitValue(value: string) {
    this.inputChange.emit({value: value});
  }

}
