export interface SchedulingEndpoints {
  tenant: any;
}

export const SchedulingEndpoints: SchedulingEndpoints = {
  tenant: {
    logEditor:'http://pfunzo.mms.africa/schedule/scheduling/logeditor',
    aquisition:'http://pfunzo.mms.africa/schedule/scheduling/new-programme',
    scheduleProgrammes:'http://pfunzo.mms.africa/schedule/scheduling/programme-list',
    scheduling:'http://pfunzo.mms.africa/schedule/scheduling/new-schedule',
    reconciliation:'http://pfunzo.mms.africa/schedule/scheduling/reconciliation',
    imports:'http://pfunzo.mms.africa/schedule/scheduling/import',
    exports:'http://pfunzo.mms.africa/schedule/scheduling/export',
  }
};   