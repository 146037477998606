import {Injectable} from '@angular/core';
import {ChannelModel} from '../../models/channel.model';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChannelService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  getChannelsFromRemote(): Observable<ChannelModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `getchannels.php?bdcr_no=10`)
      .pipe(map(channels => {
        if (channels && channels.length > 0) {
          const mappedChannels = this.channelMapper(channels)
          localStorage.setItem('channels', JSON.stringify(mappedChannels));
        }
        return this.channelMapper(channels);
      }));
  }

  getChannelsFromLocal() {
    return JSON.parse(localStorage.getItem('channels'));
  }

  getChannelsForBroadcaster(): Observable<ChannelModel[]> {
    const channels = this.getChannelsFromLocal();
    if (!channels) {
      // uncomment when using API
      return this.getChannelsFromRemote();
    }

    return of(channels);
  }

  getChannelForId(id, channels): ChannelModel {
    const channel = channels.find(chan => chan.channelId === id);
    return channel;
  }

  private channelMapper(channelData): ChannelModel[] {
    let mappedSpotCampaigns: ChannelModel[] = [];
    if (channelData && channelData.length > 0) {
      channelData.forEach(function (channel) {
        mappedSpotCampaigns.push({
          channelId: channel.sare_no || null,
          channelName: channel.sare_name || null,
          channelCode: channel.sare_code || null,
          broadcasterId: channel.bdcr_no || null,
        });
      });
    }

    return mappedSpotCampaigns;
  }
}
