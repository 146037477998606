import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {AudienceGuaranteedModel} from '../../models/audience-guaranteed.model';
import {ChannelService} from '../../services/channel/channel.service';
import {AudienceGuaranteedTradingService} from '../../services/audience-guaranteed-trading/audience-guaranteed-trading.service';

import * as _moment from 'moment';
import {CampaignService} from '../../services/campaign/campaign.service';

const moment = _moment;

@Component({
  selector: 'app-create-audience-guaranteed-trading',
  templateUrl: './create-audience-guaranteed-trading.component.html',
  styleUrls: ['./create-audience-guaranteed-trading.component.scss']
})
export class CreateAudienceGuaranteedTradingComponent implements OnInit {
  detailsFormGroup: FormGroup;
  tradingFormGroup: FormGroup;
  channelFormGroup: FormGroup;
  channelConfigFormGroups: FormGroup;
  spotFormGroup: FormGroup;
  audienceGuaranteedModel: AudienceGuaranteedModel;
  complete = false;
  channels: any[];
  totalPercentage = 0;
  shoulderPercentageTotals: any[];
  dayPercentageTotals: any[];
  weekChannelPercentageTotals: any[] = [];
  numberOfWeeks: number[];
  product: any;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  constructor(private _formBuilder: FormBuilder, private channelService: ChannelService,
              private audienceGuaranteedTradingService: AudienceGuaranteedTradingService, private campaignService: CampaignService) {
  }

  ngOnInit() {
    this.detailsFormGroup = this._formBuilder.group({
      allowWeeks: [false],
      budgetCtrl: ['', [Validators.required]]
    });

    // this.tradingFormGroup = this._formBuilder.group({
    //   budgetCtrl: ['', [Validators.required]]
    // });

    this.channelService.getChannelsForBroadcaster().subscribe(channels => {
      this.channels = channels;
      this.channelFormGroup = this._formBuilder.group({
        dummy: new FormControl('',)
      });
    });

    this.audienceGuaranteedModel = new AudienceGuaranteedModel();
  }


  submitDetails(model) {
    this.audienceGuaranteedModel = model;
    this.numberOfWeeks = model.numberOfWeeks;
    this.createChannelConfigForms(model.packageChannelModels);
  }

  submitTradingModel(model) {
    this.audienceGuaranteedModel = model;
    this.createChannelConfigForms(model.packageChannelModels);
  }

  minPercentage(min = 100) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
      // get a list of input values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      this.totalPercentage = totalSelected;
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : {minTotal: true};
    };

    return validator;
  }

  maxPercentage(max = 100) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is  greater than the maximum, return the error message
      return totalSelected <= max ? null : {maxTotal: true};
    };

    return validator;
  }

  submitSpotForm(model) {
    this.audienceGuaranteedModel = model;
    this.createChannelConfigForms(model.packageChannelModels);
  }

  createChannelConfigForms(packageChannelModels) {
    this.channelConfigFormGroups = this._formBuilder.group({dummyControl: ['']});
    this.shoulderPercentageTotals = [];
    this.dayPercentageTotals = [];
    packageChannelModels.forEach(pckModel => {
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-early',
        new FormControl(0, Validators.required));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-prime',
        new FormControl(0, Validators.required));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-late',
        new FormControl(0, Validators.required));
      this.daysOfWeek.forEach(day => {
        this.channelConfigFormGroups.addControl(pckModel.channelId + '-' + day, new FormControl(0, Validators.required));
      });

      this.numberOfWeeks.forEach(week => {
        this.channelConfigFormGroups.addControl(pckModel.channelId + '-' + week, new FormControl(0, Validators.required));
      });
      this.shoulderPercentageTotals.push({id: pckModel.channelId, total: 0});
      this.dayPercentageTotals.push({id: pckModel.channelId, total: 0});
      this.weekChannelPercentageTotals.push({id: pckModel.channelId, total: 0});
    });
    this.channelConfigFormGroups.setValidators([this.minShoulderPercentage(100, packageChannelModels),
      this.maxShoulderPercentage(100, packageChannelModels), this.minDayPercentage(100, packageChannelModels),
      this.minWeekPercentage(100, packageChannelModels), this.maxWeekPercentage(100, packageChannelModels)]);
  }

  maxShoulderPercentage(max = 100, packageChannelModels) {
    const validator: ValidatorFn = (formArray: FormGroup) => {
      let totalSelected = 0;
      packageChannelModels.forEach(pack => {
        const total = formArray.controls[pack.channelId + '-early'].value + formArray.controls[pack.channelId + '-late'].value
          + formArray.controls[pack.channelId + '-prime'].value;

        this.shoulderPercentageTotals.forEach((percentage => {
          if (percentage.id === pack.channelId) {
            percentage.total = total;
          }
        }));

        totalSelected += total;
      });
      return this.roundOffNumber(totalSelected) <= (max * packageChannelModels.length) ? null : {minShoulderPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  minShoulderPercentage(min = 100, packageChannelModels) {
    const validator: ValidatorFn = (formArray: FormGroup) => {
      let totalSelected = 0;
      packageChannelModels.forEach(pack => {
        const total = formArray.controls[pack.channelId + '-early'].value + formArray.controls[pack.channelId + '-late'].value
          + formArray.controls[pack.channelId + '-prime'].value;

        this.shoulderPercentageTotals.forEach((percentage => {
          if (percentage.id === pack.channelId) {
            percentage.total = total;
          }
        }));

        totalSelected += total;
      });
      return this.roundOffNumber(totalSelected) >= (min * packageChannelModels.length) ? null : {minShoulderPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  minDayPercentage(min = 100, packageChannelModels) {
    const validator: ValidatorFn = (formArray: FormGroup) => {
      let totalSelected = 0;
      packageChannelModels.forEach(pack => {
        let total = 0;
        this.daysOfWeek.forEach(day => {
          total += formArray.controls[pack.channelId + '-' + day].value;
        });

        this.dayPercentageTotals.forEach((percentage => {
          if (percentage.id === pack.channelId) {
            percentage.total = total;
          }
        }));

        totalSelected += total;
      });
      return this.roundOffNumber(totalSelected) >= (min * packageChannelModels.length) ? null : {minDayPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  minWeekPercentage(min = 100, packageChannelModels) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0;
      packageChannelModels.forEach(pack => {
        let total = 0;
        this.numberOfWeeks.forEach(week => {
          total += formGroup.controls[pack.channelId + '-' + week].value;
        });
        this.weekChannelPercentageTotals.forEach((percentage => {
          if (percentage.id === pack.channelId) {
            percentage.total = total;
          }
        }));
        totalSelected += total;
      });
      return this.roundOffNumber(totalSelected) >= (min * packageChannelModels.length) ? null : {minWeekPercentage: true};
    };

    return validator;
  }

  maxWeekPercentage(max = 100, packageChannelModels) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0;
      packageChannelModels.forEach(pack => {
        let total = 0;
        this.numberOfWeeks.forEach(week => {
          total += formGroup.controls[pack.channelId + '-' + week].value;
        });
        this.weekChannelPercentageTotals.forEach((percentage => {
          if (percentage.id === pack.channelId) {
            percentage.total = total;
          }
        }));
        totalSelected += total;
      });
      return this.roundOffNumber(totalSelected) <= (max * packageChannelModels.length) ? null : {minWeekPercentage: true};
    };

    return validator;
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  } 

  submitChannelConfigForm(model) {
    this.audienceGuaranteedModel = model;
    this.audienceGuaranteedTradingService.confirmAudienceGuaranteedCreation(this.audienceGuaranteedModel)
      .subscribe(result => {
        this.campaignService.getCampaignsFromRemote().subscribe(success => {
          
        });
      }, error => console.log(error));
    this.audienceGuaranteedModel.startDate = moment(this.audienceGuaranteedModel.startDate).format('YYYY/MM/DD');
    this.audienceGuaranteedModel.endDate = moment(this.audienceGuaranteedModel.endDate).format('YYYY/MM/DD');
    this.complete = true;
  }

  selectProduct(product) {
    this.product = product;
  }

}
