export class ProductClass{
    idNo: number;
    product_name: String;
    description: String;
    quantity: number;
    date_time: string;
    image: string;
    position: string;
    category: string;
    company: string;
    clientNo: number;

    //Selling
    unit_price: number;
    discount: number;
    turnVat: boolean;
    vat_type: any;
    vat_rate: number;
}