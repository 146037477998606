import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ProductService} from '../../services/products/product.service';
import {ProductModel} from '../../models/product.model';
import {map, startWith} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/internal/operators';
import {MatDialog} from '@angular/material/dialog';
import {AddProductDialogComponent} from '../add-product-dialog/add-product-dialog.component';


@Component({
  selector: 'app-product-selector',
  templateUrl: './product-selector.component.html',
  styleUrls: ['./product-selector.component.scss']
})
export class ProductSelectorComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Output() SelectedItem = new EventEmitter<ProductModel>();
  products: any[];
  productCtrl: FormControl = new FormControl('', Validators.required);
  filteredProducts: Observable<ProductModel[]>;

  constructor(private productService: ProductService, public dialog: MatDialog) {

  }

  ngOnInit() {
    this.products = [];
    this.filteredProducts = this.productCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((product => product ? this._filterProducts(product) : this.products.slice())));
    this.formGroup.addControl('product', this.productCtrl);

  }

  private _filterProducts(value: string): Observable<ProductModel[]> {
    const filterValue = value.toLowerCase();
    if (this.products.length <= 0 || filterValue.length == 1) {
      return this.productService.getProducts(filterValue).pipe(
        map(products => {
          return products;
        })
      );
    } else {
      return of([]);
    }
  }

  onSelect(product) {
    this.SelectedItem.emit(product);
  }

  createNewProduct(): void {
    const dialogRef = this.dialog.open(AddProductDialogComponent, {
      width: '600px',
      data: null
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const product = {
          productId: 0,
          productName: result,
          productCategory: 'Category',
          owner: '',
          ownerId: '0',
          payer: '',
          payer_Id: '0',
          categoryId: 'Category code'
        };
        this.formGroup.controls['product'].setValue(product.productName);
        this.SelectedItem.emit(product);
      }
    });
  }
}
