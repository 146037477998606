import {Component, OnInit} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {AlertService} from '../../services/alert/alert.service';
import * as _moment from 'moment';
import {Router} from '@angular/router';
import {TargetAudienceService} from '../../services/target-audience/target-audience.service';
import {BaseCppDialogComponent} from '../base-cpp-dialog/base-cpp-dialog.component';
import {MatDialog} from '@angular/material/dialog';

const moment = _moment;


@Component({
  selector: 'app-base-ratings',
  templateUrl: './base-ratings.component.html',
  styleUrls: ['./base-ratings.component.scss']
})
export class BaseRatingsComponent implements OnInit {
  rateSettingMonth: any;
  rateSettingModel: RateSettingModel;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  demographicsPerHour = [];
  topDemos = [];
  displayedColumns: string[] = ['channel', 'startTime', 'topDeliveringDemo', 'rating', 'minimumGrossRate', 'targetCPP',
    'projectedRate', 'weighting'];
  displayedBaseColumns: string[] = ['demographic', 'index', 'rate'];
  dataSource = [];
  step = 1;
  baseRatings = [];
  targets: any[] = [];

  constructor(private rateSettingActionsService: RateSettingActionsService, private rateSettingService: RateSettingService,
              private alertService: AlertService, private router: Router, private targetAudienceService: TargetAudienceService,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    const data = this.rateSettingActionsService.getRateSettingMon();
    if (data) {
      this.rateSettingMonth = data.month;
      this.rateSettingModel = data.rateSettingModel;
      this.rateSettingService.getBaseRatingsForMonth(this.rateSettingMonth.month, this.rateSettingModel.channelId)
        .subscribe(baseRatingData => {
          this.baseRatings = baseRatingData;
          this.rateSettingService.getTopDemos(this.rateSettingModel.channels[0].channelId)
            .subscribe(topDemoData => {
              this.topDemos = topDemoData;
              this.createTableData();
            });
        });
      this.targetAudienceService.getTargetAudiencesForBroadcasterId().subscribe(targets => {
        this.targets = targets || [];
      });
    }
  }

  calculateMinimumGrossRate(startTime, day) {
    const matchedSlot = this.rateSettingMonth[day.toLowerCase() + 'KeySlots'].find(slot => {
      return slot.startTime.toString().slice(0, 4) === startTime.toString().slice(0, 4);
    });
    return matchedSlot && matchedSlot.minGrossPerSlot || 0;
  }

  createTableData() {
    this.daysOfWeek.forEach(day => {
      this.demographicsPerHour.push([
        {
          startTime: '06:00',
          endTime: '6:29',
          topDeliveringDemo: this.mapTopDemos('06:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('06:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '06:30',
          endTime: '6:59',
          topDeliveringDemo: this.mapTopDemos('06:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('06:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '07:00',
          endTime: '7:29',
          topDeliveringDemo: this.mapTopDemos('07:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('07:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '07:30',
          endTime: '7:59',
          topDeliveringDemo: this.mapTopDemos('07:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('07:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '08:00',
          endTime: '8:29',
          topDeliveringDemo: this.mapTopDemos('08:00', day),
          rating: 0.08,
          minimumGrossRate: this.calculateMinimumGrossRate('08:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '08:30',
          endTime: '8:59',
          topDeliveringDemo: this.mapTopDemos('08:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('08:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '09:00',
          endTime: '9:29',
          topDeliveringDemo: this.mapTopDemos('09:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('09:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '09:30',
          endTime: '9:59',
          topDeliveringDemo: this.mapTopDemos('09:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('09:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '10:00',
          endTime: '10:29',
          topDeliveringDemo: this.mapTopDemos('10:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('10:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '10:30',
          endTime: '10:59',
          topDeliveringDemo: this.mapTopDemos('10:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('10:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '11:00',
          endTime: '11:29',
          topDeliveringDemo: this.mapTopDemos('11:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('11:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '11:30',
          endTime: '11:59',
          topDeliveringDemo: this.mapTopDemos('11:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('11:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '12:00',
          endTime: '12:29',
          topDeliveringDemo: this.mapTopDemos('12:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('12:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '12:30',
          endTime: '12:59',
          topDeliveringDemo: this.mapTopDemos('12:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('12:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '13:00',
          endTime: '13:29',
          topDeliveringDemo: this.mapTopDemos('13:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('13:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '13:30',
          endTime: '13:59',
          topDeliveringDemo: this.mapTopDemos('13:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('13:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '14:00',
          endTime: '14:29',
          topDeliveringDemo: this.mapTopDemos('14:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('14:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '14:30',
          endTime: '14:59',
          topDeliveringDemo: this.mapTopDemos('14:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('14:30', day),
          projectedRate: 0,
          weighting: 0
        }, {
          startTime: '15:00',
          endTime: '15:29',
          topDeliveringDemo: this.mapTopDemos('15:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('15:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '15:30',
          endTime: '15:59',
          topDeliveringDemo: this.mapTopDemos('15:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('15:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '16:00',
          endTime: '16:29',
          topDeliveringDemo: this.mapTopDemos('16:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('16:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '16:30',
          endTime: '16:59',
          topDeliveringDemo: this.mapTopDemos('16:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('16:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '17:00',
          endTime: '17:29',
          topDeliveringDemo: this.mapTopDemos('17:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('17:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '17:30',
          endTime: '17:59',
          topDeliveringDemo: this.mapTopDemos('17:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('17:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '18:00',
          endTime: '18:29',
          topDeliveringDemo: this.mapTopDemos('18:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('18:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '18:30',
          endTime: '18:59',
          topDeliveringDemo: this.mapTopDemos('18:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('18:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '19:00',
          endTime: '19:29',
          topDeliveringDemo: this.mapTopDemos('19:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('19:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '19:30',
          endTime: '19:59',
          topDeliveringDemo: this.mapTopDemos('19:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('19:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '20:00',
          endTime: '20:29',
          topDeliveringDemo: this.mapTopDemos('20:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('20:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '20:30',
          endTime: '20:59',
          topDeliveringDemo: this.mapTopDemos('20:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('20:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '21:00',
          endTime: '21:29',
          topDeliveringDemo: this.mapTopDemos('21:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('21:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '21:30',
          endTime: '21:59',
          topDeliveringDemo: this.mapTopDemos('21:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('21:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '22:00',
          endTime: '22:29',
          topDeliveringDemo: this.mapTopDemos('22:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('22:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '22:30',
          endTime: '22:59',
          topDeliveringDemo: this.mapTopDemos('22:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('22:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '23:00',
          endTime: '23:29',
          topDeliveringDemo: this.mapTopDemos('23:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('23:00', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '23:30',
          endTime: '23:59',
          topDeliveringDemo: this.mapTopDemos('23:30', day),
          minimumGrossRate: this.calculateMinimumGrossRate('23:30', day),
          projectedRate: 0,
          weighting: 0
        },
        {
          startTime: '00:00',
          endTime: '5:59',
          topDeliveringDemo: this.mapTopDemos('24:00', day),
          minimumGrossRate: this.calculateMinimumGrossRate('00:00', day),
          projectedRate: 0,
          weighting: 0
        }
      ]);
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  mapTopDemos(startTime, day) {
    const noTopDemo = {demographic: 'Not set', rating: 0, baseRating: 0};
    if (this.topDemos.length > 0) {
      const matchedDemo = this.topDemos.find(demo => {
        return demo.start_time.toString().slice(0, 5) === startTime.toString().slice(0, 5) && demo.day === day;
      });
      if (matchedDemo) {
        const baseRating = this.baseRatings.find(br => {
          return br.demographic.toLowerCase() === matchedDemo.demographic.toLowerCase();
        });
        return {demographic: matchedDemo.demographic, rating: matchedDemo.rating, baseRating: baseRating && baseRating.rate || 0};
      }
    }
    return noTopDemo;
  }

  changeDemographic(element, demographic, dayIndex) {
    const baseRating = this.baseRatings.find(br => {
      return br.demographic.toLowerCase() === demographic.toLowerCase();
    });
    this.demographicsPerHour[dayIndex].forEach(demog => {
      if (demog.startTime === element.startTime) {
        demog.topDeliveringDemo.baseRating = baseRating && parseFloat(baseRating.rate) || 0;
      }
    });
    // element.topDeliveringDemo.baseRatings = baseRating && parseFloat(baseRating.rate) || 0;
  }

  exportTR() {
    this.rateSettingService.exportTargetRates(this.mapResult()).subscribe(success => {
      this.success('target');
    });
  }

  exportAR() {
    this.rateSettingService.exportAudienceRates(this.mapResult()).subscribe(success => {
      this.success('audience');
    });
  }

  success(type) {
    const message = 'The ' + type + ' rates for ' + moment(this.rateSettingMonth.month, 'MMM').format('MMMM') + ' has been exported';
    this.alertService.success(message, true);
  }

  error(type) {
    const message = 'Error saving ' + type + ' rates for ' + moment(this.rateSettingMonth.month, 'MMM').format('MMMM');
    this.alertService.error(message, true);
  }

  backToTracking() {
    this.rateSettingActionsService.setRateSettingMonth({month: this.rateSettingMonth, rateSettingModel: this.rateSettingModel});
    this.router.navigate(['home/createRateSettingTool/captureKeySlotTarget']);
  }

  returnToPhasing() {
    this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
    this.router.navigate(['home/createRateSettingTool/keySlotTargets']);
  }

  mapResult() {
    return this.daysOfWeek.map((day, index) => {
      return {
        day: day,
        month: this.rateSettingMonth.month,
        year: this.rateSettingModel.year,
        results: this.mapResultsForDay(index)
      };
    });
  }

  mapResultsForDay(index) {
    return this.demographicsPerHour[index].map(demo => {
      return {
        channelId: this.rateSettingModel.channels[0].channelId,
        startTime: demo.startTime,
        targetRate: demo.minimumGrossRate,
        audienceRate: this.roundOffNumber(demo.topDeliveringDemo.rating * demo.topDeliveringDemo.baseRating) * ((demo.weighting / 100) + 1)
      };
    });
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }

  openBaseCppDialog() {
    const dialogRef = this.dialog.open(BaseCppDialogComponent, {
      width: '600px',
      data: {baseRatings: this.baseRatings, month: moment(this.rateSettingMonth.month, 'MMM').format('MMMM')}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.month !== '1') {
        this.daysOfWeek.forEach((day, index) => {
          this.demographicsPerHour[index].forEach(demographic => {
            const baseRating = this.baseRatings.find(br => {
              return br.demographic.toLowerCase() === demographic.topDeliveringDemo.demographic.toLowerCase();
            });
            if (baseRating) {
              demographic.topDeliveringDemo.baseRating = baseRating.index_value * baseRating.Jan;
            }
          });
        });
      }
    });
  }

}
