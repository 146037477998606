import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AudienceGuaranteedModel} from '../../models/audience-guaranteed.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {PackageChannelModel} from '../../models/packageChannel';

@Component({
  selector: 'app-audience-guaranteed-trading',
  templateUrl: './audience-guaranteed-trading.component.html',
  styleUrls: ['./audience-guaranteed-trading.component.scss']
})
export class AudienceGuaranteedTradingComponent implements OnInit {
  @Input() currentModel: AudienceGuaranteedModel;
  @Input() tradingFormGroup: FormGroup;
  @Output() submitModel = new EventEmitter<AudienceGuaranteedModel>();

  constructor() {
  }

  ngOnInit() {
  }

  selectTarget(target) {
    this.currentModel.targetId = target.targetId;
  }

  selectTradingModel(model) {
    this.removeControl();
    this.setFieldsForModel(model);
  }

  // TODO: remove magic strings
  setFieldsForModel(tradingModel) {
    if (tradingModel === 'Fixed rating') {
      const ratingCtrl = new FormControl(0, Validators.required);
      this.tradingFormGroup.addControl('ratingCtrl', ratingCtrl);
    }
    if (tradingModel === 'Fixed CPP') {
      const CPPCtrl = new FormControl(0, Validators.required);
      this.tradingFormGroup.addControl('CPPCtrl', CPPCtrl);
    }
    if (tradingModel === 'Fixed CPT') {
      const CPPCtrl = new FormControl(0, Validators.required);
      this.tradingFormGroup.addControl('CPTCtrl', CPPCtrl);
    }
    if (tradingModel === 'CPM') {
      const CPMCtrl = new FormControl(0, Validators.required);
      this.tradingFormGroup.addControl('CPMCtrl', CPMCtrl);
    }
    if (tradingModel === 'Fixed spots') {
      const numberOfSpotsCtrl = new FormControl(0, Validators.required);
      this.tradingFormGroup.addControl('NumberOfSpotsCtrl', numberOfSpotsCtrl);
      if (this.tradingFormGroup.get('target')) {
        this.tradingFormGroup.removeControl('target');
      }
    }

    this.currentModel.tradingModel = tradingModel;

  }

  removeControl() {
    if (this.currentModel.tradingModel === 'Fixed rating') {
      this.tradingFormGroup.removeControl('ratingCtrl');
    }
    if (this.currentModel.tradingModel === 'Fixed CPP') {
      this.tradingFormGroup.removeControl('CPPCtrl');
    }
    if (this.currentModel.tradingModel === 'Fixed CPT') {
      this.tradingFormGroup.removeControl('CPTCtrl');
    }
    if (this.currentModel.tradingModel === 'CPM') {
      this.tradingFormGroup.removeControl('CPMCtrl');
    }
    if (this.currentModel.tradingModel === 'Fixed spots') {
      this.tradingFormGroup.removeControl('NumberOfSpotsCtrl');
    }
  }

  setTradingValues() {
    if (this.currentModel.tradingModel === 'Fixed rating') {
      this.currentModel.tradingModelValues = {
        budget: this.tradingFormGroup.controls['budgetCtrl'].value,
        rating: this.tradingFormGroup.controls['ratingCtrl'].value,
        CPP: this.tradingFormGroup.controls['budgetCtrl'].value / this.tradingFormGroup.controls['ratingCtrl'].value
      };
    }
    if (this.currentModel.tradingModel === 'Fixed CPP') {
      this.currentModel.tradingModelValues = {
        budget: this.tradingFormGroup.controls['budgetCtrl'].value,
        CPP: this.tradingFormGroup.controls['CPPCtrl'].value,
        rating: this.tradingFormGroup.controls['budgetCtrl'].value / this.tradingFormGroup.controls['CPPCtrl'].value
      };
    }
    if (this.currentModel.tradingModel === 'Fixed CPT') {
      this.currentModel.tradingModelValues = {
        budget: this.tradingFormGroup.controls['budgetCtrl'].value,
        CPT: this.tradingFormGroup.controls['CPTCtrl'].value,
        Impacts: this.tradingFormGroup.controls['budgetCtrl'].value / this.tradingFormGroup.controls['CPTCtrl'].value
      };
    }
    if (this.currentModel.tradingModel === 'CPM') {
      this.currentModel.tradingModelValues = {
        budget: this.tradingFormGroup.controls['budgetCtrl'].value,
        CPM: this.tradingFormGroup.controls['CPMCtrl'].value,
        minutes: this.tradingFormGroup.controls['budgetCtrl'].value / this.tradingFormGroup.controls['CPMCtrl'].value
      };
    }
    if (this.currentModel.tradingModel === 'Fixed spots') {
      this.currentModel.tradingModelValues = {
        budget: this.tradingFormGroup.controls['budgetCtrl'].value,
        CPM: this.tradingFormGroup.controls['NumberOfSpotsCtrl'].value,
        costPerSpot: this.tradingFormGroup.controls['budgetCtrl'].value / this.tradingFormGroup.controls['NumberOfSpotsCtrl'].value
      };
    }
    this.submitModel.emit(this.currentModel);
  }

  submitTrading() {
    this.currentModel.channels = this.tradingFormGroup.controls['channel'].value;
    const packageChannelModels = this.tradingFormGroup.controls['channel'].value.map((v, i) =>
      v ? new PackageChannelModel(this.tradingFormGroup.controls['channel'].value[i].channelName,
        this.tradingFormGroup.controls['channel'].value[i].channelId, this.tradingFormGroup.controls[v.channelCode + 'Ctrl'].value) : null)
      .filter(v => v !== null);
    this.currentModel.packageChannelModels = packageChannelModels;
    this.setTradingValues();
  }
}
