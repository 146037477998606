import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import { SeasonClass } from '../../classes/season';
import { EpisodeClass } from '../../classes/episode';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {Location} from '@angular/common';

import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'; 
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';


//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;
 

//Pop up]
import { EpisodeDialogComponent } from '../episode-dialog/episode-dialog.component';
import { CampaignSpotService } from 'src/app/services/campaignSpot/campaign-spot.service';
import {CampaignSpotModel} from '../../models/campaign-spot.model';
import { EpisodeDialogClass } from 'src/app/classes/episode.dialog';
import { fromEvent, Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { debounceTime } from 'rxjs/operators';

@Component({ 
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.scss']
})
export class SchedulingComponent implements OnInit {

  events: any[] = [];
  options: any;
  header: any;
  current_dates: any = moment().format("YYYY-MM-DD");
  dragEpisode: any;

  start_date: String = moment().format("YYYY-MM-DD");
  end_date: String = moment().format("YYYY-MM-DD");
  start_time: String = "00:00";
  end_time: String = "23:59";

  employee_no: number = 0;
  selected_channels: string = "1";
  searchFormGroup: FormGroup;
  channels: SareClass[] = [];

  display_array: ProgrammeClass[] = [];
  season_array: SeasonClass[] = [];
  episode_array: EpisodeClass[] = [];
  
  prog_no: number = 0;
  season_no: number = 0;
  view_filters = true;

  @ViewChild('calendar') calendar: FullCalendarComponent;
  //@ViewChild('calendar',{static: true}) calendar: FullCalendarComponent;

  //Pop up 
  campaign: any;
  prog_name = '';
  epi_start = '';
  epi_end = ''; 
  epi_desc = '';
  epi_no = 0;
  episodeData: EpisodeDialogClass = new EpisodeDialogClass();
 

  //Copy Schedule
  scheduleFormGroup: FormGroup;
  blnselectAll: boolean = true;
  prog_array: ProgrammeClass[] = [];
  selected_programmes: number[] = [];

  copy_start_date: String = moment().format("YYYY-MM-DD");
  copy_end_date: String = moment().format("YYYY-MM-DD");
  copy_start_time: String = "00:00";
  copy_end_time: String = "23:59";

  start_date_to: String = moment().format("YYYY-MM-DD");
  end_date_to: String = moment().format("YYYY-MM-DD");
  new_episodes: any = 0;
  skipped_episodes: any = 0;
  copy_episodes: any = 0;

  //Clear Schedule
  clearscheduleFormGroup: FormGroup;
  clear_start_date: String = moment().format("YYYY-MM-DD");
  clear_end_date: String = moment().format("YYYY-MM-DD");
  clear_start_time: String = "00:00";
  clear_end_time: String = "23:59";

  //Planning
  mon = false;
  tue = false;
  wed = false;
  thu = false;
  fri = false;
  sat = false;
  sun = false;

  planningSearchFormGroup: FormGroup;
  displayedColumns: string[] = ['select','programme','seasonNo', 'episodeNo'];
  dataSource = new MatTableDataSource(this.episode_array);
  selection = new SelectionModel<EpisodeClass>(true, []);


  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,public dialog: MatDialog,private _location: Location,private campaignSpotService: CampaignSpotService) 
  {
    

  } 

  ngOnInit() {
    
    this.clearscheduleFormGroup = this.formBuilder.group({
      start_dateCtrl: [this.clear_start_date,Validators.required], 
      end_dateCtrl: [this.clear_end_date,Validators.required],
      start_timeCtrl: [this.clear_start_time,Validators.required],
      end_timeCtrl: [this.clear_end_time,Validators.required],
      channelsCtrl: [this.selected_channels,Validators.required],
    });

    this.searchFormGroup = this.formBuilder.group({
      start_dateCtrl: [this.start_date],
      channelsCtrl: [this.selected_channels],
      progNoCtrl: [this.prog_no], 
      seasonNoCtrl: [this.season_no],
    });

    this.scheduleFormGroup = this.formBuilder.group({
      channelsCtrl: [this.selected_channels,Validators.required],
      programmesCtrl: [this.selected_programmes,Validators.required],
      start_dateCtrl: [this.copy_start_date,Validators.required],
      end_dateCtrl: [this.copy_end_date,Validators.required],
      start_timeCtrl: [this.copy_start_time,Validators.required],
      end_timeCtrl: [this.copy_end_time,Validators.required],
      start_date_toCtrl: [this.start_date_to,Validators.required],
      end_date_toCtrl: [this.end_date_to,Validators.required],
    });

    this.options = {
        plugins:[ dayGridPlugin, timeGridPlugin, interactionPlugin ],
        defaultDate: new Date(),
        themeSystem: 'bootstrap',
        header: false,
        editable: true,
        droppable: true,
        selectable: true,
        selectHelper: true,
        navLinks: true,
        allDaySlot: false,
        defaultView: 'timeGridWeek', 
        contentHeight:'auto',
        minTime: '00:00:00',
        maxTime: '24:00:00',
        eventLimit: true,
        eventDurationEditable: false, 
        deepChangeDetection: true,   
    };

    //Planning
    this.planningSearchFormGroup = this.formBuilder.group({
      selected_channelCtrl: [this.selected_channels],
      progNoCtrl: [this.prog_no],
      seasonNoCtrl: [this.season_no],
      start_dateCtrl: [this.start_date,Validators.required],
      end_dateCtrl: [this.end_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
      monCtrl: [this.mon],
      tueCtrl: [this.tue],
      wedCtrl: [this.wed],
      thuCtrl: [this.thu],
      friCtrl: [this.fri],
      satCtrl: [this.sat],
      sunCtrl: [this.sun], 
    });

    fromEvent(window, 'resize')
    .pipe(debounceTime(200))
    .subscribe(event => {
      console.log('size changed.', event);
    });
  } 
 
  ngAfterViewInit(){

    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));

    if(localStorage.getItem("rememberDate") != null && String(localStorage.getItem("rememberDate")) == '"yes"') {

      let date = localStorage.getItem("log_date");
      date = date.replace(/\//g, "-");
      let s = new Date(date);
      let calendarApi = this.calendar.getApi();
      calendarApi.gotoDate(s);
    }

    this.onChannels();
    this.onReadProgrammes();

    fromEvent(window, 'resize')
    .pipe(debounceTime(200))
    .subscribe(event => {
      console.log('size changed.', event);
    });
  }
 

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  } 

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: EpisodeClass): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idNo + 1}`;
  }

  //Apply filter when using search box
  applyFilter(filterValue: string) {
    this.selection.clear();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  resetWeek()
  {
    let calendarApi = this.calendar.getApi();
    calendarApi.gotoDate(this.start_date);
    this.current_dates = calendarApi.view.title;
    this.onReadWeekSchedule();
  }

  refresh()
  {
    this.next();
    this.prev(); 
  }
  
  next()
  {
    let calendarApi = this.calendar.getApi();
    calendarApi.next();
    this.current_dates = calendarApi.view.title;
    this.onReadWeekSchedule();
  }

  prev()
  {
    let calendarApi = this.calendar.getApi();
    calendarApi.prev();
    this.current_dates = calendarApi.view.title;
    this.onReadWeekSchedule();
  }

  eventRender(mainEvent) {

    var event = mainEvent.event;
    var element = mainEvent.el.getElementsByClassName("fc-content")[0];
    var view = mainEvent.view;

    element.className += " newTooltip";
    element.innerHTML = element.innerHTML + '<link rel="stylesheet" href="https://inxprime.mmsafrica.co.za/api/schedule/schedule/tooltip.css">';
    element.innerHTML = element.innerHTML + '<div class="newTooltiptext">Tooltip text</div>';
 }


 eventClick(event): void {
 
  console.log('Clicked...');
 
  var title = event.event.title;
  var idNo = event.event.id;
  var start = "00:00";
  var end = "23:59";
  var desc = event.event._def.extendedProps.description;

  if(event.event.end != null){
    end = event.event.end.toString().substring(0, 24);
  }
  if(event.event.start != null){
    start = event.event.start.toString().substring(0,24);
  }
 
  this.episodeData.prog_name = title;
  this.episodeData.epi_start = start;
  this.episodeData.epi_end = end;
  this.episodeData.epi_desc = desc;
  this.episodeData.epi_no = idNo;
 
  this.openAddDialog();
}

openLogEditor(event): void{

  let date = moment(event).format("YYYY/MM/DD");
  let channelId = this.selected_channels;

  localStorage.setItem('log_date', JSON.stringify(date));
  localStorage.setItem('log_channelId', JSON.stringify(channelId));
  localStorage.setItem('rememberDate', JSON.stringify('yes'));

  this.router.navigate(['/home/logEditor']);
}


addEpisode(event): void {

  var title = "";
  var idNo = 0;
  var start = event.dateStr.toString().substring(0,16);
  var end = event.dateStr.toString().substring(0,16);
  var desc = "";

  this.episodeData.prog_name = title;
  this.episodeData.epi_start = start;
  this.episodeData.epi_end = end;
  this.episodeData.epi_no = idNo;
  this.episodeData.epi_desc = this.selected_channels;

  this.openAddDialog();
}
 
openAddDialog(): void {
  const dialogRef = this.dialog.open(EpisodeDialogComponent, {
    width: '750px',
    data: this.episodeData
  });
 
  dialogRef.afterClosed().subscribe(result => {

    if (result) {
 
      if(this.episodeData.epi_no > 0){
        this.deleteEpisode();
      }
      else{
        this.refresh();
      }
      
    }
    else{
      this.refresh();
    }
  });
}

async deleteEpisode()
{ 
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/schedule/delete_episode.php`,
    {
      idNo: this.episodeData.epi_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Episode successfully deleted');
          this.onReadWeekSchedule();
        }
        else
        { 
          this.alertService.error(data.message);
        }
      } 
    ) 
  }

  async updateMoveEpisode(event)
  {
    var idNo = event.event._def.publicId;
    var dateTime = event.event.start;
    dateTime = moment(dateTime).format("YYYY/MM/DD H:mm:ss");

    var dateTimeArray = dateTime.split(' ');
    var date = dateTimeArray[0];
    var time = dateTimeArray[1];

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/schedule/move_episode.php`,
    {
      channel: this.selected_channels,
      idNo: idNo,
      date: date,
      time: time,
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          //this.alertService.success('Episode successfully added');
        }
        else
        { 
          this.alertService.error(data.message);
        }

      } 
    ) 
  }
  
  async newEpisodeDroped(event)
  { 

    var idNo = event.draggedEl.id;
    var dateTime = event.dateStr;

    var dateTimeArray = dateTime.split('T');
    var date = dateTimeArray[0].replaceAll('-','/');
    date = date.replaceAll('-','/');
    var time = dateTimeArray[1].substring(0, 8);

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/schedule/save_episode.php`,
    {
      channel: this.selected_channels,
      idNo: idNo,
      date: date,
      time: time,
      employee_no: this.employee_no,
      prog_no: this.prog_no,
      seasonNo: this.season_no

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          //this.alertService.success('Episode successfully added');
          this.onReadWeekSchedule();
        }
        else
        { 
          this.alertService.error(data.message);
        }
      } 
    ) 
  }

  async onReadWeekSchedule(){
 
    var start_date = "";
    var end_date = "";
    
    let calendarApi = this.calendar.getApi();
    this.current_dates = calendarApi.view.title;

    var strDate = this.current_dates.split(' ');
    var len = strDate.length;

    if(len == 5){
      start_date =strDate[0]+' '+strDate[1]+', '+strDate[4];
      end_date = strDate[0]+' '+strDate[3]+' '+strDate[4];
    }
    else
    if(len == 6)
    {
      start_date = strDate[0]+' '+strDate[1]+', '+strDate[5];
      end_date = strDate[3]+' '+strDate[4]+' '+strDate[5];
    }
    else 
    if(len == 7)
    {
      start_date = strDate[0]+' '+strDate[1]+' '+strDate[2];
      end_date = strDate[4]+' '+strDate[5]+' '+strDate[6];
    }

    start_date = moment(start_date).format("YYYY/MM/DD");
    end_date = moment(end_date).format("YYYY/MM/DD");
    
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/schedule/read_schedule.php`,
    {
      channes: this.selected_channels,
      start_date: start_date,
      end_date: end_date,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.events = [];
        let calendarApi = this.calendar.getApi();
        calendarApi.removeAllEvents();
        calendarApi.render();

        for(var i=0;i<data.length;i++)
        {
            var desc = data[i].ep_title.replaceAll(';',',')+";"+data[i].genre.trim()+";"+data[i].pg+";"+data[i].synopsis.replaceAll(';',',')+";"+data[i].prog_no+";"+data[i].season+";"+data[i].episode+";"+data[i].topDemographic+";"+data[i].breakPrice+";"+data[i].ratting;
            var genre = data[i].genre.trim();
            var genColor = data[i].color;
            var foreColor = "black";

            var strDate = data[i].date.replaceAll('/','-');
            strDate = strDate.replaceAll('/','-');

            var dt = new Date(strDate+' '+data[i].start_time.substring(0, 5)); 
            dt.setMinutes( dt.getMinutes() + Number(data[i].length));
            var strEndDate = strDate+' '+dt.toLocaleTimeString('it-IT').substring(0, 5);

            if(dt.toLocaleTimeString('it-IT').substring(0, 5) == "00:00"){
              strEndDate = strDate+' 23:59';
            }
 
            this.events.push({
              id: Number(data[i].idNo),
              title: data[i].title+' (S'+data[i].season+' E'+data[i].episode+')',
              start: strDate+' '+data[i].start_time.substring(0, 5),
              end: strEndDate,
              overlap: false,
              backgroundColor: genColor,
              textColor: foreColor, 
              description: desc,
            });
        } 

        localStorage.setItem('rememberDate', JSON.stringify('no'));
        calendarApi.render();  
      } 
    ) 
  }

  async onChannels(){ 
          
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channels,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.channels = [];
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

        if(data.length < 1){this.season_no = 0;}

        let calendarApi = this.calendar.getApi();
        calendarApi.next();
        calendarApi.prev();

        this.current_dates = calendarApi.view.title;
      } 
    ) 
  }

  async onReadEpisodes(season_no)
  {
    this.season_no = season_no;
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_episodes.php`,
    {
        employee_no: this.employee_no,
        prog_no: this.prog_no,
        seasonNo: this.season_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.episode_array = [];
        var containerEl = document.getElementById('external-events');
        if(this.dragEpisode != null){this.dragEpisode.destroy();}

        var length = 0;
        var genre = "";

        for(var i=0;i<data.length;i++)
        {
            this.episode_array[i] = {epi_len: data[i].epi_len,seas_no: data[i].seas_no,title: data[i].title,b: data[i].b,break_price: data[i].break_price,d: data[i].d,day: data[i].day,epi_date: data[i].epi_date,epi_no: data[i].epi_no,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,n: data[i].n,nl: data[i].nl,omni_epi_no: data[i].omni_epi_no,p: data[i].p,pg: data[i].pg,prog_name: data[i].prog_name,prog_no: data[i].prog_no,rate_code: data[i].rate_code,replaced: data[i].replaced,run_no: data[i].run_no,s: data[i].s,synopsis: data[i].synopsis,v: data[i].v,material: data[i].material,segments_data: data[i].segments_data};

            length = data[i].epi_len;
            genre = data[i].genre.trim();
        }

        this.dataSource = new MatTableDataSource(this.episode_array);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.masterToggle();

        var genColor = "";
        var foreColor = "black";

        if(genre == 'Movies'){genColor = "#9fc5e8";}
        if(genre == 'Series'){genColor = "#fce5cd";}
        if(genre == 'Shows'){genColor = "#fff2cc";}
        if(genre == 'Sport'){genColor = "#d9ead3";}
        if(genre == 'Actuality / Information'){genColor = "#d0e0e3";}
        if(genre == 'Youth / Children'){genColor = "#cfe2f3";}
        if(genre == 'Music'){genColor = "#d9d2e9";}
        if(genre == 'Religious'){genColor = "#ead1dc";}

        var dt = new Date('October 13, 2020 00:00:00');
        dt.setMinutes( dt.getMinutes() + length);
        var strDur = dt.toLocaleTimeString('it-IT');
        strDur = strDur.substring(0, 5);

        this.dragEpisode = new Draggable(containerEl, {
          itemSelector: '.fc-event',
          eventData: function(eventEl) {
            return {
              id: eventEl.id,
              title: eventEl.innerText,
              duration: strDur,
              overlap: false,
              backgroundColor: genColor,
              textColor: foreColor
            };
          }
        });

        this.dragEpisode;

        fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(event => {
        console.log('size changed.', event);
      });
      } 
    )
  }
 
  async onReadSeasons(progN)
  {
    this.prog_no = progN;

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_seasons.php`,
    {
        employee_no: this.employee_no,
        prog_no: this.prog_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.season_array = [];
        this.episode_array = [];
        this.season_no = 0;

        for(var i=0;i<data.length;i++)
        {
            this.season_array[i] = {actors: data[i].actors,directors: data[i].directors,producer: data[i].producer,cost_per_min: data[i].cost_per_min,end_date: data[i].end_date,episodes: data[i].episodes,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,prog_name: data[i].prog_name,prog_no: data[i].prog_no,run_no: data[i].run_no,seas_no: data[i].seas_no,segments: data[i].segments,sst_date: data[i].sst_date,year_of_release: data[i].year_of_release,censorship: '',pg: '',synopsis: '',hashtags: data[i].hashtags};
        }
      } 
    )
  }

  async onReadProgrammes(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_programme.php`,
    {
      channes: this.selected_channels,
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.display_array = [];
        this.season_array = [];
        this.episode_array = [];
        this.events = []; 
        
        for(var i=0;i<data.length;i++)
        {
            this.display_array[i] = {genre: data[i].genre,image: data[i].image,language: data[i].language,length: data[i].length,prod_house: data[i].prod_house,producer: data[i].producer,prog_name: data[i].prog_name,prog_no: data[i].prog_no,bdcr_no: data[i].bdcr_no,type: data[i].type,category: data[i].category,rcrd_no: data[i].rcrd_no,repeat_name: data[i].repeat_name,sare_no: data[i].sare_no,subgenre: data[i].subgenre,season_data: data[i].season_data,genre_sentech: data[i].genre_sentech,subgenre_sentech: data[i].subgenre_sentech,
            contract_no: data[i].contract_no,
            contract_type: data[i].contract_type,
            distributors: data[i].distributors};
        }

        this.onReadWeekSchedule();
      } 
    ) 
  } 


  selectAll(){

    this.selected_programmes = [];

    if(this.blnselectAll == true){
       this.selected_programmes[0] = 0;
       var index = 1;
       for(var i=0;i<this.display_array.length;i++)
       {
          this.selected_programmes[index] = this.display_array[i].prog_no;
          index = index + 1;
       }

      this.blnselectAll = false;
    }
    else{
      this.blnselectAll = true;
    }
  }

  validatePeriod()
  {
    var dif_daye = this.daysDiff(this.start_date,this.end_date) + 1;

    if(dif_daye > 7)
    {
      this.alertService.error('You can copy episodes from one week (up-to 7 days) to the rest of months');
      this.end_date = '';
    }
  }


  daysDiff(d1, d2) {
    let hours = this.hoursDiff(d1, d2);
    let daysDiff = Math.floor( hours / 24 );
    return daysDiff;
  }

  hoursDiff(d1, d2) {
    let minutes = this.minutesDiff(d1, d2);
    let hoursDiff = Math.floor( minutes / 60 );
    return hoursDiff;
  }
  
  minutesDiff(d1, d2) {
    let seconds = this.secondsDiff(d1, d2);
    let minutesDiff = Math.floor( seconds / 60 );
    return minutesDiff;
  }
  
  secondsDiff(d1, d2) {
    let millisecondDiff = d2 - d1;
    let secDiff = Math.floor( ( d2 - d1) / 1000 );
    return secDiff;
  }

  async copySchedule(){ 

    var strstart_date = moment(this.copy_start_date.toString()).format("YYYY/MM/DD");
    var strend_date = moment(this.copy_end_date.toString()).format("YYYY/MM/DD");
    var strstart_date_to = moment(this.start_date_to.toString()).format("YYYY/MM/DD");
    var strend_date_to = moment(this.end_date_to.toString()).format("YYYY/MM/DD");

    var strstart_time = this.copy_start_time.toString()+":00";
    var strend_time = this.copy_end_time.toString()+":00";
    
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/schedule/copy_schedule.php`,
    {
      programmes: this.selected_programmes,
      start_date: strstart_date,
      end_date: strend_date,
      start_date_to: strstart_date_to,
      end_date_to: strend_date_to,
      start_time: strstart_time,
      end_time: strend_time,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Schedule successfully copied !');
          this.new_episodes = data.episodes;
          this.skipped_episodes = data.skipped;
          this.copy_episodes = data.copy_episodes;
        }
        else
        { 
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  async clearSchedule(){ 

    var strstart_date = moment(this.clear_start_date.toString()).format("YYYY/MM/DD");
    var strend_date = moment(this.clear_end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.clear_start_time.toString()+":00";
    var strend_time = this.clear_end_time.toString()+":00";
    
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/schedule/clear_schedule.php`,
    {
      channelNo: this.selected_channels,
      start_date: strstart_date,
      end_date: strend_date,
      start_time: strstart_time,
      end_time: strend_time,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Schedule successfully cleard !');
          this.resetWeek();
        }
        else
        { 
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  async schedulePlanning()
  {
    var start_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var end_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/schedule/schedule_planning.php`,
    {
      start_date: start_date,
      end_date: end_date,
      channelNo: this.selected_channels,
      prog_no: this.prog_no,
      season_no: this.season_no,
      start_time: strstart_time,
      selection: this.selection.selected,
      mon: this.mon,
      tue: this.tue,
      wed: this.wed,
      thu: this.thu,
      fri: this.fri,
      sat: this.sat,
      sun: this.sun,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success(data.episodes+' Episodes successfully scheduled');
        }
        else
        { 
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  back(){
    this._location.back();
  }

}
