export class SeasonClass{
    idNo: number;
    prog_no: number;
    seas_no: number;
    sst_date: String;
    end_date: String;
    segments: number;
    episodes: number;
    run_no: number;
    exhibition_no: number;
    cost_per_min: number;
    producer: String;
    actors: String;
    directors: String;
    year_of_release: String;
    prog_name: String; 
    pg: string;
    censorship: any;
    synopsis: string;
    hashtags: string;
} 