import {AfterViewInit, Component, OnInit} from '@angular/core';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {RateSettingModel} from '../../models/rate-setting-model';
import {Chart} from 'chart.js';


@Component({
  selector: 'app-rates-graph',
  templateUrl: './rates-graph.component.html',
  styleUrls: ['./rates-graph.component.scss']
})
export class RatesGraphComponent implements OnInit, AfterViewInit {
  rateSettings: RateSettingModel[];
  chart: any;

  constructor(private rateSettingService: RateSettingService) {
  }

  ngOnInit() {
    this.rateSettingService.getStoredRateSettings().subscribe(data => {
      this.rateSettings = data;
    });
  }

  ngAfterViewInit() {
    this.rateSettingService.getStoredRateSettings().subscribe(data => {
      this.rateSettings = data;
      this.createChart();
    });
    if (this.rateSettings && this.rateSettings.length > 0) {

    }
  }

  createChart() {
    const dataSet = this.rateSettings.map(rateSetting => {
      return {data: rateSetting.fiscalTarget, label: rateSetting.fiscalTarget, borderColor: '#6917c2'};
    });
    const labels = this.rateSettings.map(rateSetting => {
      return rateSetting.rateSettingId;
    });
    const canvas = <HTMLCanvasElement> document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'Fiscal target',
          data: this.rateSettings.map(rateSetting => {
            return rateSetting.fiscalTarget;
          }),
          backgroundColor: this.rateSettings.map(rateSetting => {
            return '#6917c2';
          }),
          borderColor: this.rateSettings.map(rateSetting => {
            return '#6917c2';
          }),
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

}
