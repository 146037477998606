import {Component, OnInit} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
  selector: 'app-national-targets',
  templateUrl: './national-targets.component.html',
  styleUrls: ['./national-targets.component.scss']
})
export class NationalTargetsComponent implements OnInit {

  rateSettingModel: RateSettingModel;
  nationalTargetsFormGroup: FormGroup;
  displayedColumns: string[] = ['Channel'];
  columnsToDisplay: string[] = [];
  displayedWeekColumns: string[] = ['Channel'];
  columnsWeekToDisplay: string[] = [];
  weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  minMonthlyTotals = [];
  monthDayTotals = [];

  constructor(private rateSettingActionsService: RateSettingActionsService,
              private _formBuilder: FormBuilder, private router: Router) {
  }

  ngOnInit() {
    this.rateSettingModel = this.rateSettingActionsService.getRateSettingMod();
    if (this.rateSettingModel) {

      this.rateSettingModel.months.forEach(month => {
        this.displayedColumns.push(month);
        this.rateSettingModel.nationalTargetTotals.push({
          month: month,
          total: 0
        });
      });

      this.displayedColumns.push('Total');
      this.columnsToDisplay = this.displayedColumns;
      this.weekDays.forEach(day => {
        this.displayedWeekColumns.push(day);
      });
      this.displayedWeekColumns.push('Total');
      this.columnsWeekToDisplay = this.displayedWeekColumns;
      this.nationalTargetsFormGroup = this._formBuilder.group({
        dummy: [0]
      });
      this.rateSettingModel.channels.forEach(channel => {
        this.addMonthControls(this.rateSettingModel.months, channel.channelId);
      });
    }
  }

  addMonthControls(monthValues, channelId) {
    this.minMonthlyTotals.push({
      channelId: channelId,
      total: 0
    });
    this.monthDayTotals.push({
      channelId: channelId,
      total: 0
    });

    if (monthValues && monthValues.length > 0) {
      monthValues.forEach(month => {
        if (!this.nationalTargetsFormGroup.get(month + channelId)) {
          this.nationalTargetsFormGroup.addControl(month + channelId,
            new FormControl(0));
        }
        this.weekDays.forEach(day => {
          if (!this.nationalTargetsFormGroup.get(day + channelId)) {
            this.nationalTargetsFormGroup.addControl(day + channelId,
              new FormControl(0));
          }
        });
      });
      this.nationalTargetsFormGroup.setValidators([this.minMonthPercentage(), this.minDayPercentage()]);
    }
  }

  minMonthPercentage(min = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      this.rateSettingModel.channels.forEach(channel => {
        let totalSelected = 0;
        this.rateSettingModel.months.forEach(month => {
          if (this.nationalTargetsFormGroup.get(month + channel.channelId)) {
            totalSelected += formGroup.controls[month + channel.channelId].value;
          }
        });
        this.minMonthlyTotals.forEach(mnthTotal => {
          if (mnthTotal.channelId === channel.channelId) {
            mnthTotal.total = totalSelected;
          }
        });
      });
      return this.roundOffNumber(100) >= min ? null : {minMonthPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  getMonthlyTotal(channelId) {
    const total = this.minMonthlyTotals.find(monthlyTotal => {
      return monthlyTotal.channelId === channelId;
    });
    return total && total.total || 0;
  }

  getWeeklyTotal(channelId) {
    const total = this.monthDayTotals.find(dayTotal => {
      return dayTotal.channelId === channelId;
    });
    return total && total.total || 0;
  }

  minDayPercentage(min = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      this.rateSettingModel.channels.forEach(channel => {
        let totalSelected = 0;
        this.weekDays.forEach(day => {
          if (formGroup.get(day + channel.channelId)) {
            totalSelected += formGroup.controls[day + channel.channelId].value;
          }
        });

        this.monthDayTotals.forEach(mnthTotal => {
          if (mnthTotal.channelId === channel.channelId) {
            mnthTotal.total = totalSelected;
          }
        });
      });

      return 100 >= min ? null : {minDayPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  splitRemainder() {
    this.rateSettingModel.channels.forEach(channel => {
      const monthsWithoutPercentage = this.rateSettingModel.months.filter(monthValue => {
        return this.nationalTargetsFormGroup.controls[monthValue + channel.channelId].value === 0;
      });
      const monthsWithPercentage = this.rateSettingModel.months.filter(monthValue => {
        return this.nationalTargetsFormGroup.controls[monthValue + channel.channelId].value !== 0;
      });
      let total = 0;
      monthsWithPercentage.forEach(month => {
        total += this.nationalTargetsFormGroup.controls[month + channel.channelId].value;
      });
      this.rateSettingModel.months.forEach(month => {
        if (this.nationalTargetsFormGroup.controls[month + channel.channelId].value === 0) {
          this.nationalTargetsFormGroup.controls[month + channel.channelId]
            .setValue(this.calculateRemainderWithDecimal(total, monthsWithoutPercentage));
        }
      });
    });
  }

  copyFirstRow() {
    this.rateSettingModel.channels.forEach((channel, index) => {
      if (index !== this.rateSettingModel.channels.length) {
        this.rateSettingModel.months.forEach(month => {

          this.nationalTargetsFormGroup.controls[month + this.rateSettingModel.channels[index + 1].channelId].setValue(
            this.nationalTargetsFormGroup.controls[month + this.rateSettingModel.channels[0].channelId].value
          );
        });
      }
    });
  }

  splitWeekdayRemainder() {
    const weekDays = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

    this.rateSettingModel.channels.forEach(channel => {
      const emptyWeekday = weekDays.filter(day => {
        return this.nationalTargetsFormGroup.controls[day + channel.channelId].value === 0;
      });

      const nonEmptyWeekday = weekDays.filter(day => {
        return this.nationalTargetsFormGroup.controls[day + channel.channelId].value !== 0;
      });

      let total = 0;
      nonEmptyWeekday.forEach(day => {
        total += this.nationalTargetsFormGroup.controls[day + channel.channelId].value;
      });

      weekDays.forEach(day => {
        if (this.nationalTargetsFormGroup.controls[day + channel.channelId].value === 0) {
          this.nationalTargetsFormGroup.controls[day + channel.channelId].setValue(this.calculateRemainderWithDecimal(total, emptyWeekday));
        }
      });
    });
  }

  copyFirstWeekRow() {
    this.rateSettingModel.channels.forEach((channel, index) => {
      if (index !== this.rateSettingModel.channels.length) {
        this.weekDays.forEach(day => {
          this.nationalTargetsFormGroup.controls[day + this.rateSettingModel.channels[index + 1].channelId].setValue(
            this.nationalTargetsFormGroup.controls[day + this.rateSettingModel.channels[0].channelId].value
          );
        });
      }
    });
  }

  submitNationalTargets() {
    this.rateSettingModel.channelTargets.forEach(channelTarget => {
      this.rateSettingModel.months.forEach(month => {
        const monthlyRandValue = (this.nationalTargetsFormGroup.controls[month + channelTarget.channel.channelId].value / 100) *
          channelTarget.target;
        this.rateSettingModel.nationalTargetTotals.forEach(nationalTarget => {
          if (nationalTarget.month === month) {
            nationalTarget.total += monthlyRandValue;
          }
        });
        channelTarget.monthlyTargets.push({
          channelId: channelTarget.channel.channelId,
          month: month,
          lessDiscount: this.rateSettingModel.lessDiscount / 100,
          sellout: this.rateSettingModel.sellout / 100,
          addedValue: this.rateSettingModel.addedValue / 100,
          compensation: this.rateSettingModel.compensation / 100,
          volumeDiscount: this.rateSettingModel.volumeDiscount / 100,
          advMinPerHour: this.rateSettingModel.advMinPerHour,
          percentage: this.nationalTargetsFormGroup.controls[month + channelTarget.channel.channelId].value,
          randValue: monthlyRandValue,
          mondayPercentage: this.nationalTargetsFormGroup.controls['mon' + channelTarget.channel.channelId].value,
          mondayRandValue: this.roundOffNumber((this.nationalTargetsFormGroup.controls['mon' +
          channelTarget.channel.channelId].value / 100) * monthlyRandValue),
          tuesdayPercentage: this.nationalTargetsFormGroup.controls['tue' + channelTarget.channel.channelId].value,
          tuesdayRandValue: this.roundOffNumber((this.nationalTargetsFormGroup.controls['tue' +
          channelTarget.channel.channelId].value / 100) * monthlyRandValue),
          wednesdayPercentage: this.nationalTargetsFormGroup.controls['wed' + channelTarget.channel.channelId].value,
          wednesdayRandValue: this.roundOffNumber((this.nationalTargetsFormGroup.controls['wed' +
          channelTarget.channel.channelId].value / 100) * monthlyRandValue),
          thursdayPercentage: this.nationalTargetsFormGroup.controls['thu' + channelTarget.channel.channelId].value,
          thursdayRandValue: this.roundOffNumber((this.nationalTargetsFormGroup.controls['thu' +
          channelTarget.channel.channelId].value / 100) * monthlyRandValue),
          fridayPercentage: this.nationalTargetsFormGroup.controls['fri' + channelTarget.channel.channelId].value,
          fridayRandValue: this.roundOffNumber((this.nationalTargetsFormGroup.controls['fri' +
          channelTarget.channel.channelId].value / 100) * monthlyRandValue),
          saturdayPercentage: this.nationalTargetsFormGroup.controls['sat' + channelTarget.channel.channelId].value,
          saturdayRandValue: this.roundOffNumber((this.nationalTargetsFormGroup.controls['sat' +
          channelTarget.channel.channelId].value / 100) * monthlyRandValue),
          sundayPercentage: this.nationalTargetsFormGroup.controls['sun' + channelTarget.channel.channelId].value,
          sundayRandValue: this.roundOffNumber((this.nationalTargetsFormGroup.controls['sun' +
          channelTarget.channel.channelId].value / 100) * monthlyRandValue),
          mondayKeySlots: [],
          tuesdayKeySlots: [],
          wednesdayKeySlots: [],
          thursdayKeySlots: [],
          fridayKeySlots: [],
          saturdayKeySlots: [],
          sundayKeySlots: []
        });
      });
    });

    this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
    this.router.navigate(['home/createRateSettingTool/regionalTargets']);
  }

  calculateRemainderWithDecimal(totalEntered, arrayWithoutPercentage) {
    const val = (100 - totalEntered) / arrayWithoutPercentage.length;
    return this.roundOffNumber(val);
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }

}
