import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user';
import {environment} from '../../../environments/environment';
import {AgencyModel} from '../../models/agency.model';

@Injectable({
  providedIn: 'root'
})
export class AgencyService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  getAgenciesFromRemote(queryString): Observable<AgencyModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_agency.php?broadcasterId=` + this.currentUser.broadcasterId
      + '&search=' + queryString)
      .pipe(map(agencies => {
        if (agencies && agencies.length > 0) {
          const mappedAgencies = this.agencyMapper(agencies);
          localStorage.setItem(queryString + 'agencies', JSON.stringify(mappedAgencies));
        }
        return this.agencyMapper(agencies);
      }));
  }

  getAgenciesFromLocal(queryString) {
    return JSON.parse(localStorage.getItem(queryString + 'agencies'));
  }

  getAgencies(queryString): Observable<AgencyModel[]> {
    const agencies = this.getAgenciesFromLocal(queryString);
    if (!agencies) {

      return this.getAgenciesFromRemote(queryString);
    }

    return of(agencies);
  }

  private agencyMapper(agencyData): AgencyModel[] {
    return agencyData.map(agency => new AgencyModel(agency.agency, agency.agency_code));
  }
}
