import {Injectable} from '@angular/core';
import {RateCalculationModel} from '../../models/rate-calculation.model';

@Injectable({
  providedIn: 'root'
})
export class RateCalculationService {

  constructor() {
  }

  ratios: RateCalculationModel[] = [
    {
      seconds: 5,
      ratio: 0.5
    },
    {
      seconds: 10,
      ratio: 0.6
    },
    {
      seconds: 15,
      ratio: 0.7
    },
    {
      seconds: 20,
      ratio: 0.8
    },
    {
      seconds: 25,
      ratio: 0.9
    },
    {
      seconds: 30,
      ratio: 1
    },
    {
      seconds: 35,
      ratio: 1.5
    },
    {
      seconds: 40,
      ratio: 1.6
    },
    {
      seconds: 45,
      ratio: 1.7
    }, {
      seconds: 50,
      ratio: 1.8
    }, {
      seconds: 55,
      ratio: 1.9
    },
    {
      seconds: 60,
      ratio: 2
    },
    {
      seconds: 65,
      ratio: 2.5
    },
    {
      seconds: 70,
      ratio: 2.6
    },
    {
      seconds: 75,
      ratio: 2.7
    },
    {
      seconds: 80,
      ratio: 2.8
    },
    {
      seconds: 85,
      ratio: 2.9
    },
    {
      seconds: 90,
      ratio: 5
    }, {
      seconds: 95,
      ratio: 3.5
    }
  ];

  getRatioForDuration(duration): number {
    return this.ratios.find(ratio => ratio.seconds === duration).ratio;
  }
}
