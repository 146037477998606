import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl,NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem,TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {ContractClass} from '../../classes/acquisition';
import {LanguageClass} from '../../classes/language';
import {PGClass} from '../../classes/parentalguidance';
import {ContractListClass} from '../../classes/contractlist';
import { GenreClass } from '../../classes/genre';
import { SeasonClass } from '../../classes/season';
import { UserClass } from '../../classes/users';
import { CategoryClass } from '../../classes/categories';
import { SubGenreClass } from '../../classes/subgenre';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
 
//To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import { Router, ActivatedRoute } from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-new-programme',
  templateUrl: './new-programme.component.html',
  styleUrls: ['./new-programme.component.scss'],
})
export class NewProgrammeComponent implements OnInit {

  employee_no: number = 0;
  isLinear = true;

  programmeFormGroup: FormGroup;
  seasonFormGroup: FormGroup;

  selected_channel: number = 0;
  parentalG: String = '';
  channels: SareClass[] = [];
  genres: GenreClass[] = [];
  languages: LanguageClass[] = [];
  contractTypes: ContractListClass[] = [];
  pgs: PGClass[] = []; 
  users: UserClass[] = [];
  categories: CategoryClass[] = [];
  subgenries: SubGenreClass[] = [];
  selected_subgenries: SubGenreClass[] = []; 

  programme: ProgrammeClass = new ProgrammeClass();
  contract: ContractClass = new ContractClass();
  season: SeasonClass = new SeasonClass();

  defaultTitle: String ='';
  genericSynopsis: String = '';
  chkLN = false;
  chkN = false;
  chkS = false;
  chkV = false;
  chkB = false;
  chkD = false;
  chkP = false;

  display_array: SeasonClass[] = [];
  displayedColumns: string[] = ['select', 'Programme','season', 'episodes','producer','director','actors','View'];
  dataSource = new MatTableDataSource(this.display_array);
  selection = new SelectionModel<SeasonClass>(true, []);

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,private _location: Location) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.contract.contract_no = moment().format("YYYY-MM-DD H mm ss");
    this.programme.prog_no = 0;

    this.onChannels();
    this.onReadProgOptions();
  }

  ngOnInit() {
    
    this.programmeFormGroup = this.formBuilder.group({
      channelsCtrl: [this.selected_channel,Validators.required],
      contractNoCtrl: [this.contract.contract_no],
      progNameCtrl: [this.programme.prog_name,Validators.required],
      prdHouseCtrl: [this.programme.prod_house],
      prdPartnerCtrl: [this.contract.prod_partner],
      progLengthCtrl: [this.programme.length,Validators.required],
      genreCtrl: [this.programme.genre],
      contTypeCtrl: [this.contract.contract_type],
      languageCtrl: [this.programme.language,Validators.required],
      subgenryCtrl: [this.programme.subgenre],
      categoryCtrl: [this.programme.category],
    });

    this.seasonFormGroup = this.formBuilder.group({
      seasonNoCtrl: [this.season.seas_no,Validators.required],
      numberOfEpisodesCtrl: [this.season.episodes,Validators.required],
      segmentsCtrl: [this.season.segments,Validators.required],
      defaultTitleCtrl: [this.defaultTitle],
      genericSynopsisCtrl: [this.genericSynopsis],
      chkLNCtrl: [this.chkLN],
      chkNCtrl: [this.chkN],
      chkSCtrl: [this.chkS],
      chkVCtrl: [this.chkV],
      chkBCtrl: [this.chkB],
      chkDCtrl: [this.chkD],
      chkPCtrl: [this.chkP],
      start_dateCtrl: [this.season.sst_date,Validators.required],
      end_dateCtrl: [this.season.end_date,Validators.required],
      producerCtrl: [this.season.producer],
      directorCtrl: [this.season.directors],
      actorsCtrl: [this.season.actors],
      year_of_releaseCtrl: [this.season.year_of_release],
      parentalGCtrl: [this.parentalG,Validators.required],
    });

  }

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  } 

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: SeasonClass): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idNo + 1}`;
  }

   //Apply filter when using search box
  applyFilter(filterValue: string) {
    this.selection.clear();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  viewSeason(season)
  {
    localStorage.setItem('prog_no', JSON.stringify(this.programme.prog_no));
    localStorage.setItem('season_no', JSON.stringify(season));
    this.router.navigate(['/home/view-season']); 
  }

  async onReadProgSeasons()
  {
  
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_seasons.php`,
    {
        employee_no: this.employee_no,
        prog_no: this.programme.prog_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.display_array[i] = {actors: data[i].actors,directors: data[i].directors,producer: data[i].producer,cost_per_min: data[i].cost_per_min,end_date: data[i].end_date,episodes: data[i].episodes,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,prog_name: data[i].prog_name,prog_no: data[i].prog_no,run_no: data[i].run_no,seas_no: data[i].sea_no,segments: data[i].segments,sst_date: data[i].sst_date,year_of_release: data[i].year_of_release,censorship: '',pg: '',synopsis: '',hashtags: data[i].hashtags};
        }

        this.dataSource = new MatTableDataSource(this.display_array);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator; 

      } 
    )
  }

  async createProgramme(){ 


    if(this.season.directors == null){this.season.directors = ' ';}
    if(this.season.actors == null){this.season.actors = ' ';}
    if(this.season.producer == null){this.season.producer = ' ';}
    if(this.contract.prod_partner == null){this.contract.prod_partner = ' ';}
    if(this.programme.prod_house == null){this.programme.prod_house = ' ';}
    if(this.contract.contract_no == null){this.contract.contract_no = ' ';}
    if(this.programme.genre == null){this.programme.genre = ' ';}
    if(this.programme.subgenre == null){this.programme.subgenre = ' ';}
    if(this.programme.category == null){this.programme.category = ' ';}
    if(this.contract.contract_type == null){this.contract.contract_type = ' ';}
    if(this.genericSynopsis == null){this.genericSynopsis = ' ';}
    if(this.season.producer == null){this.season.producer = ' ';}
    if(this.season.directors == null){this.season.directors = ' ';}
    if(this.season.actors == null){this.season.actors = ' ';}

      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/create_programme.php`,
    {

      employee_no: this.employee_no,
      epi_no: this.season.episodes,
      epi_seg: this.season.segments,
      prog_no: this.programme.prog_no, 
      sea_no: this.season.seas_no,
      start_date: moment(this.season.sst_date.toString()).format("YYYY-MM-DD"),
      end_date: moment(this.season.end_date.toString()).format("YYYY-MM-DD"),
      producer: this.season.producer,
      synopsis: this.genericSynopsis,
      actors: this.season.actors,
      directors: this.season.directors,
      year_of_release: this.season.year_of_release,
      defaultTitle: this.defaultTitle,

      chkLN: this.chkLN,
      chkN: this.chkN,
      chkS: this.chkS,
      chkV: this.chkV,
      chkB: this.chkB,
      chkD: this.chkD,
      chkP: this.chkP,

      chann: this.selected_channel,
      contract: this.contract.contract_no,
      prog_name: this.programme.prog_name,
      prod_house: this.programme.prod_house,
      prod_partner: this.contract.prod_partner,
      prog_length: this.programme.length,
      genre: this.programme.genre,
      contract_type: this.contract.contract_type,
      language: this.programme.language,
      parental_g: this.parentalG,
      subgenre: this.programme.subgenre,
      category: this.programme.category,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {

        if(data.message == 'success')
        {
          this.alertService.success('Programme successfully saved');
          this.programme.prog_no = Number(data.prog_no);

          this.season.seas_no = null;
          this.season.episodes = null;
          this.season.segments = null;
          this.genericSynopsis = null;
          this.chkLN = false;
          this.chkN = false;
          this.chkV = false;
          this.chkB = false;
          this.chkD = false;
          this.chkP = false;
          this.season.sst_date = null;
          this.season.end_date = null;
          this.season.producer = null;
          this.season.directors = null;
          this.season.actors = null;
          this.parentalG = null;

          this.onReadProgSeasons();
        }
        else
        { 
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  done()
  {
    this.programme = new ProgrammeClass();
    this.contract = new ContractClass();
    this.genericSynopsis = '';
    this.parentalG = '';
    this.router.navigate(['/home/programme-list']);
  }

  genreChanged(idNO)
  {
      this.selected_subgenries = [];

      for(var i=0;i<this.subgenries.length;i++)
      {
          if(this.subgenries[i].genre == idNO)
          {
            this.selected_subgenries.push(this.subgenries[i]);
          }
      }
  }

  async onReadProgOptions(){ 
    
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_programme_options.php`,
    {
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {

        for(var i=0;i<data[0].genre.length;i++)
        {
            this.genres[i] = {bdcr_no: data[0].genre[i].bdcr_no,genre: data[0].genre[i].genre,genreId: data[0].genre[i].genreId,color: data[0].genre[i].color,code: 0};
        } 

        for(var i=0;i<data[0].pgs.length;i++)
        {
            this.pgs[i] = {pg_id: data[0].pgs[i].pg_id,code: data[0].pgs[i].code,pg_name: data[0].pgs[i].pg_name};
        } 

        for(var i=0;i<data[0].language.length;i++)
        {
            this.languages[i] = {idNo: data[0].language[i].idNo,bdcr_no: data[0].language[i].bdcr_no,language: data[0].language[i].language};
        } 

        for(var i=0;i<data[0].contract.length;i++)
        {
            this.contractTypes[i] = {contract_id: data[0].contract[i].contract_id,contract: data[0].contract[i].contract};
        } 

        for(var i=0;i<data[0].user.length;i++)
        {
            this.users[i] = {name: data[0].user[i].name,
              bdcr_no: data[0].user[i].bdcr_no,
              paswd: data[0].user[i].paswd,
              email: data[0].user[i].email,
              role_id: data[0].user[i].role_id,
              atemps: data[0].user[i].atemps,
              pers_code: data[0].user[i].pers_code,
              accountType: data[0].user[i].accountType,
              phoneNumber: data[0].user[i].phoneNumber,
              status: data[0].user[i].status,
              surname: data[0].user[i].surname,
              analytics_delete: null,
              analytics_read: null,
              analytics_write:null,
              content_management_delete:null,
              content_management_read:null,
              content_management_write:null,
              content_sales_delete:null,
              content_sales_read:null,
              content_sales_write:null,
              elearning_delete:null,
              elearning_read:null,
              elearning_write:null,
              finance_delete:null,
              finance_read:null,
              finance_write:null,
              hr_delete:null,
              hr_read:null,
              hr_write:null,
              inventory_management_delete:null,
              inventory_management_read:null,
              inventory_management_write:null,
              market_place_delete:null,
              market_place_read:null,
              market_place_write:null,
              mat_man_delete:null,
              mat_man_read:null,
              mat_man_write:null,
              news_delete:null,
              news_read:null,
              news_write:null,
              production_services_delete:null,
              production_services_read:null,
              production_services_write:null,
              prog_delete:null,
              prog_read:null,
              prog_write:null,
              project_management_delete:null,
              project_management_read:null,
              project_management_write:null,
              revenue_management_delete:null,
              revenue_management_read:null,
              revenue_management_write:null,
              schedule_delete:null,
              schedule_read:null,
              schedule_write:null,
              web2app_delete:null,
              web2app_read:null,
              web2app_write:null};
        } 

        for(var i=0;i<data[0].categories.length;i++)
        {
            this.categories[i] = {idNo: data[0].categories[i].idNo,name: data[0].categories[i].name};
        } 

        for(var i=0;i<data[0].subgenries.length;i++)
        {
            this.subgenries[i] = {idNo: data[0].subgenries[i].idNo,genre: data[0].subgenries[i].genre,subgenre: data[0].subgenries[i].subgenre,code: 0};
        }

      } 
    ) 
  }

  async onChannels(){ 

      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channel,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        } 

        if(data.length >= 1){this.selected_channel = this.channels[0].sare_no;}
      } 
    ) 
  }

  back(){
    this._location.back();
  }

}
 