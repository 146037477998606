export class SponsorshipClass{
    spons_no: number;
    prog_no: number;
    prod_code: number;
    sare_no: number;
    no_of_elements: number;
    total_nominal_price: number;
    sst_date: string;
    end_date: string;
    disc_percent: number;
    pers_code: number;
    owner_code: number;
    agency_code: number;
    rcrd_no: string;
}