import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AlertService } from 'src/app/services/alert/alert.service';
import {Location} from '@angular/common';
import { WebArticleClass } from 'src/app/classes/web.article';
import { ArticleAuthor } from 'src/app/classes/article.author';
import * as $ from "jquery";

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { FTPFiles } from 'src/app/classes/ftp.files';
import { VideoProcessingService } from './video-processing-service';
import { DomSanitizer } from '@angular/platform-browser';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-new-article',
  templateUrl: './new-article.component.html',
  styleUrls: ['./new-article.component.scss'],
  providers: [MessageService]
})
export class NewArticleComponent implements OnInit {

  formData = new FormData();
  imageSrc: string | ArrayBuffer;
  selectedFile: FileList  = null;
  author_list: ArticleAuthor[] = [];
  uploadedFiles: any[] = [];
  popularOptions: any[] = [{label: 'Not Popular', value: 'Not Popular'}, {label: 'Popular', value: 'Popular'}];
  statusOptions: any[] = [{label: 'Draft', value: 'Draft'}, {label: 'Publish Now', value: 'Publish Now'}, {label: 'Auto Publish', value: 'Auto Publish'}];

  videoOptions: any[] = [{label: 'Youtube Video', value: 'Youtube Video'}, {label: 'Upload Video', value: 'Upload Video'}];
  videoType = {label: 'Youtube Video', value: 'Youtube Video'};

  public thumbnailData: string;
  public video_file = null;

  safeUrl;
  disp_youtbe = false;

  employee_no: number = 0;
  articleFormGroup: FormGroup;
  article: WebArticleClass  = new WebArticleClass();
 
  constructor(private messageService: MessageService,private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,public dialog: MatDialog,private _location: Location,private videoService: VideoProcessingService,private sanitizer: DomSanitizer) {

    this.onReadAuthors();
   }

  ngOnInit() {

    this.article.is_popular = {label: 'Not Popular', value: 'Not Popular'};
    this.article.status = {label: 'Draft', value: 'Draft'};
    this.article.image_caption = '';
    this.article.image_credit = '';
    this.article.video = '';
    this.article.date_published = moment().format("YYYY-MM-DD");
    this.article.time_published = moment().format("HH:mm");

    this.selectTextColor('#000000');
    this.selectColor('#cccccc');

    //Planning
    this.articleFormGroup = this.formBuilder.group({
      headlineCtrl: [this.article.headline],
      slugCtrl: [this.article.slug],
      tagCtrl: [this.article.tag],
      summary_pull_quoteCtrl: [this.article.summary_pull_quote],
      post_contentCtrl: [this.article.post_content],
      imageCtrl: [this.article.image],
      videoCtrl: [this.article.video],
      videoTypeCtrl: [this.videoType],
      //twitter_embadedCtrl: [this.article.twitter_embaded],
      article_colorCtrl: [this.article.article_color],
     // is_popularCtrl: [this.article.is_popular],
      date_publishedCtrl: [this.article.date_published],
      time_publishedCtrl: [this.article.time_published],
      statusCtrl: [this.article.status],
      //article_byCtrl: [this.article.article_by],
      image_captionCtrl: [this.article.image_caption],
      image_creditCtrl: [this.article.image_credit],
      text_over_colorCtrl: [this.article.text_over_color],
      featured_homepageCtrl: [this.article.featured_homepage],
      featured_popularCtrl: [this.article.featured_popular],
      featured_subscetionCtrl: [this.article.featured_subscetion],
      authorCtrl: [this.article.author],
      guest_authorCtrl: [this.article.guest_author],
      categoryCtrl: [this.article.category],
      //auto_publishCtrl: [this.article.auto_publish],
    });
  }

  ngAfterViewInit(){
    
    //File import
    $("form").on("change", ".file-upload-field18", function(){ 
      $(this).parent(".file-upload-wrapper").attr("data-text",  $(this).val().replace(/.*(\/|\\)/, '') );
    });

    $("form").on("change", ".file-upload-field19", function(){ 
      $(this).parent(".file-upload-wrapper").attr("data-text",  $(this).val().replace(/.*(\/|\\)/, '') );
    });

  }

  getTrustedUrl(url:any){ 
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
   }

   reloadVideo(url){

    this.article.video = url.replace("watch?v=","embed/");

    this.disp_youtbe = false;
    if(this.article.video.length > 10){
      this.getTrustedUrl(this.article.video);
      this.disp_youtbe = true;
    }
  }

  headlineKeyUp(){ 
   
    const specialCharsRegex = /[^a-zA-Z0-9]/g;
    //this.article.headline = this.article.headline.trim();
    this.article.slug = (this.article.headline.replace(' ','-').replace('"','').replace("'","")).replace(specialCharsRegex, '-');
  }

  onFileSelected(event){
    
    this.selectedFile  = null;
    this.selectedFile = <FileList>event.target.files;

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.imageSrc = reader.result;
      reader.readAsDataURL(file);
  }
  }


  public add(): void {
    this.videoService.promptForVideo().then(videoFile => { 
      //console.dir(videoFile);
      this.video_file = videoFile;
      return this.videoService.generateThumbnail(videoFile);
    }).then(thumbnailData => {
      //console.dir(thumbnailData); 
      this.thumbnailData = thumbnailData;
    })
  }

  resetFiles(): void{
    
    this.thumbnailData = '';
    this.video_file = null;  
    this.article.video = '';
    this.disp_youtbe = false;
  }
  

  async saveArticle(){
    
    if(this.selectedFile != null)
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }

    var firstLetter = this.article.slug.charAt(0);
    var LastLetter = this.article.slug.charAt(this.article.slug.length-1);
    var strPublishDate = moment(this.article.date_published.toString()).format("YYYY-MM-DD");

    if(firstLetter == "-"){this.article.slug = '' + this.article.slug.slice(1);}
    if(LastLetter == "-"){this.article.slug = this.article.slug.slice(0, -1) + '_';}

    this.formData.append('headline',<any>this.article.headline);
    this.formData.append('slug',<any>this.article.slug);
    this.formData.append('tag',<any>this.article.tag);
    this.formData.append('summary_pull_quote',<any>this.article.summary_pull_quote);
    this.formData.append('post_content',<any>this.article.post_content);
    this.formData.append('image',<any>this.article.image);
    this.formData.append('video',<any>this.article.video);
    this.formData.append('twitter_embaded',<any>this.article.twitter_embaded);
    this.formData.append('article_color',<any>this.article.article_color);
    this.formData.append('date_published',<any>strPublishDate);
    this.formData.append('time_published',<any>this.article.time_published);
    this.formData.append('auto_publish',<any>this.article.auto_publish);
    this.formData.append('status',<any>this.article.status.value);
    this.formData.append('article_by',<any>this.article.article_by);
    this.formData.append('category',<any>this.article.category);
    this.formData.append('image_caption',<any>this.article.image_caption);
    this.formData.append('image_credit',<any>this.article.image_credit);
    this.formData.append('text_over_color',<any>this.article.text_over_color);
    this.formData.append('featured_homepage',<any>this.article.featured_homepage);
    this.formData.append('featured_popular',<any>this.article.featured_popular);
    this.formData.append('featured_subscetion',<any>this.article.featured_subscetion);
    this.formData.append('author',<any>this.article.author);
    this.formData.append('guest_author',<any>this.article.guest_author);
    if(this.video_file != null){this.formData.append('video_file',<any>this.video_file);}

    this.httpClient.post('https://inxprime.mmsafrica.co.za/api/web-admin/save_article.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {
      

      if(data.message == 'success')
        {
          this.articleFormGroup.reset();
          this.alertService.success("Article successfully saved"); 
          this.router.navigate(['/home/zimoja']);
          this.formData = new FormData();
        }
        else{

          this.alertService.error(data.message);
        } 

    });
  }


  selectColor(color)
  {
    this.article.article_color = color;
  }

  selectTextColor(color)
  {
    this.article.text_over_color = color;
  }

  async onReadAuthors() {

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/web-admin/zimoja/read_authors.php`,
        {
            employee_no: this.employee_no,
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                this.author_list = [];
    
                for (let i = 0; i < data.length; i++) {
                    this.author_list[i] = {Bio: data[i].Bio,Bio_Summary: data[i].Bio_Summary,Collection_ID: data[i].Collection_ID,Created_On: data[i].Created_On,Email: data[i].Email,Facebook_Profile_Link: data[i].Facebook_Profile_Link,Instagram_Profile_Link: data[i].Instagram_Profile_Link,Item_ID: data[i].Item_ID,Name: data[i].Name,Picture: data[i].Picture,Published_On: data[i].Published_On,Slug: data[i].Slug,Twitter_Profile_Link: data[i].Twitter_Profile_Link,Updated_On: data[i].Updated_On
                    };
                }
    
            }
        ); 
    }

  back(){
    this._location.back();
  }

} 
