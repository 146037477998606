export class ElementModel {
  broadcasterId: number;
  element: string;
  elementType: string; // TODO: change magic string to constant
  indexValue: number;

  constructor(broadcasterId = 0, element = '', elementType = '', indexValue = 0) {

    this.broadcasterId = broadcasterId;
    this.element = element;
    this.elementType = elementType;
    this.indexValue = indexValue;
  }
}
