import {Component, OnInit, ViewChild} from '@angular/core';
import * as _moment from 'moment';
import {CampaignModel} from '../../models/campaign.model';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {AlertService} from '../../services/alert/alert.service';
import {CampaignService} from '../../services/campaign/campaign.service';
import {Router} from '@angular/router';
import {EditCampaignDialogComponent} from '../edit-campaign-dialog/edit-campaign-dialog.component';

const moment = _moment;

@Component({
  selector: 'app-trading-table',
  templateUrl: './trading-table.component.html',
  styleUrls: ['./trading-table.component.scss']
})
export class TradingTableComponent implements OnInit {
  campaigns: CampaignModel[];
  displayedColumns: string[] = ['campaignId', 'start_date', 'end_date', 'product', 'advertiser', 'agency', 'numberOfSpots', 'status',
    'totalNominalPrice', 'edit', 'viewSpots'];
  dataSource: any;
  @ViewChild('paginator11') paginator11: MatPaginator;

  constructor(private campaignService: CampaignService, private alertService: AlertService, private router: Router,
              public dialog: MatDialog) {
  }

  ngOnInit() {
    this.campaignService.getCampaigns().subscribe(campaigns => {
      this.campaigns = campaigns;
      this.dataSource = new MatTableDataSource<CampaignModel>(campaigns);
      this.dataSource.paginator = this.paginator11;
    }, error => {
      this.alertService.error("Something went wrong, Try again");
    });
  }

  applyFilter2(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  formatDate(date) {
    return moment(date).format('YYYY/MM/DD');
  }

  openEditDialog(campaign) {
    const dialogRef = this.dialog.open(EditCampaignDialogComponent, {
      width: '600px',
      data: campaign
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const updatedCampaign = {
          campaignId: result.campaignId,
          startDate: result.start_date,
          endDate: result.end_date,
          productId: result.productId,
          agency: result.agencyModel,
          advertiser: result.advertiserModel
        };
        this.campaignService.postCampaignHeader(updatedCampaign).subscribe(success => {
          this.alertService.success('Campaign successfully edited', false);
        }, error => {
          this.alertService.error('Error updating campaign. Try again later', false);
        });
      }
    });
  }

  viewSpots(campaign) {
    localStorage.setItem('selectedCampaign', JSON.stringify(campaign));
    this.router.navigate(['/home/spotView']);
  }

}
