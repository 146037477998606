import {Component, OnInit} from '@angular/core';
import {RateSettingModel} from '../../models/rate-setting-model';
import {RegionsService} from '../../services/regions/regions.service';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {FormBuilder, FormControl, FormGroup, ValidatorFn} from '@angular/forms';
import {AlertService} from '../../services/alert/alert.service';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-regional-targets',
  templateUrl: './regional-targets.component.html',
  styleUrls: ['./regional-targets.component.scss']
})
export class RegionalTargetsComponent implements OnInit {

  rateSettingModel: RateSettingModel;
  regions: any[] = [];
  regionTotals: any[] = [];
  dataSource: any[] = [];
  regionalTargetsFormGroup: FormGroup;
  displayedColumns: string[] = ['Month'];
  columnsToDisplay: string[] = [];
  minMonthlyTotals = [];

  constructor(private regionsService: RegionsService,
              private rateSettingActionsService: RateSettingActionsService,
              private _formBuilder: FormBuilder,
              private rateSettingService: RateSettingService,
              private alertService: AlertService,
              private router: Router) {
  }

  ngOnInit() {
    this.rateSettingModel = this.rateSettingActionsService.getRateSettingMod();
    this.regionalTargetsFormGroup = this._formBuilder.group({
      dummy: [0]
    });
    this.regionsService.getRegions().subscribe(regions => {
      this.regions = regions;
      this.regions.forEach(region => {
        this.regionTotals.push({
          region: region.region,
          total: 0
        });
        this.displayedColumns.push(region.region);
        this.rateSettingModel.regionalTargets.push({
          region: region.region,
          monthlyTargets: []
        });
      });

      this.displayedColumns.push('Total');
      this.columnsToDisplay = this.displayedColumns;

      if (this.rateSettingModel && this.rateSettingModel.nationalTargetTotals) {
        this.rateSettingModel.nationalTargetTotals.forEach(month => {
          this.dataSource.push({
            month: month.month,
          });
          this.minMonthlyTotals.push({
            month: month.month,
            total: 0
          });
          this.regions.forEach(region => {
            this.regionalTargetsFormGroup.addControl(month.month + '-' + region.region, new FormControl(0));
          });
        });
      }

      this.regionalTargetsFormGroup.setValidators([this.minMonthPercentage()]);
    });
  }

  minMonthPercentage(min = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      this.rateSettingModel.nationalTargetTotals.forEach(month => {
        let totalSelected = 0;
        this.regions.forEach(region => {
          if (this.regionalTargetsFormGroup.get(month.month + '-' + region.region)) {
            totalSelected += formGroup.controls[month.month + '-' + region.region].value;
          }
        });
        this.minMonthlyTotals.forEach(mnthTotal => {
          if (mnthTotal.month === month.month) {
            mnthTotal.total = totalSelected;
          }
        });
      });
      return this.roundOffNumber(100) >= min ? null : {minMonthPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  splitRemainder() {
    this.rateSettingModel.nationalTargetTotals.forEach(month => {
      const monthsWithoutPercentage = this.regions.filter(region => {
        return this.regionalTargetsFormGroup.controls[month.month + '-' + region.region].value === 0;
      });
      const monthsWithPercentage = this.regions.filter(region => {
        return this.regionalTargetsFormGroup.controls[month.month + '-' + region.region].value !== 0;
      });
      let total = 0;
      monthsWithPercentage.forEach(region => {
        total += this.regionalTargetsFormGroup.controls[month.month + '-' + region.region].value;
      });
      this.regions.forEach(region => {
        if (this.regionalTargetsFormGroup.controls[month.month + '-' + region.region].value === 0) {
          this.regionalTargetsFormGroup.controls[month.month + '-' + region.region]
            .setValue(this.calculateRemainderWithDecimal(total, monthsWithoutPercentage));
        }
      });
    });
  }

  copyFirstRow() {
    this.rateSettingModel.nationalTargetTotals.forEach((month, index) => {
      if (index !== this.rateSettingModel.nationalTargetTotals.length) {
        this.regions.forEach(region => {

          this.regionalTargetsFormGroup.controls[
          this.rateSettingModel.nationalTargetTotals[index + 1].month + '-' + region.region].setValue(
            this.regionalTargetsFormGroup.controls[
            this.rateSettingModel.nationalTargetTotals[0].month + '-' + region.region].value
          );
        });
      }
    });
  }


  saveRegionalTargets() {
    this.rateSettingModel.regionalTargets.forEach(regionalTarget => {
      const region = regionalTarget.region;
      this.rateSettingModel.nationalTargetTotals.forEach((month, index) => {
        regionalTarget.monthlyTargets.push({
          month: month,
          target: this.regionalTargetsFormGroup.controls[month.month + '-' + region].value,
          randValue: (this.regionalTargetsFormGroup.controls[month.month + '-' + region].value / 100)
            * (this.rateSettingModel.nationalTargetTotals[index].total)
        });
      });
    });

    this.rateSettingService.postNationalTargets(this.rateSettingModel).subscribe(success => {
      this.alertService.success('National targets successfully saved');
      this.router.navigate(['home/revenueManagement']);
    });
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }

  calculateRemainderWithDecimal(totalEntered, arrayWithoutPercentage) {
    const val = (100 - totalEntered) / arrayWithoutPercentage.length;
    return this.roundOffNumber(val);
  }
}
