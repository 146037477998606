import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {User} from '../../models/user';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import * as _moment from 'moment';

const moment = _moment;

@Injectable({
  providedIn: 'root'
})
export class RegionsService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  getRegions(): Observable<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_regions.php?broadcasterId=` + this.currentUser.broadcasterId);
  }

  getMonthsForRegionalTargets(rateSettingId): Observable<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_monthly_target.php?rate_setting_id=` + rateSettingId)
      .pipe(map(monthlyTargets => {
        const monthArray = [];
        if (monthlyTargets && monthlyTargets.length > 0) {
          monthlyTargets.forEach(month => {
            monthArray.push({
              month: moment().month(month.rate_month).format('MMM')
            });
          });
        }
        return monthArray;
      }));
  }

  getRegionalTargets(rateSettingId): Observable<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_regional_targets.php?rate_setting_id=` + rateSettingId);
  }

  updateRegionalTargets(regionalTargets): Observable<any> {
    return this.http.post<any>(environment.serverUrl + 'edit_regional_targets.php', regionalTargets);
  }

}
