export class SponsorshipTableModel {
  sponsorshipId: number;
  start_date: Date;
  end_date: Date;
  productId: string;
  product: string;
  advertiser: string;
  agency: string;
  numberOfElements: number;
  userId: number;
  totalNominalPrice: number;

  constructor(sponsorshipId = 0, start_date = '', end_date = '', product = '', advertiser = '', agency = '',
              numberOfElements = 0, totalNominalPrice = 0) {

    this.sponsorshipId = sponsorshipId;
    this.start_date = new Date(start_date);
    this.end_date = new Date(end_date);
    this.product = product;
    this.advertiser = advertiser;
    this.agency = agency;
    this.numberOfElements = numberOfElements;
    this.totalNominalPrice = totalNominalPrice;
  }
}
