import {Component, OnInit, ViewChild} from '@angular/core';
import {CampaignModel} from '../../models/campaign.model';
import {CampaignService} from '../../services/campaign/campaign.service';
import {AlertService} from '../../services/alert/alert.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-campaigns',
  templateUrl: './campaigns.component.html',
  styleUrls: ['./campaigns.component.scss']
})
export class CampaignsComponent implements OnInit {
  campaigns: CampaignModel[];
  displayedColumns: string[] = ['campaignId', 'start_date', 'end_date', 'product', 'advertiser', 'numberOfSpots', 'status',
    'totalNominalPrice', 'viewSpots'];
  dataSource: any;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator; 

  constructor(private campaignService: CampaignService, private alertService: AlertService, private router: Router) {
  }

  ngOnInit() {
    this.campaignService.getCampaigns().subscribe(campaigns => {
      this.campaigns = campaigns;

      this.table.renderRows();
      this.dataSource = new MatTableDataSource<CampaignModel>(campaigns);
      this.dataSource.paginator = this.paginator;
    }, error => {
      this.alertService.error("Something went wrong, Try again");
    });
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  formatDate(date) {
    return moment(date).format('YYYY/MM/DD');
  }

  viewSpots(campaign) {
    localStorage.setItem('selectedCampaign', JSON.stringify(campaign));
    this.router.navigate(['/home/spotView']);
  }

}
