import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-duration-picker',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.scss']
})
export class DurationPickerComponent implements OnInit {
  @Input() group: FormGroup;
  @Output() SelectedItem = new EventEmitter<any>();
  durations: any[];
  durationCtrl: FormControl = new FormControl('', Validators.required);

  constructor() {
  }

  ngOnInit() {
    this.group.addControl('durationCtrl', this.durationCtrl);
    this.durations = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90];
  }

  onSelect(duration) {
    this.SelectedItem.emit(duration);
  }
}
