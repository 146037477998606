import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'formatDecimalPlaces'
})

export class FormatDecimalPlacesPipe implements PipeTransform {
  transform(value: number, decimalPlaces: string): string {
    const fractionDigits = parseFloat(decimalPlaces);
    if (value % 1 !== 0) {
      if (fractionDigits === 0) {
        return parseFloat((Math.floor(value * 100) / 100).toFixed().toLocaleString()).toLocaleString('en-ZA');
      }
      return parseFloat((Math.floor(value * 100) / 100).toFixed(fractionDigits).toLocaleString()).toLocaleString('en-ZA');
    } else {
      return  value.toLocaleString('en-ZA');
    }
  }
}
