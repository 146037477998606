import {Injectable} from '@angular/core';
import {CampaignSpotsModel} from '../../models/campaignSpots.model';
import {Observable, of, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {User} from '../../models/user';
import {CampaignSpotModel} from '../../models/campaign-spot.model';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignSpotService {
  currentUser: User;
  mockData = [{
    'sare_name': 'SOWETO TV',
    'brek_sched_date': '2018/12/03',
    'stt_time': '08:30:00',
    'end_time': '09:00:00',
    'rate': '11000',
    'duration': '15',
    'u1': '26',
    'industry_code': '',
    'prog_name': 'Local Testing Show'
  },
    {
      'spot_no': 122,
      'sare_name': 'SOWETO TV',
      'brek_sched_date': '2018/12/03',
      'stt_time': '09:00:00',
      'end_time': '09:30:00',
      'rate': '15000',
      'duration': '15',
      'u1': '24',
      'industry_code': '',
      'prog_name': 'Local News'
    },
    {
      'spot_no': 123,
      'sare_name': 'SOWETO TV',
      'brek_sched_date': '2018/12/04',
      'stt_time': '08:30:00',
      'end_time': '09:00:00',
      'rate': '11000',
      'duration': '15',
      'u1': '26',
      'industry_code': '',
      'prog_name': 'Local Testing Show'
    },
    {
      'spot_no': 124,
      'sare_name': 'SOWETO TV',
      'brek_sched_date': '2018/12/04',
      'stt_time': '09:00:00',
      'end_time': '09:30:00',
      'rate': '15000',
      'duration': '15',
      'u1': '24',
      'industry_code': '',
      'prog_name': 'Local News'
    }];

  constructor(private http: HttpClient) {
  }

  getCampaignSpotsFromRemote(): Observable<CampaignSpotsModel[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + 'get_dashboard.php?userId=' + this.currentUser.userId + '&type=spot')
      .pipe(map(campaignSpots => {
        if (campaignSpots && campaignSpots.length > 0) {
          const mappedSpots = this.campaignSpotMapper(campaignSpots);
          localStorage.setItem('campaignSpots', JSON.stringify(mappedSpots));
        }
        return this.campaignSpotMapper(campaignSpots);
      }));
  }

  getCampaignSpotsFromLocal() {
    return JSON.parse(localStorage.getItem('campaignSpots'));
  }

  getCampaignSpotsForCampaignFromRemote(campaignId) {
    return this.http.get<any>(environment.serverUrl + 'get_campaign_spots.php?camp_no=' + campaignId)
      .pipe(map(campaignSpots => {
        return this.mapSpotsForCampaign(campaignSpots);
      }));
  }

  getCampaignSpotsForCampaign(campaignId): Observable<CampaignSpotModel[]> {
   // return of(this.mapSpotsForCampaign(this.mockData));
    return this.getCampaignSpotsForCampaignFromRemote(campaignId);
  }

  editSpots(spots,data) {
    return this.http.post<any>(environment.serverUrl + `edit_campaign_spots.php`,
      {spots,data});
  }

  addSpot(spot) {
    return this.http.post<any>(environment.serverUrl + `add_campaign_spots.php`,
      [spot]);
  }

  deleteSpots(spots) {
    return this.http.post<any>(environment.serverUrl + `delete_campaign_spots.php`,
      [spots]);
  }

  getCampaignSpots(): Observable<CampaignSpotsModel[]> {
    const campaignSpots = this.getCampaignSpotsFromLocal();
    if (!campaignSpots) {

      // uncomment when using API
      return this.getCampaignSpotsFromRemote();
    }

    return of(campaignSpots);
  }

  refreshSpots(): Observable<CampaignSpotsModel[]> {
    return this.getCampaignSpotsFromRemote();
  }

  private campaignSpotMapper(campaignSpotData): CampaignSpotsModel[] {
    let mappedSpotCampaigns: CampaignSpotsModel[] = [];
    if (campaignSpotData && campaignSpotData.length > 0) {
      campaignSpotData.forEach(function (campaignSpot) {
        mappedSpotCampaigns.push({
          campaignId: campaignSpot.camp_no || null,
          channelId: campaignSpot.sare_no || null,
          date: new Date(campaignSpot.brek_sched_date) || null,
          startTime: campaignSpot.stt_time || null,
          endTime: campaignSpot.end_time || null,
          programId: campaignSpot.prog_no || null,
          duration: campaignSpot.duration || null,
          day: campaignSpot.day || null,
          ulSpots: campaignSpot.ul || null
        });
      });
    }

    return mappedSpotCampaigns;
  }

  private mapSpotsForCampaign(campaignSpotData): CampaignSpotModel[] {
    return campaignSpotData.map(spot => new CampaignSpotModel(spot.sare_name, spot.brek_sched_date, spot.stt_time, spot.end_time, spot.rate,
      spot.duration, spot.u1, spot.industry_code, spot.prog_name, spot.spot_no));
  }
}
