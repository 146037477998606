import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AgencyModel} from '../../models/agency.model';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/internal/operators';
import {map, startWith} from 'rxjs/operators';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AgencyService} from '../../services/agency/agency.service';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-agency-selector',
  templateUrl: './agency-selector.component.html',
  styleUrls: ['./agency-selector.component.scss']
})
export class AgencySelectorComponent implements OnInit {
  @Input() productAgency: AgencyModel;
  @Input() formGroup: FormGroup;
  @Output() SelectedItem = new EventEmitter<AgencyModel>();
  agencies: any[];
  agencyCtrl: FormControl;
  filteredAgencies: Observable<AgencyModel[]>;

  constructor(private agencyService: AgencyService) {

  }

  ngOnInit() {
    this.agencies = [];
    this.agencyCtrl = new FormControl(this.productAgency.agency, Validators.required);
    this.filteredAgencies = this.agencyCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((agency => agency ? this._filterAgencies(agency) : this.agencies.slice())));
    this.formGroup.addControl('agency', this.agencyCtrl);

  }

  private _filterAgencies(value: string): Observable<AgencyModel[]> {
    const filterValue = value.toLowerCase();
    if (filterValue.length > 2) {
      return this.agencyService.getAgencies(filterValue).pipe(
        map(agencies => {
          return agencies;
        })
      );
    } else {
      return of([]);
    }
  }

  onSelect(agency) {
    this.SelectedItem.emit(agency);
  }

}
