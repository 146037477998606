import { Component, OnInit, ViewChild } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { FormGroup, FormBuilder, Validators, FormControl,NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem,TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import { SeasonClass } from '../../classes/season';
import { EpisodeClass } from '../../classes/episode';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {Location} from '@angular/common';
  
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';
 
//To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import {AlertService} from '../../services/alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from "jquery";

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { ReconSpots } from 'src/app/classes/recon.spots';
import { ReconAsrun } from 'src/app/classes/recon.asrun';
import { ReconDialogComponent } from '../recon-dialog/recon-dialog.component';
import { Reconcile } from 'src/app/classes/recon';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-reconciliation',
  templateUrl: './reconciliation.component.html',
  styleUrls: ['./reconciliation.component.scss']
})
export class ReconciliationComponent implements OnInit {

  selectedFile: FileList  = null;
  formData = new FormData();
  reconFormGroup: FormGroup;
  employee_no: number = 0;
  selected_channel; 
  recon_status: string = 'All';
  selectedPlannedID = 0;
  selectedAsrunID = 0;

  selected_channels: number[] = [];
  channels: SareClass[] = [];

  start_date: String = moment().format("YYYY-MM-DD");
  start_time: String = "00:00";
  end_time: String = "23:59";

  spots_array: ReconSpots[] = [];
  displayedColumns: string[] = ['select','start_time', 'advertiser_name','industry_code','tx_time'];
  dataSource = new MatTableDataSource(this.spots_array);
  selection = new SelectionModel<ReconSpots>(true, []);
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('paginator1') paginator1: MatPaginator;

  asRun_array: ReconAsrun[] = [];
  asRun_displayedColumns: string[] = ['select','start_time', 'advertiser_name','flighting_code','event_type'];
  asRun_dataSource = new MatTableDataSource(this.asRun_array);
  asRun_selection = new SelectionModel<ReconAsrun>(true, []);
  @ViewChild(MatSort, {static: true}) sort2: MatSort;
  @ViewChild('paginator2') paginator2: MatPaginator;

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,private _location: Location) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.onChannels();
  }

  ngOnInit() {

    this.reconFormGroup = this.formBuilder.group({
      selected_channelCtrl: [this.selected_channel,Validators.required],
      recon_statusCtrl: [this.recon_status,Validators.required],
      start_dateCtrl: [this.start_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
    });

  }

  ngAfterViewInit(){
    
    //File import
    $("form").on("change", ".file-upload-field", function(){ 
      $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
    });
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    } 
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: ReconSpots): string {
      if (!row) {
        return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
      }
      return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.spot_no + 1}`;
    }

    //Apply filter when using search box
    applyFilter(filterValue: string) {
      this.selection.clear();
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }


    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected2() {
      const numSelected = this.asRun_selection.selected.length;
      const numRows = this.asRun_dataSource.data.length;
      return numSelected === numRows;
    } 
  
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle2() {
      this.isAllSelected() ?
          this.asRun_selection.clear() :
          this.asRun_dataSource.data.forEach(row => this.asRun_selection.select(row));
    }
  
    /** The label for the checkbox on the passed row */
    checkboxLabel2(row?: any): string {
      if (!row) {
        return `${this.isAllSelected2() ? 'select' : 'deselect'} all`;
      }
      return `${this.asRun_selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idNo + 1}`;
    }
  
     //Apply filter when using search box
    applyFilter2(filterValue: string) {
      this.asRun_selection.clear();
      this.asRun_dataSource.filter = filterValue.trim().toLowerCase();
    }


    selectPlanned(selectedPlannedID){
      this.selectedPlannedID = selectedPlannedID;

      if(this.selectedAsrunID > 0){

        let data: Reconcile[] = [];
        for(var i=0;i<this.spots_array.length;i++)
        {
          if(this.spots_array[i].spot_no == this.selectedPlannedID){
            data[0] = {advertiser_name: this.spots_array[i].advertiser_name,break_time: this.spots_array[i].break_time,brek_sched_date: this.spots_array[i].brek_sched_date,industry_code: this.spots_array[i].industry_code,selectedAsrunID: this.selectedAsrunID,selectedPlannedID: this.selectedPlannedID}; 
          }
        }

        for(var i=0;i<this.asRun_array.length;i++)
        {
          if(this.asRun_array[i].idNo == this.selectedAsrunID){
            data[1] = {advertiser_name: this.asRun_array[i].advertiser_name,break_time: this.asRun_array[i].start_time,brek_sched_date: this.asRun_array[i].event_date,industry_code: this.asRun_array[i].flighting_code,selectedAsrunID: this.selectedAsrunID,selectedPlannedID: this.selectedPlannedID}; 
          }
        }

        this.openAddDialog(data);
      }
    }

    selectAsrun(selectedAsrunID){
      this.selectedAsrunID = selectedAsrunID;

      if(this.selectedPlannedID > 0){

        let data: Reconcile[] = [];
        for(var i=0;i<this.spots_array.length;i++)
        {
          if(this.spots_array[i].spot_no == this.selectedPlannedID){
            data[0] = {advertiser_name: this.spots_array[i].advertiser_name,break_time: this.spots_array[i].break_time,brek_sched_date: this.spots_array[i].brek_sched_date,industry_code: this.spots_array[i].industry_code,selectedAsrunID: this.selectedAsrunID,selectedPlannedID: this.selectedPlannedID}; 
          }
        }

        for(var i=0;i<this.asRun_array.length;i++)
        {
          if(this.asRun_array[i].idNo == this.selectedAsrunID){
            data[1] = {advertiser_name: this.asRun_array[i].advertiser_name,break_time: this.asRun_array[i].start_time,brek_sched_date: this.asRun_array[i].event_date,industry_code: this.asRun_array[i].flighting_code,selectedAsrunID: this.selectedAsrunID,selectedPlannedID: this.selectedPlannedID}; 
          }
        }

        this.openAddDialog(data);
      }
    }


    openAddDialog(reconData): void {
      const dialogRef = this.dialog.open(ReconDialogComponent, {
        width: '750px',
        data: reconData
      });
     
      dialogRef.afterClosed().subscribe(result => {
    
        if (result) {
     
          this.saveSpotRecon();
          //Save
        }
        else{

          this.selectedPlannedID = 0;
          this.selectedAsrunID = 0;
          //Cancel
        }
      });
    }

    async saveSpotRecon()
    { 
        const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/transmission/reconcile_spot.php`,
        {
          selectedPlannedID: this.selectedPlannedID,
          selectedAsrunID: this.selectedAsrunID,

        },{ responseType: 'json'}).pipe()
        .subscribe(
          (data:any) => {
            
            if(data.message == 'success')
            {
              //this.alertService.success('Spot successfully reconcilled');
              this.selectedPlannedID = 0;
              this.selectedAsrunID = 0;
              this.onReadReconData();
            }
            else
            { 
              this.alertService.error(data.message);
            }
          } 
        ) 
      }

  async exportRecon(){
 
    var spots_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strstart_date = moment(this.start_date.toString()).format("DD/MM/YYYY");
    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('channel',<any>this.selected_channel);
    this.formData.append('date',<any>strstart_date);
    this.formData.append('start_time',<any>strstart_time);
    this.formData.append('end_time',<any>strend_time);
    this.formData.append('recon_status',<any>this.recon_status);
    this.formData.append('spots_date',<any>spots_date);

    this.httpClient.post('https://inxprime.mmsafrica.co.za/api/schedule/transmission/export_reconcile.php',this.formData,{ responseType: 'json'})
    .subscribe((res_data:any) => {
      
      if(res_data.message == 'success')
      {
        this.alertService.success('Recon Report successfully exported');

        var files = res_data.download_files.split(";");
        for(var i=0;i<(files.length-1);i++)
        {
          var strName = files[i];
          this.export(strName).subscribe(data2 => saveAs(data2, 'Reconciliation Export.xlsx'));
        }
 
      }
      else
      {
        this.alertService.error(res_data.message);
      }

    });
  }

  export(name) {
    return this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/schedule_files/download_file.php?file_name=${name}`, 
      {responseType: 'blob'});
  }

  onFileSelected(event)
  {
    this.selectedFile = <FileList>event.target.files;
    this.importAsRun();
  }


  async onReadReconData(){ 

    var spots_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strstart_date = moment(this.start_date.toString()).format("DD/MM/YYYY");
    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/transmission/read_recon_data.php`,
    {
      channel: this.selected_channel,
      recon_status: this.recon_status,
      date: strstart_date,
      spots_date: spots_date,
      start_time: strstart_time,
      end_time: strend_time,
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => { 
         
        this.spots_array = [];
        this.asRun_array = [];
        
        let spots = data.spots;
        for(var i=0;i<spots.length;i++)
        {
            this.spots_array[i] = {actual_duration: spots[i].actual_duration,advertiser_name: spots[i].advertiser_name,break_time: spots[i].break_time,brek_sched_date: spots[i].brek_sched_date,camp_no: spots[i].camp_no,cost: spots[i].cost,day: spots[i].day,discount: spots[i].discount,duration: spots[i].duration,end_time: spots[i].end_time,industry_code: spots[i].industry_code,material: spots[i].material,net: spots[i].net,position: spots[i].position,product_name: spots[i].product_name,prog_no: spots[i].prog_no,sare_no: spots[i].sare_no,spot_no: spots[i].spot_no,status: spots[i].status,stt_time: spots[i].stt_time,tx_time: spots[i].tx_time};
        } 

        this.dataSource = new MatTableDataSource(this.spots_array);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator1;


        let asrun = data.asrun;
        for(var i=0;i<asrun.length;i++)
        {
          var idNo = i +1;
          this.asRun_array[i] = {advertiser_name: asrun[i].advertiser_name,channel_name: asrun[i].channel_name,duration: asrun[i].duration,end_time: asrun[i].end_time,event_date: asrun[i].event_date,event_description: asrun[i].event_description,event_type: asrun[i].event_type,flighting_code: asrun[i].flighting_code,idNo: asrun[i].idNo,layoff_time: asrun[i].layoff_time,material_id: asrun[i].material_id,sec_duration: asrun[i].sec_duration,secondary_event: asrun[i].secondary_event,start_time: asrun[i].start_time,status: asrun[i].status};
        }

        this.asRun_dataSource = new MatTableDataSource(this.asRun_array);
        this.asRun_dataSource.sort = this.sort2;
        this.asRun_dataSource.paginator = this.paginator2;
        
      } 
    ) 
  }


  async importAsRun(){
    
    if(this.selectedFile != null)
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }
    
    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>true);
    this.formData.append('chk_email',<any>false);
    this.formData.append('chk_export',<any>false);
    this.formData.append('start_date',<any>strstart_date);
    this.formData.append('end_date',<any>strstart_date);
    this.formData.append('start_time',<any>strstart_time);
    this.formData.append('end_time',<any>strend_time);
    this.formData.append('chk_importfromFTP',<any>false);
    this.formData.append('ftpHost',<any>"");
    this.formData.append('ftpUsername',<any>"");
    this.formData.append('ftpPassword',<any>"");

    this.httpClient.post('https://inxprime.mmsafrica.co.za/api/schedule/schedule_files/import_asrun.php',this.formData,{ responseType: 'json'})
    .subscribe((res_data:any) => {
      
      if(res_data.message == 'success')
      {
        this.selectedFile = null;
        this.alertService.success('As-Run successfully saved');
        var files = res_data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          var strName = files[i];
          this.export(strName).subscribe(data2 => saveAs(data2, 'As-Run Export.xlsx'));
        }
 
        this.onReadReconData();
      }
      else
      {
        this.alertService.error(res_data.message);
      }

      this.formData = new FormData();

    });
  }

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channel,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};

            this.selected_channel = data[i].sare_no;
        }

        this.onReadReconData();
      } 
    ) 
  }

  back(){
    this._location.back();
  }

}
