import {Injectable} from '@angular/core';
import {User} from '../../models/user';
import {Observable} from 'rxjs';
import {ChannelModel} from '../../models/channel.model';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DemographicService {
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  getDemographics(): Observable<any[]> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + `get_demographic.php`);
  }
}
