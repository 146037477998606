import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TargetAudienceModel} from '../../models/target-audience.model';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TargetAudienceService} from '../../services/target-audience/target-audience.service';

@Component({
  selector: 'app-target-audience-selector',
  templateUrl: './target-audience-selector.component.html',
  styleUrls: ['./target-audience-selector.component.scss']
})
export class TargetAudienceSelectorComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Output() SelectedItem = new EventEmitter<TargetAudienceModel>();
  targets: any[];
  targetCtrl: FormControl = new FormControl('', Validators.required);
  filteredTargets: Observable<TargetAudienceModel[]>;

  constructor(private targetAudienceService: TargetAudienceService) {
  }

  ngOnInit() {
    this.targetAudienceService.getTargetAudiencesForBroadcasterId().subscribe(targets => {
      this.targets = targets || [];
      this.filteredTargets = this.targetCtrl.valueChanges
        .pipe(
          startWith(''),
          map(target => target ? this._filtertargets(target) : this.targets.slice())
        );
    });

    this.formGroup.addControl('target', this.targetCtrl);

  }

  private _filtertargets(value: string): TargetAudienceModel[] {
    const filterValue = value.toLowerCase();

    return this.targets.filter(target => target.targetAudience.toLowerCase().indexOf(filterValue) > -1);
  }

  onSelect(target) {
    this.SelectedItem.emit(target);
  }

}
