import { FormControl } from "@angular/forms";

export class AppBannersClass{
    idNo: number;
    title: string;
    url: string | ArrayBuffer = null;
    position: string;
    status: string;
    upload: FileList = null;
    reader = new FileReader();
}