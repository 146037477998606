import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {BaseRatingsService} from '../../services/base-ratings/base-ratings.service';

@Component({
  selector: 'app-import-base-cpp',
  templateUrl: './import-base-cpp.component.html',
  styleUrls: ['./import-base-cpp.component.scss']
})
export class ImportBaseCppComponent implements OnInit {
  importCPPFormGroup: FormGroup;
  file: any;
  uploadedFile: any;

  constructor(public dialogRef: MatDialogRef<ImportBaseCppComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder,
              private baseRatingsService: BaseRatingsService) {
  }

  ngOnInit() {
    this.importCPPFormGroup = this._formBuilder.group({
      uploadCSV: ['']
    });
  }

  fileChanged(e) {
    this.file = e.target.files[0];
  }

  importBaseCPP() {
    const importData = {
      file: this.file
    };
    this.baseRatingsService.importBaseCPP(importData).subscribe(success => {
      this.dialogRef.close('success');

    }, error => {
      this.dialogRef.close(error);
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
