import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as _XLSX from 'xlsx';
import {ScheduleService} from '../../services/schedule/schedule.service';
import {Router} from '@angular/router';

const xlsx = _XLSX;

@Component({
  selector: 'app-import-schedule',
  templateUrl: './import-schedule.component.html',
  styleUrls: ['./import-schedule.component.scss']
})
export class ImportScheduleComponent implements OnInit {
  importScheduleFormGroup: FormGroup;
  file: any;
  uploadedFile: any;
  month: any;
  channel: any;

  constructor(private _formBuilder: FormBuilder, private scheduleService: ScheduleService, private router: Router) {
  }

  ngOnInit() {
    this.importScheduleFormGroup = this._formBuilder.group({
      uploadCSV: ['']
    });
  }

  fileChanged(e) {
    this.file = e.target.files[0];
    // const fileReader = new FileReader();
    //
    // fileReader.onload = (evt) => {
    //   const data = evt.target.result;
    //
    //   const workbook = xlsx.read(data, {type: 'binary'});
    //
    //   this.uploadedFile = workbook;
    // };
    // fileReader.readAsBinaryString(this.file);
  }

  selectChannel(channel) {
    this.channel = channel;
  }

  getMonth(month) {
    this.month = month;
  }

  importSchedule() {
    const importData = {
      channel: this.channel,
      file: this.file
    };
    this.scheduleService.importSchedule(importData).subscribe(success => {
      this.router.navigate(['/home']);    });
  }

}
