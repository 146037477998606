import {Injectable} from '@angular/core';
import {Router, NavigationStart} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {RateSettingModel} from '../../models/rate-setting-model';

@Injectable({
  providedIn: 'root'
})
export class RateSettingActionsService {
  private subject = new Subject<any>();
  private monthSubject = new Subject<any>();
  private keepAfterNavigationChange = false;
  private rateSettingMonth: any;

  constructor(private router: Router) {
    // clear alert message on route change
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          if (this.rateSettingMonth) {
            this.subject.next(this.rateSettingMonth);
          }
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
          this.monthSubject.next();
        }
      }
    });
  }

  sendRateSettingMonth(rateSettingMonth: any, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.monthSubject.next(rateSettingMonth);
  }

  setRateSettingModel(rateSettingModel) {
    localStorage.setItem('currentRateSettingModel', JSON.stringify(rateSettingModel));
  }

  setRateSettingMonth(rateSettingMonth) {
    localStorage.setItem('currentRateSettingMonth', JSON.stringify(rateSettingMonth));
  }

  getRateSettingMod() {
    const rateSettingModel = localStorage.getItem('currentRateSettingModel');
    return JSON.parse(rateSettingModel);
  }

  getRateSettingMon() {
    const rateSettingMonth = localStorage.getItem('currentRateSettingMonth');
    return JSON.parse(rateSettingMonth);
  }

  sendRateSettingModel(rateSettingMonth: RateSettingModel, keepAfterNavigationChange = false) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next(rateSettingMonth);
    this.rateSettingMonth = rateSettingMonth;
  }

  getRateSettingModel(): Observable<any> {
    return this.subject.asObservable();
  }

  getRateSettingMonth(): Observable<any> {
    return this.monthSubject.asObservable();
  }
}
