import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/internal/operators';
import {AdvertiserService} from '../../services/advertiser/advertiser.service';
import {map, startWith} from 'rxjs/operators';
import {AdvertiserModel} from '../../models/advertiser.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-advertiser-selector',
  templateUrl: './advertiser-selector.component.html',
  styleUrls: ['./advertiser-selector.component.scss']
})
export class AdvertiserSelectorComponent implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() productAdvertiser: AdvertiserModel;
  @Output() SelectedItem = new EventEmitter<AdvertiserModel>();
  advertisers: any[];
  advertiserCtrl: FormControl;
  filteredAdvertisers: Observable<AdvertiserModel[]>;

  constructor(private advertiserService: AdvertiserService) {

  }

  ngOnInit() {
    this.advertisers = [];
    this.advertiserCtrl = new FormControl(this.productAdvertiser.owner, Validators.required);
    this.filteredAdvertisers = this.advertiserCtrl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((advertiser => advertiser ? this._filterAdvertisers(advertiser) : this.advertisers.slice())));
    this.formGroup.addControl('advertiser', this.advertiserCtrl);

  }

  private _filterAdvertisers(value: string): Observable<AdvertiserModel[]> {
    const filterValue = value.toLowerCase();
    if (filterValue.length > 2) {
      return this.advertiserService.getAdvertisers(filterValue).pipe(
        map(advertisers => {
          return advertisers;
        })
      );
    } else {
      return of([]);
    }
  }

  onSelect(advertiser) {
    this.SelectedItem.emit(advertiser);
  }

}
