export class RateSettingModel {
  rateSettingId: number;
  fiscalTarget: number;
  startMonth: string;
  endMonth: string;
  period: any;
  months: any[];
  channels: any[];
  channelTargets: any[];
  nationalTargetTotals: any[];
  regionalTargets: any[];
  sellout: number;
  lessDiscount: number;
  advMinPerHour: number;
  addedValue: number;
  compensation: number;
  volumeDiscount: number;
  year: any;
  channelId: number;
  channel: any;

  constructor() {
    this.months = [];
    this.channels = [];
    this.channelTargets = [];
    this.nationalTargetTotals = [];
    this.regionalTargets = [];
  }
}
