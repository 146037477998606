import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
})
export class ReportingComponent implements OnInit {

  selectedInsight: any;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  redirect(path) {
    this.router.navigate(['home/' + path]);
  }

  selectInsight(insight) {
    this.selectedInsight = insight;
  }


}
