import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProgramService} from '../../services/program/program.service';
import { EpisodeDialogClass } from 'src/app/classes/episode.dialog';

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { PGClass } from 'src/app/classes/parentalguidance';
import { SareClass } from 'src/app/classes/sare';
import { ProgrammeClass } from 'src/app/classes/programme';
import { SeasonClass } from 'src/app/classes/season';
import { EpisodeClass } from 'src/app/classes/episode';
import {map, startWith} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { BaseRatings } from 'src/app/classes/base_ratings';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-episode-dialog',
  templateUrl: './episode-dialog.component.html',
  styleUrls: ['./episode-dialog.component.scss'],
})
export class EpisodeDialogComponent implements OnInit { 

  channel: any;
  pgs: PGClass[] = []; 
 
  //Edit Episode 
  epi_no: number = 0;
  genre: string = '';
  title: string = '';
  parentalGuidance: string = '';
  synopsis: string = '';
  breakPrice: string = '';
  topDemographic: string = '';
  ratting: string = '';

  //Add Episode
  strDate: string = moment().format("YYYY-MM-DD"); 
  strTime: string = '00:00';
  prog_no: number = 0;
  season_no: number = 0;
  episode_no: number = 0;

  channels: SareClass[] = [];
  display_array: ProgrammeClass[] = [];
  season_array: SeasonClass[] = [];
  episode_array: EpisodeClass[] = [];

  myControl = new FormControl(); 
  filteredOptions: Observable<ProgrammeClass[]>; 

  //Audience 
  audenace_array: BaseRatings[] = []; 
  aud_displayedColumns: string[] = ['target','startTime','endTime','rating','rch'];
  aud_dataSource: any;
  aud_selection = new SelectionModel<BaseRatings>(true, [], true);
  @ViewChild(MatPaginator) aud_paginator: MatPaginator;

  //Planning
  mon = false;
  tue = false;
  wed = false;
  thu = false;
  fri = false;
  sat = false;
  sun = false;

  plan_display_array: ProgrammeClass[] = [];
  plan_season_array: SeasonClass[] = [];
  plan_episode_array: EpisodeClass[] = [];

  selected_channels: string = "1";
  start_date: String = moment().format("YYYY-MM-DD");
  end_date: String = moment().format("YYYY-MM-DD");
  start_time: String = "00:00";
  end_time: String = "23:59";
  plan_prog_no: number = 0;
  plan_season_no: number = 0;

  planningSearchFormGroup: FormGroup;
  displayedColumns: string[] = ['select','programme','seasonNo', 'episodeNo'];
  dataSource = new MatTableDataSource(this.episode_array);
  selection = new SelectionModel<EpisodeClass>(true, []);


  constructor(public dialogRef: MatDialogRef<EpisodeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EpisodeDialogClass,
              private httpClient: HttpClient,
              private alertService: AlertService,
              private formBuilder: FormBuilder) {

      this.onChannels();
      this.onReadProgrammes();
  } 
 
  ngOnInit() {  

    this.epi_no = this.data.epi_no;

    if(this.epi_no > 0){

      let arrayData = this.data.epi_desc.split(";");
      this.genre = arrayData[1];
      this.title = arrayData[0];
      this.parentalGuidance = arrayData[2];
      this.synopsis = arrayData[3];
      this.prog_no = Number(arrayData[4]);
      this.season_no = Number(arrayData[5]);
      this.episode_no = Number(arrayData[6]);

      this.breakPrice= 'R'+Number(arrayData[8]).toFixed(2);
      this.topDemographic = arrayData[7];
      this.ratting = Number(arrayData[9]).toFixed(2);

      var start_date = moment(this.data.epi_start.toString()).format("HH:mm:ss");
      this.onReadAudience(start_date);
      this.onReadProgOptions();  
    }
    else{

      let arrayData = this.data.epi_start.split("T");

      this.strDate = arrayData[0];
      this.strTime = arrayData[1];
      this.selected_channels = this.data.epi_desc;

      var start_date = this.strTime+':00';
      this.onReadAudience(start_date);
    }
    
    this.filteredOptions = this.myControl.valueChanges.pipe( 
      startWith(''),
      map(value => this._filter(value)),
    );

      //Planning
      this.planningSearchFormGroup = this.formBuilder.group({
        selected_channelCtrl: [this.selected_channels],
        progNoCtrl: [this.plan_prog_no],
        seasonNoCtrl: [this.plan_season_no],
        start_dateCtrl: [this.start_date,Validators.required],
        end_dateCtrl: [this.end_date,Validators.required],
        start_timeCtrl: [this.start_time,Validators.required],
        end_timeCtrl: [this.end_time,Validators.required],
        monCtrl: [this.mon],
        tueCtrl: [this.tue],
        wedCtrl: [this.wed],
        thuCtrl: [this.thu],
        friCtrl: [this.fri],
        satCtrl: [this.sat],
        sunCtrl: [this.sun], 
      });  
 
  }

  ionViewWillEnter(){

    
  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  } 

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: EpisodeClass): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idNo + 1}`;
  }

  //Apply filter when using search box
  applyFilter(filterValue: string) {
    this.selection.clear();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  private _filter(value: string): ProgrammeClass[] {
    const filterValue = value.toLowerCase();

    return this.display_array.filter(option => option.prog_name.toLowerCase().includes(filterValue));
  }

  async onReadProgOptions(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_programme_options.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data[0].pgs.length;i++)
        {
            this.pgs[i] = {pg_id: data[0].pgs[i].pg_id,code: data[0].pgs[i].code,pg_name: data[0].pgs[i].pg_name};
        } 

        this.onReadSeasons(this.prog_no);
      } 
    ) 
  }

  async onReadAudience(start_date){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/sales/read_base_ratings.php`,
    {
      channel: 1,
      startime: start_date

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.monday.length;i++)
        {
            this.audenace_array[i] = {channel: data.monday[i].channel,daily_rch: data.monday[i].daily_rch,day: data.monday[i].day,demographics: data.monday[i].demographics,end_time: data.monday[i].end_time,idNo: data.monday[i].idNo,rating: data.monday[i].rating,start_time: data.monday[i].start_time};
        } 

        this.aud_dataSource = new MatTableDataSource<BaseRatings>(this.audenace_array);
        this.dataSource.paginator = this.aud_paginator;
      } 
    ) 
  }

  async saveChanges(){ 

    //var strstart_date = moment(this.copy_start_date.toString()).format("YYYY/MM/DD");
    //var strstart_time = this.copy_start_time.toString()+":00";
    
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/edit_schedule_episode.php`,
    {
      start_date: '',
      end_date: '',
      epi_no: this.epi_no,
      genre: this.genre,
      title: this.title,
      parentalGuidance: this.parentalGuidance,
      synopsis: this.synopsis,
      prog_no: this.prog_no,
      season_no: this.season_no,
      episode_no: this.episode_no


    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Episode successfully saved !');
          this.dialogRef.close();
        }
        else
        { 
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  async scheduleEpisode()
  { 

    var strDate = moment(this.strDate.toString()).format("YYYY/MM/DD");

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/schedule/schedule_one_episode.php`,
    {
      channel: this.selected_channels,
      episode_no: this.episode_no,
      date: strDate,
      time: this.strTime+':00',
      employee_no: 0,
      prog_no: this.prog_no,
      seasonNo: this.season_no

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.results == 'success')
        {
          this.alertService.success('Episode successfully saved');
        }
        else
        { 
          this.alertService.error(data.results);
        }
      } 
    ) 
  }

  async onChannels(){ 
          
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channels,
      date: moment().format("YYYY/MM/DD"),
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }
        

      } 
    ) 
  }

  async onReadProgrammes(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_programme.php`,
    {
      channes: this.selected_channels,
      employee_no: 0,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.display_array[i] = {genre: data[i].genre,image: data[i].image,language: data[i].language,length: data[i].length,prod_house: data[i].prod_house,producer: data[i].producer,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),bdcr_no: data[i].bdcr_no,type: data[i].type,category: data[i].category,rcrd_no: data[i].rcrd_no,repeat_name: data[i].repeat_name,sare_no: data[i].sare_no,subgenre: data[i].subgenre,season_data: data[i].season_data,genre_sentech: data[i].genre_sentech,subgenre_sentech: data[i].subgenre_sentech,
              contract_no: data[i].contract_no,
              contract_type: data[i].contract_type,
              distributors: data[i].distributors};
        }

      } 
    ) 
  }

  async onReadPlanningProgrammes(channelNo){ 
    
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_programme.php`,
    {
      channes: channelNo,
      employee_no: 0,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.plan_display_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.plan_display_array[i] = {genre: data[i].genre,image: data[i].image,language: data[i].language,length: data[i].length,prod_house: data[i].prod_house,producer: data[i].producer,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),bdcr_no: data[i].bdcr_no,type: data[i].type,category: data[i].category,rcrd_no: data[i].rcrd_no,repeat_name: data[i].repeat_name,sare_no: data[i].sare_no,subgenre: data[i].subgenre,season_data: data[i].season_data,genre_sentech: data[i].genre_sentech,subgenre_sentech: data[i].subgenre_sentech,
              contract_no: data[i].contract_no,
              contract_type: data[i].contract_type,
              distributors: data[i].distributors};
        }

      } 
    ) 
  }

  async onReadSeasons(progNo)
  {
    this.prog_no = progNo;
    
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_seasons.php`,
    {
        employee_no: 0,
        prog_no: this.prog_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.season_array = [];
        this.episode_array = [];
        if(this.epi_no <= 0 || this.epi_no == null){this.season_no = 0;}

        for(var i=0;i<data.length;i++)
        {
            this.season_array[i] = {actors: data[i].actors,directors: data[i].directors,producer: data[i].producer,cost_per_min: data[i].cost_per_min,end_date: data[i].end_date,episodes: data[i].episodes,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),run_no: data[i].run_no,seas_no: Number(data[i].seas_no),segments: data[i].segments,sst_date: data[i].sst_date,year_of_release: data[i].year_of_release,censorship: '',pg: '',synopsis: '',hashtags: data[i].hashtags};
        }

        this.onReadEpisodes();
      } 
    )
  }

  async onReadPlanningSeasons(progNo)
  {
    this.prog_no = progNo;
    
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_seasons.php`,
    {
        employee_no: 0,
        prog_no: this.prog_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.plan_season_array = [];
        this.plan_episode_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.plan_season_array[i] = {actors: data[i].actors,directors: data[i].directors,producer: data[i].producer,cost_per_min: data[i].cost_per_min,end_date: data[i].end_date,episodes: data[i].episodes,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),run_no: data[i].run_no,seas_no: Number(data[i].seas_no),segments: data[i].segments,sst_date: data[i].sst_date,year_of_release: data[i].year_of_release,censorship: '',pg: '',synopsis: '',hashtags: data[i].hashtags};
        }
      } 
    )
  }

  async onReadEpisodes()
  {
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_episodes.php`,
    {
        employee_no: 0,
        prog_no: this.prog_no,
        seasonNo: this.season_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.episode_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.episode_array[i] = {epi_len: data[i].epi_len,seas_no: Number(data[i].seas_no),title: data[i].title,b: data[i].b,break_price: data[i].break_price,d: data[i].d,day: data[i].day,epi_date: data[i].epi_date,epi_no: Number(data[i].epi_no),exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,n: data[i].n,nl: data[i].nl,omni_epi_no: data[i].omni_epi_no,p: data[i].p,pg: data[i].pg,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),rate_code: data[i].rate_code,replaced: data[i].replaced,run_no: data[i].run_no,s: data[i].s,synopsis: data[i].synopsis,v: data[i].v,material: data[i].material,segments_data: data[i].segments_data};
        }

      } 
    )
  }

  async onReadPlanningEpisodes(prog_no,season_no)
  {
    this.plan_prog_no = prog_no;
    this.plan_season_no = season_no;

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/programme/get_episodes.php`,
    {
        employee_no: 0,
        prog_no: prog_no,
        seasonNo: season_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.plan_episode_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.plan_episode_array[i] = {epi_len: data[i].epi_len,seas_no: Number(data[i].seas_no),title: data[i].title,b: data[i].b,break_price: data[i].break_price,d: data[i].d,day: data[i].day,epi_date: data[i].epi_date,epi_no: Number(data[i].epi_no),exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,n: data[i].n,nl: data[i].nl,omni_epi_no: data[i].omni_epi_no,p: data[i].p,pg: data[i].pg,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),rate_code: data[i].rate_code,replaced: data[i].replaced,run_no: data[i].run_no,s: data[i].s,synopsis: data[i].synopsis,v: data[i].v,material: data[i].material,segments_data: data[i].segments_data};
        }

        this.dataSource = new MatTableDataSource(this.plan_episode_array);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.masterToggle();

      } 
    )
  }

  async schedulePlanning()
  {
    var start_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var end_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/schedule/schedule_planning.php`,
    {
      start_date: start_date,
      end_date: end_date,
      channelNo: this.selected_channels,
      prog_no: this.plan_prog_no,
      season_no: this.plan_season_no,
      start_time: strstart_time,
      selection: this.selection.selected,
      mon: this.mon,
      tue: this.tue,
      wed: this.wed,
      thu: this.thu,
      fri: this.fri,
      sat: this.sat,
      sun: this.sun,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success(data.episodes+' Episodes successfully scheduled');
          this.cancel();
        }
        else
        { 
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  validatePeriod()
  {
    var dif_daye = this.daysDiff(this.start_date,this.end_date) + 1;

    if(dif_daye > 7)
    {
      this.alertService.error('You can copy episodes from one week (up-to 7 days) to the rest of months');
      this.end_date = '';
    }
  }


  daysDiff(d1, d2) {
    let hours = this.hoursDiff(d1, d2);
    let daysDiff = Math.floor( hours / 24 );
    return daysDiff;
  }

  hoursDiff(d1, d2) {
    let minutes = this.minutesDiff(d1, d2);
    let hoursDiff = Math.floor( minutes / 60 );
    return hoursDiff;
  }
  
  minutesDiff(d1, d2) {
    let seconds = this.secondsDiff(d1, d2);
    let minutesDiff = Math.floor( seconds / 60 );
    return minutesDiff;
  }
  
  secondsDiff(d1, d2) {
    let millisecondDiff = d2 - d1;
    let secDiff = Math.floor( ( d2 - d1) / 1000 );
    return secDiff;
  }
  
  cancel(): void {
    this.dialogRef.close();
  }

}
