import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {AudienceGuaranteedModel} from '../../models/audience-guaranteed.model';
import {PackageChannelModel} from '../../models/packageChannel';

@Component({
  selector: 'app-spot-form',
  templateUrl: './spot-form.component.html',
  styleUrls: ['./spot-form.component.scss']
})
export class SpotFormComponent implements OnInit {
  @Input() currentModel: AudienceGuaranteedModel;
  @Input() spotFormGroup: FormGroup;
  @Input() totalPercentage: number;
  @Input() cardTitle: string;
  @Input() cardIcon: string;
  @Output() submitModel = new EventEmitter<AudienceGuaranteedModel>();

  constructor() {
  }

  ngOnInit() {
  }

  submitPercentages() {
    const packageChannelModels = this.spotFormGroup.value.selectedChannels
      .map((v, i) => v ? new PackageChannelModel(this.currentModel.channels[i].channelName,
        this.currentModel.channels[i].channelId, v) : null)
      .filter(v => v !== null);

    this.currentModel.packageChannelModels = packageChannelModels;
    this.currentModel.spots = this.spotFormGroup.controls['spotCtrl'].value;

    this.submitModel.emit(this.currentModel);
  }

}
