import {ChannelModel} from './channel.model';
import {PackageChannelModel} from './packageChannel';
import {AdvertiserModel} from './advertiser.model';
import {AgencyModel} from './agency.model';

export class PackageModel {
  campaignId: number;
  channels: any[];
  packageChannelModels: PackageChannelModel[];
  duration: number;
  startDate: string;
  endDate: string;
  productId: number;
  broadcasterId: number;
  userId: number;
  spots: number;
  totalCost: number;
  netBudget: number;
  weekPercentages: any[];
  advertiser: AdvertiserModel;
  agency: AgencyModel;

  constructor() {
    this.channels = [];
    this.packageChannelModels = [];
    this.weekPercentages = [];
  }
}
