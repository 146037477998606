import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn, Validators} from '@angular/forms';
import {ChannelService} from '../../services/channel/channel.service';
import {PackageModel} from '../../models/packageModel';
import {PackageChannelModel} from '../../models/packageChannel';
import {ProductModel} from '../../models/product.model';
import {PackageService} from '../../services/package/package.service';
import * as _moment from 'moment';
import {Router} from '@angular/router';
import {SpotSummaryService} from '../../services/spot-summary/spot-summary.service';
import {SpotSummaryModel} from '../../models/spot-summary.model';
import {CampaignSpotService} from '../../services/campaignSpot/campaign-spot.service';

const moment = _moment;

// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }

@Component({
  selector: 'app-create-package',
  templateUrl: './create-package.component.html',
  styleUrls: ['./create-package.component.scss'],
})

export class CreatePackageComponent implements OnInit {
  isLinear = false;
  spotFormGroup: FormGroup;
  channelFormGroup: FormGroup;
  channelConfigFormGroups: FormGroup;
  packageDetailsFormGroup: FormGroup;
  channels: any[];
  package: PackageModel;
  totalPercentage: number;
  channelsDaysofWeek: any[];
  shoulderPercentageTotals: any[];
  dayPercentageTotals: any[];
  product: ProductModel;
  duration: number;
  startDate: any;
  endDate: any;
  complete: boolean;
  spotSummaries: any[0];
  displayedColumns: string[] = ['name', 'early', 'earlyShoulderCost', 'prime',
    'primeTimeCost', 'late', 'lateShoulderCost', 'spots', 'cost'];
  weekNumbers = 0;
  weekArray = [];
  allowWeeks = false;
  minWeeklyPercentage = 0;
  maxWeeklyPercentage = 0;

  constructor(private _formBuilder: FormBuilder,
              private channelService: ChannelService,
              private packageService: PackageService,
              private router: Router,
              private spotSummaryService: SpotSummaryService,
              private campaignSpotService: CampaignSpotService) {
  }

  ngOnInit() {
    this.channelService.getChannelsForBroadcaster().subscribe(channels => {
      this.channels = channels;
      const controls = this.channels.map(c => new FormControl(false));
      this.channelFormGroup = this._formBuilder.group({
        channels: new FormArray(controls, this.minSelectedCheckboxes(1))
      });
    });
    
    this.complete = false;
    this.package = this.packageService.createPackage();
    this.channelsDaysofWeek = [];
    this.packageDetailsFormGroup = this._formBuilder.group({
      allowWeeks: [false],
    });
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : {required: true};
    };

    return validator;
  }

  minPercentage(min = 100) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
      // get a list of input values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);

      this.totalPercentage = totalSelected;
      // if the total is not greater than the minimum, return the error message
      return totalSelected >= min ? null : {minTotal: true};
    };

    return validator;
  }

  minShoulderPercentage(min = 100, packageChannelModels) {
    const validator: ValidatorFn = (formArray: FormGroup) => {
      let totalSelected = 0;
      packageChannelModels.forEach(pack => {
        const total = formArray.controls[pack.channelId + '-early'].value + formArray.controls[pack.channelId + '-late'].value
          + formArray.controls[pack.channelId + '-prime'].value;

        this.shoulderPercentageTotals.forEach((percentage => {
          if (percentage.id === pack.channelId) {
            percentage.total = total;
          }
        }));

        totalSelected += total;
      });
      return totalSelected >= (min * packageChannelModels.length) ? null : {minShoulderPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  minDayPercentage(min = 100, packageChannelModels) {
    const validator: ValidatorFn = (formArray: FormGroup) => {
      let totalSelected = 0;
      packageChannelModels.forEach(pack => {
        const total = formArray.controls[pack.channelId + '-mon'].value + formArray.controls[pack.channelId + '-tue'].value
          + formArray.controls[pack.channelId + '-wed'].value + formArray.controls[pack.channelId + '-thu'].value +
          formArray.controls[pack.channelId + '-fri'].value + formArray.controls[pack.channelId + '-sat'].value +
          formArray.controls[pack.channelId + '-sun'].value;

        this.dayPercentageTotals.forEach((percentage => {
          if (percentage.id === pack.channelId) {
            percentage.total = total;
          }
        }));

        totalSelected += total;
      });
      return totalSelected >= (min * packageChannelModels.length) ? null : {minDayPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  minWeekPercentage(min = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0;
      this.weekArray.forEach(week => {
        totalSelected += formGroup.controls['weekPercentage' + week].value;
      });
      this.maxWeeklyPercentage = totalSelected;
      this.minWeeklyPercentage = totalSelected;
      return totalSelected >= min ? null : {minDayPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  maxPercentage(max = 100) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
      // get a list of checkbox values (boolean)
        .map(control => control.value)
        // total up the number of checked checkboxes
        .reduce((prev, next) => next ? prev + next : prev, 0);
      // if the total is  greater than the maximum, return the error message
      return totalSelected <= max ? null : {maxTotal: true};
    };

    return validator;
  }

  maxShoulderPercentage(max = 100, packageChannelModels) {
    const validator: ValidatorFn = (formArray: FormGroup) => {
      let totalSelected = 0;
      packageChannelModels.forEach(pack => {
        const total = formArray.controls[pack.channelId + '-early'].value + formArray.controls[pack.channelId + '-late'].value
          + formArray.controls[pack.channelId + '-prime'].value;

        this.shoulderPercentageTotals.forEach((percentage => {
          if (percentage.id === pack.channelId) {
            percentage.total = total;
          }
        }));

        totalSelected += total;
      });
      return totalSelected <= (max * packageChannelModels.length) ? null : {minShoulderPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  maxWeekPercentage(min = 100) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0;
      this.weekArray.forEach(week => {
        totalSelected += formGroup.controls['weekPercentage' + week].value;
      });
      this.minWeeklyPercentage = totalSelected;
      return totalSelected <= min ? null : {minDayPercentage: true};
      // if the total is not greater than the minimum, return the error message
    };

    return validator;
  }

  createSpotForm() {
    const controls = this.package.channels.map(c => new FormControl('', [
      <any>Validators.required,
      <any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)
    ]));
    this.spotFormGroup = this._formBuilder.group({
      spotCtrl: ['', [
        Validators.required,
        <any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      netBudgetCtrl: ['', [
        Validators.required,
        <any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      selectedChannels: new FormArray(controls, [
        <any>this.minPercentage(),
        <any>this.maxPercentage()])
    });
  }

  createChannelConfigForms(packageChannelModels) {
    this.channelConfigFormGroups = this._formBuilder.group({dummyControl: ['']});
    this.channelsDaysofWeek = [];
    this.shoulderPercentageTotals = [];
    this.dayPercentageTotals = [];
    packageChannelModels.forEach(pckModel => {
      const dayOfWeekArray = [
        {display: 'Monday', name: pckModel.channelId + 'monCheck'},
        {display: 'Tuesday', name: pckModel.channelId + 'tueCheck'},
        {display: 'Wednesday', name: pckModel.channelId + 'wedCheck'},
        {display: 'Thursday', name: pckModel.channelId + 'thuCheck'},
        {display: 'Friday', name: pckModel.channelId + 'friCheck'},
        {display: 'Saturday', name: pckModel.channelId + 'satCheck'},
        {display: 'Sunday', name: pckModel.channelId + 'sunCheck'},
      ];

      this.channelConfigFormGroups.addControl(pckModel.channelId + '-early',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-prime',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-late',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-mon',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-tue',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-wed',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-thu',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-fri',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-sat',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
      this.channelConfigFormGroups.addControl(pckModel.channelId + '-sun',
        new FormControl(0, [<any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));

      this.channelConfigFormGroups.addControl(pckModel.channelId + '-selectedDays', new FormControl('', Validators.required));
      this.channelsDaysofWeek.push(dayOfWeekArray);
      this.shoulderPercentageTotals.push({id: pckModel.channelId, total: 0});
      this.dayPercentageTotals.push({id: pckModel.channelId, total: 0});
    });
    this.channelConfigFormGroups.setValidators([this.minShoulderPercentage(100, packageChannelModels),
      this.maxShoulderPercentage(100, packageChannelModels), this.minDayPercentage(100, packageChannelModels)]);
  }

  submitForm() {
    this.totalPercentage = 0;
    const selectedChannels = this.channelFormGroup.value.channels
      .map((v, i) => v ? this.channelService.getChannelForId(this.channels[i].channelId, this.channels) : null)
      .filter(v => v !== null);

    this.package.channels = selectedChannels;
    this.createSpotForm();
  }

  submitPercentages() {
    const packageChannelModels = this.spotFormGroup.value.selectedChannels
      .map((v, i) => v ? new PackageChannelModel(this.package.channels[i].channelName, this.package.channels[i].channelId, v) : null)
      .filter(v => v !== null);

    this.package.packageChannelModels = packageChannelModels;
    this.package.spots = this.spotFormGroup.controls['spotCtrl'].value;
    this.createChannelConfigForms(packageChannelModels);
  }

  submitScheduleSlots() {
    this.package.packageChannelModels.forEach(pckModel => {
      pckModel.earlyShoulderPercentage = this.channelConfigFormGroups.value[pckModel.channelId + '-early'];
      pckModel.primeTimePercentage = this.channelConfigFormGroups.value[pckModel.channelId + '-prime'];
      pckModel.lateShoulderPercentage = this.channelConfigFormGroups.value[pckModel.channelId + '-late'];

    });
  }

  selectProduct(product) {
    this.product = product;
    this.package.productId = product && product.productId;
  }

  selectDuration(duration) {
    this.package.duration = duration;
  }

  selectStartDate(startDate) {
    this.package.startDate = moment(startDate).format();
    this.getWeeks();
  }

  selectWeeks() {
    this.getWeeks();
  }

  selectEndDate(endDate) {
    this.package.endDate = moment(endDate).format();
    this.getWeeks();
  }

  selectAdvertiser(advertiser) {
    this.package.advertiser = advertiser;
  }

  selectAgency(agency) {
    this.package.agency = agency;
  }

  clearWeeks() {
    if (this.weekNumbers && this.weekNumbers !== 0) {
      this.weekArray = [];
      for (let i = 0; i < this.weekNumbers; i++) {
        this.packageDetailsFormGroup.removeControl('weekPercentage' + i);
      }
    }
  }

  getWeeks() {
    this.clearWeeks();
    if (this.package.startDate && this.package.endDate && this.packageDetailsFormGroup.controls['allowWeeks'].value) {
      this.weekNumbers = moment(this.package.startDate).diff(moment(this.package.endDate), 'week');
      this.weekNumbers = this.weekNumbers * -1;
      for (let i = 0; i < this.weekNumbers; i++) {
        this.packageDetailsFormGroup.addControl('weekPercentage' + i,
          new FormControl(0, [Validators.required, <any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]));
        this.weekArray.push(i);
      }
      this.packageDetailsFormGroup.setValidators([this.minWeekPercentage(), this.maxWeekPercentage()]);
    }

  }

  submitPackage() {
    for (let i = 0; i < this.weekNumbers; i++) {
      this.package.weekPercentages.push({week: i, percentage: this.packageDetailsFormGroup.controls['weekPercentage' + i].value});
    }

    this.packageService.confirmPackageCreation(this.package).subscribe(data => {
      this.package = data;
      this.spotSummaryService.getSpotSummariesForCampaignId(this.package.campaignId)
        .subscribe(summaries => this.spotSummaries = summaries);
    });

    this.package.startDate = moment(this.package.startDate).format('DD/MM/YYYY');
    this.package.endDate = moment(this.package.endDate).format('DD/MM/YYYY');
    this.complete = true;
  }

  back() {
  }

  done() {
    this.campaignSpotService.refreshSpots().subscribe(spots => {
        if (spots) {
          this.router.navigate(['/home/spotView']);
        }
      },
      error => {
        this.router.navigate(['/home']);
      });

    // this.router.navigate(['/', 'home']);
  }
}
