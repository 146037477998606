import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CampaignModel} from '../../models/campaign.model';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as _moment from 'moment';
import {AdvertiserModel} from '../../models/advertiser.model';
import {AgencyModel} from '../../models/agency.model';

const moment = _moment;


@Component({
  selector: 'app-edit-campaign-dialog',
  templateUrl: './edit-campaign-dialog.component.html',
  styleUrls: ['./edit-campaign-dialog.component.scss']
})
export class EditCampaignDialogComponent implements OnInit {
  editCampaignFormGroup: FormGroup;
  product: any;

  constructor(public dialogRef: MatDialogRef<EditCampaignDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CampaignModel, private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.editCampaignFormGroup = this._formBuilder.group({
      dummy: ['']
    });
    this.product = null;
  }

  selectStartDate(startDate) {
    this.data.start_date = moment(startDate).toDate();
  }

  selectEndDate(endDate) {
    this.data.end_date = moment(endDate).toDate();
  }

  selectProduct(product) {
    this.product = product;
    this.data.advertiserModel = new AdvertiserModel(product.owner, product.ownerId);
    this.data.agencyModel = new AgencyModel(product.payer, product.payer_Id);
    this.data.productId = product && product.productId;
  }

  selectAdvertiser(advertiser) {
    this.data.advertiserModel = advertiser;
  }

  selectAgency(agency) {
    this.data.agencyModel = agency;
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
