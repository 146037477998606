import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inventory-dashboard',
  templateUrl: './inventory-dashboard.component.html',
  styleUrls: ['./inventory-dashboard.component.scss'],
})
export class InventoryDashboardComponent implements OnInit {

  selectedInsight: any;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  redirect(path) {
    this.router.navigate(['home/' + path]);
  }

  selectInsight(insight) {
    this.selectedInsight = insight;
  }

}
