import { FormControl } from "@angular/forms";

export class AppChannelsClass{
    idNo: number;
    name: string;
    logo: string | ArrayBuffer = null;
    url: string;
    status: string;
    upload: FileList = null;
    reader = new FileReader();
}