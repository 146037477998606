import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {CampaignSpotModel} from '../../models/campaign-spot.model';
import {SelectionModel} from '@angular/cdk/collections';

@Component({
  selector: 'app-spot-table',
  templateUrl: './spot-table.component.html',
  styleUrls: ['./spot-table.component.scss']
})
export class SpotTableComponent implements OnInit, OnChanges {
  @Input() spots: any[];
  @Output() submitSelectedSpots = new EventEmitter<CampaignSpotModel[]>();
  displayedColumns: string[] = ['select', 'channelName', 'breakScheduledDate', 'startTime', 'endTime', 'rate',
    'duration', 'u1', 'industryCode', 'programName'];
  dataSource = new MatTableDataSource<CampaignSpotModel>(this.spots);
  selection = new SelectionModel<CampaignSpotModel>(true, [], true);

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const _spots: SimpleChange = changes.spots;
    this.dataSource = new MatTableDataSource<CampaignSpotModel>(_spots.currentValue);
    if (this.table) {
      this.table.renderRows();
    }

    // this.dataSource.renderRows()
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    
    if (this.selection.selected.length !== 0) {
      this.submitSelectedSpots.emit(this.selection.selected);
    } else {
      this.submitSelectedSpots.emit([]);
    }
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
  }


}
