import {Component, OnInit} from '@angular/core';
import {ChannelService} from '../../services/channel/channel.service';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {ImportBaseCppComponent} from '../import-base-cpp/import-base-cpp.component';
import {AlertService} from '../../services/alert/alert.service';

@Component({
  selector: 'app-revenue-management-dashboard',
  templateUrl: './revenue-management-dashboard.component.html',
  styleUrls: ['./revenue-management-dashboard.component.scss']
})
export class RevenueManagementDashboardComponent implements OnInit {
  selectedInsight: any;
  channels: any[];
  rateSettings = [];
  selectedChannel: any;
  revManFormGroup: FormGroup;

  constructor(private channelService: ChannelService,
              private rateSettingService: RateSettingService,
              private _formBuilder: FormBuilder,
              private router: Router, public dialog: MatDialog, private alertService: AlertService) {
  }

  ngOnInit() {
    this.selectedChannel = null;
    this.channelService.getChannelsForBroadcaster().subscribe(channels => this.channels = channels);
    this.rateSettingService.getStoredRateSettings().subscribe(data => {
      this.rateSettings = data;
    });
    this.revManFormGroup = this._formBuilder.group({dummy: ['']});
  }

  selectInsight(insight) {
    this.selectedInsight = insight;
  }

  redirect(path) {
    this.router.navigate(['home/' + path]);
  }

  selectChannel(channel) {
    this.selectedChannel = channel;
  }

  openBaseCPPDialog() {
    const dialogRef = this.dialog.open(ImportBaseCppComponent, {
      width: '600px',
      data: null
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result === 'success') {
          this.alertService.success('Base CPP successfully imported.', true);

        } else {
          this.alertService.error('Error importing base CPP. Please Try again.', true);
        }
      }
    });
  }


}
