export class MaterialClass{
    idNo: number;
    status: string;
    MATERIAL_ID: string;
    SEGMENTS: number;
    MATERIAL_EVENTS: string;
    MATERIAL_TITLE: string;
    DESCRIPTION: string;
    ABBREVIATED_TITLE: string;
    AGENCY_COPY_NUMBER: string;
    SOM: number;
    DURATION: number;
    NOMINAL_DURATION: string;
    ENTRY_DATE: string;
    ENTRY_OPERATOR: string;
    FRAME_RATE: number;
    ASPECT_RATIO: number;
    AUDIO1: string;
    AUDIO2: string;
    AUDIO3: string;
    AUDIO4: string;
    EMBARGO_DATE: string;
    EXPIRE_DATE: string;
    RELEASED: string;
    GROUP_ID: string;
    MATERIAL_TYPE: string;
    _NOT_ASSIGNED: string;
    _NOT_ASSIGNED_TIME: string;
    CHANGE_TIME: string;
} 