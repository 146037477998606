import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EditCampaignSpotDialogComponent} from '../edit-campaign-spot-dialog/edit-campaign-spot-dialog.component';

@Component({
  selector: 'app-edit-inventory-dialog',
  templateUrl: './edit-inventory-dialog.component.html',
  styleUrls: ['./edit-inventory-dialog.component.scss']
})
export class EditInventoryDialogComponent implements OnInit {
  inventoryFormGroup: FormGroup;

  constructor(public dialogRef: MatDialogRef<EditCampaignSpotDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.data.addedValue = this.data.addedValue * 100 ;
    this.data.sellout = this.data.sellout * 100;
    this.data.advMinPerHour = this.data.advMinPerHour;
    this.data.lessDiscount = this.data.lessDiscount * 100;
    this.data.compensation = this.data.compensation * 100;
    this.data.volumeDiscount = this.data.volumeDiscount * 100;
    this.inventoryFormGroup = this._formBuilder.group({
      sellout: [0, <any>Validators.required],
      lessDiscount: [0, <any>Validators.required],
      advMinPerHour: [0, <any>Validators.required],
      addedValue: [0, <any>Validators.required],
      compensation: [0, <any>Validators.required],
      volumeDiscount: [0, <any>Validators.required]
    });
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
