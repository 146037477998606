import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

import {Observable} from 'rxjs';
import {FlightCodeModel} from '../../models/flight-code.model';
import {FlightingCodeService} from '../../services/flighting-code/flighting-code.service';

@Component({
  selector: 'app-flighting-code-auto-complete',
  templateUrl: './flighting-code-auto-complete.component.html',
  styleUrls: ['./flighting-code-auto-complete.component.scss']
})
export class FlightingCodeAutoCompleteComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() productId: number;
  @Output() SelectedItem = new EventEmitter<FlightCodeModel>();
  flightingCodes: any[];
  flightingCodeCtrl: FormControl = new FormControl('', Validators.required);
  filteredFlightingCodes: Observable<FlightCodeModel[]>;

  constructor(private flightingCodeService: FlightingCodeService) {
  }

  ngOnInit() {
    this.flightingCodeService.getFlightingCodesForProductId(this.productId).subscribe(flightingCodes => {
      this.flightingCodes = flightingCodes || [];
      this.filteredFlightingCodes = this.flightingCodeCtrl.valueChanges
        .pipe(
          startWith(''),
          map(flightingCode => flightingCode ? this._filterflightingCodes(flightingCode) : this.flightingCodes.slice())
        );
    });

    this.formGroup.addControl('flightingCode', this.flightingCodeCtrl);

  }

  private _filterflightingCodes(value: string): FlightCodeModel[] {
    const filterValue = value.toLowerCase();

    return this.flightingCodes.filter(flightingCode => flightingCode.flightingCodeName.toLowerCase().indexOf(filterValue) > -1);
  }

  onSelect(flightingCode) {
    this.SelectedItem.emit(flightingCode);
  }


}
