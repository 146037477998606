import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-e-learning-dashboard',
  templateUrl: './e-learning-dashboard.component.html',
  styleUrls: ['./e-learning-dashboard.component.scss'],
})
export class ELearningDashboardComponent implements OnInit {

  selectedInsight: any;

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  redirect(path) {
    this.router.navigate(['home/' + path]);
  }

  selectInsight(insight) {
    this.selectedInsight = insight;
  }

}
