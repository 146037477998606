import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SponsorshipService} from '../../services/sponsorship/sponsorship.service';
import {SponsorshipModel} from '../../models/sponsorship.model';
import * as _moment from 'moment';
import {ProgramService} from '../../services/program/program.service';
import {RateCardService} from '../../services/rate-card/rate-card.service';

const moment = _moment;

@Component({
  selector: 'app-create-sponsorship',
  templateUrl: './create-sponsorship.component.html',
  styleUrls: ['./create-sponsorship.component.scss']
})
export class CreateSponsorshipComponent implements OnInit {
  sponsorshipDetailsFormGroup: FormGroup;
  sponsorshipElementsFormGroup: FormGroup;
  sponsorship: SponsorshipModel;
  rateCards: any[];
  product: any;
  program: any;
  done = false;

  constructor(private _formBuilder: FormBuilder,
              private sponsorShipService: SponsorshipService,
              private rateCardService: RateCardService) {
  }

  ngOnInit() {
    this.sponsorship = this.sponsorShipService.createSponsorship();
    this.sponsorshipDetailsFormGroup = this._formBuilder.group({
      numberOfBroadcasts: [0, [
        Validators.required,
        <any> Validators.pattern(/^-?(0|[1-9]\d*)?$/)]]
    });
    this.sponsorshipElementsFormGroup = this._formBuilder.group({
      elementNameCtrl: ['', [<any>Validators.required]]
    });
    this.rateCardService.getRateCardsForBroadcasterId().subscribe(rateCards => this.rateCards = rateCards);
  }

  submitSponsorship(sponsorship) {
    this.sponsorship = sponsorship;

  }

  selectProgram(program) {
    this.program = program;
  }

  selectProduct(product) {
    this.product = product;
  }

  submitElements(sponsorship) {
    this.sponsorShipService.confirmSponsorshipCreation(sponsorship).subscribe(sponsorshipData => {
      this.done = true;
      this.sponsorship = sponsorshipData;

    });
  }
}
