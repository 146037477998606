import { Component, OnInit, ViewChild } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { FormGroup, FormBuilder, Validators, FormControl,NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem,TreeNode} from 'primeng/api';
import { saveAs } from 'file-saver';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import { SeasonClass } from '../../classes/season';
import { EpisodeClass } from '../../classes/episode';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {Location} from '@angular/common'; 
 
import {animate, state, style, transition, trigger} from '@angular/animations';


//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
 
//To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import {AlertService} from '../../services/alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from "jquery";

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { MaterialClass } from 'src/app/classes/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ProgrammeTV } from 'src/app/classes/abcd.programmetv';
import { MaterialColumns } from 'src/app/classes/abcd.columns';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-material-management',
  templateUrl: './material-management.component.html',
  styleUrls: ['./material-management.component.scss'],
  styles: [`
  .filter-container {
      text-align: center;
  }

  .car-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d9dad9;
  }

  .car-details > div {
      display: flex;
      align-items: center;
  }

  .car-detail {
      padding: 0 1em 1em 1em;
      border-bottom: 1px solid #d9dad9;
  }
`],
animations: [
  trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
],
})
export class MaterialManagementComponent implements OnInit {

  selectedFile: FileList  = null;
  formData = new FormData();
  employee_no: number = 0;
  selected_channel; 

  searchFormGroup: FormGroup;
  channels: SareClass[] = [];
  blnselectAll: boolean = true;
  material_Sidebar: boolean = false;
  material_infor_Sidebar: boolean = false;
  material_status: string = 'All';
  material_type: string = 'All';
 
  programmeMaterial_array: MaterialClass[] = [];
  material_array: MaterialClass[] = [];
  available_array: MaterialClass[] = [];
  programme_array: ProgrammeClass[] = [];

  reconFormGroup: FormGroup;
  displayedColumns: string[] = ['prog_name', 'length', 'language', 'genre', 'subgenre', 'category', 'prod_house', 'prod_partner'];
  dataSource = new MatTableDataSource(this.programme_array);
  dataEpisodeSource = new MatTableDataSource(this.programme_array);
  selection = new SelectionModel<ScheduleClass>(true, []);
  expandedElement: ProgrammeClass | null;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  material_displayedColumns: string[] = ['select','MATERIAL_ID', 'MATERIAL_TITLE','DURATION','AGENCY_COPY_NUMBER', 'ENTRY_DATE','ACTION'];
  material_dataSource = new MatTableDataSource(this.material_array);
  material_selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, {static: true}) sort2: MatSort;
  @ViewChild('paginator2') paginator2: MatPaginator;

  available_displayedColumns: string[] = ['MATERIAL_ID', 'MATERIAL_TITLE','DURATION','ACTION'];
  available_dataSource = new MatTableDataSource(this.available_array);
  available_selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, {static: true}) sort3: MatSort;
  @ViewChild('paginator3') paginator3: MatPaginator;

  //Assign
  selected_progNo: number = 0;
  selected_seasNo: number = 0;
  selected_epiNo: number = 0;
  selected_segm_no: number = 0;
  material: MaterialClass = new MaterialClass();


  //ABCD
  abcdFormGroup: FormGroup;
  database: string = "Programme TV";
  broadcastDate = false;
  material_columns_Sidebar: boolean = false;
  from_date: string = moment().format("YYYY-MM-DD");
  to_date: string = moment().format("YYYY-MM-DD");
  status = {label: 'All', value: 'All'};
  statusOptions: any[] = [{label: 'All', value: 'All'},{label: 'Available', value: 'Available'}, {label: 'Missing', value: 'Missing'}, {label: 'Damaged', value: 'Damaged'}, {label: 'Lost', value: 'Lost'}];

  //abcd_programme_tv_array: ProgrammeTV[] = [];
  abcd_programme_tv_array: any[] = [];
  temp_columns: any[] = [];
  abcd_displayedColumns: string[] = ['select','tape_number', 'tape_format','status','location_of_tape','series_title','broadcast_date','programme_category','date_received','first_control','ACTION'];

  columnsToDisplay: MaterialColumns[] = []; 
  column_name: any = null;

  abcd_programme_tv_dataSource = new MatTableDataSource(this.abcd_programme_tv_array);
  abcd_programme_tv_selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, {static: true}) sort4: MatSort;
  @ViewChild('paginator4') paginator4: MatPaginator; 


  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,private _location: Location) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));

    if(localStorage.getItem('abcd_database')){
      this.database = localStorage.getItem('abcd_database');
    }

    //this.onChannels();
    //this.onReadProgrammeMaterials();
    this.onReadABCDMaterials();
  }


  ngOnInit() {

    this.searchFormGroup = this.formBuilder.group({
      selected_channelCtrl: [this.selected_channel,Validators.required],
      material_statusCtrl: [this.material_status,Validators.required],
      material_typeCtrl: [this.material_type,Validators.required],
    });
    
    this.abcdFormGroup = this.formBuilder.group({
      databaseCtrl: [this.database,Validators.required],
      statusCtrl: [this.status],
      broadcastDateCtrl: [this.broadcastDate],
      from_dateCtrl: [this.from_date],
      to_dateCtrl: [this.to_date],
    });
  }

  ngAfterViewInit(){
    
    //File import
    $("form").on("change", ".file-upload-field", function(){ 
      $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
    });
  }

  isAllSelected() {
    const numSelected = this.material_selection.selected.length;
    const numRows = this.material_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.material_selection.clear() :
      this.material_dataSource.data.forEach(row => this.material_selection.select(row));
  }

  abcd_programme_tv_isAllSelected() {
    const numSelected = this.abcd_programme_tv_selection.selected.length;
    const numRows = this.abcd_programme_tv_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  abcd_programme_tv_masterToggle() {
    this.abcd_programme_tv_isAllSelected() ?
      this.abcd_programme_tv_selection.clear() :
      this.abcd_programme_tv_dataSource.data.forEach(row => this.abcd_programme_tv_selection.select(row));
  }

  //Apply filter when using search box
  abcd_programme_tv_applyFilter(filterValue: string) {
    this.abcd_programme_tv_selection.clear();
    this.abcd_programme_tv_dataSource.filter = filterValue.trim().toLowerCase();
  }

  //Apply filter when using search box
  applyFilter(filterValue: string) {
    this.selection.clear();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

    //Apply filter when using search box
  applyFilter2(filterValue: string) {
    this.material_selection.clear();
    this.material_dataSource.filter = filterValue.trim().toLowerCase();
  }

  //Apply filter when using search box
  applyFilter3(filterValue: string) {
    this.available_selection.clear();
    this.available_dataSource.filter = filterValue.trim().toLowerCase();
  }

  removeMaterial(progNo,seasNo,epiNo,segm_no,materialId): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: 'Are you sure you want to remove material '+materialId+' ?'
    });
    
    dialogRef.afterClosed().subscribe(result => {
        if (result) {

            this.remMaterial(progNo,seasNo,epiNo,segm_no,materialId);
        }
    });
  }

  remMaterial(progNo,seasNo,epiNo,segm_no,materialId) {
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/material/remove_material.php`,
        {
          progNo: progNo,
          seasNo: seasNo,
          epiNo: epiNo,
          segm_no: segm_no,
          materialId: materialId
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                if (data.message === 'success') {
                    this.alertService.success('Material successfully removed');

                    for(var i=0;i<this.programmeMaterial_array.length;i++)
                    {
                        if(this.programmeMaterial_array[i].MATERIAL_ID == materialId){
                          this.available_array.push(this.programmeMaterial_array[i]);

                          this.available_dataSource = new MatTableDataSource(this.available_array);
                          this.available_dataSource.sort = this.sort3;
                          this.available_dataSource.paginator = this.paginator3;
                          

                          //Updating table after removing (USELESS CODE- JUST FOR VIEW)
                          for(var x=0;x<this.programme_array.length;x++)
                          {
                            if(this.programme_array[x].prog_no == progNo){
                              for(var x2=0;x2<this.programme_array[x].season_data.length;x2++)
                              {
                                if(this.programme_array[x].season_data[x2].seas_no == seasNo){
                                  for(var x3=0;x3<this.programme_array[x].season_data[x2].episode_data.length;x3++)
                                  {
                                    if(this.programme_array[x].season_data[x2].episode_data[x3].episode_no == epiNo){
                                      this.programme_array[x].season_data[x2].episode_data[x3].material = '';
                                      for(var x4=0;x4<this.programme_array[x].season_data[x2].episode_data[x3].segments_data.length;x4++)
                                      {
                                        if(this.programme_array[x].season_data[x2].episode_data[x3].segments_data[x4].segm_no == segm_no){
                                          this.programme_array[x].season_data[x2].episode_data[x3].segments_data[x4].material = '';
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          //END--OF--UPDATING TABLE

                        }
                    }

                } else {
                    this.alertService.error(data.message);
                }
            }
        );
 }
 
 viewMaterial(materialElement: MaterialClass) {

  this.material = materialElement;
  this.material_infor_Sidebar = true;
 }

 viewMaterialData(materialId) {

  for(var i=0;i<this.programmeMaterial_array.length;i++){
      if(this.programmeMaterial_array[i].MATERIAL_ID == materialId){
        this.material = this.programmeMaterial_array[i];
      }
  }

  this.material_infor_Sidebar = true;
 }

 assignMaterial(progNo,seasNo,epiNo,segm_no) {

  this.selected_progNo = progNo;
  this.selected_seasNo = seasNo;
  this.selected_epiNo = epiNo;
  this.selected_segm_no = segm_no;
  this.material_Sidebar = true;
 }

 addMaterial(materialId,object) {

      const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/material/add_material.php`,
      {
        progNo: this.selected_progNo,
        seasNo: this.selected_seasNo,
        epiNo: this.selected_epiNo,
        segm_no: this.selected_segm_no,
        materialId: materialId

      }, {responseType: 'json'}).pipe()
      .subscribe(
          (data: any) => {

              if (data.message === 'success') {
                  this.alertService.success('Material successfully saved');
                  this.material_Sidebar = false;
                  
                  const index = this.available_array.indexOf(object, 0);
                  if (index > -1) {
                    this.available_array.splice(index, 1);
                  }

                  //Updating table after adding (USELESS CODE- JUST FOR VIEW)
                  for(var x=0;x<this.programme_array.length;x++)
                  {
                    if(this.programme_array[x].prog_no == this.selected_progNo){
                      for(var x2=0;x2<this.programme_array[x].season_data.length;x2++)
                      {
                        if(this.programme_array[x].season_data[x2].seas_no == this.selected_seasNo){
                          for(var x3=0;x3<this.programme_array[x].season_data[x2].episode_data.length;x3++)
                          {
                            if(this.programme_array[x].season_data[x2].episode_data[x3].episode_no == this.selected_epiNo){
                              this.programme_array[x].season_data[x2].episode_data[x3].material = 'Yes';
                              for(var x4=0;x4<this.programme_array[x].season_data[x2].episode_data[x3].segments_data.length;x4++)
                              {
                                if(this.programme_array[x].season_data[x2].episode_data[x3].segments_data[x4].segm_no == this.selected_segm_no){
                                  this.programme_array[x].season_data[x2].episode_data[x3].segments_data[x4].material = materialId;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  //END--OF--UPDATING TABLE
                  this.available_dataSource = new MatTableDataSource(this.available_array);
                  this.available_dataSource.sort = this.sort3;
                  this.available_dataSource.paginator = this.paginator3;
                  
              } else {
                  this.alertService.error(data.message);
              }
          }
      );
  }

  columns(){
    this.material_columns_Sidebar = true;
  }

  changeColumns(){

    this.abcd_displayedColumns = [];
    this.abcd_displayedColumns.push('select');

    for(var i=0;i<this.columnsToDisplay.length;i++)
    {
      if(this.columnsToDisplay[i].show){
        this.abcd_displayedColumns.push(this.columnsToDisplay[i].field);
        this.temp_columns.push(this.columnsToDisplay[i].field);
      }
      else
      {
        for(var v=0;v<this.temp_columns.length;v++)
        {
          if(this.temp_columns[v] == this.columnsToDisplay[i].field){
           
            let index = this.temp_columns.indexOf(this.temp_columns[v], 0);
            if (index > -1) {
              this.temp_columns.splice(index, 1);
            }
          }
        }
      }
    }

    localStorage.setItem('temp_columns', JSON.stringify(this.temp_columns));
    this.abcd_displayedColumns.push('ACTION');
  }

  viewABCDMaterial(materialID){

    localStorage.setItem('materialID', JSON.stringify(materialID));
    localStorage.setItem('abcd_database', this.database);
    this.router.navigate(['/home/view-abcd-material']);
  } 

  newMaterial(){
    
    localStorage.setItem('abcd_database', this.database);
    this.router.navigate(['/home/new-abcd-material']);
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected materials?'
    }); 

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.abcd_programme_tv_selection.selected.forEach(material => {          
          this.deleteABCDMaterial(material.idNo);
        });
        
        this.abcd_programme_tv_selection.clear(); 
        this.alertService.success('Material successfully deleted')
      }
    });
  }

  async deleteABCDMaterial(materialID)
  {
    this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/material/delete_abcd_material.php?materialID=${materialID}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadABCDMaterials();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadABCDMaterials(){ 

    if(localStorage.getItem('temp_columns')){
      this.temp_columns = JSON.parse(localStorage.getItem('temp_columns') || '[]');
    }

    var strstart_date = moment(this.from_date.toString()).format("YYYY-MM-DD");
    var strend_date = moment(this.to_date.toString()).format("YYYY-MM-DD");
    localStorage.setItem('abcd_database', this.database);
 
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/material/read_abcd_materials.php`,
    {
      employee_no: this.employee_no,
      database: this.database,
      status: this.status,
      broadcastDate: this.broadcastDate,
      from_date: strstart_date,
      to_date: strend_date,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.abcd_programme_tv_array = [];
        this.columnsToDisplay = [];

        let index = 0;
        let added_cols = 0;
        for(var i=1;i<data.column.length;i++){

          let show = false;
          if(i < 10 && this.temp_columns.length < 1){
            show = true;
          }

          for(var v=0;v<this.temp_columns.length;v++){
            if(this.temp_columns[v] == data.column[i].COLUMN_NAME){
              show = true;
            } 
          }

          if(show){added_cols = added_cols + 1;}
          this.columnsToDisplay[index] = {field: data.column[i].COLUMN_NAME,show: show};
          index = index + 1;
        }


        for(var i=0;i<data.material.length;i++)
        {
          const object: Record<string, any> = {};
          for(var x=0;x<data.column.length;x++){

            let column_name = data.column[x].COLUMN_NAME;
            object[column_name] = data.material[i][column_name];
          }

          this.abcd_programme_tv_array[i] = object;
        }

        if(added_cols <= 3 && added_cols >= 1){
          this.material_columns_Sidebar = true;
        }
        else
        if(added_cols == 0){

          for(var i=1;i<this.columnsToDisplay.length;i++){

            if(i < 10){
              this.columnsToDisplay[i].show = true;
            }
          }
        }

        this.changeColumns();
        this.abcd_programme_tv_dataSource = new MatTableDataSource(this.abcd_programme_tv_array);
        this.abcd_programme_tv_dataSource.sort = this.sort4;
        this.abcd_programme_tv_dataSource.paginator = this.paginator4;
      } 
    ) 
  }

  async onReadProgrammeMaterials(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/material/read_materials.php`,
    {
      material_type: 'X',
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.programmeMaterial_array = [];
        this.available_array = [];
        let index = 0;

        for(var i=0;i<data.length;i++)
        {
            this.programmeMaterial_array[i] = {idNo: data[i].idNo,_NOT_ASSIGNED: data[i]._NOT_ASSIGNED,_NOT_ASSIGNED_TIME: data[i]._NOT_ASSIGNED_TIME,ABBREVIATED_TITLE: data[i].ABBREVIATED_TITLE,AGENCY_COPY_NUMBER: data[i].AGENCY_COPY_NUMBER,ASPECT_RATIO: data[i].ASPECT_RATIO,AUDIO1: data[i].AUDIO1,AUDIO2: data[i].AUDIO2,AUDIO3: data[i].AUDIO3,AUDIO4: data[i].AUDIO4,CHANGE_TIME: data[i].CHANGE_TIME,DESCRIPTION: data[i].DESCRIPTION,DURATION: data[i].DURATION,EMBARGO_DATE: data[i].EMBARGO_DATE,ENTRY_DATE: data[i].ENTRY_DATE,ENTRY_OPERATOR: data[i].ENTRY_OPERATOR,EXPIRE_DATE: data[i].EXPIRE_DATE,FRAME_RATE: data[i].FRAME_RATE,GROUP_ID: data[i].GROUP_ID,MATERIAL_EVENTS: data[i].MATERIAL_EVENTS,MATERIAL_ID: data[i].MATERIAL_ID,MATERIAL_TITLE: data[i].MATERIAL_TITLE,MATERIAL_TYPE: data[i].MATERIAL_TYPE,NOMINAL_DURATION: data[i].NOMINAL_DURATION,RELEASED: data[i].RELEASED,SEGMENTS: data[i].SEGMENTS,SOM: data[i].SOM,status: data[i].status};

            if(data[i].status == '' || data[i].status == null){
              this.available_array[index] = this.programmeMaterial_array[i];
              index = index + 1;
            }
        }

        this.available_dataSource = new MatTableDataSource(this.available_array);
        this.available_dataSource.sort = this.sort3;
        this.available_dataSource.paginator = this.paginator3;
      } 
    ) 
  }

  async onReadMaterials(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/material/read_materials.php`,
    {
      material_type: this.material_type,
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.material_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.material_array[i] = {idNo: data[i].idNo,_NOT_ASSIGNED: data[i]._NOT_ASSIGNED,_NOT_ASSIGNED_TIME: data[i]._NOT_ASSIGNED_TIME,ABBREVIATED_TITLE: data[i].ABBREVIATED_TITLE,AGENCY_COPY_NUMBER: data[i].AGENCY_COPY_NUMBER,ASPECT_RATIO: data[i].ASPECT_RATIO,AUDIO1: data[i].AUDIO1,AUDIO2: data[i].AUDIO2,AUDIO3: data[i].AUDIO3,AUDIO4: data[i].AUDIO4,CHANGE_TIME: data[i].CHANGE_TIME,DESCRIPTION: data[i].DESCRIPTION,DURATION: data[i].DURATION,EMBARGO_DATE: data[i].EMBARGO_DATE,ENTRY_DATE: data[i].ENTRY_DATE,ENTRY_OPERATOR: data[i].ENTRY_OPERATOR,EXPIRE_DATE: data[i].EXPIRE_DATE,FRAME_RATE: data[i].FRAME_RATE,GROUP_ID: data[i].GROUP_ID,MATERIAL_EVENTS: data[i].MATERIAL_EVENTS,MATERIAL_ID: data[i].MATERIAL_ID,MATERIAL_TITLE: data[i].MATERIAL_TITLE,MATERIAL_TYPE: data[i].MATERIAL_TYPE,NOMINAL_DURATION: data[i].NOMINAL_DURATION,RELEASED: data[i].RELEASED,SEGMENTS: data[i].SEGMENTS,SOM: data[i].SOM,status: data[i].status};
        }
        
        this.material_dataSource = new MatTableDataSource(this.material_array);
        this.material_dataSource.sort = this.sort2;
        this.material_dataSource.paginator = this.paginator2;
      } 
    ) 
  }

  onClickRow(expandedElement, element) {
  this.dataEpisodeSource.data = element.season_data;
  this.expandedElement = this.expandedElement === element ? null : element
  }

  async onReadProgrammes(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/material/get_programme.php`,
    {
      channes: this.selected_channel,
      employee_no: this.employee_no,
      material_status: this.material_status

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.programme_array = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.programme_array[i] = {genre: data[i].genre,image: data[i].image,language: data[i].language,length: data[i].length,prod_house: data[i].prod_house,producer: data[i].producer,prog_name: data[i].prog_name,prog_no: data[i].prog_no,bdcr_no: data[i].bdcr_no,type: data[i].type,category: data[i].category,rcrd_no: data[i].rcrd_no,repeat_name: data[i].repeat_name,sare_no: data[i].sare_no,subgenre: data[i].subgenre,season_data: data[i].season_data,genre_sentech: data[i].genre_sentech,subgenre_sentech: data[i].subgenre_sentech,
              contract_no: data[i].contract_no,
              contract_type: data[i].contract_type,
              distributors: data[i].distributors};
        }

        this.dataSource = new MatTableDataSource(this.programme_array);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } 
    ) 
  }


  async exportUnassigned(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/material/export_unassigned.php`,
    {
      channes: this.selected_channel,
      employee_no: this.employee_no,
      material_status: 'Not-Assigned'

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Un-Assigned Programmes successfully exported');

          var files = data.download_files.split(";");

          for(var i=0;i<(files.length-1);i++)
          {
            this.export(files[i]).subscribe(data => saveAs(data, 'Un-Assigned Programmes.xlsx')); 
          }
  
        }
        else
        {
          this.alertService.error(data.message);
        }
        
      } 
    ) 
  }


  async exportABCDMaterial(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/material/export_abcd_material.php`,
    {
      channes: this.selected_channel,
      employee_no: this.employee_no,
      materialData: this.abcd_programme_tv_array

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Material successfully exported');

          var files = data.download_files.split(";");

          for(var i=0;i<(files.length-1);i++)
          {
            this.export(files[i]).subscribe(data => saveAs(data, 'Material Report.xlsx')); 
          }
  
        }
        else
        {
          this.alertService.error(data.message);
        }
        
      } 
    ) 
  }

  export(name) {
    return this.httpClient.get(`https://inxprime.mmsafrica.co.za/api/schedule/material/download_file.php?file_name=${name}`, 
      {responseType: 'blob'});
  }

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channel,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
            this.selected_channel = data[i].sare_no;
        }
      } 
    ) 
  }

  back(){
    this._location.back(); 
  }

}