import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {RateSettingActionsService} from '../../services/rate-setting-actions/rate-setting-actions.service';
import {RateSettingModel} from '../../models/rate-setting-model';
import {Router} from '@angular/router';
import {Chart} from 'chart.js';
import {TopDemographicsService} from '../../services/top-demographics/top-demographics.service';
import {RateSettingService} from '../../services/rate-setting/rate-setting.service';

@Component({
  selector: 'app-capture-key-slot-targets',
  templateUrl: './capture-key-slot-targets.component.html',
  styleUrls: ['./capture-key-slot-targets.component.scss']
})
export class CaptureKeySlotTargetsComponent implements OnInit, AfterViewInit {
  private subscription: Subscription;
  daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  hoursByHalfAnHour = [
    {
      startTime: '06:00',
      endTime: '6:29:59 AM'
    },
    {
      startTime: '06:30',
      endTime: '6:59:59 AM'
    },
    {
      startTime: '07:00',
      endTime: '7:29:59 AM'
    },
    {
      startTime: '07:30',
      endTime: '7:59:59 AM'
    },
    {
      startTime: '08:00',
      endTime: '8:29:59 AM'
    },
    {
      startTime: '08:30',
      endTime: '8:59:59 AM'
    },
    {
      startTime: '09:00',
      endTime: '9:29:59 AM'
    },
    {
      startTime: '09:30',
      endTime: '9:59:59 AM'
    },
    {
      startTime: '10:00',
      endTime: '10:29:59 AM'
    },
    {
      startTime: '10:30',
      endTime: '10:59:59 AM'
    },
    {
      startTime: '11:00',
      endTime: '11:29:59 AM'
    },
    {
      startTime: '11:30',
      endTime: '11:59:59 AM'
    },
    {
      startTime: '12:00',
      endTime: '12:29:59 PM'
    },
    {
      startTime: '12:30',
      endTime: '12:59:59 PM'
    },
    {
      startTime: '13:00',
      endTime: '13:29:59 PM'
    },
    {
      startTime: '13:30',
      endTime: '13:59:59 PM'
    },
    {
      startTime: '14:00',
      endTime: '2:59:59 PM'
    },
    {
      startTime: '14:30',
      endTime: '2:59:59 PM'
    },
    {
      startTime: '15:00',
      endTime: '3:59:59 PM'
    },
    {
      startTime: '15:30',
      endTime: '3:59:59 PM'
    },
    {
      startTime: '16:00',
      endTime: '4:59:59 PM'
    },
    {
      startTime: '16:30',
      endTime: '4:59:59 PM'
    },
    {
      startTime: '17:00',
      endTime: '5:59:59 PM'
    },
    {
      startTime: '17:30',
      endTime: '5:59:59 PM'
    },
    {
      startTime: '18:00',
      endTime: '6:59:59 PM'
    },
    {
      startTime: '18:30',
      endTime: '6:59:59 PM'
    },
    {
      startTime: '19:00',
      endTime: '7:59:59 PM'
    },
    {
      startTime: '19:30',
      endTime: '7:59:59 PM'
    },
    {
      startTime: '20:00',
      endTime: '8:59:59 PM'
    },
    {
      startTime: '20:30',
      endTime: '8:59:59 PM'
    },
    {
      startTime: '21:00',
      endTime: '9:59:59 PM'
    },
    {
      startTime: '21:30',
      endTime: '9:59:59 PM'
    },
    {
      startTime: '22:00',
      endTime: '10:59:59 PM'
    },
    {
      startTime: '22:30',
      endTime: '10:59:59 PM'
    },
    {
      startTime: '23:00',
      endTime: '11:59:59 PM'
    },
    {
      startTime: '23:30',
      endTime: '11:59:59 PM'
    },
    {
      startTime: '00:00',
      endTime: '12:59:59 AM'
    },
  ];
  keySlotFormDialogGroup: FormGroup;
  rateSettingMonth: any;
  rateSettingModel: RateSettingModel;
  demographics: any[];
  chart: any;
  dayHourTotals: any[];
  step = 0;
  @ViewChild('canvas') private chartRef;
  colours = ['#6917c2', '#C71585', '#F09423', '#043BC4', '#240B58'];
  displayedColumns: string[] = ['startTime', 'target', 'revTarget', 'weeklyTargets', 'minatage', 'minNetRatePerSlot', 'minGrossPerSlot'];

  constructor(private rateSettingActionsService: RateSettingActionsService, private _formBuilder: FormBuilder, private router: Router,
              private topDemographicsService: TopDemographicsService, private rateSettingService: RateSettingService) {
  }

  ngOnInit() {
    const data = this.rateSettingActionsService.getRateSettingMon();
    if (data) {
      this.rateSettingMonth = data;
      this.rateSettingModel = data.rateSettingModel;
      this.keySlotFormDialogGroup = this._formBuilder.group({
        dummy: [0],
      });
      this.dayHourTotals = [];
      this.rateSettingService.getDemographicPercentages().subscribe(demographicPercentages => {
        this.daysOfWeek.forEach(day => {
          const length = this.rateSettingMonth.month[day.toLowerCase() + 'KeySlots'].length;
          const percentagesForDay = demographicPercentages[0].dataset.filter(percentageList => {
            return percentageList.day === day;
          });
          this.hoursByHalfAnHour.forEach(hour => {
            this.keySlotFormDialogGroup.addControl(day + '-target-' + hour.startTime, new FormControl(0));
            if (length === 0) {
              const percentageForHour = percentagesForDay.find(percentage => {
                return percentage.start_time.toString().slice(0, 4) === hour.startTime.toString().slice(0, 4);
              });
              this.rateSettingMonth.month[day.toLowerCase() + 'KeySlots'].push({
                startTime: hour.startTime,
                target: percentageForHour && parseFloat(percentageForHour.percent) || 0,
                revTarget: 0,
                weeklyTargetSlots: 0,
                minNetRatePerSlot: 0,
                minGrossPerSlot: 0
              });
            }

          });
          this.dayHourTotals.push({day: day, hourTotal: 0});
        });

      });

      this.keySlotFormDialogGroup.setValidators([this.maxMonPercentage(),
        this.maxTuePercentage(),
        this.maxWedPercentage(),
        this.maxThuPercentage(),
        this.maxFriPercentage(),
        this.maxSatPercentage(),
        this.maxSunPercentage()]);
    }
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  ngAfterViewInit() {
    this.topDemographicsService.getTop5Demographics(this.rateSettingModel.channels[0].channelId).subscribe(demoG => {
      this.demographics = demoG;
      this.daysOfWeek.forEach((day, index) => {
        this.createChart(index, day);
      });
    });
  }

  createChart(indexOfDay, day) {
    const dataSet = this.demographics.map((demoG, index) => {
      return {
        data: demoG.dataset.filter(demo => {
          return demo.day === day;
        }).map(data => data.rating), label: demoG.name, fill: false, borderColor: this.colours[index]
      };
    });
    const labels = this.hoursByHalfAnHour.map(hour => {
      return hour.startTime;
    });
    const canvas = <HTMLCanvasElement>document.getElementById('canvas' + indexOfDay);
    const ctx = canvas.getContext('2d');
    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: dataSet
      },
      options: {
        legend: {
          display: true
        },
        scales: {
          xAxes: [{
            display: true
          }],
          yAxes: [{
            display: true
          }],
        }
      }
    });
  }

  mapDayHourTotal(day, total, totalRand) {
    this.dayHourTotals.forEach(dayTotal => {
      if (dayTotal.day === day) {
        dayTotal.hourTotal = total;
        dayTotal.randTotal = totalRand;
      }
    });
  }

  calculateRemainder() {
    this.daysOfWeek.forEach(day => {
      let totalEnteredPercentage = 0;
      const hoursWithoutPercentage = this.rateSettingMonth.month[day.toLowerCase() + 'KeySlots'].filter(dayValue => {
        return dayValue.target === 0;
      });
      const hoursWithPercentage = this.rateSettingMonth.month[day.toLowerCase() + 'KeySlots'].filter(dayValue => {
        return dayValue.target !== 0;
      });
      hoursWithPercentage.forEach(hr => {
        totalEnteredPercentage += hr.target;
      });
      this.rateSettingMonth.month[day.toLowerCase() + 'KeySlots'].forEach(dayValue => {
        let target = this.hoursByHalfAnHour.length;
        if (dayValue.target === 0) {
          if (hoursWithoutPercentage.length > 0) {
            target = this.calculateRemainderWithDecimal(totalEnteredPercentage, hoursWithoutPercentage);
          }
          dayValue.target = target;
          dayValue.revTarget = this.rateSettingMonth.month[day.toLowerCase() + 'RandValue'] * (dayValue.target / 100);
          dayValue.weeklyTargetSlots = dayValue.revTarget / 4;
          dayValue.minNetRatePerSlot = this.roundOffNumber((dayValue.weeklyTargetSlots /
            ((this.rateSettingModel.advMinPerHour * this.rateSettingModel.sellout)
              / (1 + this.rateSettingModel.addedValue))));
          dayValue.minGrossPerSlot = this.roundOffNumber(dayValue.minNetRatePerSlot / this.rateSettingModel.lessDiscount);
        }
      });
    });
  }

  setRemainder(day) {
    const hoursWithoutPercentage = this.hoursByHalfAnHour.filter(hour => {
      return this.keySlotFormDialogGroup.controls[day + '-target-' + hour.startTime].value === 0;
    });

    const hoursWithPercentage = this.hoursByHalfAnHour.filter(hour => {
      return this.keySlotFormDialogGroup.controls[day + '-target-' + hour.startTime].value !== 0;
    });

    let totalEntered = 0;
    hoursWithPercentage.forEach(hour => {
      totalEntered += this.keySlotFormDialogGroup.controls[day + '-target-' + hour.startTime].value;
    });

    hoursWithoutPercentage.forEach(hour => {
      this.keySlotFormDialogGroup.controls[day + '-target-' + hour.startTime]
        .setValue(this.calculateRemainderWithDecimal(totalEntered, hoursWithoutPercentage));
    });
    let totalSelected = 0, totalRand = 0;
    this.hoursByHalfAnHour.forEach(hour => {
      totalSelected += this.keySlotFormDialogGroup.controls[day + '-target-' + hour.startTime].value;
      totalRand += this.rateSettingMonth.month[day.toLowerCase() + 'RandValue'] *
        (this.keySlotFormDialogGroup.controls[day + '-target-' + hour.startTime].value / 100);
    });
    this.mapDayHourTotal(day, totalSelected, totalRand);
  }

  calculateRemainderWithDecimal(totalEntered, hoursWithoutPercentage) {
    const val = (100 - totalEntered) / hoursWithoutPercentage.length;
    return this.roundOffNumber(val);
  }

  roundOffNumber(val) {
    if (val % 1 !== 0) {
      return parseFloat((Math.floor(val * 100) / 100).toFixed(1));
    } else {
      return val;
    }
  }

  matchResultToModel(selectedMonth) {
    this.rateSettingModel.months.forEach(month => {
      if (month.month === selectedMonth.month) {
        month.mondayKeySlots = selectedMonth.mondayKeySlots;
        month.tuesdayKeySlots = selectedMonth.tuesdayKeySlots;
        month.wednesdayKeySlots = selectedMonth.wednesdayKeySlots;
        month.thursdayKeySlots = selectedMonth.thursdayKeySlots;
        month.fridayKeySlots = selectedMonth.fridayKeySlots;
        month.saturdayKeySlots = selectedMonth.saturdayKeySlots;
        month.sundayKeySlots = selectedMonth.sundayKeySlots;
        month = selectedMonth;
      }
    });
  }

  saveChanges() {
    if (this.keySlotFormDialogGroup.valid) {
      this.daysOfWeek.forEach(day => {
        this.hoursByHalfAnHour.forEach(hour => {
          this.rateSettingMonth.month[day.toLowerCase() + 'KeySlots'].forEach(slot => {
            slot.target = this.roundOffNumber(slot.target);
            slot.revTarget = this.rateSettingMonth.month[day.toLowerCase() + 'RandValue'] * (slot.target / 100);
            slot.weeklyTargetSlots = slot.revTarget / 4;
            slot.minNetRatePerSlot = this.roundOffNumber(slot.weeklyTargetSlots /
              ((this.rateSettingModel.advMinPerHour * this.rateSettingModel.sellout)
                / (1 + this.rateSettingModel.addedValue)));
            slot.minGrossPerSlot = this.roundOffNumber(slot.minNetRatePerSlot / this.rateSettingModel.lessDiscount);
          });
        });
      });
      this.calculateRemainder();

      this.matchResultToModel(this.rateSettingMonth.month);
      this.rateSettingActionsService.setRateSettingMonth({month: this.rateSettingMonth.month, rateSettingModel: this.rateSettingModel});
      this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
      this.router.navigate(['home/createRateSettingTool/captureBaseRatings']);
    }
  }

  back() {
    this.rateSettingActionsService.setRateSettingModel(this.rateSettingModel);
    this.router.navigate(['home/createRateSettingTool/keySlotTargets']);
  }

  maxMonPercentage(max = 102) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        if (formGroup.controls['Monday-target-' + hour.startTime]) {
          totalSelected += formGroup.controls['Monday-target-' + hour.startTime].value;
          totalRand += this.rateSettingMonth.month['mondayRandValue'] * (formGroup.controls['Monday-target-' + hour.startTime].value / 100);
        }
      });
      totalSelected = this.roundOffNumber(totalSelected);
      this.mapDayHourTotal('Monday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxMondayPercentage: true};
    };
    return validator;
  }

  maxTuePercentage(max = 102) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        if (formGroup.controls['Tuesday-target-' + hour.startTime]) {
          totalSelected += formGroup.controls['Tuesday-target-' + hour.startTime].value;
          totalRand += this.rateSettingMonth.month['tuesdayRandValue'] *
            (formGroup.controls['Tuesday-target-' + hour.startTime].value / 100);
        }
      });
      this.mapDayHourTotal('Tuesday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxTuesdayPercentage: true};
    };

    return validator;
  }

  maxWedPercentage(max = 102) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        if (formGroup.controls['Wednesday-target-' + hour.startTime]) {
          totalSelected += formGroup.controls['Wednesday-target-' + hour.startTime].value;
          totalRand += this.rateSettingMonth.month['wednesdayRandValue'] *
            (formGroup.controls['Wednesday-target-' + hour.startTime].value / 100);
        }
      });
      this.mapDayHourTotal('Wednesday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxWednesdayPercentage: true};
    };
    return validator;
  }

  maxThuPercentage(max = 102) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        if (formGroup.controls['Thursday-target-' + hour.startTime]) {
          totalSelected += formGroup.controls['Thursday-target-' + hour.startTime].value;
          totalRand += this.rateSettingMonth.month['thursdayRandValue'] *
            (formGroup.controls['Thursday-target-' + hour.startTime].value / 100);
        }
      });
      this.mapDayHourTotal('Thursday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxThursdayPercentage: true};
    };
    return validator;
  }

  maxFriPercentage(max = 102) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        if (formGroup.controls['Friday-target-' + hour.startTime]) {
          totalSelected += formGroup.controls['Friday-target-' + hour.startTime].value;
          totalRand += this.rateSettingMonth.month['fridayRandValue'] * (formGroup.controls['Friday-target-' + hour.startTime].value / 100);
        }
      });
      this.mapDayHourTotal('Friday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxFridayPercentage: true};
    };
    return validator;
  }

  maxSatPercentage(max = 102) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        if (formGroup.controls['Saturday-target-' + hour.startTime]) {
          totalSelected += formGroup.controls['Saturday-target-' + hour.startTime].value;
          totalRand += this.rateSettingMonth.month['saturdayRandValue'] *
            (formGroup.controls['Saturday-target-' + hour.startTime].value / 100);
        }
      });
      this.mapDayHourTotal('Saturday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxSaturdayPercentage: true};
    };
    return validator;
  }

  maxSunPercentage(max = 102) {
    const validator: ValidatorFn = (formGroup: FormGroup) => {
      let totalSelected = 0, totalRand = 0;
      this.hoursByHalfAnHour.forEach(hour => {
        if (formGroup.controls['Sunday-target-' + hour.startTime]) {
          totalSelected += formGroup.controls['Sunday-target-' + hour.startTime].value;
          totalRand += this.rateSettingMonth.month['sundayRandValue'] * (formGroup.controls['Sunday-target-' + hour.startTime].value / 100);
        }
      });
      this.mapDayHourTotal('Sunday', totalSelected, totalRand);
      return totalSelected <= max ? null : {maxSundayPercentage: true};
    };
    return validator;
  }

}
