export class SponsorshipElementModel {
  elementId: number;
  element: string;
  elementType: string;
  indexValue: number;
  sponsorshipId: number;
  duration: number;
  rateCardId: string;
  rate: number;
  breakScheduledDate: string;
  day: string;
  rateCardTotal: number;
  sponsorshipCost: number;
  discount: number;

  constructor(_element = '', elementType = '', indexValue = 0, _duration = 0, _rateCardId = '', _rate = 0, _breakScheduledDate = '',
              _rateCalculation = 1, discount = 0) {
    this.element = _element;
    this.elementType = elementType;
    this.indexValue = indexValue;
    this.duration = _duration;
    this.rateCardId = _rateCardId;
    this.rate = _rate;
    this.breakScheduledDate = _breakScheduledDate;
    this.rateCardTotal = _rate * _rateCalculation;
    this.discount = discount;
    this.sponsorshipCost = this.rateCardTotal * ((100 - this.discount) / 100);

  }
}
