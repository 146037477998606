import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
import * as currencies from '../../commom/currency.json';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { ClientClass } from 'src/app/classes/client';
import { SetupClass } from 'src/app/classes/setup';

@Component({
  selector: 'app-view-advertiser',
  templateUrl: './view-advertiser.component.html',
  styleUrls: ['./view-advertiser.component.scss'],
})
export class ViewAdvertiserComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  cur_obj: any = (currencies as any).default;
  setupClass:SetupClass = new SetupClass();
  client:ClientClass = new ClientClass();
  same_address = true;
  currency: string[] = [];
  display_array: ClientClass[] = [];
  client_id: number = 0;

  selectedFile: FileList  = null; 
  formData = new FormData();
  clientFormGroup: FormGroup;

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

    this.client_id = parseInt(JSON.parse(localStorage.getItem('client_id')));
    this.onReadAgencies();
    this.onReadOneClients();
  }
 
  ngOnInit() {
   
    this.client.late_fee_tax_number = '';
	  this.client.late_fee_tax_name = '';
	  this.client.late_fee_tax_rate = 0;
	  this.client.late_fee_days = 0;
	  this.client.late_fee_value = 0;
	  this.client.late_fee_option = 'percentage';
	  this.client.late_fee_percentage = 0;
	  this.client.currency = 'ZAR - South African rand';
	  this.client.rem_position = 'After due date';
    this.client.rem_days = 0;
    this.client.agencyId = 0;

    this.client.ship_address = '';
    this.client.ship_city = '';
    this.client.ship_state = '';
    this.client.ship_zip_code = '';


    this.clientFormGroup = this.formBuilder.group({
      companyCtrl: [this.client.company, Validators.required],
      first_nameCtrl: [this.client.first_name, Validators.required],
      last_nameCtrl: [this.client.last_name],
      emailCtrl: [this.client.email, Validators.required],
      phoneCtrl: [this.client.phone, Validators.required],
      addressCtrl: [this.client.address, Validators.required],
      cityCtrl: [this.client.city,Validators.required],
      stateCtrl: [this.client.state,Validators.required],
      zip_codeCtrl: [this.client.zip_code,Validators.required],
      currencyCtrl: [this.client.currency,Validators.required],
      vat_numberCtrl: [this.client.vat_number],
      agencyIdCtrl: [this.client.agencyId],
      same_addressCtrl: [this.same_address],
    });

    for(var i=0;i<this.cur_obj.length;i++)
    {
        this.currency[i] = this.cur_obj[i].cc+' - '+this.cur_obj[i].name;
    }
    
  }


  async onReadAgencies()
  {

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/sales/agency/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.display_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: data[i].agencyId,agency: ''};
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  async onReadOneClients()
  {

    const response = this.httpClient.post(`https://inxprime.mmsafrica.co.za/api/sales/client/filter_clients.php`,
    {
        employee_no: 0,
        clientId: this.client_id,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {

        for(var i=0;i<data.length;i++)
        {
            this.client.employee_no = data[i].employee_no;
            this.client.idNo = data[i].idNo;
            this.client.company = data[i].company;
            this.client.first_name = data[i].first_name;
            this.client.last_name = data[i].last_name;
            this.client.email = data[i].email;
            this.client.phone = data[i].phone;
            this.client.address = data[i].address;
            this.client.city = data[i].city;
            this.client.state = data[i].state;
            this.client.zip_code = data[i].zip_code;
            this.client.ship_address = data[i].ship_address;
            this.client.ship_city = data[i].ship_city;
            this.client.ship_state = data[i].ship_state;
            this.client.ship_zip_code = data[i].ship_zip_code;
            this.client.tax_number = data[i].tax_number;
            this.client.vat_number = data[i].vat_number;
            this.client.currency = data[i].currency;
            this.client.rem_days = data[i].rem_days;
            this.client.rem_position = data[i].rem_position;
            this.client.late_fee_percentage = data[i].late_fee_percentage;
            this.client.late_fee_option = data[i].late_fee_option;
            this.client.late_fee_value = data[i].late_fee_value;
            this.client.late_fee_days = data[i].late_fee_days;
            this.client.late_fee_tax_rate = data[i].late_fee_tax_rate;
            this.client.late_fee_tax_name = data[i].late_fee_tax_name;
            this.client.late_fee_tax_number = data[i].late_fee_tax_number;
            this.client.agencyId = data[i].agencyId;
            this.client.company_logo = data[i].company_logo;
        }

      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  async saveclient(){

    if(this.client.tax_number == null){
      this.client.tax_number = " ";
    }

    if(this.client.vat_number == null){
      this.client.vat_number = " ";
    }
    
    this.formData.append('clientId',<any>this.client_id);
    this.formData.append('employee_no',<any>0);
    this.formData.append('company',<any>this.client.company);
    this.formData.append('first_name',<any>this.client.first_name);
    this.formData.append('last_name',<any>this.client.last_name);
    this.formData.append('email',<any>this.client.email);
    this.formData.append('phone',<any>this.client.phone);
    this.formData.append('address',<any>this.client.address);
    this.formData.append('city',<any>this.client.city);
    this.formData.append('state',<any>this.client.state);
    this.formData.append('zip_code',<any>this.client.zip_code);
    this.formData.append('ship_address',<any>this.client.ship_address);
    this.formData.append('ship_city',<any>this.client.ship_city);
    this.formData.append('ship_state',<any>this.client.ship_state);
    this.formData.append('ship_zip_code',<any>this.client.ship_zip_code);
    this.formData.append('vat_number',<any>this.client.vat_number);
    this.formData.append('tax_number',<any>this.client.tax_number);
    this.formData.append('currency',<any>this.client.currency);
    this.formData.append('rem_days',<any>this.client.rem_days);
    this.formData.append('rem_position',<any>this.client.rem_position);
    this.formData.append('late_fee_percentage',<any>this.client.late_fee_percentage);
    this.formData.append('late_fee_option',<any>this.client.late_fee_option);
    this.formData.append('late_fee_value',<any>this.client.late_fee_value);
    this.formData.append('late_fee_days',<any>this.client.late_fee_days);
    this.formData.append('late_fee_tax_rate',<any>this.client.late_fee_tax_rate);
    this.formData.append('late_fee_tax_name',<any>this.client.late_fee_tax_name);
    this.formData.append('late_fee_tax_number',<any>this.client.late_fee_tax_number);
    this.formData.append('agencyId',<any>this.client.agencyId);
    this.formData.append('type','client');

    this.httpClient.post('https://inxprime.mmsafrica.co.za/api/sales/client/update_client.php',this.formData)
    .subscribe((message:any) => {

      if(message.message == 'success')
      {
        this.client = new ClientClass();
        this.alertService.success('Advertiser successfully saved');
        this.router.navigate(['/home/advertisers-list']);
        this.clientFormGroup.reset();
      }
      else
      { 
        this.alertService.error(message.message);
      }

    },error => {
      this.alertService.error("Something went wrong, Try again");
    });
  }


  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}