import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {User} from '../../models/user';
import {CampaignModel} from '../../models/campaign.model';
import {CampaignSpotsModel} from '../../models/campaignSpots.model';
import {first} from 'rxjs/internal/operators';
import {environment} from '../../../environments/environment';
import {AdvertiserModel} from '../../models/advertiser.model';
import {AgencyModel} from '../../models/agency.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  currentUser: User;
  campaigns: CampaignModel[] = [];

  constructor(private http: HttpClient) {
  }

  postCampaignHeader(campaignHeader): Observable<any> {
    return this.http.post<any>(environment.serverUrl + `save_sponsorship.php`, campaignHeader).pipe(map(repsonse => {
      return repsonse;
    }));
  }

  getCampaignsFromRemote(): Observable<any> {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return this.http.get<any>(environment.serverUrl + 'get_dashboard.php?userId=' + this.currentUser.userId + '&type=campaign')
      .pipe(map((campaignData => {
        if (campaignData && campaignData.length > 0) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const mappedCampaigns = this.campaignMapper(campaignData);
          localStorage.setItem('campaigns', JSON.stringify(mappedCampaigns));
        }
        return this.campaignMapper(campaignData);
      })));
  }

  getCampaignsFromLocal(): CampaignModel[] {
    return JSON.parse(localStorage.getItem('campaigns'));
  }

  getCampaigns(): Observable<CampaignModel[]> {
    const campaigns = this.getCampaignsFromLocal();
    if (!campaigns) {
      // this.getCampaignsMockData().subscribe(campaignsData => campaigns = campaignsData);

      // uncomment when using API
      return this.getCampaignsFromRemote();
    }

    return of(campaigns);
  }

  getCampaignsMockData(): Observable<CampaignModel[]> {
    const mockCampaignData = [
      {
        ref_no: 370,
        advertiser: 'Cell C',
        product: 'Music Album',
        spots: 100,
        duration: 5,
        start_date: '08/27/2018 12:00 AM',
        end_date: '09/15/2018 12:00 AM',
        account_exc: 1, broadcaster: 'VND',
        ratecard_cost: 0.00, copyweeks: 'Yes',
        status: 'Sent'
      },
      {
        ref_no: 371,
        advertiser: 'Cell C',
        product: 'Music Album',
        spots: 226,
        duration: 5,
        start_date: '08/26/2018 12:00 AM',
        end_date: '09/29/2018 12:00 AM',
        account_exc: 1,
        broadcaster: 'VND',
        ratecard_cost: 0.00,
        copyweeks: 'Yes',
        status: 'Pending'
      },
      {
        ref_no: 372,
        advertiser: 'Cell C',
        product: 'Music Album',
        spots: 0,
        duration: 5,
        start_date: '08/26/2018 12:00 AM',
        end_date: '09/29/2018 12:00 AM',
        account_exc: 1,
        broadcaster: 'VND',
        ratecard_cost: 0.00,
        copyweeks: 'Yes',
        status: 'Pending'
      },
      {
        ref_no: 373,
        advertiser: 'Cell C',
        product: 'Music Album',
        spots: 237,
        duration: 5,
        start_date: '08/26/2018 12:00 AM',
        end_date: '09/29/2018 12:00 AM',
        account_exc: 1,
        broadcaster: 'VND',
        ratecard_cost: 0.00,
        copyweeks: 'Yes',
        status: 'Sent'
      }];

    this.campaigns = this.campaignMapper(mockCampaignData);
    localStorage.setItem('campaigns', JSON.stringify(this.campaigns));
    return of(this.campaigns);
  }

  private campaignMapper(campaignData): CampaignModel[] {
    let MappedCampaigns: CampaignModel[] = [];
    campaignData.forEach(function (campaign) {
      MappedCampaigns.push({
        campaignId: campaign.camp_no,
        advertiser: campaign.advertiser_name,
        agency: campaign.agency,
        agencyId: campaign.agency_code,
        advertiserId: campaign.advertiser_code,
        agencyModel: new AgencyModel(campaign.agency, campaign.agency_code),
        advertiserModel: new AdvertiserModel(campaign.advertiser, campaign.advertiser_code),
        productId: campaign.prod_code,
        product: campaign.product_name,
        spots: [],
        numberOfSpots: campaign.no_of_spots,
        start_date: new Date(campaign.sst_date),
        end_date: new Date(campaign.end_date),
        userId: campaign.account_exc,
        status: campaign.status,
        totalNominalPrice: campaign.total_nominal_price
      });
    });
    return MappedCampaigns;
  }

}
