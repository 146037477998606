import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-channel-selector',
  templateUrl: './channel-selector.component.html',
  styleUrls: ['./channel-selector.component.scss']
})
export class ChannelSelectorComponent implements OnInit {
  @Input() channelFormGroup: FormGroup;
  @Input() channels: any[];
  constructor() { }

  ngOnInit() {

  }

}
