export class BreakClass{
    break_id: number;
    sare_no: number;
    sare_name: string;
    break_date: string;
    start_time: String;
    end_time: string;
    length: number;
    availability: number;
    break_target: number;
    actual_value: number;
    break_type: string;
    rcrd_no: string;
    prog_name: string;
    prog_no: number;
    season: number;
    episode: number;
    run_no: number;
    exhibition: number;
    topDemographic: string;
    breakPrice: string;
    ratting: number;
    daily_rch: number;
} 