import {Injectable} from '@angular/core';
import {AudienceGuaranteedModel} from '../../models/audience-guaranteed.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {User} from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class AudienceGuaranteedTradingService {
  audienceGuaranteedModel: AudienceGuaranteedModel;  
  currentUser: User;

  constructor(private http: HttpClient) {
  }

  confirmAudienceGuaranteedCreation(audienceGuaranteedModel: AudienceGuaranteedModel): Observable<any> {
    return this.postAudienceGuaranteed(audienceGuaranteedModel);
  }

  postAudienceGuaranteed(audienceGuaranteedModel: AudienceGuaranteedModel): Observable<any> {
    
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    audienceGuaranteedModel.userId = this.currentUser.userId;
    if (audienceGuaranteedModel.tradingModel === 'Fixed spots') {
      return this.http.post<any>(environment.serverUrl + `save_fixed_spots.php`, audienceGuaranteedModel).pipe(map(data => {
        audienceGuaranteedModel.campaignId = data.camp_no;
        audienceGuaranteedModel.spots = data.saved_spots;
        audienceGuaranteedModel.totalCost = data.total_cost;

        localStorage.removeItem('createdAudienceGuaranteed');
        localStorage.setItem('createdAudienceGuaranteed', JSON.stringify(audienceGuaranteedModel));

        return audienceGuaranteedModel;
      }));

    } else {
      return this.http.post<any>(environment.serverUrl + `save_audience_guaranteed.php`, audienceGuaranteedModel).pipe(map(data => {
        audienceGuaranteedModel.campaignId = data.camp_no;
        audienceGuaranteedModel.spots = data.saved_spots;
        audienceGuaranteedModel.totalCost = data.budget;

        localStorage.removeItem('createdAudienceGuaranteed');
        localStorage.setItem('createdAudienceGuaranteed', JSON.stringify(audienceGuaranteedModel));

        return audienceGuaranteedModel;
      }));
    }
  }
}
