export class ProgrammeClass{
    prog_no: number;
    prog_name: String;
    language: number;
    prod_house: String;
    length: number;
    producer: number;
    image: String;
    genre: String;
    bdcr_no: number;
    sare_no: number;
    rcrd_no: String;
    repeat_name: String;
    type: String;
    subgenre: String;
    category: String;
    genre_sentech: string;
    subgenre_sentech: string;
    contract_type: String;
    contract_no: string;
    distributors: string;
    season_data: any[];
}
